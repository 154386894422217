import { DataTableColumns } from '@components/common/table';

export enum ETeamTableTab {
  All = 'ALL',
}

export type TeamTableTabsCounter = {
  [name in ETeamTableTab]?: number;
};

export enum ETeamTableColumn {
  Logo = 'logo',
  Name = 'name',
  ActivityTypes = 'activityTypes',
  Locality = 'locality',
  Captain = 'captain',
}

export const teamsTableColumns: DataTableColumns<ETeamTableColumn> = {
  [ETeamTableColumn.Logo]: {
    width: '2rem',
  },
  [ETeamTableColumn.Name]: {
    title: 'Название',
    width: '15rem',
    wrap: true,
    sortable: true,
  },
  [ETeamTableColumn.ActivityTypes]: {
    title: 'Интересы',
    width: '16rem',
    sortable: true,
    wrap: true,
  },
  [ETeamTableColumn.Locality]: {
    title: 'Населенный пункт',
    sortable: true,
  },
  [ETeamTableColumn.Captain]: {
    title: 'Капитан',
    width: '10rem',
    wrap: true,
  },
};

export const getTeamTableColumns = (): DataTableColumns<ETeamTableColumn> => {
  const { captain, ...columns } = teamsTableColumns;
  return columns;
};

interface GetTeamsTableAdapterKeyResult {
  key: string;
}

export const getTeamsTableAdapterKey = (): GetTeamsTableAdapterKeyResult => {
  return {
    key: 'teams',
  };
};

export const getTeamTableTabName = (tab: ETeamTableTab): string => {
  switch (tab) {
    case ETeamTableTab.All:
      return 'Все команды';
  }
};
