import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Nullable, UUID } from '../../../../../domain/model/types';
import { SportsmanFetchProps } from '../../table/store/slice';
import {
  sportsmanSearchFilterSelector,
  sportsmanSearchGuidSelector,
  sportsmanSearchNeedRefreshWatcherSelector,
  sportsmanSearchPageNumberSelector,
  sportsmanSearchSearchSelector,
} from '../store/selectors';
import { sportsmanSearchNeedRefreshWatcherReset, sportsmansSearchFetch } from '../store/slice';

type UseSportsmansSearchSearchProps = {
  readonly guid: UUID;
  readonly teamId?: UUID;
};

type UseSportsmansSearchQueryParams = Omit<SportsmanFetchProps, 'signal'>;

const useSportsmansSearchSearch = ({ guid, teamId }: UseSportsmansSearchSearchProps): void => {
  const dispatch = useDispatch();

  const [searchPromises, setSearchPromises] = useState<Nullable<any[]>>(null);

  const needRefreshWatcher = useSelector(sportsmanSearchNeedRefreshWatcherSelector);
  const pageNumber = useSelector(sportsmanSearchPageNumberSelector);
  const currentGuid = useSelector(sportsmanSearchGuidSelector);
  const search = useSelector(sportsmanSearchSearchSelector);
  const filter = useSelector(sportsmanSearchFilterSelector);

  const queryParams = useMemo<UseSportsmansSearchQueryParams>(() => {
    return {
      filter,
      search,
      pageNumber,
      teamId,
    };
  }, [filter, search, pageNumber]);

  const onSearch = useCallback(() => {
    setSearchPromises([dispatch(sportsmansSearchFetch(queryParams))]);
  }, [dispatch, queryParams]);

  // начальная загрузка
  useEffect(() => {
    // Добавляем проверку на null если мы закрываем диалоговое окно это предотвращает лишний запрос
    if (guid === currentGuid && guid !== null) {
      searchPromises?.map(promise => promise.abort());
      onSearch();

      return () => {
        searchPromises?.map(promise => promise.abort());
      };
    }
  }, [dispatch, guid, currentGuid]);

  // необходимость обновить данные
  useEffect(() => {
    if (needRefreshWatcher > 0) {
      searchPromises?.map(promise => promise.abort());
      onSearch();

      return () => {
        searchPromises?.map(promise => promise.abort());
      };
    }
  }, [dispatch, needRefreshWatcher]);

  // сброс вотчера после ухода
  useEffect(() => {
    return () => {
      dispatch(sportsmanSearchNeedRefreshWatcherReset());
    };
  }, [dispatch]);
};

export default useSportsmansSearchSearch;
