import axios, { AxiosResponse } from 'axios';
import { v4 as uuidv4, v4 as uuid } from 'uuid';
import {
  Nullable,
  UUID,
  SportOption,
  AddressPosition,
  EPresenceStatus,
  ESportEventLevel,
  ESportEventParticipationFormat,
  ESportEventStatus,
  ESportEventType,
  ETournamentApplicationStatuses,
  ETournamentIndividualActionDiscriminator,
  ETournamentIndividualApplicationActionDiscriminator,
  ActivityEvent,
  ActivityEventRequest,
  ActivityType,
  FriendlyMatch,
  FriendlyMatchResults,
  SportEvent,
  SportEventShort,
  SportsFest,
  SportsFestCombinedTeam,
  SportsFestPublishedRequest,
  SportsFestRequest,
  SportsFestResultsRequest,
  Tournament,
  TournamentApplication,
  TournamentIndividual,
  TournamentIndividualApplication,
  TournamentIndividualMemberListView,
  TournamentIndividualPublishedRequest,
  TournamentIndividualRequest,
  TournamentIndividualResultsRequest,
  TournamentPublishedRequest,
  TournamentRequest,
  TournamentResultsRequest,
  TournamentTeam,
} from '@/domain';
import { createCancelToken } from './index';
import {
  ApiCancellable,
  ApiRequestListDiscriminator,
  ApiRequestPageable,
  ApiResponseWithPageable,
  ApiSportResponseMaBeCounters,
} from './types';
import { getSportBaseEndpoint } from './utils';

export type AllProps<D extends ApiRequestListDiscriminator> = ApiRequestPageable &
  ApiCancellable & {
    readonly name?: Nullable<string>;
    readonly addressObjectId?: Nullable<UUID>;
    readonly activityTypes?: Nullable<ActivityType[]>;
    readonly eventTypes?: Nullable<ESportEventType[]>;
    readonly eventLevels?: Nullable<ESportEventLevel[]>;
    readonly statuses?: Nullable<ESportEventStatus[]>;
    readonly participationFormat?: Nullable<ESportEventParticipationFormat[]>;
    readonly roads?: Nullable<UUID[]>;
    readonly userId?: Nullable<UUID>;
    readonly admin?: Nullable<SportOption>;
    readonly playgroundId?: Nullable<UUID>;
    readonly teamId?: Nullable<UUID>;
    readonly scheduledDateFrom?: Nullable<string>;
    readonly scheduledDateTo?: Nullable<string>;
    readonly discriminator?: D;
  };

type OneProps = ApiCancellable & {
  readonly id: UUID;
};

export type EventOneProps = ApiCancellable & {
  readonly id: UUID;
};

type TournamentCreateProps = {
  readonly data: TournamentRequest | TournamentPublishedRequest;
};

type TournamentSaveProps = {
  readonly id: UUID;
  readonly data: TournamentRequest | TournamentPublishedRequest;
};

type TournamentSaveUnpublishedProps = {
  readonly data: TournamentRequest;
};

type ActivityEventCreateProps = {
  readonly data: ActivityEventRequest;
};

type ActivityEventSaveProps = {
  readonly id: UUID;
  readonly data: ActivityEventRequest;
};

type ActivityEventSaveUnpublishedProps = {
  readonly data: ActivityEventRequest;
};

type ActivityEventPublishProps = {
  readonly id: UUID;
};

type SportsFestSaveUnpublishedProps = {
  readonly data: SportsFestRequest;
};

type SportsFestSaveProps = {
  readonly id: UUID;
  readonly data: SportsFestRequest | SportsFestPublishedRequest;
};

type SportsFestCombinedTeamsOneProps = ApiRequestPageable &
  ApiCancellable & {
    readonly id: UUID;
  };

type SportsFestCreateProps = {
  readonly data: SportsFestRequest | SportsFestPublishedRequest;
};

type SportsFestPublishProps = {
  readonly id: UUID;
};

type TournamentPublishProps = {
  readonly id: UUID;
};

export type TournamentSaveResultsProps = {
  readonly id: UUID;
  readonly results: TournamentResultsRequest;
};

export type TournamentPublishResultsProps = {
  readonly id: UUID;
};

export type TournamentApplicationsProps = ApiRequestPageable &
  ApiCancellable & {
    readonly id: UUID;
  };

type TournamentTeamsProps = ApiRequestPageable &
  ApiCancellable & {
    readonly id: UUID;
  };

type TournamentInviteTeamsProps = {
  readonly id: UUID;
  readonly teams: { id: UUID }[];
};

type SportsFestSaveResultsProps = {
  readonly id: UUID;
  readonly results: SportsFestResultsRequest;
};

type SportsFestPublishResultsProps = {
  readonly id: UUID;
};

type FriendlyMatchSaveProps = FriendlyMatch;

type FriendlyMatchSetResultsProps = {
  readonly friendlyMatchResults: FriendlyMatchResults;
  readonly id: UUID;
};

export type TournamentApplicationAcceptProps = {
  readonly applicationId: UUID;
  readonly tournamentId: UUID;
};

export type TournamentApplicationRejectProps = {
  readonly applicationId: UUID;
  readonly tournamentId: UUID;
  readonly message?: string;
};

type TeamMember = { id: UUID };

export type TournamentApplicationCreateProps = {
  readonly tournamentId: UUID;
  readonly teamMembers: TeamMember[];
  readonly teamId: UUID;
};

export interface EventApplicationIdState {
  readonly id: UUID;
  readonly eventId: UUID;
}

type InviteUser = {
  readonly id: UUID;
};

type TournamentIndividualCreateApplicationProps = ApiCancellable & {
  readonly tournamentId: UUID;
  readonly invitedUsers: InviteUser[];
};

export type TournamentIndividualMembersProps = ApiRequestPageable &
  ApiCancellable & {
    readonly id: UUID;
  };

export type TournamentIndividualCreateProps = {
  readonly data: TournamentIndividualRequest | TournamentIndividualPublishedRequest;
};

export type TournamentIndividualUpdateDraftProps = {
  readonly id: UUID;
  readonly data: TournamentIndividualRequest | TournamentIndividualPublishedRequest;
};

export type TournamentIndividualUpdateAnnounceProps = {
  readonly id: UUID;
  readonly data: TournamentIndividualRequest | TournamentIndividualPublishedRequest;
};

export type TournamentIndividualUpdatePublishProps = {
  readonly id: UUID;
  readonly data: TournamentIndividualPublishedRequest;
};

export type TournamentIndividualCancelApplicationsProps = ApiCancellable & {
  readonly appId: UUID;
  readonly tournamentId: UUID;
};

export type TournamentIndividualPresenceConfirmedProps = ApiCancellable & {
  readonly tournamentId: UUID;
  readonly position: AddressPosition;
};

export type ApplicationsProps = ApiCancellable &
  ApiRequestPageable & {
    readonly tournamentId: UUID;
    readonly userId?: UUID;
    readonly statuses?: ETournamentApplicationStatuses[];
  };

export type ApplicationProps = ApiCancellable & {
  readonly tournamentId: UUID;
  readonly appId: UUID;
};

export type PageTournamentIndividualApplication = ApiResponseWithPageable<TournamentIndividualApplication> & {
  readonly size: number;
  readonly first: boolean;
  readonly last: boolean;
};

type AloneIdProps = ApiCancellable & {
  readonly id: UUID;
};

/** Анонсировать индивидуальный турнир */
export type TournamentIndividualAnnounceCommand = {
  readonly discriminator: ETournamentIndividualActionDiscriminator.AnnounceTournamentIndividualCommand;
};

/** Запрос на обновление неопубликованного (в неопубликованном статусе) турнира */
export type TournamentIndividualDraftUpdateCommand = TournamentIndividualUpdateDraftProps & {
  readonly discriminator: ETournamentIndividualActionDiscriminator.DraftTournamentIndividualUpdateCommand;
};

/** Запрос на анонса (в статусах ANNOUNCED) индивидуального турнира */
export type TournamentIndividualAnnounceUpdateCommand = TournamentIndividualUpdateAnnounceProps & {
  readonly discriminator: ETournamentIndividualActionDiscriminator.AnnouncedTournamentIndividualUpdateCommand;
};

/** Опубликовать индивидуальный турнир */
export type TournamentIndividualPublishCommand = {
  readonly discriminator: ETournamentIndividualActionDiscriminator.PublishTournamentIndividualCommand;
};

/** Запрос на обновление опубликованного (в статусах WAITING_FOR_START, APPLICATIONS_COLLECTING) индивидуального турнира */
export type TournamentIndividualPublishUpdateCommand = TournamentIndividualUpdatePublishProps & {
  readonly discriminator: ETournamentIndividualActionDiscriminator.PublishTournamentIndividualUpdateCommand;
};

/** Пригласить пользователей(я) на индивидуальный турнир */
export type TournamentIndividualInviteUsersToCommand = {
  readonly discriminator: ETournamentIndividualActionDiscriminator.InviteUsersToTournamentIndividualCommand;
  readonly users: { id: UUID }[];
};

/** Команда на сохранение результатов индивидуального турнира */
export type TournamentIndividualResultsCommand = TournamentIndividualResultsRequest & {
  readonly discriminator: ETournamentIndividualActionDiscriminator.TournamentIndividualResultsCommand;
};

/** Публикация результатов индивидуального турнира */
export type TournamentIndividualPublishResultCommand = {
  readonly discriminator: ETournamentIndividualActionDiscriminator.PublishTournamentIndividualResultCommand;
};

/** Принять заявку на участие в турнире. */
export type TournamentIndividualApplicationAcceptCommand = {
  readonly discriminator: ETournamentIndividualApplicationActionDiscriminator.AcceptTournamentApplicationCommand;
};

/** Отклонитьб заявку на участие в турнире. */
export type TournamentIndividualApplicationRejectCommand = {
  readonly discriminator: ETournamentIndividualApplicationActionDiscriminator.RejectTournamentApplicationCommand;
  readonly message: string;
};

export type TournamentIndividualAction =
  | {
      discriminator: ETournamentIndividualActionDiscriminator;
    }
  | TournamentIndividualDraftUpdateCommand
  | TournamentIndividualAnnounceCommand
  | TournamentIndividualAnnounceUpdateCommand
  | TournamentIndividualPublishCommand
  | TournamentIndividualPublishUpdateCommand
  | TournamentIndividualResultsCommand
  | TournamentIndividualPublishResultCommand
  | TournamentIndividualInviteUsersToCommand;

export type TournamentIndividualApplicationAction =
  | {
      discriminator: ETournamentIndividualApplicationActionDiscriminator;
    }
  | TournamentIndividualApplicationAcceptCommand
  | TournamentIndividualApplicationRejectCommand;

type ActionApplicationProps = TournamentIndividualApplicationAction & {
  readonly appId: UUID;
  readonly tournamentId: UUID;
};

type TeamMemberType = {
  id: UUID;
};

type TournamentCreateApplicationProps = {
  readonly tournamentId: UUID;
  readonly teamId: UUID;
  readonly teamMembers: TeamMemberType[];
};

type TournamentIndividualActionProps = AloneIdProps & TournamentIndividualAction;

type EventApi = {
  readonly all: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: AllProps<D>
  ) => Promise<AxiosResponse<ApiSportResponseMaBeCounters<D, ApiResponseWithPageable<SportEventShort>>>>;
  readonly one: (props: OneProps) => Promise<AxiosResponse<SportEvent>>;
  readonly cancel: (props: OneProps) => Promise<AxiosResponse<void>>;
  readonly tournament: {
    readonly one: (props: EventOneProps) => Promise<AxiosResponse<Tournament>>;
    readonly create: (props: TournamentCreateProps) => Promise<AxiosResponse<Tournament>>;
    readonly saveUnpublished: (props: TournamentSaveUnpublishedProps) => Promise<AxiosResponse<Tournament>>;
    readonly saveAnnounced: (props: TournamentSaveProps) => Promise<AxiosResponse<Tournament>>;
    readonly save: (props: TournamentSaveProps) => Promise<AxiosResponse<Tournament>>;
    readonly publish: (props: TournamentPublishProps) => Promise<AxiosResponse<Tournament>>;
    readonly publishAnnounce: (props: TournamentPublishProps) => Promise<AxiosResponse<Tournament>>;
    readonly applications: (
      props: TournamentApplicationsProps
    ) => Promise<AxiosResponse<ApiResponseWithPageable<TournamentApplication>>>;
    readonly teams: (props: TournamentTeamsProps) => Promise<AxiosResponse<ApiResponseWithPageable<TournamentTeam>>>;
    readonly inviteTeams: (props: TournamentInviteTeamsProps) => Promise<AxiosResponse<void>>;
    readonly applicationAccept: (
      props: TournamentApplicationAcceptProps
    ) => Promise<AxiosResponse<EventApplicationIdState>>;
    readonly applicationReject: (
      props: TournamentApplicationRejectProps
    ) => Promise<AxiosResponse<EventApplicationIdState>>;
    readonly applicationCreate: (
      props: TournamentApplicationCreateProps
    ) => Promise<AxiosResponse<EventApplicationIdState>>;
    readonly saveResults: (props: TournamentSaveResultsProps) => Promise<AxiosResponse<Tournament>>;
    readonly publishResults: (props: TournamentPublishResultsProps) => Promise<AxiosResponse<Tournament>>;
    readonly createApplication: (
      props: TournamentCreateApplicationProps
    ) => Promise<AxiosResponse<TournamentApplication>>;
  };
  readonly tournamentIndividual: {
    readonly one: (props: EventOneProps) => Promise<AxiosResponse<TournamentIndividual>>;
    readonly createDraft: (props: TournamentIndividualCreateProps) => Promise<AxiosResponse<TournamentIndividual>>;
    readonly createApplication: (
      props: TournamentIndividualCreateApplicationProps
    ) => Promise<AxiosResponse<TournamentIndividualApplication>>;
    readonly cancelApplication: (props: TournamentIndividualCancelApplicationsProps) => Promise<AxiosResponse>;
    readonly members: (
      props: TournamentIndividualMembersProps
    ) => Promise<AxiosResponse<ApiResponseWithPageable<TournamentIndividualMemberListView>>>;
    readonly applications: (props: ApplicationsProps) => Promise<AxiosResponse<PageTournamentIndividualApplication>>;
    readonly applicationsAction: (props: ActionApplicationProps) => Promise<AxiosResponse<void>>;
    readonly presenceConfirmed: (
      props: TournamentIndividualPresenceConfirmedProps
    ) => Promise<AxiosResponse<EPresenceStatus>>;
    readonly action: (props: TournamentIndividualActionProps) => Promise<AxiosResponse<TournamentIndividual>>;
  };
  readonly sportsFest: {
    readonly one: (props: EventOneProps) => Promise<AxiosResponse<SportsFest>>;
    readonly combinedTeams: (
      props: SportsFestCombinedTeamsOneProps
    ) => Promise<AxiosResponse<ApiResponseWithPageable<SportsFestCombinedTeam>>>;
    readonly create: (props: SportsFestCreateProps) => Promise<AxiosResponse<SportsFest>>;
    readonly saveUnpublished: (props: SportsFestSaveUnpublishedProps) => Promise<AxiosResponse<SportsFest>>;
    readonly saveAnnounced: (props: SportsFestSaveProps) => Promise<AxiosResponse<SportsFest>>;
    readonly save: (props: SportsFestSaveProps) => Promise<AxiosResponse<SportsFest>>;
    readonly publish: (props: SportsFestPublishProps) => Promise<AxiosResponse<SportsFest>>;
    readonly publishAnnounce: (props: SportsFestPublishProps) => Promise<AxiosResponse<SportsFest>>;
    readonly saveResults: (props: SportsFestSaveResultsProps) => Promise<AxiosResponse<SportsFest>>;
    readonly publishResults: (props: SportsFestPublishResultsProps) => Promise<AxiosResponse<SportsFest>>;
  };
  readonly friendlyMatch: {
    readonly one: (props: EventOneProps) => Promise<AxiosResponse<FriendlyMatch>>;
    readonly create: (props: FriendlyMatchSaveProps) => Promise<AxiosResponse<FriendlyMatch>>;
    readonly setResults: (props: FriendlyMatchSetResultsProps) => Promise<AxiosResponse<FriendlyMatchResults>>;
  };
  readonly activity: {
    readonly one: (props: EventOneProps) => Promise<AxiosResponse<ActivityEvent>>;
    readonly create: (props: ActivityEventCreateProps) => Promise<AxiosResponse<ActivityEvent>>;
    readonly saveUnpublished: (props: ActivityEventSaveUnpublishedProps) => Promise<AxiosResponse<ActivityEvent>>;
    readonly save: (props: ActivityEventSaveProps) => Promise<AxiosResponse<ActivityEvent>>;
    readonly publish: (props: ActivityEventPublishProps) => Promise<AxiosResponse<ActivityEvent>>;
  };
};

/**
 * АПИ по работе с мероприятиями
 */
const event: EventApi = {
  all: props => {
    const {
      page,
      pageSize,
      name,
      sort,
      addressObjectId,
      roads,
      activityTypes,
      eventTypes,
      eventLevels,
      userId,
      admin,
      statuses,
      scheduledDateFrom,
      scheduledDateTo,
      playgroundId,
      teamId,
      discriminator,
      signal,
      participationFormat,
    } = props;

    const params = new URLSearchParams();

    params.append('page', (page - 1).toString(10));
    params.append('size', pageSize.toString(10));

    if (name) {
      params.append('name', name);
    }

    if (sort) {
      if (typeof sort === 'string') {
        params.append('sort', sort);
      } else {
        sort.forEach(item => params.append('sort', item));
      }
    }

    if (addressObjectId) {
      params.append('addressObjectId', addressObjectId);
    }

    if (activityTypes) {
      activityTypes.forEach(item => params.append('activityType', item.id));
    }

    if (eventTypes) {
      eventTypes.forEach(item => params.append('type', item));
    }

    if (roads) {
      roads.forEach(item => params.append('road', item));
    }

    if (eventLevels) {
      eventLevels.forEach(item => params.append('level', item));
    }

    if (statuses) {
      statuses.forEach(item => params.append('statuses', item));
    }

    if (userId) {
      params.append('userId', userId);
    }

    if (admin) {
      params.append('adminId', admin.id);
    }

    if (scheduledDateFrom) {
      params.append('scheduledDateFrom', scheduledDateFrom);
    }

    if (scheduledDateTo) {
      params.append('scheduledDateTo', scheduledDateTo);
    }

    if (playgroundId) {
      params.append('playgroundId', playgroundId);
    }

    if (teamId) {
      params.append('teamId', teamId);
    }

    if (discriminator) {
      params.append('resultType', discriminator);
    }

    if (participationFormat) {
      participationFormat.forEach(item => params.append('participationFormat', item));
    }

    return axios.get(`${getSportBaseEndpoint()}/admin/events`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  one: ({ id, signal }) => {
    return axios.get(`${getSportBaseEndpoint()}/events/${id}`, {
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  cancel: ({ id, signal }) => {
    return axios.post(`${getSportBaseEndpoint()}/admin/events/${id}/cancel`, {
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  tournament: {
    one: ({ id, signal }) => {
      return axios.get(`${getSportBaseEndpoint()}/events/tournament/${id}`, {
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    create: ({ data }) => {
      return axios.post(`${getSportBaseEndpoint()}/admin/events/tournament/unpublished`, data);
    },
    saveUnpublished: ({ data }) => {
      return axios.put(`${getSportBaseEndpoint()}/admin/events/tournament/unpublished/${data.id}`, data);
    },
    saveAnnounced: ({ id, data }) => {
      return axios.put(`${getSportBaseEndpoint()}/admin/events/tournament/announced/${id}`, data);
    },
    save: ({ id, data }) => {
      return axios.put(`${getSportBaseEndpoint()}/admin/events/tournament/published/${id}`, data);
    },
    publish: ({ id }) => {
      return axios.patch(`${getSportBaseEndpoint()}/admin/events/tournament/${id}/published`);
    },
    publishAnnounce: ({ id }) => {
      return axios.patch(`${getSportBaseEndpoint()}/admin/events/tournament/${id}/announced`);
    },
    applications: ({ id, signal, page, pageSize, sort }) => {
      const params = new URLSearchParams();

      params.append('page', (page - 1).toString(10));
      params.append('size', pageSize.toString(10));

      if (sort) {
        if (typeof sort === 'string') {
          params.append('sort', sort);
        } else {
          sort.forEach(item => params.append('sort', item));
        }
      }

      return axios.get(`${getSportBaseEndpoint()}/admin/events/tournament/${id}/applications`, {
        params,
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    saveResults: ({ id, results }) => {
      return axios.put(`${getSportBaseEndpoint()}/admin/events/tournament/${id}/results`, results);
    },
    publishResults: ({ id }) => {
      return axios.patch(`${getSportBaseEndpoint()}/admin/events/tournament/${id}/results-published`);
    },
    inviteTeams: ({ id, teams }) => {
      return axios.post(`${getSportBaseEndpoint()}/admin/events/tournament/${id}/invitedTeams`, teams);
    },
    teams: ({ id, signal, page, pageSize, sort }) => {
      const params = new URLSearchParams();

      params.append('page', (page - 1).toString(10));
      params.append('size', pageSize.toString(10));

      if (sort) {
        if (typeof sort === 'string') {
          params.append('sort', sort);
        } else {
          sort.forEach(item => params.append('sort', item));
        }
      }

      return axios.get(`${getSportBaseEndpoint()}/admin/events/tournament/${id}/tournament-teams`, {
        params,
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    applicationAccept: ({ applicationId, tournamentId }) => {
      return axios.patch(
        `${getSportBaseEndpoint()}/admin/events/tournament/${tournamentId}/applications/${applicationId}/accepted`
      );
    },
    applicationCreate: ({ tournamentId, teamMembers, teamId }) => {
      const params = {
        teamMembers,
        team: {
          id: teamId,
        },
      };

      return axios.post(`${getSportBaseEndpoint()}/admin/events/tournament/${tournamentId}/applications`, { params });
    },
    applicationReject: ({ applicationId, tournamentId, message }) => {
      return axios.patch(
        `${getSportBaseEndpoint()}/admin/events/tournament/${tournamentId}/applications/${applicationId}/rejected`,
        {
          message,
        }
      );
    },
    createApplication: ({ tournamentId, teamId, teamMembers }) => {
      return axios.post(`${getSportBaseEndpoint()}/events/tournament/${tournamentId}/applications`, {
        id: uuidv4(),
        team: {
          id: teamId,
        },
        teamMembers,
      });
    },
  },
  tournamentIndividual: {
    one: ({ id, signal }) => {
      return axios.get(`${getSportBaseEndpoint()}/events/tournament-individual/${id}`, {
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    members: ({ id, page, pageSize, sort, signal }) => {
      const params = new URLSearchParams();

      params.append('page', (page - 1).toString(10));
      params.append('size', pageSize.toString(10));

      if (sort) {
        if (typeof sort === 'string') {
          params.append('sort', sort);
        } else {
          sort.forEach(item => params.append('sort', item));
        }
      }

      return axios.get(`${getSportBaseEndpoint()}/events/tournament-individual/${id}/tournament-members`, {
        params,
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    createApplication: ({ tournamentId, invitedUsers }) => {
      return axios.post(
        `${getSportBaseEndpoint()}/admin/events/tournament-individual/${tournamentId}/create-applications`,
        {
          invitedUsers,
        }
      );
    },
    cancelApplication: ({ appId, tournamentId, signal }) => {
      return axios.patch(
        `${getSportBaseEndpoint()}/events/tournament-individual/${tournamentId}/applications/${appId}/cancelled`,
        {
          cancelToken: signal && createCancelToken(axios, signal),
        }
      );
    },
    presenceConfirmed: ({ tournamentId, position, signal }) => {
      return axios.patch(`${getSportBaseEndpoint()}/events/tournament-individual/${tournamentId}/presence-confirmed`, {
        ...position,
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    action: ({ id, signal, ...data }) => {
      return axios.post(`${getSportBaseEndpoint()}/admin/events/tournament-individual/${id}`, data, {
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    createDraft: ({ data }) => {
      return axios.post(`${getSportBaseEndpoint()}/admin/events/tournament-individual`, data);
    },
    applications: props => {
      const { tournamentId, page, pageSize, sort, signal, userId, statuses } = props;
      const params = new URLSearchParams();

      params.append('page', (page - 1).toString(10));
      params.append('size', pageSize.toString(10));

      if (userId) {
        params.append('userId', userId);
      }

      if (statuses) {
        statuses.forEach(item => params.append('statuses', item));
      }

      if (sort) {
        if (typeof sort === 'string') {
          params.append('sort', sort);
        } else {
          sort.forEach(item => params.append('sort', item));
        }
      }
      return axios.get(`${getSportBaseEndpoint()}/admin/events/tournament-individual/${tournamentId}/applications`, {
        params,
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    applicationsAction: ({ tournamentId, appId, ...data }) => {
      return axios.post(
        `${getSportBaseEndpoint()}/admin/events/tournament-individual/${tournamentId}/applications/${appId}`,
        data
      );
    },
  },
  sportsFest: {
    one: ({ id, signal }) => {
      return axios.get(`${getSportBaseEndpoint()}/events/sports-fest/${id}`, {
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    combinedTeams: props => {
      const { page, pageSize, sort, id, signal } = props;

      const params = new URLSearchParams();

      params.append('page', (page - 1).toString(10));
      params.append('size', pageSize.toString(10));

      if (sort) {
        if (typeof sort === 'string') {
          params.append('sort', sort);
        } else {
          sort.forEach(item => params.append('sort', item));
        }
      }

      return axios.get(`${getSportBaseEndpoint()}/events/sports-fest/${id}/combined-teams`, {
        params,
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    create: ({ data }) => {
      return axios.post(`${getSportBaseEndpoint()}/admin/events/sports-fest/unpublished`, data);
    },
    saveUnpublished: ({ data }) => {
      return axios.put(`${getSportBaseEndpoint()}/admin/events/sports-fest/unpublished/${data.id}`, data);
    },
    saveAnnounced: ({ id, data }) => {
      return axios.put(`${getSportBaseEndpoint()}/admin/events/sports-fest/announced/${id}`, data);
    },
    save: ({ id, data }) => {
      return axios.put(`${getSportBaseEndpoint()}/admin/events/sports-fest/published/${id}`, data);
    },
    publish: ({ id }) => {
      return axios.patch(`${getSportBaseEndpoint()}/admin/events/sports-fest/${id}/published`);
    },
    publishAnnounce: ({ id }) => {
      return axios.patch(`${getSportBaseEndpoint()}/admin/events/sports-fest/${id}/announced`);
    },
    saveResults: ({ id, results }) => {
      return axios.put(`${getSportBaseEndpoint()}/admin/events/sports-fest/${id}/results`, results);
    },
    publishResults: ({ id }) => {
      return axios.patch(`${getSportBaseEndpoint()}/admin/events/sports-fest/${id}/results-published`);
    },
  },
  friendlyMatch: {
    one: ({ id, signal }) => {
      return axios.get(`${getSportBaseEndpoint()}/events/friendlyMatch/${id}`, {
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    create: friendlyMatch => {
      const { id = uuid() } = friendlyMatch;
      return axios.post(`${getSportBaseEndpoint()}/admin/events/friendlyMatch`, {
        ...friendlyMatch,
        id,
      });
    },
    setResults: ({ friendlyMatchResults, id }) => {
      return axios.post(`${getSportBaseEndpoint()}/admin/events/friendlyMatch/${id}/set-results`, {
        ...friendlyMatchResults,
      });
    },
  },
  activity: {
    one: ({ id, signal }) => {
      return axios.get(`${getSportBaseEndpoint()}/events/activity-event/${id}`, {
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    create: ({ data }) => {
      return axios.post(`${getSportBaseEndpoint()}/admin/events/activity-event/unpublished`, data);
    },
    saveUnpublished: ({ data }) => {
      return axios.put(`${getSportBaseEndpoint()}/admin/events/activity-event/unpublished/${data.id}`, data);
    },
    save: ({ id, data }) => {
      return axios.put(`${getSportBaseEndpoint()}/admin/events/activity-event/published/${id}`, data);
    },
    publish: ({ id }) => {
      return axios.patch(`${getSportBaseEndpoint()}/admin/events/activity-event/${id}/published`);
    },
  },
};

export default event;
