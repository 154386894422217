import { SportUserProfile } from '@/domain';
import { SportsmanSelectRestriction } from '@features/sportsman/search/types';
import { NumberRange } from '../../../../types';
import useSportsmansSearchSearch from '../hooks/useSearch';
import useSportsmansSearchSession from '../hooks/useSession';

interface SportsmansSearchBehaviorAdapterSingleProps {
  readonly initialSelected: Nullable<SportUserProfile>;
  readonly selectedMaxCount: 1;
  readonly onSelect: (sportsman: Nullable<SportUserProfile>) => void;
  readonly teamId?: UUID;
}

interface SportsmansSearchBehaviorAdapterMultipleProps {
  readonly initialSelected: Nullable<SportUserProfile[]>;
  readonly selectedMaxCount: NumberRange<2, 100> | false;
  readonly onSelect: (sportsmen: SportUserProfile[]) => void;
  readonly teamId?: UUID;
}

export type SportsmansSearchBehaviorAdapterSelectedProps =
  | SportsmansSearchBehaviorAdapterSingleProps
  | SportsmansSearchBehaviorAdapterMultipleProps;

type SportsmansSearchBehaviorAdapterProps = {
  readonly guid: UUID;
  readonly selectRestrictions?: SportsmanSelectRestriction[];
} & SportsmansSearchBehaviorAdapterSelectedProps;

export const SportsmansSearchBehaviorAdapter = (props: SportsmansSearchBehaviorAdapterProps) => {
  useSportsmansSearchSession({ ...props });
  useSportsmansSearchSearch({ guid: props.guid, teamId: props.teamId });

  return null;
};
