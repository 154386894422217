import { ClientOrgUser, UserAccessPartitionClientOrgUsers } from '@/domain';
import { ClientOrgUserLifeCycle } from '@features/user/userClientOrg/lifecycle/types';
import { EPanelActionPosition } from 'presentation/types';
import { ClientOrgUserActions, ClientOrgUserActionTableType, EClientOrgUserActionType } from '../types';

export const getClientOrgUserActionName = (action: EClientOrgUserActionType) => {
  switch (action) {
    case EClientOrgUserActionType.ResendWelcomeMail:
      return 'Отправить приглашение повторно';
  }
};

export const removeClientOrgUserAction = (actions: ClientOrgUserActions, type: EClientOrgUserActionType) => {
  const index = actions.findIndex(action => action.type === type);
  if (index !== -1) {
    actions.splice(index, 1);
  }
};

/**
 * Получение экшенов для таблицы
 * @param lifecycle
 * @param clientOrgUser
 * @param allowedActions
 */
export const getClientOrgUserTableActions = (
  lifecycle: ClientOrgUserLifeCycle,
  clientOrgUser: ClientOrgUser,
  allowedActions: ClientOrgUserActions<ClientOrgUserActionTableType>
) => {
  return lifecycle.build({
    obj: clientOrgUser,
    allowedActions,
  }).nextActions as ClientOrgUserActions<ClientOrgUserActionTableType>;
};

export type GetClientOrgUserActionsByPermissionsProps = UserAccessPartitionClientOrgUsers;

// получение разрешенных действий с пользователями организаций с учетом разрешений (AccessMatrix)
export const getClientOrgUserActionsConfigByPermissions = (
  props: GetClientOrgUserActionsByPermissionsProps
): ClientOrgUserActions => {
  const actions: ClientOrgUserActions = [];

  // Переотправка приглашений
  if (props.resendWelcomeMail) {
    actions.push({
      type: EClientOrgUserActionType.ResendWelcomeMail,
      primary: true,
      label: getClientOrgUserActionName(EClientOrgUserActionType.ResendWelcomeMail),
      position: [EPanelActionPosition.Default],
    });
  }

  return actions;
};
