import { userInfoView } from 'presentation/utils/userInfoView';
import Api from '../../../../data/api';
import {
  Nullable,
  ESportEventLevel,
  ESportEventParticipationFormat,
  ESportEventType,
  EUserRole,
  DataFilterQueryDslOperator,
  DataFilterStrategyAdapterTreeMultipleEditAdapter,
  DataFilterStrategyBase,
  DataFilterStrategyListItem,
  DataFilterStrategyView,
  DataFilterValueItem,
  EDataFilterType,
  SportOption,
  SportOptionTyped,
  Address,
  pageSizeAll,
  ActivityType,
} from '@/domain';
import { AddressHelper } from '../../../utils/address';
import ActivityTypeFilterEditAdapter from '../../activityType/adapters/filterEdit/container';
import { ESportEventTableColumn } from './utils';

export enum EEventTableFilterItem {
  Name = 'name',
  ActivityTypes = 'activityTypes',
  Type = 'type.id',
  ParticipationFormat = 'participationFormat',
  Level = 'level.id',
  Road = 'road.id',
  Admin = 'admin.id',
  Locality = 'locality',
}

export type EventTableFilterEditStrategy = DataFilterStrategyBase<EEventTableFilterItem>;
export type EventTableFilterViewStrategy = DataFilterStrategyView<EEventTableFilterItem>;
export type EventTableFilterValues = {
  readonly [EEventTableFilterItem.Name]?: DataFilterValueItem<Nullable<string>>;
  readonly [EEventTableFilterItem.ActivityTypes]?: DataFilterValueItem<Nullable<ActivityType[]>>;
  readonly [EEventTableFilterItem.Type]?: DataFilterValueItem<Nullable<ESportEventType[]>>;
  readonly [EEventTableFilterItem.ParticipationFormat]?: DataFilterValueItem<
    Nullable<ESportEventParticipationFormat[]>
  >;
  readonly [EEventTableFilterItem.Level]?: DataFilterValueItem<Nullable<ESportEventLevel[]>>;
  readonly [EEventTableFilterItem.Road]?: DataFilterValueItem<Nullable<string[]>>;
  readonly [EEventTableFilterItem.Locality]?: DataFilterValueItem<Nullable<Address>>;
  readonly [EEventTableFilterItem.Admin]?: DataFilterValueItem<Nullable<DataFilterStrategyListItem>>;
};

export const eventTableFilterItems: Record<ESportEventTableColumn, EEventTableFilterItem[]> = {
  [ESportEventTableColumn.Name]: [EEventTableFilterItem.Name],
  [ESportEventTableColumn.ActivityTypes]: [EEventTableFilterItem.ActivityTypes],
  [ESportEventTableColumn.PlaygroundCityName]: [EEventTableFilterItem.Locality],
  [ESportEventTableColumn.Admin]: [EEventTableFilterItem.Admin],
  [ESportEventTableColumn.Type]: [EEventTableFilterItem.Type],
  [ESportEventTableColumn.Status]: [],
  [ESportEventTableColumn.Road]: [EEventTableFilterItem.Road],
  [ESportEventTableColumn.ParticipationFormat]: [EEventTableFilterItem.ParticipationFormat],
  [ESportEventTableColumn.ScheduledDate]: [],
};

export const getEventTableFilterStrategies = (
  filterItem: EEventTableFilterItem,
  values: EventTableFilterValues,
  eventTypes: Nullable<SportOptionTyped<ESportEventType>[]>,
  eventLevels: Nullable<SportOptionTyped<ESportEventLevel>[]>,
  roads: Nullable<SportOption[]>,
  participationFormats: Nullable<SportOptionTyped<ESportEventParticipationFormat>[]>
): Nullable<EventTableFilterEditStrategy> => {
  const eventTypeItems: DataFilterStrategyListItem[] = (eventTypes ?? []).map(({ id, name }) => ({ id, name }));
  const eventLevelItems: DataFilterStrategyListItem[] = (eventLevels ?? []).map(({ id, name }) => ({ id, name }));
  const roadItems: DataFilterStrategyListItem[] = (roads ?? []).map(({ id, name }) => ({ id, name }));
  const participationFormatsItems: DataFilterStrategyListItem[] = participationFormats ?? [];

  switch (filterItem) {
    case EEventTableFilterItem.Name:
      return {
        type: EDataFilterType.String,
        key: EEventTableFilterItem.Name,
        label: 'Поиск по ключевому слову',
        preview: values[EEventTableFilterItem.Name]?.value || null,
        value: values[EEventTableFilterItem.Name]?.value || null,
      };
    case EEventTableFilterItem.ActivityTypes:
      return {
        type: EDataFilterType.AdapterTreeMultiple,
        key: EEventTableFilterItem.ActivityTypes,
        label: 'Тематика',
        preview: `Тематика (${values[EEventTableFilterItem.ActivityTypes]?.value?.length})`,
        previews: values[EEventTableFilterItem.ActivityTypes]?.value?.map(t => t.name),
        value: values[EEventTableFilterItem.ActivityTypes]?.value || null,
        editAdapter:
          ActivityTypeFilterEditAdapter as DataFilterStrategyAdapterTreeMultipleEditAdapter<EEventTableFilterItem>,
      };
    case EEventTableFilterItem.Type:
      return {
        type: EDataFilterType.ListMultiple,
        key: EEventTableFilterItem.Type,
        label: 'Тип мероприятия',
        preview: `Тип мероприятия (${values[EEventTableFilterItem.Type]?.value?.length})`,
        previews: eventTypeItems
          .filter(t => values[EEventTableFilterItem.Type]?.value?.some(v => v === t.id))
          .map(t => t.name),
        value: values[EEventTableFilterItem.Type]?.value || null,
        items: eventTypeItems,
        querydsl: {
          operator: DataFilterQueryDslOperator.In,
        },
      };
    case EEventTableFilterItem.ParticipationFormat:
      return {
        type: EDataFilterType.ListMultiple,
        key: EEventTableFilterItem.ParticipationFormat,
        label: 'Формат участия',
        preview: `Формат участия (${values[EEventTableFilterItem.ParticipationFormat]?.value?.length})`,
        previews: participationFormatsItems
          .filter(t => values[EEventTableFilterItem.ParticipationFormat]?.value?.some(v => v === t.id))
          .map(t => t.name),
        value: values[EEventTableFilterItem.ParticipationFormat]?.value || null,
        items: participationFormatsItems,
        querydsl: {
          operator: DataFilterQueryDslOperator.In,
        },
      };
    case EEventTableFilterItem.Level:
      return {
        type: EDataFilterType.ListMultiple,
        key: EEventTableFilterItem.Level,
        label: 'Уровень мероприятия',
        preview: `Уровень мероприятия (${values[EEventTableFilterItem.Level]?.value?.length})`,
        previews: eventLevelItems
          .filter(t => values[EEventTableFilterItem.Level]?.value?.some(v => v === t.id))
          .map(t => t.name),
        value: values[EEventTableFilterItem.Level]?.value || null,
        items: eventLevelItems,
        querydsl: {
          operator: DataFilterQueryDslOperator.In,
        },
      };
    case EEventTableFilterItem.Road:
      return {
        type: EDataFilterType.ListMultiple,
        key: EEventTableFilterItem.Road,
        label: 'Железная дорога',
        preview: `Железная дорога (${values[EEventTableFilterItem.Road]?.value?.length})`,
        previews: roadItems
          .filter(t => values[EEventTableFilterItem.Road]?.value?.some(v => v === t.id))
          .map(t => t.name),
        value: values[EEventTableFilterItem.Road]?.value || null,
        items: roadItems,
        querydsl: {
          operator: DataFilterQueryDslOperator.In,
        },
      };
    case EEventTableFilterItem.Locality:
      return {
        type: EDataFilterType.AddressLocalitySingle,
        key: EEventTableFilterItem.Locality,
        label: 'Населённый пункт',
        preview: values[EEventTableFilterItem.Locality]?.value
          ? new AddressHelper(values[EEventTableFilterItem.Locality]!.value!).getLocalityFullPath()
          : null,
        value: values[EEventTableFilterItem.Locality]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Equals,
        },
      };
    case EEventTableFilterItem.Admin:
      return {
        type: EDataFilterType.AutocompleteSingle,
        key: EEventTableFilterItem.Admin,
        label: 'Организатор',
        value: values[EEventTableFilterItem.Admin]?.value || null,
        preview: values[EEventTableFilterItem.Admin]?.value?.name || null,
        source: (name: string) =>
          name
            ? Api.user.sport
                .all({
                  name,
                  page: 1,
                  pageSize: pageSizeAll,
                  sort: ['lastName,asc', 'firstName,asc', 'midName,asc'],
                  roles: [EUserRole.SystemAdmin, EUserRole.EventAdmin],
                })
                .then(response =>
                  response.data.content.map(item => ({
                    ...item,
                    name: userInfoView(item).fullName,
                  }))
                )
            : Promise.resolve([]),
        querydsl: {
          operator: DataFilterQueryDslOperator.In,
          valueAttribute: 'id',
        },
      };
  }
};
