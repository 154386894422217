import {
  AppFile,
  ClientOrgShort,
  EPartnerPermission,
  EPartnerType,
  FileDescription,
  PartnerData,
  SportOptionTyped,
} from '@/domain';
import { Checkbox } from '@components/common/checkboxPanel/controls';
import OrgStructureClientOrgSingleSelector from '@components/orgStructure/clientOrg/selector/single';
import { PartnerDataStepPermissions } from '@features/general/partner/validation';
import { FormControlLabel, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { MPMenuItem } from '@ui-kit/menu/item';
import { MPSelect } from '@ui-kit/select';
import { fileTypes } from 'presentation/constants';
import { ContentTypeDocumentIcon } from 'presentation/icons';
import { EAppFeature } from 'presentation/types';
import { ValidationResult } from 'presentation/utils/validation';
import FileInfo from '../../../../../../components/common/files/info';
import FileUploader from '../../../../../../components/common/files/uploader';
import Splitter from '../../../../../../components/common/splitter';
import ValidationErrorPanel from '../../../../../../components/common/validation/errorPanel';
import useTechConfig from '../../../../../../hooks/useTechConfig';
import { Content } from '../../../controls';

interface PartnerEditStepPermissionsProps {
  readonly partner: PartnerData;
  readonly partnerTypes: SportOptionTyped<EPartnerType>[];
  readonly permissions: Nullable<EPartnerPermission[]>;
  readonly partnerType: Nullable<EPartnerType>;
  readonly clientOrg: Nullable<ClientOrgShort>;
  readonly isValid: boolean;
  readonly validation: Nullable<ValidationResult<PartnerDataStepPermissions>>;
  readonly onChangePermissions: (value: EPartnerPermission[]) => void;
  readonly onChangePartnerType: (value: EPartnerType) => void;
  readonly onChangeClientOrg: (value: Nullable<ClientOrgShort>) => void;
  readonly onChangeAgreements: (value: FileDescription[]) => void;
}

const PartnerEditStepPermissions = (props: PartnerEditStepPermissionsProps) => {
  const {
    partner,
    partnerType,
    clientOrg,
    partnerTypes,
    permissions,
    isValid,
    validation,
    onChangePermissions,
    onChangeAgreements,
    onChangePartnerType,
    onChangeClientOrg,
  } = props;

  const { agreements } = partner;

  const { hasFeature } = useTechConfig();
  const productOffers = hasFeature(EAppFeature.ProductOffer);
  const bookingOffers = hasFeature(EAppFeature.Booking);

  const tradeOffersAllowed: boolean = permissions?.includes(EPartnerPermission.TradeOfferPublic) ?? false;
  const corpOffersAllowed: boolean = permissions?.includes(EPartnerPermission.CorpOfferPublic) ?? false;
  const productOffersAllowed: boolean = permissions?.includes(EPartnerPermission.ProductOfferPublic) ?? false;
  const bookingOffersAllowed: boolean = permissions?.includes(EPartnerPermission.BookingOfferPublic) ?? false;

  const selectedPartnerType = partnerTypes.find(pt => pt.id === partnerType) ?? '';

  const onChangePermission = (permission: EPartnerPermission, enabled: boolean) => {
    const newPermissions: EPartnerPermission[] = [...(permissions ?? [])];

    const existedIndex = newPermissions.indexOf(permission);
    if (existedIndex !== -1) {
      newPermissions.splice(existedIndex, 1);
    }

    if (enabled) {
      newPermissions.push(permission);
    }
    onChangePermissions(newPermissions);
  };

  const onUploadAgreement = (file: AppFile) => {
    const newAgreements: FileDescription[] = [...(agreements ?? []), { path: file.path }];
    onChangeAgreements(newAgreements);
  };

  const onRemoveAgreement = (removedAgreement: FileDescription) => {
    const newAgreements: FileDescription[] = [...(agreements ?? [])];

    const existedIndex = newAgreements.findIndex(agreement => agreement.path === removedAgreement.path);
    if (existedIndex !== -1) {
      newAgreements.splice(existedIndex, 1);
    }

    onChangeAgreements(newAgreements);
  };

  return (
    <Content>
      <Grid
        container
        direction='column'
      >
        <Grid item>
          <Typography variant='h3'>Доступ</Typography>
          <Splitter variant='horizontal' />
        </Grid>

        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                checked={tradeOffersAllowed}
                onChange={(event, checked) => onChangePermission(EPartnerPermission.TradeOfferPublic, checked)}
              />
            }
            label={<Typography>Торговые предложения</Typography>}
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                checked={corpOffersAllowed}
                onChange={(event, checked) => onChangePermission(EPartnerPermission.CorpOfferPublic, checked)}
              />
            }
            label={<Typography>Корпоративные предложения</Typography>}
          />
        </Grid>
        {productOffers && (
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  color='primary'
                  checked={productOffersAllowed}
                  onChange={(event, checked) => onChangePermission(EPartnerPermission.ProductOfferPublic, checked)}
                />
              }
              label={<Typography>Товарные предложения</Typography>}
            />
          </Grid>
        )}

        {bookingOffers && (
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  color='primary'
                  checked={bookingOffersAllowed}
                  onChange={(event, checked) => onChangePermission(EPartnerPermission.BookingOfferPublic, checked)}
                />
              }
              label={<Typography>Объекты досуга и отдыха</Typography>}
            />
          </Grid>
        )}

        <Grid item>
          <Splitter
            variant='horizontal'
            size={3}
          />
          <Typography variant='subtitle2'>Вид партнёра</Typography>
          <Splitter variant='horizontal' />
          <MPSelect<SportOptionTyped<EPartnerType>>
            label='Вид партнёра'
            value={selectedPartnerType}
            error={validation?.type?.hasError}
            helperText={validation?.type?.message}
            onChange={value => onChangePartnerType(value.id)}
          >
            {partnerTypes.map(pt => (
              <MPMenuItem
                key={pt.id}
                value={pt}
              >
                <Typography variant='body1'>{pt.name}</Typography>
              </MPMenuItem>
            ))}
          </MPSelect>
        </Grid>

        {hasFeature(EAppFeature.CompanyOffer) && partnerType === EPartnerType.External && (
          <Grid item>
            <Splitter
              variant='horizontal'
              size={3}
            />
            <Typography variant='subtitle2'>Разрешения на компанию</Typography>
            <Splitter variant='horizontal' />
            <OrgStructureClientOrgSingleSelector
              label='Компания-клиент'
              value={clientOrg}
              onChange={onChangeClientOrg}
            />
          </Grid>
        )}
        <Grid item>
          <Splitter
            variant='horizontal'
            size={3}
          />
          <Typography variant='subtitle2'>Договоры и соглашения</Typography>
          <Splitter variant='horizontal' />
        </Grid>
        <Grid item>
          <Grid
            container
            spacing={1}
            direction='column'
          >
            {agreements?.map(agreement => (
              <Grid
                item
                key={agreement.path}
              >
                <FileInfo
                  id={agreement.path}
                  onRemove={() => onRemoveAgreement(agreement)}
                />
              </Grid>
            ))}
            <Grid item>
              <FileUploader
                text='Загрузите документ'
                filesMaxCount={9999}
                icon={<ContentTypeDocumentIcon />}
                fileTypes={[fileTypes.pdf.mimeType, fileTypes.doc.mimeType, fileTypes.docx.mimeType]}
                accept={[fileTypes.pdf.ext, fileTypes.docx.ext, fileTypes.docx.ext]}
                onUpload={file => onUploadAgreement(file)}
              />
            </Grid>
          </Grid>
        </Grid>
        {!isValid && (
          <Grid
            item
            xs={12}
          >
            <ValidationErrorPanel />
          </Grid>
        )}
      </Grid>
    </Content>
  );
};

export default PartnerEditStepPermissions;
