import validator from 'validator';
import { FileDescription } from '../../../../domain/model';
import { Address } from '../../../../domain/model/address';
import { EPartnerPermission, EPartnerType, ETaxationSystemType } from '../../../../domain/model/enums';
import { PartnerDataDraft } from '../../../../domain/model/partner';
import { Nullable } from '../../../../domain/model/types';
import { AddressHelper } from '../../../utils/address';
import { isInn, isOgrn, isPaymentBankAccount } from '../../../utils/juristic';
import {
  EValidationMessages,
  EValidationType,
  validationCommonValidators,
  ValidationRules,
} from '../../../utils/validation';

const extendedValidator = {
  ...validator,
  isPaymentBankAccount,
  isInn,
  isOgrn,
};

export type PartnerGeneralIndividual = PartnerDataDraft;

export const partnerEditGeneralIndividualValidation: ValidationRules<PartnerGeneralIndividual> = {
  regInfo: {
    required: true,
  },
  name: {
    required: true,
  },
  inn: {
    required: true,
    validator: (object: PartnerDataDraft, value: Nullable<string>) => {
      if (!value) return null;

      const isInnValid = extendedValidator.isInn(value);
      if (!isInnValid) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: EValidationMessages.InnInvalid,
        };
      } else {
        return null;
      }
    },
  },
  ownerFullName: {
    required: true,
  },
  email: {
    required: true,
    validator: validationCommonValidators.email,
  },
  website: {
    required: true,
    validator: (object: PartnerDataDraft, value) => {
      if (!value) return null;

      const isURL = extendedValidator.isURL(value);
      if (!isURL) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: EValidationMessages.URLInvalid,
        };
      } else {
        return null;
      }
    },
  },
  phone: {
    required: true,
  },
};

export type PartnerGeneralCompany = PartnerDataDraft & {
  readonly companyName: Nullable<string>;
};

export const partnerEditGeneralCompanyValidation: ValidationRules<PartnerGeneralCompany> = {
  ...partnerEditGeneralIndividualValidation,
  companyName: {
    required: true,
  },
};

const partnerEditDetailsValidation: ValidationRules<PartnerDataDraft> = {
  bic: {
    required: true,
  },
  checkingAccount: {
    required: true,
    validator: (object: PartnerDataDraft, value) => {
      if (!value) return null;

      const isPaymentAccount = !!object.bic && extendedValidator.isPaymentBankAccount(value, object.bic);
      if (!isPaymentAccount) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: EValidationMessages.PaymentAccountInvalid,
        };
      } else {
        return null;
      }
    },
  },
  taxSystem: {
    required: true,
  },
};

export type PartnerDetailsCompany = PartnerDataDraft & {
  readonly ogrn: Nullable<string>;
  readonly kpp: Nullable<string>;
};

export const partnerEditDetailsCompanyValidation: ValidationRules<PartnerDetailsCompany> = {
  ...partnerEditDetailsValidation,
  ogrn: {
    required: true,
    validator: (object: PartnerDataDraft, value) => {
      if (!value) return null;

      const isOgrnValid = extendedValidator.isOgrn(value);

      if (!isOgrnValid) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: EValidationMessages.OgrnInvalid,
        };
      } else {
        return null;
      }
    },
  },
  kpp: {
    required: true,
  },
};

export type PartnerDetailsIndividual = PartnerDataDraft & {
  readonly ogrnip: Nullable<string>;
};

export const partnerEditDetailsIndividualValidation: ValidationRules<PartnerDetailsIndividual> = {
  ...partnerEditDetailsValidation,
  ogrnip: {
    required: true,
    validator: (object: PartnerDataDraft, value) => {
      if (!value) return null;

      const isOgrnValid = extendedValidator.isOgrn(value);

      if (!isOgrnValid) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: EValidationMessages.OgrnipInvalid,
        };
      } else {
        return null;
      }
    },
  },
};

const partnerEditDocumentsValidation: ValidationRules<PartnerDataDraft> = {
  innCert: {
    required: true,
  },
  usnDoc: {
    required: (object: PartnerDataDraft) => object.taxSystem === ETaxationSystemType.USN,
  },
};

export type PartnerDocumentsCompany = PartnerDataDraft & {
  readonly ogrnCert: Nullable<FileDescription>;
  readonly charterDoc: Nullable<FileDescription>;
  readonly ceoAppointmentProtocol: Nullable<FileDescription>;
};

export const partnerEditDocumentsCompanyValidation: ValidationRules<PartnerDocumentsCompany> = {
  ...partnerEditDocumentsValidation,
  ogrnCert: {
    required: true,
  },
  charterDoc: {
    required: true,
  },
  ceoAppointmentProtocol: {
    required: true,
  },
};

export type PartnerDocumentsIndividual = PartnerDataDraft & {
  readonly ogrnipCert: Nullable<FileDescription>;
  readonly passportCopy: Nullable<FileDescription>;
};

export const partnerEditDocumentsIndividualValidation: ValidationRules<PartnerDocumentsIndividual> = {
  ...partnerEditDocumentsValidation,
  ogrnipCert: {
    required: true,
  },
  passportCopy: {
    required: true,
  },
};

export const partnerEditAddressStepValidation: ValidationRules<Address> = {
  hierarchy: {
    required: true,
    requiredMessage: 'Укажите полный адрес',
    validator: object => {
      if (!object) {
        return null;
      }

      const isIncorrect = new AddressHelper(object).isEmptyStartingFromStead();
      if (isIncorrect) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: 'Укажите полный адрес',
        };
      }

      return null;
    },
  },
};

export type PartnerDataStepPermissions = PartnerDataDraft & {
  readonly type: Nullable<EPartnerType>;
  readonly permissions: Nullable<EPartnerPermission[]>;
};

export type GetPartnerEditPermissionsStepValidationArgs = {
  canChangePermissions?: boolean;
};

export const getPartnerEditPermissionsStepValidation = (
  params: GetPartnerEditPermissionsStepValidationArgs
): ValidationRules<PartnerDataStepPermissions> => {
  const { canChangePermissions } = params;

  return {
    type: {
      required: true,
    },
    permissions: canChangePermissions
      ? {
          validator: (object, value) => {
            if (!value?.length) {
              return {
                type: EValidationType.Error,
                hasError: true,
                message: 'Чтобы одобрить заявку, выберите хотя бы один из доступов',
              };
            }
            return null;
          },
        }
      : {
          required: false,
        },
  };
};

export const partnerEditPermissionsStepValidation: ValidationRules<PartnerDataStepPermissions> = {
  type: {
    required: true,
  },
  permissions: {
    validator: (object, value) => {
      if (!value?.length) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: 'Чтобы одобрить заявку, выберите хотя бы один из доступов',
        };
      }
      return null;
    },
  },
};
