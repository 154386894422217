import { ImageRestrictions } from '../../../../domain/model';
import {
  BookingOffer,
  BookingOfferContact,
  BookingOfferCreateRequest,
  BookingOfferResponsiblePerson,
  BookingOfferService,
  BookingOfferUpdateRequest,
} from '../../../../domain/model/booking';
import { EOfferServiceStatus, EOfferStatus } from '../../../../domain/model/enums';
import { PartnerShort } from '../../../../domain/model/partner';
import { Nullable } from '../../../../domain/model/types';
import { fileTypes } from '../../../constants';
import { pluralize } from '../../../utils/pluralize';
import { EValidationType, ValidationCollectionResult, ValidationResult } from '../../../utils/validation';
import { BookingServicePriceItemView } from '../components/fieldsEdit/types';
import { BookingOfferCreateStepType, BookingOfferServiceView, BookingOfferView, EBookingOfferStep } from '../types';
import { getBookingOfferDisplayPriceItem } from '../utils/common';

export const bookingOfferImagesMaxCount = 30;
export const bookingOfferResponsiblePersonsMaxCount = 4 as const;

export const getBookingOfferCreateStepText = (step: BookingOfferCreateStepType): string => {
  switch (step) {
    case EBookingOfferStep.General:
      return 'Основные данные';
    case EBookingOfferStep.Contacts:
      return 'Контакты и как добраться';
    case EBookingOfferStep.Services:
      return 'Услуги';
  }
};

export const getEmptyBookingOfferContact = (): BookingOfferContact => ({
  name: null,
  phone: null,
  email: null,
  address: null,
});

export const getEmptyBookingOfferResponsiblePerson = (): BookingOfferResponsiblePerson => ({
  fullName: null,
  phone: null,
  email: null,
});

export const getEmptyServicePriceItemView = (): BookingServicePriceItemView => {
  return { id: null, name: null, price: null, service: null, unit: null };
};

export const getEmptyOfferServiceView = (): BookingOfferServiceView => {
  return {
    id: null,
    category: null,
    name: null,
    description: null,
    status: EOfferServiceStatus.Enabled,
    offer: null,
    orderByDateType: null,
    priceItems: [],
    rzdSocialPackage: null,
    rzdSocialPackageInfo: null,
  };
};

export const createEmptyBookingOfferView = (partner: Nullable<PartnerShort>): BookingOfferView => {
  return {
    name: '',
    partner: partner ? partner : ({} as PartnerShort),
    description: null,
    images: null,
    responsiblePersons: [],
    address: null,
    routeDescription: null,
    contacts: [],
    category: null,
    services: [],
    rzdSocialPackage: false,

    id: null,
    status: EOfferStatus.Draft,
    code: null,
    approvingAdmin: null,
    pausedReason: null,
    lastStatusDate: null,
    lastStatusAuthor: null,
    displayPriceItem: null,
    moderator: null,
    rejectionReasonType: null,
    createdAt: '',
    createdBy: '',
    updatedAt: null,
    updatedBy: null,
    lastStatusComment: null,
    canDelete: null,
  };
};

export const convertBookingOfferToBookingOfferView = (offer: BookingOffer): BookingOfferView => {
  const offerView = {
    name: offer.name,
    partner: offer.partner,
    description: offer.description,
    images: offer.images,
    responsiblePersons: offer.responsiblePersons ?? [],
    address: offer.address,
    routeDescription: offer.routeDescription,
    contacts: offer.contacts ?? [],
    category: offer.category,
    services: offer.services ? offer.services.map(item => convertOfferServiceToView(item)) : [],
    rzdSocialPackage: offer.rzdSocialPackage,

    id: offer.id,
    status: offer.status,
    code: offer.code,
    approvingAdmin: offer.approvingAdmin,
    pausedReason: offer.pausedReason,
    lastStatusDate: offer.lastStatusDate,
    lastStatusAuthor: offer.lastStatusAuthor,
    displayPriceItem: offer.displayPriceItem,
    moderator: offer.moderator,
    rejectionReasonType: offer.rejectionReasonType,
    createdAt: offer.createdAt,
    createdBy: offer.createdBy,
    updatedAt: offer.updatedAt,
    updatedBy: offer.updatedBy,
    lastStatusComment: offer.lastStatusComment,
    canDelete: offer.canDelete,
  };
  return { ...offerView, displayPriceItem: offerView.displayPriceItem ?? getBookingOfferDisplayPriceItem(offerView) };
};

export const convertOfferServiceToView = (serviceView: BookingOfferService): BookingOfferServiceView => {
  const {
    id,
    category,
    name,
    description,
    status,
    offer,
    orderByDateType,
    priceItems,
    rzdSocialPackage,
    rzdSocialPackageInfo,
  } = serviceView;

  return {
    id,
    category,
    name,
    description,
    status,
    offer,
    orderByDateType,
    priceItems: priceItems ?? [],
    rzdSocialPackage,
    rzdSocialPackageInfo,
  };
};

export const convertBookingOfferViewToUpdateRequest = (
  offerView: Omit<BookingOfferView, 'services'> & { readonly services: BookingOfferService[] }
): BookingOfferUpdateRequest => {
  const {
    name,
    partner,
    description,
    images,
    responsiblePersons,
    address,
    routeDescription,
    contacts,
    category,
    services,
    rzdSocialPackage,
  } = offerView;

  return {
    name,
    partner,
    description,
    images,
    responsiblePersons,
    address,
    routeDescription,
    contacts,
    category,
    rzdSocialPackage,
    services: services.map(service => ({ id: service.id })),
  };
};

export const convertBookingOfferViewToCreateRequest = (offerView: BookingOfferView): BookingOfferCreateRequest => {
  const {
    name,
    partner,
    description,
    images,
    responsiblePersons,
    address,
    routeDescription,
    contacts,
    category,
    rzdSocialPackage,
  } = offerView;

  return {
    name,
    partner,
    description,
    images,
    responsiblePersons,
    address,
    routeDescription,
    contacts,
    category,
    rzdSocialPackage,
  };
};

const bookingOfferGetValidationCommonError = (
  step: BookingOfferCreateStepType,
  count: number
): Nullable<ValidationResult<any>> => {
  if (!count) {
    return null;
  }

  switch (step) {
    case EBookingOfferStep.Services:
      return {
        common: {
          type: EValidationType.Error,
          hasError: true,
          message: 'Заполните услуги',
        },
      };
    default:
      return {
        common: {
          type: EValidationType.Error,
          hasError: true,
          message: `Не заполнено ${count} ${pluralize(count, ['поле', 'поля', 'полей'])}`,
        },
      };
  }
};

export const bookingOfferCreateInitValidationStepper = (
  validation: ValidationResult<BookingOfferView>,
  validationResponsiblePersons: ValidationCollectionResult<BookingOfferResponsiblePerson>,
  validationContacts: ValidationCollectionResult<BookingOfferContact>,
  validationServices: ValidationCollectionResult<BookingOfferServiceView>
) => {
  const generalErrorsCount = [
    validation.responsiblePersons,
    validation.category,
    validation.address,
    validation.name,
    validation.images,
    validation.description,
  ]
    .concat(validationResponsiblePersons.flatMap(item => (item ? [item.fullName, item.phone, item.email] : [])))
    .filter(item => !!item).length;

  const contactsErrorsCount = [validation.contacts, validation.routeDescription]
    .concat(validationContacts.flatMap(item => (item ? [item.name, item.phone, item.email, item.address] : [])))
    .filter(item => !!item).length;

  // TODO - booking добавить фалидацию по тарифам!
  const servicesErrorsCount = [validation.services]
    .concat(
      validationServices.flatMap(item =>
        item ? [item.name, item.description, item.category, item.orderByDateType] : []
      )
    )
    .filter(item => !!item).length;

  return {
    [EBookingOfferStep.General]: bookingOfferGetValidationCommonError(EBookingOfferStep.General, generalErrorsCount),
    [EBookingOfferStep.Contacts]: bookingOfferGetValidationCommonError(EBookingOfferStep.Contacts, contactsErrorsCount),
    [EBookingOfferStep.Services]: bookingOfferGetValidationCommonError(EBookingOfferStep.Services, servicesErrorsCount),
  };
};

export const getBookingOfferCreateSteps = (): BookingOfferCreateStepType[] => {
  return [EBookingOfferStep.General, EBookingOfferStep.Contacts, EBookingOfferStep.Services];
};

export const bookingOfferImageRestrictions: ImageRestrictions = {
  recommendedWidthInPx: 964,
  recommendedHeightInPx: 660,
  maxFileSizeInBytes: 1 * 1024 * 1024,
  whStrict: true,
  fileAccept: [fileTypes.jpg.ext, fileTypes.jpeg.ext, fileTypes.png.ext],
  fileType: [fileTypes.jpg.mimeType, fileTypes.jpeg.mimeType, fileTypes.png.mimeType],
};
