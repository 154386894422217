import { removeSendProfileQueryActionRule } from './rules';
import * as schemas from './scheme';
import { CustomerLifeCycleRulesConfiguration } from './types';

/**
 * конфигурация правил админа
 */
export const commonAdminConfiguration: CustomerLifeCycleRulesConfiguration = {
  statusScheme: schemas.adminStatusScheme.statusScheme,
  actionsScheme: schemas.adminStatusScheme.actionsScheme,
  actionRules: [removeSendProfileQueryActionRule],
};

/**
 * конфигурация правил партнёра
 */
export const commonPartnerConfiguration: CustomerLifeCycleRulesConfiguration = {
  statusScheme: schemas.partnerStatusScheme.statusScheme,
  actionsScheme: schemas.partnerStatusScheme.actionsScheme,
  actionRules: [removeSendProfileQueryActionRule],
};
