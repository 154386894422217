import { ECmsLinkObjectType } from '@/domain';
import EntityMasterPreviewWrapper from '../../../../components/common/entityMaster/preview/wrapper';
import CmsPreviewByContainerType from '../../components/preview/byContainerType';
import { CmsContainerView, CmsLinkedObject } from '../../types';
import { ColumnWrapper, PreviewWrapper, Wrapper } from './controls';
import CmsContainerDetailsStepBanner from './steps/banner';
import CmsContainerDetailsStepCollection from './steps/collection';
import CmsContainerDetailsStepGeneral from './steps/general';
import { CmsContainerDetailsComponentDictionaries, CmsContainerDetailsConfiguration } from './types';

interface CmsContainerDetailsProps {
  readonly configuration: CmsContainerDetailsConfiguration;
  readonly guid: UUID;
  readonly cmsContainer: CmsContainerView;
  readonly cmsLinkedObject: Nullable<CmsLinkedObject>;
  readonly cmsLinkedObjects: CmsLinkedObject[];
  readonly dictionaries: CmsContainerDetailsComponentDictionaries;
}

const CmsContainerDetails = (props: CmsContainerDetailsProps) => {
  const { guid, cmsContainer, cmsLinkedObject, cmsLinkedObjects, configuration, dictionaries } = props;

  return (
    <Wrapper>
      <ColumnWrapper>
        {!cmsLinkedObject && (
          <CmsContainerDetailsStepGeneral
            configuration={configuration}
            cmsContainer={cmsContainer}
            dictionaries={dictionaries}
          />
        )}
        {cmsLinkedObject?.type === ECmsLinkObjectType.Banner && (
          <CmsContainerDetailsStepBanner
            guid={guid}
            cmsBanner={cmsLinkedObject.banner}
            cmsContainer={cmsContainer}
          />
        )}
        {cmsLinkedObject?.type === ECmsLinkObjectType.Collection && (
          <CmsContainerDetailsStepCollection cmsCollection={cmsLinkedObject.collection} />
        )}
      </ColumnWrapper>

      {cmsContainer.type && (
        <ColumnWrapper>
          <PreviewWrapper>
            <EntityMasterPreviewWrapper label={'Предпросмотр'}>
              {({ mode }) => (
                <CmsPreviewByContainerType
                  mode={mode}
                  canActivateItem
                  type={cmsContainer.type!.code}
                  cmsLinkedObjects={cmsLinkedObjects}
                  activePreviewNumber={cmsLinkedObject && cmsLinkedObjects.indexOf(cmsLinkedObject)}
                />
              )}
            </EntityMasterPreviewWrapper>
          </PreviewWrapper>
        </ColumnWrapper>
      )}
    </Wrapper>
  );
};

export default CmsContainerDetails;
