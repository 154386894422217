import moment from 'moment';
import { userInfoView } from 'presentation/utils/userInfoView';
import { ESortDirection, EDateTimeFormat, ProductOrder } from '@/domain';
import DataTable, { DataTableMetadata, DataTableRow } from '../../../components/common/table';
import TableActionsContainer from '../../../components/common/table/actions/container';
import { DataTableLoader } from '@components/common/table/loader';
import { PanelActions } from '../../../types';
import { formatCost } from '../../../utils';
import { useProductOrderActionsSelectors } from '../actions/useAction';
import { EProductOrderTableColumn, ProductOrderActionTableType } from '../types';
import { productOrdersIsFetchingSelector } from './store/selectors';

interface ProductOrderTableProps {
  readonly metadata: DataTableMetadata<EProductOrderTableColumn>;
  readonly sort: {
    readonly column: string;
    readonly direction: ESortDirection;
  };
  readonly orders: ProductOrder[];
  readonly getActions: (order: ProductOrder) => PanelActions<ProductOrderActionTableType>;
  readonly onRequestSort: (column: EProductOrderTableColumn, direction: ESortDirection) => void;
  readonly onOrderClick: (order: ProductOrder) => void;
  readonly onActionClick: (action: ProductOrderActionTableType, order: ProductOrder) => void;
  readonly onChangeMetadata?: (metadata: DataTableMetadata<EProductOrderTableColumn>) => void;
}

const ProductOrderTable = (props: ProductOrderTableProps) => {
  const { metadata, sort, orders, getActions, onRequestSort, onOrderClick, onActionClick, onChangeMetadata } = props;

  const { getIsProductOrderProcessingSelector } = useProductOrderActionsSelectors();

  const rows: DataTableRow<ProductOrder, EProductOrderTableColumn>[] = orders.map(order => {
    const {
      createdAt,
      number: orderNumber,
      customer,
      cost,
      lastStatusComment,
      cancelReason,
      customerCancelReason,
      items,
    } = order;

    return {
      [EProductOrderTableColumn.CreateAt]: moment(createdAt).format(EDateTimeFormat.DisplayDefault),
      [EProductOrderTableColumn.Number]: orderNumber,
      [EProductOrderTableColumn.CustomerName]: customer && userInfoView(customer).fullName,
      [EProductOrderTableColumn.Cost]: formatCost(cost) ?? '',
      [EProductOrderTableColumn.CustomerCancelReason]: customerCancelReason?.name,
      [EProductOrderTableColumn.CancelReason]: cancelReason?.name,
      [EProductOrderTableColumn.ManagerComment]: lastStatusComment,
      [EProductOrderTableColumn.ItemsCount]: items?.reduce((prev, item) => prev + (item?.qty ?? 0), 0),
      data: order,
    };
  });

  return (
    <DataTable<ProductOrder, EProductOrderTableColumn>
      width='auto'
      overflowX='inherit'
      rowActions
      metadata={metadata}
      rows={rows}
      sort={{ column: sort.column, direction: sort.direction }}
      loader={<DataTableLoader selector={productOrdersIsFetchingSelector} />}
      onSort={(event, column, direction) => onRequestSort(column as EProductOrderTableColumn, direction)}
      onRowClick={(event, cell, { data }) => onOrderClick(data)}
      onChangeMetadata={onChangeMetadata}
      getRowActions={order => (
        <TableActionsContainer<ProductOrder, ProductOrderActionTableType>
          entityLink={order}
          actions={getActions(order)}
          onExecute={onActionClick}
          isFetchingSelector={getIsProductOrderProcessingSelector(order.id)}
        />
      )}
    />
  );
};

export default ProductOrderTable;
