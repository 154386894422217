import styled from '@emotion/styled';
import { MPButton } from '@ui-kit/button';
import { MPNaturalNumberInput } from '@ui-kit/input';
import { MPSelect } from '@ui-kit/select';
import { forwardRef } from 'react';

interface WrapperProps {
  readonly dense?: boolean;
  readonly children: any;
}

interface PaginationWrapperProps {
  readonly dense?: boolean;
  readonly children: any;
}

export const Wrapper = styled(
  forwardRef(({ dense, ...others }: WrapperProps, ref: any) => (
    <div
      ref={ref}
      {...others}
    />
  ))
)`
  ${p => p.dense && 'border-top: 1px solid ${p => p.theme.palette.secondary.main};'}

  background: #fff;

  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
`;

export const PaginationWrapper = styled.div<PaginationWrapperProps>`
  padding: ${p =>
    `${p.theme.spacing(p.dense ? 1 : 2)} ${p.theme.spacing(p.dense ? 0 : 2)} ${p.theme.spacing(p.dense ? 1 : 2)} 0`};
`;

export const NavButton = styled(MPButton)`
  padding-top: ${p => p.theme.spacing(0.9)};
  padding-bottom: ${p => p.theme.spacing(0.9)};
`;

export const NavButtonLeft = styled(NavButton)`
  padding-left: ${p => p.theme.spacing(0.2)};
`;

export const NavButtonRight = styled(NavButton)`
  padding-right: ${p => p.theme.spacing(0.2)};
`;

export const GoToPageButton = styled(NavButton)`
  width: auto;
  min-width: ${p => p.theme.spacing(4.2)};

  margin-left: ${p => p.theme.spacing()};
  padding: ${p => p.theme.spacing(1.3)} ${p => p.theme.spacing(0.1)} ${p => p.theme.spacing(1.3)}
    ${p => p.theme.spacing(0.2)};
`;

export const PageNumberInput = styled(MPNaturalNumberInput)`
  width: ${p => p.theme.spacing(5)};
  min-height: 0;

  .MuiInputBase-root {
    min-height: 1.5rem;
    border-radius: ${p => p.theme.shape.borderRadius}px;
  }

  .MuiInputBase-input {
    text-align: center;
  }
`;

export const PagesAmountSelect = styled(MPSelect)`
  min-height: 0;
  border-radius: ${p => p.theme.shape.borderRadius}px;

  & .MuiOutlinedInput-input {
    padding-top: ${p => p.theme.spacing(0.2)};
    padding-bottom: ${p => p.theme.spacing(0.2)};
    padding-right: ${p => p.theme.spacing(4)};
    padding-left: ${p => p.theme.spacing()};
  }
` as any;
