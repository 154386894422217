import { DefaultFooterWrapper } from '@components/common/wrappers/footer';
import MasterActionsComponent from '../../../../components/common/actions/master';
import { CustomerActionDetailsType } from '../../types';
import { useContextLifecycle } from '../hooks/useContextLifecycle';

const CustomerDetailsFooterAdapter = () => {
  const { actions, onAction } = useContextLifecycle();

  return (
    <MasterActionsComponent<CustomerActionDetailsType>
      show={!!actions.length}
      actions={actions}
      onAction={onAction}
      wrapper={DefaultFooterWrapper}
    />
  );
};

export default CustomerDetailsFooterAdapter;
