import { useAuthentication } from '@mp-npm/mp-auth-client';
import { useEffect } from 'react';
import HttpClient from '../data/network/http';
import store from '../data/store/store';
import SentryClient from '../integration/sentry/client';
import ContentLoader from '../presentation/components/common/loader';
import RenderDecoratorContainer from '../presentation/components/common/renderDecoratorContainer';
import AuthEntry from '../presentation/features/auth/entry';
import BreadcrumbsProvider from '../presentation/features/general/breadcrumbs/prodiver';
import AppConfiguration from '../presentation/features/general/config';
import ErrorBoundary from '../presentation/features/general/errorBoundary/index';
import HistoryExtensionContainer from '../presentation/features/general/historyExtension/container';
import MetadataContainer from '../presentation/features/general/metadata/container';
import NotifierContainer from '../presentation/features/general/notifier/container';
import LegalAgreementContainer from '../presentation/features/legal/agreement/container';
import CheckSignsContainer from '../presentation/features/legal/checkSigns/container';
import MainContainer from '../presentation/features/main/container';
import MainSecurity from '../presentation/features/main/security';
import Notifier from '../system/notifier';

const App = () => {
  const { initialized, authService, isAuthenticated } = useAuthentication();
  useEffect(() => {
    Notifier.getInstance().init(store.dispatch);
  }, []);

  if (!initialized) {
    return <ContentLoader />;
  }

  HttpClient.getInstance().setAuthService(authService);
  SentryClient.getInstance().setAuthService(authService);

  return (
    <AppConfiguration>
      <ErrorBoundary
        variant={SentryClient.getInstance().isActive() ? 'sentry' : 'common'}
        onLogout={isAuthenticated && authService.logout}
      >
        <BreadcrumbsProvider>
          <NotifierContainer />
          <HistoryExtensionContainer />
          {isAuthenticated ? (
            <RenderDecoratorContainer
              decorators={[
                <LegalAgreementContainer key='legalAgreementContainer' />,
                <CheckSignsContainer key='checkSignsContainer' />,
              ]}
            >
              <MainSecurity>
                <MetadataContainer>
                  <MainContainer />
                </MetadataContainer>
              </MainSecurity>
            </RenderDecoratorContainer>
          ) : (
            <AuthEntry />
          )}
        </BreadcrumbsProvider>
      </ErrorBoundary>
    </AppConfiguration>
  );
};

export default App;
