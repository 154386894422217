import { Grid, Link, Typography } from '@mui/material';
import { EUserStatus, CommonUserData } from '@/domain';

interface UserMpDetailsGeneralHeader {
  readonly user: CommonUserData;
}

const UserMpDetailsGeneral = ({ user }: UserMpDetailsGeneralHeader) => {
  const { firstName, middleName, lastName, phone, email } = user;
  const isDeleted = 'status' in user && user.status === EUserStatus.Deleted;

  return (
    <Grid
      container
      spacing={3}
      direction='column'
    >
      <Grid item>
        <Typography
          variant='body2'
          color='textSecondary'
        >
          Фамилия
        </Typography>
        <Typography>{isDeleted ? 'Аккаунт удален' : lastName ?? '-'}</Typography>
      </Grid>

      <Grid item>
        <Typography
          variant='body2'
          color='textSecondary'
        >
          Имя
        </Typography>
        <Typography>{firstName ?? '-'}</Typography>
      </Grid>

      <Grid item>
        <Typography
          variant='body2'
          color='textSecondary'
        >
          Отчество
        </Typography>
        <Typography>{middleName ?? '-'}</Typography>
      </Grid>

      <Grid item>
        <Typography
          variant='body2'
          color='textSecondary'
        >
          Телефон
        </Typography>
        {phone ? (
          <Typography
            component={Link}
            href={`tel:${phone}`}
            color='textPrimary'
          >
            {phone ?? '-'}
          </Typography>
        ) : (
          <Typography>-</Typography>
        )}
      </Grid>

      <Grid item>
        <Typography
          variant='body2'
          color='textSecondary'
        >
          Электронная почта
        </Typography>
        {email && !isDeleted ? (
          <Typography
            component={Link}
            href={`mailto:${email}`}
            color='textPrimary'
          >
            {email}
          </Typography>
        ) : (
          <Typography>-</Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default UserMpDetailsGeneral;
