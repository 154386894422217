import { GeneralInfoWrapper } from '@features/customer/details/controls';
import UserMpDetailsGeneral from '@features/user/components/detailsGeneral';
import { useSelector } from 'react-redux';
import { customerDetailsCustomerSelector } from '../store/selectors';

export const CustomerDetailsGeneralInfoAdapter = () => {
  const customer = useSelector(customerDetailsCustomerSelector);

  return customer ? (
    <GeneralInfoWrapper>
      <UserMpDetailsGeneral user={customer} />
    </GeneralInfoWrapper>
  ) : null;
};
