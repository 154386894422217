import {
  EPartnerOwnershipType,
  EPartnerStatus,
  EPartnerType,
  ETaxationSystemType,
  Partner,
  SportOptionTyped,
} from '@/domain';
import { OnChangeObjectAttribute } from '../../../types';
import { ValidationResult } from '../../../utils/validation';
import { EPartnerStep } from '../../general/partner/types';
import { PartnerDataStepPermissions } from '../../general/partner/validation';
import PartnerApplicationViewStepAddress from '../components/steps/address/view';
import PartnerApplicationViewStepDetails from '../components/steps/details/view';
import PartnerApplicationViewStepDocuments from '../components/steps/documents/view';
import PartnerApplicationViewStepGeneral from '../components/steps/general/view';
import PartnerApplicationEditStepPermissions from '../components/steps/permissions/edit';
import PartnerApplicationViewStepPermissions from '../components/steps/permissions/view';
import { PartnerPermissionsAttributes } from '../types';
import { ColumnWrapper, Content, Wrapper } from './controls';

interface PartnerApplicationVerificationProps {
  readonly partnerApplication: Partner;
  readonly partnerTypes: SportOptionTyped<EPartnerType>[];
  readonly step: EPartnerStep;
  readonly isValidPermissions: boolean;
  readonly isVerifier: boolean;
  readonly ownerShipTypes: SportOptionTyped<EPartnerOwnershipType>[];
  readonly taxationSystemTypes: SportOptionTyped<ETaxationSystemType>[];
  readonly validationPermissions: Nullable<ValidationResult<PartnerDataStepPermissions>>;
  readonly onChangeAttribute: OnChangeObjectAttribute<PartnerPermissionsAttributes>;
}

const PartnerApplicationVerification = (props: PartnerApplicationVerificationProps) => {
  const {
    partnerApplication,
    partnerTypes,
    step,
    isValidPermissions,
    isVerifier,
    ownerShipTypes,
    taxationSystemTypes,
    validationPermissions,
    onChangeAttribute,
  } = props;

  const { status } = partnerApplication;

  return (
    <Wrapper>
      <ColumnWrapper>
        {step === EPartnerStep.General && (
          <Content>
            <PartnerApplicationViewStepGeneral
              partnerApplication={partnerApplication}
              ownerShipTypes={ownerShipTypes}
            />
          </Content>
        )}
        {step === EPartnerStep.Address && (
          <Content>
            <PartnerApplicationViewStepAddress
              address={partnerApplication.address}
              orgType={partnerApplication.regInfo?.orgType ?? null}
            />
          </Content>
        )}
        {step === EPartnerStep.Details && (
          <Content>
            <PartnerApplicationViewStepDetails
              partnerApplication={partnerApplication}
              taxationSystemTypes={taxationSystemTypes}
            />
          </Content>
        )}
        {step === EPartnerStep.Documents && (
          <Content>
            <PartnerApplicationViewStepDocuments partnerApplication={partnerApplication} />
          </Content>
        )}
        {step === EPartnerStep.Permissions && (
          <Content>
            {isVerifier && status === EPartnerStatus.OnVerification ? (
              <PartnerApplicationEditStepPermissions
                agreements={partnerApplication.agreements}
                isValid={isValidPermissions}
                partnerTypes={partnerTypes}
                permissions={partnerApplication.permissions}
                partnerType={partnerApplication.type}
                clientOrg={partnerApplication.clientOrg}
                validation={validationPermissions}
                onChangePermissions={permissions => onChangeAttribute('permissions', permissions)}
                onChangeAgreements={agreements => onChangeAttribute('agreements', agreements)}
                onChangeType={newType => onChangeAttribute('type', newType)}
                onChangeClientOrg={newClientOrg => onChangeAttribute('clientOrg', newClientOrg)}
              />
            ) : (
              <PartnerApplicationViewStepPermissions
                partnerApplication={partnerApplication}
                partnerTypes={partnerTypes}
              />
            )}
          </Content>
        )}
      </ColumnWrapper>
    </Wrapper>
  );
};

export default PartnerApplicationVerification;
