import { UserFieldView } from '@components/common/userInfoView';
import SportsmanTableCell, { SportsmanTableCellCommonProps } from './index';

const SportsmanTableCellDepartmentName = (props: SportsmanTableCellCommonProps) => {
  const { sportsman } = props;

  return (
    <SportsmanTableCell
      sportsman={sportsman}
      value={
        <UserFieldView
          user={sportsman}
          field={'departmentName'}
        />
      }
    />
  );
};

export default SportsmanTableCellDepartmentName;
