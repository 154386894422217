import ErrorHandler from '@/data/network/errorHandler';
import { ServerErrorResponse } from '@/data/network/types';
import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault, fetchableFailed, fetchableFetched, fetchableFetching } from '@/data/store/types';
import { ClientOrgUser } from '@/domain';
import { ClientOrg } from '@/domain/model/clientOrg';
import { Nullable, UUID } from '@/domain/model/types';
import clientOrgServices from '@features/clientOrg/services';
import userServices from '@features/user/services';
import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { EClientOrgUserActionType } from '../../types';

export interface ClientOrgUserActionsState {
  readonly actions: (Fetchable & {
    id: UUID;
    type: EClientOrgUserActionType;
    error: Nullable<ServerErrorResponse>;
  })[];
  readonly upload: Fetchable;
  readonly dialogs: {
    readonly import: Nullable<ClientOrg>;
  };
}

type Reducer<T = undefined> = CaseReducer<ClientOrgUserActionsState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<ClientOrgUserActionsState> {
  clientOrgUserActionsChangeDialogState: Reducer<{
    name: keyof ClientOrgUserActionsState['dialogs'];
    data: Nullable<ClientOrg>;
  }>;
  clientOrgUserActionsOptimize: Reducer;
}

const getActionProcess = (state: ClientOrgUserActionsState, id: UUID, actionType: EClientOrgUserActionType) => {
  let process = state.actions.find(change => change.id === id);
  if (process) return process;

  process = {
    ...fetchableDefault,
    id,
    type: actionType,
    error: null,
  };
  state.actions.push(process);

  return process;
};

export const clientOrgUserActionsImport = createAsyncThunk<string, { id: UUID; file: File }, AppThunkAPIConfig>(
  'clientOrgUser/actions/import',
  async ({ id, file }, { getState, rejectWithValue }) => {
    try {
      return await userServices.clientOrgUser.import({ clientOrgId: id, file });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue({
        error: e.response.data,
      });
    }
  }
);

export const clientOrgUserActionsResendWelcomeMail = createAsyncThunk<
  ClientOrg,
  {
    id: UUID;
    clientOrgUsers: ClientOrgUser[];
  },
  AppThunkAPIConfig
>('clientOrgUser/actions/resendWelcomeMail', async ({ id, clientOrgUsers }, { rejectWithValue }) => {
  try {
    return await clientOrgServices.clientOrg.resendWelcomeMail({
      id,
      userIds: clientOrgUsers.map(clientOrgUser => clientOrgUser.id),
    });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue({
      error: e.response.data,
    });
  }
});

const slice = createSlice<ClientOrgUserActionsState, Reducers>({
  name: 'clientOrgUser/actions',
  initialState: {
    actions: [],
    upload: fetchableDefault,
    dialogs: {
      import: null,
    },
  },
  reducers: {
    clientOrgUserActionsChangeDialogState: (state, { payload }) => {
      const { name, data } = payload;
      state.dialogs[name] = data;
    },
    clientOrgUserActionsOptimize: state => {
      // Оставляем только выполняющиеся действия
      state.actions = state.actions.filter(action => action.isFetching);
      if (!state.upload.isFetching) {
        state.upload = fetchableDefault;
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(clientOrgUserActionsResendWelcomeMail.pending, (state, { meta }) => {
        const { clientOrgUsers } = meta.arg;

        clientOrgUsers.forEach(clientOrgUser => {
          const actionType = EClientOrgUserActionType.ResendWelcomeMail;
          const process = getActionProcess(state, clientOrgUser.id, actionType);

          process.isFetching = true;
          process.isFetched = false;
          process.isFailed = false;

          process.id = clientOrgUser.id;
          process.type = actionType;
          process.error = null;
        });
      })
      .addCase(clientOrgUserActionsResendWelcomeMail.fulfilled, (state, { meta }) => {
        const { clientOrgUsers } = meta.arg;

        clientOrgUsers.forEach(clientOrgUser => {
          const actionType = EClientOrgUserActionType.ResendWelcomeMail;
          const process = getActionProcess(state, clientOrgUser.id, actionType);

          process.isFetching = false;
          process.isFetched = true;
          process.isFailed = false;

          process.id = clientOrgUser.id;
          process.type = actionType;
          process.error = null;
        });
      })
      .addCase(clientOrgUserActionsResendWelcomeMail.rejected, (state, { meta }) => {
        const { clientOrgUsers } = meta.arg;

        clientOrgUsers.forEach(clientOrgUser => {
          const actionType = EClientOrgUserActionType.ResendWelcomeMail;
          const process = getActionProcess(state, clientOrgUser.id, actionType);

          process.isFetching = false;
          process.isFetched = false;
          process.isFailed = true;

          process.id = clientOrgUser.id;
          process.type = actionType;
          process.error = null;
        });
      })
      .addCase(clientOrgUserActionsImport.pending, state => {
        state.upload = fetchableFetching;
      })
      .addCase(clientOrgUserActionsImport.fulfilled, state => {
        state.upload = fetchableFetched;
      })
      .addCase(clientOrgUserActionsImport.rejected, state => {
        state.upload = fetchableFailed;
      });
  },
});

export const { clientOrgUserActionsOptimize, clientOrgUserActionsChangeDialogState } = slice.actions;

export default slice.reducer;
