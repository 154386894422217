import ErrorHandler from '@/data/network/errorHandler';
import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault } from '@/data/store/types';
import { ESortDirection, Nullable, Pageable, RzdRestSocialPackageApplication, UUID } from '@/domain';
import { PaginationSize } from '@/presentation/types';
import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { SocialPackageApplicationTableFilterValues } from '../../filterUtils';
import socialPackageApplicationServices, { AllCommandProps } from '../../services';
import { ESocialPackageApplicationTableColumn, SocialPackageApplicationTableSort } from '../../types';

const defaultSort = { column: ESocialPackageApplicationTableColumn.CreatedAt, direction: ESortDirection.Desc };

export interface SocialPackageApplicationListState extends Fetchable, Pageable<RzdRestSocialPackageApplication> {
  readonly guid: Nullable<UUID>;
  readonly needRefreshWatcher: number;
  readonly pageNumber: number;
  readonly totalCount: number;
  readonly pageCount: number;
  readonly filter: SocialPackageApplicationTableFilterValues;
  readonly allSocialPackageApplications: Fetchable & {
    readonly count: Nullable<number>;
    readonly needRefreshWatcher: number;
  };
  readonly search: {
    readonly sort: Nullable<SocialPackageApplicationTableSort>;
    readonly pageSize: PaginationSize;
  };
}

type Reducer<T = undefined> = CaseReducer<SocialPackageApplicationListState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<SocialPackageApplicationListState> {
  socialPackageApplicationsStateReset: Reducer;
  socialPackageApplicationsDataReset: Reducer;
  socialPackageApplicationsStartSession: Reducer<{ guid: UUID }>;
  socialPackageApplicationsSetPageSize: Reducer<{ pageSize: PaginationSize }>;
  socialPackageApplicationsSetPage: Reducer<{ pageNumber: number }>;
  socialPackageApplicationsNeedRefreshWatcherReset: Reducer;
  socialPackageApplicationsNeedRefreshWatcherIncrement: Reducer;
  socialPackageApplicationsSortReset: Reducer;
  socialPackageApplicationsSetSort: Reducer<Nullable<SocialPackageApplicationTableSort>>;
}

type SocialPackageApplicationsFetchProps = Omit<AllCommandProps, 'signal'>;

export const socialPackageApplicationsFetch = createAsyncThunk<
  Pageable<RzdRestSocialPackageApplication>,
  SocialPackageApplicationsFetchProps,
  AppThunkAPIConfig
>('socialPackageApplication/list/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    return await socialPackageApplicationServices.all({ ...props, signal });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

const initialState: SocialPackageApplicationListState = {
  guid: null,
  needRefreshWatcher: 0,
  pageNumber: 1,
  totalCount: 0,
  pageCount: 0,
  filter: {},
  allSocialPackageApplications: {
    ...fetchableDefault,
    count: null,
    needRefreshWatcher: 0,
  },
  ...fetchableDefault,
  data: [],
  search: {
    sort: defaultSort,
    pageSize: 10,
  },
};

const slice = createSlice<SocialPackageApplicationListState, Reducers>({
  name: 'list',
  initialState,
  reducers: {
    socialPackageApplicationsStateReset: () => {
      return initialState;
    },
    socialPackageApplicationsDataReset: state => {
      state.data = [];
      state.totalCount = 0;
      state.pageCount = 0;
      state.pageNumber = 1;
    },
    socialPackageApplicationsStartSession: (state, { payload }) => {
      const { guid } = payload;

      if (guid !== state.guid) {
        return { ...initialState, guid };
      }
    },
    socialPackageApplicationsSetPageSize: (state, { payload }) => {
      const { pageSize } = payload;
      state.pageNumber = 1;
      state.search.pageSize = pageSize;
      state.needRefreshWatcher++;
    },
    socialPackageApplicationsSetPage: (state, { payload }) => {
      const { pageNumber } = payload;
      state.pageNumber = pageNumber;
      state.needRefreshWatcher++;
    },
    socialPackageApplicationsNeedRefreshWatcherReset: state => {
      state.needRefreshWatcher = 0;
    },
    socialPackageApplicationsNeedRefreshWatcherIncrement: state => {
      state.needRefreshWatcher++;
    },
    socialPackageApplicationsSetFilter: (state, { payload }) => {
      state.filter = payload;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    socialPackageApplicationsSetSort: (state, { payload }) => {
      state.search.sort = payload;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    socialPackageApplicationsSortReset: state => {
      state.search = {
        ...state.search,
        sort: defaultSort,
      };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(socialPackageApplicationsFetch.pending, state => {
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;
      })
      .addCase(socialPackageApplicationsFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;

        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;

        state.data = data;
        state.totalCount = totalCount;
        state.pageCount = pageCount;
      })
      .addCase(socialPackageApplicationsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = true;
          state.data = [];
        }
      });
  },
});

export const {
  socialPackageApplicationsStateReset,
  socialPackageApplicationsDataReset,
  socialPackageApplicationsStartSession,
  socialPackageApplicationsSetPageSize,
  socialPackageApplicationsSetPage,
  socialPackageApplicationsSetFilter,
  socialPackageApplicationsSortReset,
  socialPackageApplicationsSetSort,
  socialPackageApplicationsNeedRefreshWatcherReset,
  socialPackageApplicationsNeedRefreshWatcherIncrement,
} = slice.actions;

export default slice.reducer;
