import { ClientOrg, ClientOrgUser, ESortDirection } from '@/domain';
import { getDataFilterValuesByStrategies } from '@/presentation/utils/filtering';
import { addSearchParamToLocation } from '@/routing/globalRouting';
import ClientOrgUserActionsContext from '@features/user/userClientOrg/actions/context';
import { PaginationSize } from 'presentation/types';
import { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { ClientOrgUserTableFilterEditStrategy } from '../../filterUtils';
import { EClientOrgUserTableTab } from '../../types';
import { EClientOrgUserUrlParam } from '../entry';
import {
  clientOrgUsersAllSelect,
  clientOrgUsersAllUnselect,
  clientOrgUsersDataReset,
  clientOrgUsersNeedRefreshWatcherIncrement,
  clientOrgUsersSelect,
  clientOrgUsersSetFilter,
  clientOrgUsersSetPage,
  clientOrgUsersSetPageSize,
  clientOrgUsersSetSort,
  clientOrgUsersSortReset,
  clientOrgUsersUnselect,
} from '../store/slice';

export type UseClientOrgUsersTable = {
  readonly onTryCreate: (clientOrg: ClientOrg) => void;
  readonly onRefresh: () => void;
  readonly onResetSort: () => void;
  readonly onChangePage: (page: number) => void;
  readonly onChangeTab: (newTab: EClientOrgUserTableTab) => void;
  readonly onChangePageSize: (pageSize: PaginationSize) => void;
  readonly onChangeFilter: (strategies: ClientOrgUserTableFilterEditStrategy[]) => void;
  readonly onChangeSort: (name: string, direction: ESortDirection) => void;
  readonly onClientOrgUserSelect: (clientOrgUser: ClientOrgUser, selected: boolean) => void;
  readonly onAllClientOrgUserSelect: (selected: boolean) => void;

  readonly onClientOrgUserResendWelcomeMail: (clientOrg: ClientOrg, clientOrgUsers: ClientOrgUser[]) => void;
};

type UseClientOrgUsersTableHandlersProps = {
  readonly guid: UUID;
  readonly tab: EClientOrgUserTableTab;
};

export const useClientOrgUsersTableHandlers = ({
  guid,
  tab,
}: UseClientOrgUsersTableHandlersProps): UseClientOrgUsersTable => {
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();
  const { onTryImport, onResendWelcomeMail } = useContext(ClientOrgUserActionsContext);

  const onTryCreate = useCallback<UseClientOrgUsersTable['onTryCreate']>(onTryImport, [onTryImport]);

  const onClientOrgUserResendWelcomeMail = useCallback<UseClientOrgUsersTable['onClientOrgUserResendWelcomeMail']>(
    (clientOrg, clientOrgUsers) => {
      onResendWelcomeMail(clientOrg, clientOrgUsers);
    },
    [dispatch]
  );

  const onRefresh = useCallback<UseClientOrgUsersTable['onRefresh']>(() => {
    dispatch(clientOrgUsersAllUnselect());
    dispatch(clientOrgUsersNeedRefreshWatcherIncrement());
  }, [dispatch]);

  const onChangeFilter = useCallback<UseClientOrgUsersTable['onChangeFilter']>(
    (strategies: ClientOrgUserTableFilterEditStrategy[]) => {
      dispatch(clientOrgUsersSetFilter(getDataFilterValuesByStrategies(strategies)));
    },
    [dispatch]
  );

  const onResetSort = useCallback<UseClientOrgUsersTable['onResetSort']>(
    () => dispatch(clientOrgUsersSortReset()),
    [dispatch]
  );

  const onChangePage = useCallback<UseClientOrgUsersTable['onChangePage']>(
    (page: number) => {
      dispatch(clientOrgUsersSetPage({ pageNumber: page }));
    },
    [dispatch]
  );

  const onChangePageSize = useCallback<UseClientOrgUsersTable['onChangePageSize']>(
    (pageSize: PaginationSize) => {
      dispatch(clientOrgUsersSetPageSize({ pageSize }));
    },
    [dispatch]
  );

  const onChangeTab = useCallback<UseClientOrgUsersTable['onChangeTab']>(
    (newTab: EClientOrgUserTableTab) => {
      if (newTab !== tab) {
        dispatch(clientOrgUsersDataReset());
        history.replace(
          addSearchParamToLocation({
            location,
            param: EClientOrgUserUrlParam.Tab,
            value: newTab,
            state: { guid },
          })
        );
      }
    },
    [dispatch, history, location, tab, guid]
  );

  const onChangeSort = useCallback<UseClientOrgUsersTable['onChangeSort']>(
    (name: string, direction: ESortDirection) => {
      dispatch(
        clientOrgUsersSetSort({
          sort: `${name},${direction}`,
        })
      );
    },
    [dispatch]
  );

  const onClientOrgUserSelect = useCallback<UseClientOrgUsersTable['onClientOrgUserSelect']>(
    (clientOrgUser, selected) => {
      if (selected) {
        dispatch(clientOrgUsersSelect(clientOrgUser));
      } else {
        dispatch(clientOrgUsersUnselect(clientOrgUser));
      }
    },
    [dispatch]
  );

  const onAllClientOrgUserSelect = useCallback<UseClientOrgUsersTable['onAllClientOrgUserSelect']>(
    selected => {
      if (selected) {
        dispatch(clientOrgUsersAllSelect());
      } else {
        dispatch(clientOrgUsersAllUnselect());
      }
    },
    [dispatch]
  );

  return {
    onTryCreate,
    onResetSort,
    onChangeSort,
    onChangeFilter,
    onChangePage,
    onChangePageSize,
    onChangeTab,
    onRefresh,

    onClientOrgUserSelect,
    onAllClientOrgUserSelect,

    onClientOrgUserResendWelcomeMail,
  };
};
