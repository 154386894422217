import { BookingOffer, BookingOfferService, EOfferServiceStatus, EServiceOrderByDateType } from '@/domain';
import { MPRubFormat } from '@/presentation/theme/ui-kit/format';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import useTechConfig from 'presentation/hooks/useTechConfig';
import { EAppFeature } from 'presentation/types';
import DangerouslyHtmlWrapper from '../../../../components/common/wrappers/dangerouslyHtml';
import { Field, FieldsGroup } from '../../../../components/fields';
import { ETagColors, MPTag } from '../../../../theme/ui-kit/tag';
import { serviceOrderByDateType } from '../../../general/nsi';
import { WrapperTitle } from './controls';

interface BookingOfferDetailsStepServicesProps {
  readonly bookingOffer: BookingOffer;
}

const BookingOfferDetailsStepServices = (props: BookingOfferDetailsStepServicesProps) => {
  const {
    bookingOffer: { services },
  } = props;

  const { hasFeature } = useTechConfig();

  const sectionTitle = (service: BookingOfferService, index: number) => {
    return (
      <WrapperTitle>
        <span>{index + 1}. Услуга</span>
        {service.status === EOfferServiceStatus.Disabled && (
          <MPTag
            bold
            label={'Скрыта'}
            variant={'filled'}
            color={ETagColors.Warning}
          />
        )}
      </WrapperTitle>
    );
  };

  return (
    <Grid
      container
      spacing={4}
    >
      <Grid
        item
        xs={12}
      >
        <Typography variant={'h3'}>Услуги</Typography>
      </Grid>

      <Grid
        item
        xs={12}
        spacing={3}
        container
      >
        {services?.map((service, index) => (
          <Grid
            item
            xs={12}
            key={index}
            spacing={3}
            container
          >
            <Grid
              item
              xs={12}
            >
              <FieldsGroup label={sectionTitle(service, index)}>
                <Field label={'Категория услуги'}>{service.category?.name ?? '-'}</Field>
                <Field label={'Наименование услуги'}>{service.name ?? '-'}</Field>
                <Field label={'Описание услуги'}>
                  {service.description ? (
                    <DangerouslyHtmlWrapper>
                      <Typography
                        variant='body1'
                        dangerouslySetInnerHTML={{ __html: service.description }}
                      />
                    </DangerouslyHtmlWrapper>
                  ) : (
                    '-'
                  )}
                </Field>
                <Field label={'Условие выбора дат в календаре'}>
                  {serviceOrderByDateType[service.orderByDateType ?? EServiceOrderByDateType.None].name}
                </Field>
              </FieldsGroup>
            </Grid>

            {hasFeature(EAppFeature.Csp) && service.category?.roomBooking && (
              <Grid
                item
                xs={12}
              >
                <FieldsGroup label='Льготный отдых'>
                  <Field label={'Компенсируемый социальный пакет ОАО «РЖД»'}>
                    {service.rzdSocialPackage ? 'Да' : 'Нет'}
                  </Field>
                  <Field label={'Текст для информера'}>{service.rzdSocialPackageInfo ?? '-'}</Field>
                </FieldsGroup>
              </Grid>
            )}

            <Grid
              item
              spacing={2}
              container
              xs={12}
            >
              {service.priceItems?.map((item, indexPrice) => (
                <Grid
                  item
                  xs={12}
                  key={item.id}
                >
                  <FieldsGroup label={`Тариф ${indexPrice + 1}`}>
                    <Field label={'Название тарифа'}>{item.name ?? '-'}</Field>
                    <Grid
                      container
                      spacing={2}
                    >
                      <Grid
                        item
                        xs={6}
                      >
                        <Field label={'Стоимость'}>
                          <MPRubFormat
                            showRub
                            value={item.price}
                          />
                        </Field>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                      >
                        <Field label={'Единица измерения'}>{item.unit?.name ?? '-'}</Field>
                      </Grid>
                    </Grid>
                  </FieldsGroup>
                </Grid>
              ))}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default BookingOfferDetailsStepServices;
