import { CmsLinkedObjectBannerType } from '@features/cms/types/banner';
import { EEntityPreviewMode } from 'presentation/types';
import EntityMasterPreviewContentGrid from '../../../../../components/common/entityMaster/preview/contentGrid';
import { CmsContainerViewResourceSize } from '../../../container/types';
import { CmsPreviewItemRatio } from '../../../types';
import CmsPreviewBlock from '../block';
import UnknownSizesStub from './stub';

interface CmsResizableBannersPreviewProps {
  readonly mode?: EEntityPreviewMode;
  readonly sizes: CmsContainerViewResourceSize[];
  readonly banners: Nullable<CmsLinkedObjectBannerType>[];
  readonly activePreviewNumber?: Nullable<number | false>;
  readonly canActivateItem?: boolean;
  readonly ratio?: CmsPreviewItemRatio;
  readonly rowElementsCount: number;
}

const CmsResizableBannersPreview = (props: CmsResizableBannersPreviewProps) => {
  const {
    mode = EEntityPreviewMode.Desktop,
    activePreviewNumber,
    sizes,
    canActivateItem,
    ratio,
    rowElementsCount,
    banners,
  } = props;

  return (
    <EntityMasterPreviewContentGrid
      ratio={ratio}
      maxRowElementsCount={rowElementsCount}
      preserveAspectRatio={true}
    >
      {banners.map((b, i) => (
        <CmsPreviewBlock
          key={b?.id ?? i}
          number={i + 1}
          image={mode === EEntityPreviewMode.Desktop ? b?.desktopImage : b?.mobileImage}
          isActive={canActivateItem ? activePreviewNumber === i : true}
          showNumber={canActivateItem && mode === EEntityPreviewMode.Desktop && banners.length > 1}
          stub={
            !b?.height && !b?.width ? (
              <UnknownSizesStub
                mode={mode}
                isActive={canActivateItem ? activePreviewNumber === i : true}
                sizes={sizes}
              />
            ) : undefined
          }
        />
      ))}
    </EntityMasterPreviewContentGrid>
  );
};

export default CmsResizableBannersPreview;
