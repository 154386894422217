import { UserFieldView } from '@components/common/userInfoView';
import { ListItemText } from '@mui/material';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { useMemo } from 'react';
import { MpUser } from '@/domain';
import { MenuPanelCommonHeader } from '@components/common/menuPanel/header';
import { HeaderItem, LogoIconWrapper, UserType } from '../controls';

interface CustomerManagementMenuHeaderAdapterProps {
  readonly customer: MpUser;
  readonly onBack: () => void;
}

export const CustomerManagementMenuHeaderAdapter = (props: CustomerManagementMenuHeaderAdapterProps) => {
  const { customer, onBack } = props;

  return useMemo(() => {
    return (
      <MenuPanelCommonHeader>
        <HeaderItem onClick={onBack}>
          <LogoIconWrapper>
            <ArrowBackIosOutlinedIcon fontSize='small' />
          </LogoIconWrapper>
          <ListItemText
            primary={
              <UserType variant='subtitle2'>
                <UserFieldView
                  user={customer}
                  field={'fullNameReverse'}
                />
              </UserType>
            }
          />
        </HeaderItem>
      </MenuPanelCommonHeader>
    );
  }, [customer, onBack]);
};
