import { ClientOrgUser } from '@/domain';
import { ClientOrgUserLifeCycle } from '../../lifecycle/types';
import {
  ClientOrgUserActions,
  ClientOrgUserActionTableCollectionType,
  ClientOrgUserActionTableType,
} from '../../types';

interface UseClientOrgUserCollectionActionsProps {
  readonly lifecycle: ClientOrgUserLifeCycle;
  readonly clientOrgUsers: ClientOrgUser[];
  readonly allowedActions: ClientOrgUserActions<ClientOrgUserActionTableType>;
}

type UseClientOrgUserCollectionActionsResult = ClientOrgUserActions<ClientOrgUserActionTableCollectionType>;

const intersectActions = (
  a: ClientOrgUserActions<ClientOrgUserActionTableType>,
  b: ClientOrgUserActions<ClientOrgUserActionTableType>
) =>
  a
    // фильтруем для определения пересечений
    .filter(itemA => b.some(itemB => itemA.type === itemB.type))
    // ставим disabled если действие отключено в любом из массивов
    .map(itemA => ({
      ...itemA,
      disabled: itemA.disabled && b.find(itemB => itemA.type === itemB.type)?.disabled,
    }));

const useClientOrgUserCollectionActions = (
  props: UseClientOrgUserCollectionActionsProps
): UseClientOrgUserCollectionActionsResult => {
  const { lifecycle, clientOrgUsers, allowedActions } = props;

  const resultActions = clientOrgUsers.reduce<ClientOrgUserActions<ClientOrgUserActionTableType>>(
    (prev, socialPackage) => {
      // получаем ограничения ЖЦ
      const restrictions = lifecycle.build({ obj: socialPackage, allowedActions });
      const lifecycleActions = restrictions.nextActions as ClientOrgUserActions<ClientOrgUserActionTableType>;

      // рассчитываем пересечения
      return intersectActions(prev.length ? prev : lifecycleActions, lifecycleActions);
    },
    []
  ) as ClientOrgUserActions<ClientOrgUserActionTableCollectionType>;

  return resultActions;
};

export default useClientOrgUserCollectionActions;
