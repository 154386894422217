import { EUserStatus } from '@/domain';
import { ECustomerActionType } from '../../types';
import { CustomerLifeCycleStatusScheme } from '../types';

export const adminStatusScheme: CustomerLifeCycleStatusScheme = {
  description: 'Статусная схема главного админа',
  statusScheme: {
    [EUserStatus.Enabled]: [ECustomerActionType.Deactivate, ECustomerActionType.SendProfileQuery],
    [EUserStatus.NotFilled]: [],
    [EUserStatus.Disabled]: [ECustomerActionType.Activate],
    [EUserStatus.Deleted]: [],
  },
  actionsScheme: {} as any,
};

export const partnerStatusScheme: CustomerLifeCycleStatusScheme = adminStatusScheme;
