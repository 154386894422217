import { MpUser } from '@/domain';
import { CustomerLifeCycleFactory } from '@features/customer/lifecycle/lifecycle';
import { viewShowCustomerManagementCommonMenu } from '@features/customer/management/common/store/slice';
import { viewHideMainMenu } from '@features/main/container/store/slice';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ForbiddenComponent from '../../components/common/forbidden';
import CustomerDetailsContainer from '../../features/customer/details/container';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

interface CustomerManagementScreenProps {
  readonly customer: MpUser;
}

const CustomerManagementScreen = ({ customer }: CustomerManagementScreenProps) => {
  const dispatch = useDispatch();
  const {
    accessMatrix: { customers, isPartnerUser },
    userSpecific,
  } = useCurrentUser();

  useEffect(() => {
    dispatch(viewHideMainMenu());
    dispatch(viewShowCustomerManagementCommonMenu());
  }, [dispatch]);

  if (!customers?.view?.details) {
    return <ForbiddenComponent />;
  }

  return (
    <CustomerDetailsContainer
      id={customer.id}
      lifecycle={CustomerLifeCycleFactory().create({ isPartnerUser, userId: userSpecific.id })}
      access={customers}
    />
  );
};

export default CustomerManagementScreen;
