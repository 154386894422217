import { SportUserProfile } from '@/domain';
import { SportsmanSelectRestriction } from '@features/sportsman/search/types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NumberRange } from '../../../../types';
import { sportsmanSearchGuidSelector } from '../store/selectors';
import { sportsmanSearchStartSession } from '../store/slice';

interface UseSportsmansSearchSessionSingleProps {
  readonly initialSelected: Nullable<SportUserProfile>;
  readonly selectedMaxCount: 1;
}

interface UseSportsmansSearchSessionMultipleProps {
  readonly initialSelected: Nullable<SportUserProfile[]>;
  readonly selectedMaxCount?: NumberRange<2, 100> | false;
}

type UseSportsmansSearchSessionProps = {
  readonly guid: UUID;
  readonly selectRestrictions?: SportsmanSelectRestriction[];
} & (UseSportsmansSearchSessionSingleProps | UseSportsmansSearchSessionMultipleProps);

const useSportsmansSearchSession = (props: UseSportsmansSearchSessionProps): void => {
  const { guid, selectRestrictions = [] } = props;

  const dispatch = useDispatch();

  const currentGuid = useSelector(sportsmanSearchGuidSelector);

  // инициализация сеанса
  useEffect(() => {
    const selected: SportUserProfile[] = [];

    if (props.initialSelected) {
      switch (props.selectedMaxCount) {
        case 1:
          selected.push(props.initialSelected);
          break;
        default:
          selected.push(...props.initialSelected);
          break;
      }
    }
    if (guid !== currentGuid) {
      dispatch(sportsmanSearchStartSession({ guid, selected, selectRestrictions }));
    }
  }, [dispatch, guid, currentGuid, props.initialSelected, props.selectedMaxCount, selectRestrictions]);
};

export default useSportsmansSearchSession;
