import { CommonLifeCycleInstance } from '../../../../utils/lifecycle';
import { commonAdminConfiguration, commonPartnerConfiguration } from './configurations';
import {
  ClientOrgUserLifeCycle,
  ClientOrgUserLifeCycleFactoryProps,
  ClientOrgUserLifeCycleProps,
  ClientOrgUserLifeCycleType,
} from './types';

/**
 * жизненный цикл РК
 */
const ClientOrgUserLifeCycleInstance = (
  props: ClientOrgUserLifeCycleProps<ClientOrgUserLifeCycleType>
): ClientOrgUserLifeCycle => {
  const { configuration, userId, isPartnerUser } = props;

  const commonLifecycle = CommonLifeCycleInstance(props);

  return {
    configuration,
    build: ({ allowedActions, obj }) => {
      const commonLifecycleBuild = commonLifecycle.build({ allowedActions, obj });

      return {
        ...commonLifecycleBuild,
        canEdit: false,
      };
    },
    isPartnerUser,
    userId,
  };
};

/**
 * жизненный цикл РК - основная модель
 */
export const ClientOrgUserLifeCycleFactory = () => {
  return {
    create: ({ isPartnerUser, userId, debug }: ClientOrgUserLifeCycleFactoryProps): ClientOrgUserLifeCycle => {
      if (isPartnerUser) {
        return ClientOrgUserLifeCycleInstance({
          userId,
          configuration: commonPartnerConfiguration,
          debug,
          isPartnerUser,
        });
      } else {
        return ClientOrgUserLifeCycleInstance({
          userId,
          configuration: commonAdminConfiguration,
          debug,
          isPartnerUser,
        });
      }
    },
  };
};
