import { UserAccessPartitionCustomers } from '@/domain';
import { useCustomerActionsSelectors } from '@features/customer/actions/useActions';
import CustomerDetailsFooterAdapter from '@features/customer/details/adapters/footer';
import { CustomerDetailsGeneralInfoAdapter } from '@features/customer/details/adapters/generalInfo';
import { CustomerDetailsHeaderAdapter } from '@features/customer/details/adapters/header';
import { CustomerDetailsInitializer } from '@features/customer/details/initializer';
import { CustomerLifeCycle } from '@features/customer/lifecycle/types';
import { CommonFetchErrorDecorator } from '@features/general/decorators/error';
import { CommonPreloaderDecorator } from '@features/general/decorators/preloader';
import { CommonUpdaterDecorator } from '@features/general/decorators/updater';
import MasterDetailsContainer from '@features/general/master/details/container';
import MasterContentGridLayout from '@layouts/masterContentGrid/container';
import { MasterDetailsLayout } from '@layouts/masterDetails/container';
import {
  CustomerDetailHandlersProvider,
  CustomerDetailLifecycleProvider,
  CustomerDetailParamsProvider,
} from './providers';
import { customerDetailsFetchIsFailedSelector, customerDetailsIsPreloadingSelector } from './store/selectors';

export interface CustomerDetailsContainerProps {
  readonly id: UUID;
  readonly lifecycle: CustomerLifeCycle;
  readonly access: UserAccessPartitionCustomers;
}

const CustomerDetailsContainer = ({ id, lifecycle, access }: CustomerDetailsContainerProps) => {
  const { getIsCustomerProcessingSelector } = useCustomerActionsSelectors();

  return (
    <MasterDetailsContainer
      layout={MasterDetailsLayout}
      layoutProps={{
        header: CustomerDetailsHeaderAdapter,
        footer: CustomerDetailsFooterAdapter,
        decorators: [
          <CommonPreloaderDecorator
            key='preloader'
            isFetchingSelector={customerDetailsIsPreloadingSelector}
          />,
          <CommonUpdaterDecorator
            key='actions'
            isFetchingSelector={getIsCustomerProcessingSelector(id)}
          />,
          <CommonFetchErrorDecorator
            key='fail'
            isFetchFailedSelector={customerDetailsFetchIsFailedSelector}
          />,
        ],
      }}
      initializer={CustomerDetailsInitializer}
      providers={[
        {
          name: 'params',
          factory: CustomerDetailParamsProvider,
        },
        {
          name: 'handlers',
          factory: CustomerDetailHandlersProvider,
        },
        {
          name: 'lifecycle',
          factory: CustomerDetailLifecycleProvider,
        },
      ]}
      commonProps={{
        id,
        lifecycle,
        access,
      }}
    >
      <MasterContentGridLayout>
        <CustomerDetailsGeneralInfoAdapter />
      </MasterContentGridLayout>
    </MasterDetailsContainer>
  );
};

export default CustomerDetailsContainer;
