import { UserFieldView } from '@components/common/userInfoView';
import CustomerTableCell, { CustomerTableCellCommonProps } from './index';

const CustomerTableCellPhone = (props: CustomerTableCellCommonProps) => {
  const { customer } = props;

  return (
    <CustomerTableCell
      {...props}
      value={
        <UserFieldView
          user={customer}
          field={'phone'}
        />
      }
    />
  );
};

export default CustomerTableCellPhone;
