import {
  Address,
  ClientOrgShort,
  ENoticeStatus,
  EPartnerPermission,
  EPartnerType,
  PartnerCompanyDataDraft,
  PartnerDataDraft,
  PartnerIndividualDataDraft,
} from '@/domain';
import { ButtonLink } from '@components/common/buttons/link';
import { EMPStepperTransitionMethod } from '@components/common/stepper';
import { StepperCommonHeader } from '@components/common/stepper/common/header';
import { DefaultContentWrapper } from '@components/common/wrappers/content';
import { DefaultFooterWrapper } from '@components/common/wrappers/footer';
import { Fade, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Notifier from '../../../../system/notifier';
import MasterActionsComponent from '../../../components/common/actions/master';
import useNavAdapter from '../../../components/common/actions/navAdapter/hooks';
import DefaultHeader from '../../../components/common/header';
import ContentLoader from '../../../components/common/loader';
import useHistoryExtensions from '../../../hooks/useHistoryExtensions';
import useValidation from '../../../hooks/validation/useValidation';
import { PrivilegeRestoreIcon } from '../../../icons';
import { EPanelActionPosition, OnChangeObjectAttribute, PanelAction, PanelActions } from '../../../types';
import { nsiDataSelector } from '../../general/nsi/store/selectors';
import usePartnerDetailsValidation from '../../general/partner/hooks/usePartnerDetailsValidation';
import usePartnerDocumentsValidation from '../../general/partner/hooks/usePartnerDocumentsValidation';
import usePartnerGeneralValidation from '../../general/partner/hooks/usePartnerGeneralValidation';
import usePartnerStepper from '../../general/partner/hooks/useStepper';
import { EPartnerActionType, EPartnerStep, PartnerActionEditType, PartnerStep } from '../../general/partner/types';
import { getPartnerSteps } from '../../general/partner/utils/common';
import {
  PartnerDataStepPermissions,
  partnerEditAddressStepValidation,
  partnerEditPermissionsStepValidation,
} from '../../general/partner/validation';
import StepperContainer from '../../general/stepper/container';
import { getPartnersTableRoute } from '../entry';
import { getPartnerManagementDetailsRoute } from '../management/entry';
import { getPartnerActionName } from '../utils';
import PartnerEditAdmin from './component';
import { ContainerWrapper, ContentContainer, LoaderWrapper, TitleWrapper } from './controls';
import { partnerEditAdminByIdSelector, partnerEditAdminUpdateSelector } from './store/selectors';
import {
  partnerEditAdminByIdFetch,
  partnerEditAdminRestore,
  partnerEditAdminSetAddress,
  partnerEditAdminSetAttribute,
  partnerEditAdminSetClientOrg,
  partnerEditAdminSetCompanyAttribute,
  partnerEditAdminSetIndividualAttribute,
  partnerEditAdminSetPartnerType,
  partnerEditAdminSetPermissions,
  partnerEditAdminStateReset,
  partnerEditAdminUpdate,
} from './store/slice';

interface PartnerEditAdminContainerProps {
  readonly id: UUID;
  readonly step?: EPartnerStep;
  readonly canChangePermissions?: boolean;
}

const PartnerEditAdminContainer = (props: PartnerEditAdminContainerProps) => {
  const { id, step = getPartnerSteps(null)[0].key, canChangePermissions } = props;

  const dispatch = useDispatch();

  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  const [validateOnChangeGeneral, setValidateOnChangeGeneral] = useState<boolean>(false);
  const [validateOnChangeDetails, setValidateOnChangeDetails] = useState<boolean>(false);
  const [validateOnChangeAddress, setValidateOnChangeAddress] = useState<boolean>(false);
  const [validateOnChangeDocuments, setValidateOnChangeDocuments] = useState<boolean>(false);
  const [validateOnChangePermissions, setValidateOnChangePermissions] = useState<boolean>(false);

  const { data: partner, isFetching, address, modified } = useSelector(partnerEditAdminByIdSelector);
  const { isFetching: isSaving, isFetched: isSaved } = useSelector(partnerEditAdminUpdateSelector);
  const { partnerOwnerShipTypes: ownerShipTypes, taxationSystemTypes, partnerTypes } = useSelector(nsiDataSelector);

  const orgType = partner?.regInfo?.orgType;

  const { currentStep, steps, nextStepIndex, isLastStep, openStep, openPrevStep, currentStepIndex } = usePartnerStepper(
    {
      currentStepKey: step,
      orgType,
      canChangePermissions,
    }
  );

  const actions: PanelActions<PartnerActionEditType> = isLastStep
    ? [
        {
          type: EPartnerActionType.Save,
          label: getPartnerActionName(EPartnerActionType.Save),
          primary: true,
          position: [EPanelActionPosition.Default],
        },
      ]
    : [];

  const {
    validationResultCompany: validationGeneralCompany,
    validationResultIndividual: validationGeneralIndividual,
    isValid: isValidGeneral,
    validate: validateGeneral,
  } = usePartnerGeneralValidation({
    object: partner,
    validateOnChange: validateOnChangeGeneral,
  });

  const {
    validationResultCompany: validationDetailsCompany,
    validationResultIndividual: validationDetailsIndividual,
    isValid: isValidDetails,
    validate: validateDetails,
  } = usePartnerDetailsValidation({
    object: partner,
    validateOnChange: validateOnChangeDetails,
  });

  const {
    validationResult: validationResultAddress,
    isValid: isValidAddress,
    validate: validateAddress,
  } = useValidation<Address | {}>({
    object: partner?.address ?? {},
    validateOnChange: validateOnChangeAddress,
    rules: partnerEditAddressStepValidation,
  });

  const {
    validationResultCompany: validationDocumentsCompany,
    validationResultIndividual: validationDocumentsIndividual,
    isValid: isValidDocuments,
    validate: validateDocuments,
  } = usePartnerDocumentsValidation({
    object: partner,
    validateOnChange: validateOnChangeDocuments,
  });

  const {
    validationResult: validationPermissions,
    isValid: isValidPermissions,
    validate: validatePermissions,
  } = useValidation<PartnerDataStepPermissions>({
    object: partner,
    validateOnChange: validateOnChangePermissions,
    rules: partnerEditPermissionsStepValidation,
  });

  const onChangeAttribute: OnChangeObjectAttribute<PartnerDataDraft> = (name, value) => {
    dispatch(partnerEditAdminSetAttribute({ name, value }));
  };

  const onChangeAddress = (newAddress: Address) => {
    dispatch(partnerEditAdminSetAddress(newAddress));
  };

  const onChangePermissions = (newPermissions: EPartnerPermission[]) => {
    dispatch(partnerEditAdminSetPermissions(newPermissions));
  };

  const onChangePartnerType = (newPartnerType: EPartnerType) => {
    dispatch(partnerEditAdminSetPartnerType(newPartnerType));
  };

  const onChangeClientOrg = (newClientOrg: Nullable<ClientOrgShort>) => {
    dispatch(partnerEditAdminSetClientOrg(newClientOrg));
  };

  const onChangeCompanyAttribute: OnChangeObjectAttribute<PartnerCompanyDataDraft> = (name, value) => {
    dispatch(partnerEditAdminSetCompanyAttribute({ name, value }));
  };

  const onChangeIndividualAttribute: OnChangeObjectAttribute<PartnerIndividualDataDraft> = (name, value) => {
    dispatch(partnerEditAdminSetIndividualAttribute({ name, value }));
  };

  const onNextStep = () => {
    if (!orgType) {
      return;
    }
    onChangeStep(steps[nextStepIndex]);
  };

  const onChangeStep = (nextStep: PartnerStep) => {
    if (partner && step !== nextStep.key) {
      switch (step) {
        case EPartnerStep.General: {
          const isValidObject = validateGeneral();
          if (!isValidObject) {
            setValidateOnChangeGeneral(true);
            return;
          }
          break;
        }
        case EPartnerStep.Details: {
          const isValidObject = validateDetails();
          if (!isValidObject) {
            setValidateOnChangeDetails(true);
            return;
          }
          break;
        }
        case EPartnerStep.Address: {
          const isValidObject = validateAddress();
          if (!isValidObject) {
            setValidateOnChangeAddress(true);
            return;
          }
          break;
        }
        case EPartnerStep.Documents: {
          const isValidObject = validateDocuments();
          if (!isValidObject) {
            setValidateOnChangeDocuments(true);
            return;
          }
          break;
        }
        case EPartnerStep.Permissions: {
          const isValidObject = validatePermissions();
          if (!isValidObject) {
            setValidateOnChangePermissions(true);
            return;
          }
          break;
        }
      }
      openStep(nextStep);
    }
  };

  const onClose = useCallback(() => {
    if (id) {
      gotoPrevIndependentLocation(getPartnerManagementDetailsRoute({ id }));
    } else {
      gotoPrevIndependentLocation(getPartnersTableRoute({}));
    }
  }, [gotoPrevIndependentLocation, id]);

  const onSave = () => {
    if (partner) {
      const isValidObject = validatePermissions();
      if (!isValidObject) {
        setValidateOnChangePermissions(true);
        return;
      }
      dispatch(
        partnerEditAdminUpdate({
          id,
          partner: partner!,
          permissions: partner!.permissions,
          type: partner!.type,
          clientOrg: partner!.clientOrg,
        })
      );
    }
  };

  const onRestore = () => {
    dispatch(partnerEditAdminRestore());
  };

  const onPanelAction = (action: PanelAction<PartnerActionEditType>) => {
    const { type } = action;
    switch (type) {
      case EPartnerActionType.Save:
        onSave();
        break;
    }
  };

  useEffect(() => {
    if (isSaved) {
      Notifier.getInstance().addNotice(ENoticeStatus.Success, `Партнёр успешно изменен`);
      onClose();
    }
  }, [isSaved, onClose]);

  useEffect(() => {
    dispatch(partnerEditAdminByIdFetch(id));

    return () => {
      dispatch(partnerEditAdminStateReset());
    };
  }, [dispatch, id]);

  const { adapter: navAdapter, actions: navActions } = useNavAdapter({
    openPrevStep,
    currentStepIndex,
    openNextStep: onNextStep,
    stepsCount: steps.length,
  });

  const actionsPanel = (
    <MasterActionsComponent<PartnerActionEditType>
      actions={actions}
      show={!!actions.length || !!navActions.length}
      navAdapter={navAdapter}
      onAction={onPanelAction}
      wrapper={DefaultFooterWrapper}
    />
  );

  return (
    <Fade in>
      <ContainerWrapper>
        {partner && (
          <>
            <StepperContainer<EPartnerStep>
              step={currentStep}
              steps={steps}
              forwardTransition={EMPStepperTransitionMethod.Neighbour}
              header={
                <StepperCommonHeader bottomSplitter>
                  <Typography variant={'body2'}>
                    {partner.code} - {partner.name}
                  </Typography>
                </StepperCommonHeader>
              }
              onClick={orgType && onChangeStep}
            />
            <ContentContainer>
              <DefaultContentWrapper
                type='details'
                stickyHeader
                fullHeight
                footer={actionsPanel}
              >
                <DefaultHeader
                  sticky
                  actions={
                    modified && onRestore ? (
                      <ButtonLink onClick={onRestore}>
                        <PrivilegeRestoreIcon />
                        <Typography>Отменить все изменения</Typography>
                      </ButtonLink>
                    ) : null
                  }
                  onClose={onClose}
                >
                  <TitleWrapper>
                    <Typography variant='h2'>Редактирование партнёра</Typography>
                  </TitleWrapper>
                </DefaultHeader>
                <PartnerEditAdmin
                  partner={partner}
                  permissions={partner.permissions}
                  partnerType={partner.type}
                  clientOrg={partner.clientOrg}
                  address={address}
                  step={step}
                  ownerShipTypes={ownerShipTypes}
                  taxationSystemTypes={taxationSystemTypes}
                  partnerTypes={partnerTypes}
                  isValidGeneral={isValidGeneral}
                  isValidDetails={isValidDetails}
                  isValidAddress={isValidAddress}
                  isValidDocuments={isValidDocuments}
                  isValidPermissions={isValidPermissions}
                  validationGeneralCompany={validationGeneralCompany}
                  validationGeneralIndividual={validationGeneralIndividual}
                  validationDetailsCompany={validationDetailsCompany}
                  validationDetailsIndividual={validationDetailsIndividual}
                  validationAddress={validationResultAddress}
                  validationDocumentsCompany={validationDocumentsCompany}
                  validationDocumentsIndividual={validationDocumentsIndividual}
                  validationPermissions={validationPermissions}
                  onChangeAttribute={onChangeAttribute}
                  onChangeCompanyAttribute={onChangeCompanyAttribute}
                  onChangeIndividualCompanyAttribute={onChangeIndividualAttribute}
                  onChangeAddress={onChangeAddress}
                  onChangePermissions={onChangePermissions}
                  onChangePartnerType={onChangePartnerType}
                  onChangeClientOrg={onChangeClientOrg}
                />
              </DefaultContentWrapper>
            </ContentContainer>
          </>
        )}
        {(isFetching || isSaving) && (
          <LoaderWrapper>
            <ContentLoader
              size={75}
              alpha
            />
          </LoaderWrapper>
        )}
      </ContainerWrapper>
    </Fade>
  );
};

export default PartnerEditAdminContainer;
