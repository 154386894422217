import { EOfferPausedReason, EUserRole, MpUser, SportOption } from '@/domain';
import { userInfoView } from 'presentation/utils/userInfoView';
import { fileTypes } from '../../../constants';
import { getUserCheckedRoles, getUserRoleShortName } from '../../../utils/roles';
import { ContentTargetComponentsType } from '../../general/contentTarget/types';

export const productExportAccept = fileTypes.xls.mimeType;
export const productStocksImportAccept = fileTypes.xls.mimeType;
export const productOffersImportAccept = fileTypes.xls.mimeType;
export const productExportMaxCount = 1000;

export const getProductOfferModeratorPreview = (user: MpUser, checkedRoles: EUserRole[]): string => {
  return `${userInfoView(user).fullName} (${getUserCheckedRoles(user.roles, checkedRoles)
    .map(role => getUserRoleShortName(role))
    .join()})`;
};

export const getProductPausedReasonText = (reason: SportOption): string => {
  return !reason.code || reason.code === EOfferPausedReason.PausedByAdmin ? 'Остановлено Администратором' : reason.name;
};

export const productOfferTargetAttributes: ContentTargetComponentsType = {
  localities: true,
  clientOrgs: true,
  externalUsers: true,
};
