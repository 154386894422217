import { AxiosResponse } from 'axios';
import Api from '../../../../data/api';
import {
  EventApplicationIdState,
  TournamentApplicationAcceptProps,
  TournamentApplicationCreateProps,
  TournamentApplicationRejectProps,
  TournamentPublishResultsProps,
  TournamentSaveResultsProps,
} from '@/data/api/event';
import { ApiCancellable, ApiResponseWithPageable } from '@/data/api/types';
import { ESportEventStatus, ETeamMemberStatus } from '@/domain/model/enums';
import { Tournament, TournamentApplication, TournamentRequest, TournamentTeam } from '@/domain/model/event';
import { Team } from '@/domain/model/team';
import { UUID } from '@/domain/model/types';
import eventServices from './';
import { SportUserProfile } from '@/domain';

export type ByIdProps = ApiCancellable & {
  id: UUID;
};

export type SaveProps = {
  tournament: TournamentRequest;
};

export type PublishProps = {
  tournament: TournamentRequest;
};

export type InviteTeamsProps = {
  tournament: Tournament | TournamentRequest;
  teams: Team[];
};

type TournamentApplicationsProps = ApiCancellable & {
  readonly id: UUID;
};

type TournamentTeamsProps = ApiCancellable & {
  readonly id: UUID;
};

type TournamentsCancelProps = ApiCancellable & {
  readonly id: UUID;
};

type TournamentsCreateApplicationProps = {
  readonly tournamentId: UUID;
  readonly teamId: UUID;
  readonly sportUsers: SportUserProfile[];
};

export type EventTournamentService = {
  readonly one: (props: ByIdProps) => Promise<Tournament>;
  readonly save: (props: SaveProps) => Promise<Tournament>;
  readonly publish: (props: PublishProps) => Promise<Tournament>;
  readonly publishAnnounce: (props: PublishProps) => Promise<Tournament>;
  readonly inviteTeams: (props: InviteTeamsProps) => Promise<Tournament>;
  readonly applications: (
    props: TournamentApplicationsProps
  ) => Promise<ApiResponseWithPageable<TournamentApplication>>;
  readonly teams: (props: TournamentTeamsProps) => Promise<ApiResponseWithPageable<TournamentTeam>>;
  readonly cancel: (props: TournamentsCancelProps) => Promise<AxiosResponse<void>>;
  readonly saveResults: (props: TournamentSaveResultsProps) => Promise<AxiosResponse<Tournament>>;
  readonly publishResults: (props: TournamentPublishResultsProps) => Promise<AxiosResponse<Tournament>>;
  readonly createApplication: (
    props: TournamentsCreateApplicationProps
  ) => Promise<AxiosResponse<TournamentApplication>>;
  readonly applicationAccept: (
    props: TournamentApplicationAcceptProps
  ) => Promise<AxiosResponse<EventApplicationIdState>>;
  readonly applicationReject: (
    props: TournamentApplicationRejectProps
  ) => Promise<AxiosResponse<EventApplicationIdState>>;
  readonly applicationCreate: (
    props: TournamentApplicationCreateProps
  ) => Promise<AxiosResponse<EventApplicationIdState>>;
};

export const service: EventTournamentService = {
  one: async ({ id, signal }) => {
    const { data } = await Api.event.tournament.one({ id, signal });
    return data;
  },
  save: async ({ tournament }) => {
    const tournamentToSave = eventServices.common.beforeSave(tournament);

    const id = tournamentToSave.id;
    switch (tournamentToSave.status?.code ?? null) {
      case null:
        return (await Api.event.tournament.create({ data: tournamentToSave })).data;
      case ESportEventStatus.Draft:
        return (await Api.event.tournament.saveUnpublished({ data: tournamentToSave })).data;
      case ESportEventStatus.Announced:
        return (await Api.event.tournament.saveAnnounced({ id, data: tournamentToSave })).data;
      default:
        return (await Api.event.tournament.save({ id, data: tournamentToSave })).data;
    }
  },
  publish: async ({ tournament }) => {
    const id = tournament.id;
    const { data } = await Api.event.tournament.publish({ id });
    return data;
  },
  publishAnnounce: async ({ tournament }) => {
    const id = tournament.id;
    const { data } = await Api.event.tournament.publishAnnounce({ id });
    return data;
  },
  inviteTeams: async ({ tournament, teams }) => {
    const id = tournament.id;
    await Api.event.tournament.inviteTeams({ id, teams });
    return await service.one({ id: tournament.id });
  },
  applications: async props => {
    const { data } = await Api.event.tournament.applications({ ...props, page: 1, pageSize: 999 });
    return data;
  },
  teams: async props => {
    const { data } = await Api.event.tournament.teams({ ...props, page: 1, pageSize: 999 });
    return data;
  },
  cancel: async ({ id }) => {
    return await Api.event.cancel({ id });
  },
  saveResults: async ({ id, results }) => {
    return await Api.event.tournament.saveResults({ id, results });
  },
  publishResults: async ({ id }) => {
    return await Api.event.tournament.publishResults({ id });
  },
  applicationAccept: async ({ applicationId, tournamentId }) => {
    return await Api.event.tournament.applicationAccept({ applicationId, tournamentId });
  },
  applicationCreate: async ({ tournamentId, teamMembers, teamId }) => {
    return await Api.event.tournament.applicationCreate({ tournamentId, teamMembers, teamId });
  },
  applicationReject: async ({ applicationId, tournamentId, message }) => {
    return await Api.event.tournament.applicationReject({
      applicationId,
      tournamentId,
      message,
    });
  },
  createApplication: async ({ tournamentId, teamId, sportUsers }) => {
    const teamMembers = (await Api.team.members({ id: teamId, status: ETeamMemberStatus.Active })).data;
    const members = teamMembers.filter(member => {
      return sportUsers.findIndex(sportUser => sportUser.id === member.userProfile.id) !== -1;
    });

    return await Api.event.tournament.createApplication({
      tournamentId,
      teamId,
      teamMembers: members.map(item => {
        return { id: item.id };
      }),
    });
  },
};
