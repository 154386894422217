import { userInfoView } from 'presentation/utils/userInfoView';
import Api from '@/data/api';
import {
  Nullable,
  pageSizeAll,
  EUserRole,
  DataFilterQueryDslOperator,
  DataFilterStrategyBase,
  DataFilterStrategyListItem,
  DataFilterStrategyView,
  DataFilterValueItem,
  EDataFilterType,
} from '@/domain';
import { EPartnerTableColumn } from '../general/partner/types';

export enum EPartnerTableFilterItem {
  Query = 'query',
  Name = 'name',
  Owner = 'owner.id',
  OwnerPhone = 'owner.phone',
  OwnerEmail = 'owner.email',
  Reviewer = 'partnerReviewer.id',
  Inn = 'inn',
  Kpp = 'kpp',
  Ogrn = 'ogrn',
}

export const partnerTableFilterItems: Record<EPartnerTableColumn, EPartnerTableFilterItem[]> = {
  [EPartnerTableColumn.SortIndex]: [],
  [EPartnerTableColumn.Name]: [EPartnerTableFilterItem.Name],
  [EPartnerTableColumn.Date]: [],
  [EPartnerTableColumn.OwnerName]: [EPartnerTableFilterItem.Owner],
  [EPartnerTableColumn.OwnerPhone]: [EPartnerTableFilterItem.OwnerPhone],
  [EPartnerTableColumn.OwnerEmail]: [EPartnerTableFilterItem.OwnerEmail],
  [EPartnerTableColumn.Reviewer]: [EPartnerTableFilterItem.Reviewer],
  [EPartnerTableColumn.Inn]: [EPartnerTableFilterItem.Inn],
  [EPartnerTableColumn.Kpp]: [EPartnerTableFilterItem.Kpp],
  [EPartnerTableColumn.Ogrn]: [EPartnerTableFilterItem.Ogrn],
  [EPartnerTableColumn.Actions]: [],
};

export type PartnerTableFilterEditStrategy = DataFilterStrategyBase<EPartnerTableFilterItem>;
export type PartnerTableFilterViewStrategy = DataFilterStrategyView<EPartnerTableFilterItem>;
export type PartnerTableFilterValues = {
  readonly [EPartnerTableFilterItem.Query]?: DataFilterValueItem<Nullable<string>>;
  readonly [EPartnerTableFilterItem.Name]?: DataFilterValueItem<Nullable<string>>;
  readonly [EPartnerTableFilterItem.Owner]?: DataFilterValueItem<Nullable<DataFilterStrategyListItem[]>>;
  readonly [EPartnerTableFilterItem.OwnerPhone]?: DataFilterValueItem<Nullable<string>>;
  readonly [EPartnerTableFilterItem.OwnerEmail]?: DataFilterValueItem<Nullable<string>>;
  readonly [EPartnerTableFilterItem.Reviewer]?: DataFilterValueItem<Nullable<DataFilterStrategyListItem[]>>;
  readonly [EPartnerTableFilterItem.Inn]?: DataFilterValueItem<Nullable<string>>;
  readonly [EPartnerTableFilterItem.Kpp]?: DataFilterValueItem<Nullable<string>>;
  readonly [EPartnerTableFilterItem.Ogrn]?: DataFilterValueItem<Nullable<string>>;
};

export const getPartnerTableFilterStrategy = (
  filterItem: EPartnerTableFilterItem,
  values: PartnerTableFilterValues
): Nullable<PartnerTableFilterEditStrategy> => {
  switch (filterItem) {
    case EPartnerTableFilterItem.Query:
      return {
        type: EDataFilterType.String,
        key: EPartnerTableFilterItem.Query,
        label: 'Поиск по ключевому слову',
        preview: values[EPartnerTableFilterItem.Query]?.value || null,
        value: values[EPartnerTableFilterItem.Query]?.value || null,
      };
    case EPartnerTableFilterItem.Name:
      return {
        type: EDataFilterType.String,
        key: EPartnerTableFilterItem.Name,
        label: 'Название',
        preview: values[EPartnerTableFilterItem.Name]?.value || null,
        value: values[EPartnerTableFilterItem.Name]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case EPartnerTableFilterItem.Owner:
      return {
        type: EDataFilterType.AutocompleteMultiple,
        key: EPartnerTableFilterItem.Owner,
        label: 'Ответственное лицо',
        value: values[EPartnerTableFilterItem.Owner]?.value || null,
        preview: `Ответственное лицо (${values[EPartnerTableFilterItem.Owner]?.value?.length})` || null,
        previews: values[EPartnerTableFilterItem.Owner]?.value?.map(t => t.name) || null,
        source: (name: string) =>
          name
            ? Api.user.mp
                .all({
                  query: name,
                  page: 1,
                  pageSize: pageSizeAll,
                  sort: ['lastName,asc', 'firstName,asc', 'middleName,asc'],
                  roles: [EUserRole.AdminPartner],
                })
                .then(response => response.data.map(item => ({ ...item, name: userInfoView(item).fullName })))
            : Promise.resolve([]),
        querydsl: {
          operator: DataFilterQueryDslOperator.In,
          valueAttribute: 'id',
        },
      };
    case EPartnerTableFilterItem.OwnerPhone:
      return {
        type: EDataFilterType.String,
        key: EPartnerTableFilterItem.OwnerPhone,
        label: 'Телефон',
        preview: values[EPartnerTableFilterItem.OwnerPhone]?.value || null,
        value: values[EPartnerTableFilterItem.OwnerPhone]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case EPartnerTableFilterItem.OwnerEmail:
      return {
        type: EDataFilterType.String,
        key: EPartnerTableFilterItem.OwnerEmail,
        label: 'Электронная почта',
        preview: values[EPartnerTableFilterItem.OwnerEmail]?.value || null,
        value: values[EPartnerTableFilterItem.OwnerEmail]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case EPartnerTableFilterItem.Reviewer:
      return {
        type: EDataFilterType.AutocompleteMultiple,
        key: EPartnerTableFilterItem.Reviewer,
        label: 'Ответственный',
        value: values[EPartnerTableFilterItem.Reviewer]?.value || null,
        preview: `Ответственный (${values[EPartnerTableFilterItem.Reviewer]?.value?.length})` || null,
        previews: values[EPartnerTableFilterItem.Reviewer]?.value?.map(t => t.name) || null,
        source: (name: string) =>
          name
            ? Api.user.mp
                .all({
                  query: name,
                  page: 1,
                  pageSize: pageSizeAll,
                  sort: ['lastName,asc', 'firstName,asc', 'middleName,asc'],
                  roles: [EUserRole.AdminMp],
                })
                .then(response => response.data.map(item => ({ ...item, name: userInfoView(item).fullName })))
            : Promise.resolve([]),
        querydsl: {
          operator: DataFilterQueryDslOperator.In,
          valueAttribute: 'id',
        },
      };
    case EPartnerTableFilterItem.Inn:
      return {
        type: EDataFilterType.String,
        key: EPartnerTableFilterItem.Inn,
        label: 'ИНН',
        value: values[EPartnerTableFilterItem.Inn]?.value || null,
        preview: values[EPartnerTableFilterItem.Inn]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.In,
        },
      };
    case EPartnerTableFilterItem.Kpp:
      return {
        type: EDataFilterType.String,
        key: EPartnerTableFilterItem.Kpp,
        label: 'КПП',
        value: values[EPartnerTableFilterItem.Kpp]?.value || null,
        preview: values[EPartnerTableFilterItem.Kpp]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.In,
        },
      };
    case EPartnerTableFilterItem.Ogrn:
      return {
        type: EDataFilterType.String,
        key: EPartnerTableFilterItem.Ogrn,
        label: 'ОГРН',
        value: values[EPartnerTableFilterItem.Ogrn]?.value || null,
        preview: values[EPartnerTableFilterItem.Ogrn]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.In,
        },
      };
    default:
      return null;
  }
};
