import { UserFieldView } from '@components/common/userInfoView';
import TeamTableCell, { TeamTableCellCommonProps } from './index';

const TeamTableCellCaptain = (props: TeamTableCellCommonProps) => {
  const {
    team: { captain, notSelectableReason: disabled },
  } = props;

  return (
    <TeamTableCell
      {...props}
      disabled={!!disabled}
      value={
        <UserFieldView
          user={captain?.userProfile}
          field={'shortNameReverse'}
        />
      }
    />
  );
};

export default TeamTableCellCaptain;
