/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import type { AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios';
import axios from 'axios';
import qs from 'qs';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams<D = any>
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: D;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

const stringifyFormItem = (formItem: unknown) => {
  if (typeof formItem === 'object' && formItem !== null) {
    return JSON.stringify(formItem);
  } else {
    return `${formItem}`;
  }
};

const createFormData = (input: Record<string, unknown>): FormData => {
  if (input instanceof FormData) {
    return input;
  }
  return Object.keys(input || {}).reduce((formData, key) => {
    const property = input[key];
    const propertyContent: any[] = property instanceof Array ? property : [property];

    for (const formItem of propertyContent) {
      const isFileType = formItem instanceof Blob || formItem instanceof File;
      formData.append(key, isFileType ? formItem : stringifyFormItem(formItem));
    }

    return formData;
  }, new FormData());
};

/** конвертация параметров общего назначения в понятный для axios, и выполнение запроса */
export const createRequest = <Result, Payload>({
  path,
  type,
  query,
  format,
  body,
  ...params
}: FullRequestParams<Payload>): Promise<AxiosResponse<Result, Payload>> => {
  const requestParams = params;
  const responseFormat = format || undefined;

  if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
    body = createFormData(body as Record<string, unknown>) as any;
  }

  if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
    body = JSON.stringify(body) as any;
  }

  return axios.request<Result, AxiosResponse<Result>, Payload>({
    ...requestParams,
    headers: {
      ...(requestParams.headers || {}),
      ...(type ? { 'Content-Type': type } : {}),
      'x-app-id': 'coms-admin',
    },
    paramsSerializer: {
      ...axios.defaults.paramsSerializer,
      indexes: false,
      serialize: params => {
        return qs.stringify(params, {
          indices: false,
          arrayFormat: 'repeat',
        });
      },
    },
    params: query,
    responseType: responseFormat,
    data: body,
    url: path,
  });
};
