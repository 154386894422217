import { combineReducers } from '@reduxjs/toolkit';
import actions, { CustomerActionsState } from './actions/store/slice';
import details, { CustomerDetailsState } from './details/store/slice';
import management, { CustomerManagementState } from './management/reducers';
import list, { CustomerListState } from './table/store/slice';

interface CustomerState {
  management: CustomerManagementState;
  list: CustomerListState;
  details: CustomerDetailsState;
  actions: CustomerActionsState;
}

export default combineReducers<CustomerState>({
  management,
  list,
  details,
  actions,
});
