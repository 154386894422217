import { Pageable, SportUserProfile } from '@/domain';
import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { AppThunkAPIConfig } from 'data/store/store';
import { Fetchable, fetchableDefault } from 'data/store/types';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { PaginationSize } from '../../../../types';
import { SportsmansTableFilterValues } from '../../filterUtils';
import sportsmanServices from '../../services';
import { SportsmanFetchProps } from '../../table/store/slice';
import { ESportsmanTableColumn, ESportsmanTableTab } from '../../table/utils';
import { SearchedSportsmanProfile, SportsmanSelectRestriction } from '../types';
import { sportsmanSearchSelectRestrictionsSelector } from './selectors';

const defaultSort = `${ESportsmanTableColumn.LastName},asc`;

export interface SportsmansSearchState extends Fetchable, Pageable<SearchedSportsmanProfile> {
  readonly guid: Nullable<UUID>;
  readonly needRefreshWatcher: number;
  readonly pageNumber: number;
  readonly search: {
    readonly sort: string;
    readonly pageSize: PaginationSize;
  };
  readonly tab: ESportsmanTableTab;
  readonly filter: SportsmansTableFilterValues;
  readonly selected: SearchedSportsmanProfile[];
  readonly selectRestrictions: SportsmanSelectRestriction[];
}

type Reducer<T = undefined> = CaseReducer<SportsmansSearchState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<SportsmansSearchState> {
  sportsmanSearchStartSession: Reducer<{
    guid: UUID;
    selected: SportUserProfile[];
    selectRestrictions: SportsmanSelectRestriction[];
  }>;
  sportsmanSearchSetSearch: Reducer<{
    sort: string;
    pageSize: PaginationSize;
  }>;
  sportsmanSearchSetTab: Reducer<{
    tab: ESportsmanTableTab;
  }>;
  sportsmanSearchSortReset: Reducer;
  sportsmanSearchSetPage: Reducer<{
    pageNumber: number;
  }>;
  sportsmanSearchSelect: Reducer<SearchedSportsmanProfile>;
  sportsmanSearchAllSelect: Reducer<Nullable<number>>;
  sportsmanSearchAllUnselect: Reducer;
  sportsmanSearchDataReset: Reducer;
  sportsmanSearchSetFilter: Reducer<SportsmansTableFilterValues>;
  sportsmanSearchSetPageSize: Reducer<{
    pageSize: PaginationSize;
  }>;
  sportsmanSearchSetSort: Reducer<{
    sort: string;
  }>;
  sportsmanSearchUnselect: Reducer<SearchedSportsmanProfile>;
  sportsmanSearchNeedRefreshWatcherReset: Reducer;
}

export const sportsmansSearchFetch = createAsyncThunk<
  Pageable<SearchedSportsmanProfile>,
  SportsmanFetchProps,
  AppThunkAPIConfig
>('sportsmansSearch/list/fetch', async (props, { getState, rejectWithValue, signal }) => {
  try {
    const state = getState();
    const selectRestrictions = sportsmanSearchSelectRestrictionsSelector(state);
    const result = await sportsmanServices.common.all({ ...props, signal });
    return {
      ...result,
      data: result.data.map(sportsman => {
        const selectRestriction = selectRestrictions.find(s => s.id === sportsman.id);
        return {
          ...sportsman,
          notSelectableReason: selectRestriction && (selectRestriction.reason ?? 'Выбор невозможен'),
        };
      }),
    };
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

const slice = createSlice<SportsmansSearchState, Reducers, 'search'>({
  name: 'search',
  initialState: {
    ...fetchableDefault,
    guid: null,
    selected: [],
    selectRestrictions: [],
    data: [],
    totalCount: 0,
    pageCount: 0,
    pageNumber: 1,
    needRefreshWatcher: 0,
    tab: ESportsmanTableTab.All,
    search: {
      sort: defaultSort,
      pageSize: 15,
    },
    filter: {},
  },
  reducers: {
    sportsmanSearchStartSession: (state, { payload }) => {
      const { guid, selected, selectRestrictions } = payload;
      state.guid = guid;

      state.isFetching = false;
      state.isFetched = false;
      state.isFailed = false;

      state.data = [];
      state.totalCount = 0;
      state.pageCount = 0;
      state.pageNumber = 1;
      state.needRefreshWatcher = 0;
      state.search = {
        sort: defaultSort,
        pageSize: 10,
      };
      state.filter = {};
      state.selected = selected.map(sportsman => {
        const selectRestriction = selectRestrictions.find(s => s.id === sportsman.id);
        return {
          ...sportsman,
          notSelectableReason: selectRestriction && (selectRestriction.reason ?? 'Выбор невозможен'),
        };
      });
      state.selectRestrictions = selectRestrictions;
    },
    sportsmanSearchSetSearch: (state, { payload }) => {
      const { sort, pageSize } = payload;

      state.search = {
        ...state.search,
        sort,
        pageSize,
      };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    sportsmanSearchSetTab: (state, { payload }) => {
      const { tab } = payload;

      if (state.tab !== tab) {
        state.data = [];
        state.totalCount = 0;
        state.pageCount = 0;
        state.pageNumber = 1;
        state.search.sort = defaultSort;
        state.tab = tab;
        state.needRefreshWatcher++;
      }
    },
    sportsmanSearchSetPage: (state, { payload }) => {
      const { pageNumber } = payload;
      state.pageNumber = pageNumber;
      state.needRefreshWatcher++;
    },
    sportsmanSearchSortReset: state => {
      state.search = {
        ...state.search,
        sort: defaultSort,
      };
      state.pageNumber = 1;
      state.selected = [];
      state.needRefreshWatcher++;
    },
    sportsmanSearchSelect: (state, { payload }) => {
      const selected = [...state.selected];
      const existedIndex = selected.findIndex(s => s.id === payload.id);

      if (existedIndex !== -1) {
        selected.splice(existedIndex, 1);
      }

      state.selected = [...selected, payload];
    },
    sportsmanSearchAllSelect: (state, { payload }) => {
      if (payload) {
        state.selected = [...state.data.filter(item => !item.notSelectableReason).slice(0, payload)];
      } else {
        state.selected = state.data.filter(item => !item.notSelectableReason);
      }
    },
    sportsmanSearchAllUnselect: state => {
      state.selected = [];
    },
    sportsmanSearchDataReset: state => {
      state.data = [];
      state.totalCount = 0;
      state.pageCount = 0;
      state.pageNumber = 1;
      state.selected = [];
      state.selectRestrictions = [];
    },
    sportsmanSearchSetFilter: (state, { payload }) => {
      state.filter = payload;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    sportsmanSearchSetPageSize: (state, { payload }) => {
      const { pageSize } = payload;

      state.pageNumber = 1;
      state.search.pageSize = pageSize;
      state.needRefreshWatcher++;
    },
    sportsmanSearchSetSort: (state, { payload }) => {
      const { sort } = payload;

      state.search.sort = sort;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    sportsmanSearchUnselect: (state, { payload }) => {
      const existedIndex = state.selected.findIndex(s => s.id === payload.id);

      if (existedIndex !== -1) {
        state.selected.splice(existedIndex, 1);
      }
    },
    sportsmanSearchNeedRefreshWatcherReset: state => {
      state.needRefreshWatcher = 0;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(sportsmansSearchFetch.pending, state => {
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;
        state.needRefreshWatcher = 0;
      })
      .addCase(sportsmansSearchFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;
        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;
        state.data = data;
        state.totalCount = totalCount;
        state.pageCount = pageCount;
      })
      .addCase(sportsmansSearchFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (aborted) {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = true;
          state.data = [];
        }
      });
  },
});

export const {
  sportsmanSearchSetSearch,
  sportsmanSearchSetPage,
  sportsmanSearchSelect,
  sportsmanSearchStartSession,
  sportsmanSearchAllSelect,
  sportsmanSearchAllUnselect,
  sportsmanSearchDataReset,
  sportsmanSearchSetFilter,
  sportsmanSearchSetPageSize,
  sportsmanSearchSetSort,
  sportsmanSearchSortReset,
  sportsmanSearchUnselect,
  sportsmanSearchNeedRefreshWatcherReset,
  sportsmanSearchSetTab,
} = slice.actions;

export default slice.reducer;
