export const locale = {
  short: 'ru',
  date: 'ru',
  full: 'ru-RU',
  currency: '₽',
  map: 'ru_RU',
  currencyBenefit: 'ББ',
  currencyAsp: 'РАСП',
  currencyCorp: 'бонусов',
  currencyAspRub: 'РАСП+₽',
} as const;
