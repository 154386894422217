import { combineReducers } from '@reduxjs/toolkit';
import list, { SocialPackageApplicationListState } from './table/store/slice';

interface SocialPackageApplicationState {
  list: SocialPackageApplicationListState;
}

export default combineReducers<SocialPackageApplicationState>({
  list,
});
