import { RootState } from '@/data/store/store';
import { ESortDirection, MpCustomer, Nullable } from '@/domain';
import { createSelector } from '@reduxjs/toolkit';
import { ECustomerTableColumn } from '../utils';

export const customersGuidSelector = (store: RootState) => store.customer.list.guid;
export const customersIsFetchingSelector = (store: RootState) => store.customer.list.isFetching;
export const customersCustomersSelector = (store: RootState) => store.customer.list.data;
export const customersSearchSelector = (store: RootState) => store.customer.list.search;
export const customersFilterSelector = (store: RootState) => store.customer.list.filter;
export const customersNeedRefreshWatcherSelector = (store: RootState) => store.customer.list.needRefreshWatcher;
export const customersTabsCounterSelector = (store: RootState) => store.customer.list.tabsCounter;

export const customersPageNumberSelector = (store: RootState) => store.customer.list.pageNumber;
export const customersTotalCountSelector = (store: RootState) => store.customer.list.totalCount;
export const customersPageCountSelector = (store: RootState) => store.customer.list.pageCount;
export const customersSortColumnSelector = (store: RootState) =>
  (store.customer.list.search.sort?.split(',') as [ECustomerTableColumn, ESortDirection])?.[0] ?? '';
export const customersSortDirectionSelector = (store: RootState) =>
  (store.customer.list.search.sort?.split(',') as [ECustomerTableColumn, ESortDirection])?.[1] ?? '';

const customersCustomerIndexSelector = (store: RootState, index: number) => index;
const createCustomersCustomerByIndexSelector = createSelector(
  customersCustomersSelector,
  customersCustomerIndexSelector,
  (customers, index): Nullable<MpCustomer> => customers?.[index] ?? null
);

export const customersCustomerByIndexSelector = (index: number) => (store: RootState) =>
  createCustomersCustomerByIndexSelector(store, index);
