import {
  Address,
  SportOption,
  EUserGender,
  ActivityType,
  DataFilterQueryDslOperator,
  DataFilterStrategyAdapterTreeMultipleEditAdapter,
  DataFilterStrategyBase,
  DataFilterStrategyListItem,
  DataFilterValueItem,
  EDataFilterType,
  Nullable,
} from '@/domain';

import { AddressHelper } from '../../utils/address';
import ActivityTypeFilterEditAdapter from '../activityType/adapters/filterEdit/container';
import { ESportsmanTableColumn } from './table/utils';

export enum ESportsmansTableFilterItem {
  Name = 'name',
  ActivityTypes = 'activityTypes',
  Road = 'road',
  Gender = 'gender',
  Locality = 'locality',
  AgeMin = 'ageMin',
  AgeMax = 'ageMax',
  // Team = 'team',
}

export const sportsmansTableFilterItems: Record<ESportsmanTableColumn, ESportsmansTableFilterItem[]> = {
  [ESportsmanTableColumn.Logo]: [],
  [ESportsmanTableColumn.LastName]: [ESportsmansTableFilterItem.Name],
  [ESportsmanTableColumn.ActivityTypes]: [ESportsmansTableFilterItem.ActivityTypes],
  [ESportsmanTableColumn.Road]: [ESportsmansTableFilterItem.Road],
  [ESportsmanTableColumn.Age]: [ESportsmansTableFilterItem.AgeMin, ESportsmansTableFilterItem.AgeMax],
  [ESportsmanTableColumn.Gender]: [ESportsmansTableFilterItem.Gender],
  [ESportsmanTableColumn.DepartmentName]: [],
  [ESportsmanTableColumn.Locality]: [ESportsmansTableFilterItem.Locality],
};

export type SportsmansTableFilterEditStrategy = DataFilterStrategyBase<ESportsmansTableFilterItem>;

export type SportsmansTableFilterValues = {
  readonly [ESportsmansTableFilterItem.Name]?: DataFilterValueItem<Nullable<string>>;
  readonly [ESportsmansTableFilterItem.ActivityTypes]?: DataFilterValueItem<Nullable<ActivityType[]>>;
  readonly [ESportsmansTableFilterItem.Gender]?: DataFilterValueItem<Nullable<EUserGender>>;
  readonly [ESportsmansTableFilterItem.Locality]?: DataFilterValueItem<Nullable<Address>>;
  readonly [ESportsmansTableFilterItem.Road]?: DataFilterValueItem<Nullable<string[]>>;
  readonly [ESportsmansTableFilterItem.AgeMin]?: DataFilterValueItem<Nullable<number>>;
  readonly [ESportsmansTableFilterItem.AgeMax]?: DataFilterValueItem<Nullable<number>>;
};

export const getSportsmanTableFilterStrategy = (
  filterItem: ESportsmansTableFilterItem,
  values: SportsmansTableFilterValues,
  userGenders: SportOption[],
  roads: SportOption[]
): Nullable<SportsmansTableFilterEditStrategy> => {
  const genderItems: DataFilterStrategyListItem[] = (userGenders ?? []).map(({ id, name }) => ({ id, name }));
  const roadItems: DataFilterStrategyListItem[] = (roads ?? []).map(({ id, name }) => ({ id, name }));

  switch (filterItem) {
    case ESportsmansTableFilterItem.Name:
      return {
        type: EDataFilterType.String,
        key: ESportsmansTableFilterItem.Name,
        label: 'Поиск',
        value: values[ESportsmansTableFilterItem.Name]?.value || null,
        preview: values[ESportsmansTableFilterItem.Name]?.value || null,
      };
    case ESportsmansTableFilterItem.Locality:
      return {
        type: EDataFilterType.AddressLocalitySingle,
        key: ESportsmansTableFilterItem.Locality,
        label: 'Населённый пункт',
        preview: values[ESportsmansTableFilterItem.Locality]?.value
          ? new AddressHelper(values[ESportsmansTableFilterItem.Locality]!.value!).getLocalityFullPath()
          : null,
        value: values[ESportsmansTableFilterItem.Locality]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Equals,
        },
      };
    case ESportsmansTableFilterItem.ActivityTypes:
      return {
        type: EDataFilterType.AdapterTreeMultiple,
        key: ESportsmansTableFilterItem.ActivityTypes,
        label: 'Интересы',
        preview: `Интересы (${values[ESportsmansTableFilterItem.ActivityTypes]?.value?.length})`,
        previews: values[ESportsmansTableFilterItem.ActivityTypes]?.value?.map(t => t.name),
        value: values[ESportsmansTableFilterItem.ActivityTypes]?.value || null,
        editAdapter:
          ActivityTypeFilterEditAdapter as DataFilterStrategyAdapterTreeMultipleEditAdapter<ESportsmansTableFilterItem>,
      };
    case ESportsmansTableFilterItem.Road:
      return {
        type: EDataFilterType.ListMultiple,
        key: ESportsmansTableFilterItem.Road,
        label: 'Дороги',
        preview: `Дороги (${values[ESportsmansTableFilterItem.Road]?.value?.length})`,
        previews: roadItems
          .filter(t => values[ESportsmansTableFilterItem.Road]?.value?.some(v => v === t.id))
          .map(t => t.name),
        value: values[ESportsmansTableFilterItem.Road]?.value || null,
        items: roadItems,
        querydsl: {
          operator: DataFilterQueryDslOperator.In,
        },
      };
    case ESportsmansTableFilterItem.Gender:
      return {
        type: EDataFilterType.ListSingle,
        key: ESportsmansTableFilterItem.Gender,
        label: 'Пол',
        preview: genderItems.find(g => g.id === values[ESportsmansTableFilterItem.Gender]?.value)?.name || null,
        value: values[ESportsmansTableFilterItem.Gender]?.value || null,
        items: genderItems,
        querydsl: {
          operator: DataFilterQueryDslOperator.Equals,
        },
      };
    case ESportsmansTableFilterItem.AgeMin:
      return {
        type: EDataFilterType.Number,
        key: ESportsmansTableFilterItem.AgeMin,
        label: 'Возраст от',
        preview: `Возраст от ${values[ESportsmansTableFilterItem.AgeMin]?.value}`,
        value: values[ESportsmansTableFilterItem.AgeMin]?.value || null,
      };
    case ESportsmansTableFilterItem.AgeMax:
      return {
        type: EDataFilterType.Number,
        key: ESportsmansTableFilterItem.AgeMax,
        label: 'Возраст до',
        preview: `Возраст до ${values[ESportsmansTableFilterItem.AgeMax]?.value}`,
        value: values[ESportsmansTableFilterItem.AgeMax]?.value || null,
      };
    // case ESportsmansTableFilterItem.Team:
    //   return {
    //     type: EDataFilterType.AutocompleteSingle,
    //     key: ESportsmansTableFilterItem.Team,
    //     label: 'Команда',
    //     preview: `Команда ${values[ESportsmansTableFilterItem.Team]?.value?.name}` || null,
    //     value: values[ESportsmansTableFilterItem.Team]?.value || null,
    //     querydsl: {
    //       operator: DataFilterQueryDslOperator.In,
    //       valueAttribute: 'id',
    //     },
    //     source: (name: string) =>
    //       Api.team
    //         .all({
    //           name,
    //           sort: 'name,asc',
    //           page: 1,
    //           pageSize: pageSizeAll,
    //         })
    //         .then(response => response.data.content),
    //   };
  }
};
