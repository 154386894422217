import { AutocompleteProps, Chip, Typography } from '@mui/material';
import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete/Autocomplete';
import * as React from 'react';
import { useState } from 'react';
import { Nullable } from '../../../../domain/model/types';
import ContentLoader from '../../../components/common/loader';
import { MPFormInput } from '../input';
import { Autocomplete, LoaderWrapper } from './controls';
import { MPAutocompleteMultipleSelectItem } from './types';

export interface MPAutocompleteMultipleSelectProps<T>
  extends Omit<AutocompleteProps<T, true, false, false>, 'renderInput'> {
  readonly value: T[];
  readonly options: T[];
  readonly label?: string;
  readonly helperText?: Nullable<string>;
  readonly error?: boolean;
  readonly isLoading?: boolean;
  readonly renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
  readonly onSearchValue?: (value: string) => void;
  readonly onChangeValue: (value: Nullable<T[]>) => void;
}

export function MPAutocompleteMultipleSelect<T extends MPAutocompleteMultipleSelectItem>(
  props: MPAutocompleteMultipleSelectProps<T>
): JSX.Element {
  const {
    options,
    value,
    onChangeValue,
    onSearchValue,
    label,
    error,
    helperText,
    renderInput,
    isLoading,
    placeholder = 'Поиск',
    noOptionsText,
    ...others
  } = props;

  const [internalInputValue, setInputValue] = useState<Nullable<string>>('');

  const val = value?.length ? value?.map(item => options.find(option => option.id === item.id) ?? item) : [];

  return (
    <Autocomplete
      multiple
      value={val}
      options={options}
      inputValue={internalInputValue || ''}
      noOptionsText={
        <Typography variant={'body2'}>{isLoading ? 'загрузка...' : noOptionsText || 'нет вариантов'}</Typography>
      }
      getOptionLabel={option => option.name ?? ''}
      onChange={(event, newValue) => onChangeValue(newValue)}
      onInputChange={(event, newValue) => {
        // есть сложное поведение при появлении подсказок поиск очищается когда приходит event.type='click' или event=null
        if (event && event.type !== 'click') {
          setInputValue(newValue);
          onSearchValue?.(newValue);
        }
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={
        renderInput ??
        (params => (
          <MPFormInput
            {...params}
            error={!!error}
            helperText={helperText}
            label={label}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: isLoading ? (
                <LoaderWrapper>
                  <ContentLoader size={23} />
                </LoaderWrapper>
              ) : (
                <>{params.InputProps.endAdornment}</>
              ),
            }}
          />
        ))
      }
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            component={'div' as any}
            selected
            label={option.name}
            color='primary'
            {...getTagProps({ index })}
            key={option.id}
          />
        ))
      }
      {...others}
    />
  );
}
