import { CustomerLifeCycleFactory } from '@features/customer/lifecycle/lifecycle';
import { viewShowMainMenu } from '@features/main/container/store/slice';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import ForbiddenComponent from '../../components/common/forbidden';
import CustomerDetailsContainer from '../../features/customer/details/container';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

const CustomerScreen = () => {
  const dispatch = useDispatch();
  const {
    accessMatrix: { customers, isPartnerUser },
    userSpecific,
  } = useCurrentUser();

  const { id } = useParams<{ id: UUID }>();

  useEffect(() => {
    dispatch(viewShowMainMenu());
  }, [dispatch]);

  if (!customers?.view?.details) {
    return <ForbiddenComponent />;
  }

  return (
    <CustomerDetailsContainer
      id={id}
      lifecycle={CustomerLifeCycleFactory().create({ isPartnerUser, userId: userSpecific.id })}
      access={customers}
    />
  );
};

export default CustomerScreen;
