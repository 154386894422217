import { ClientOrg } from '@/domain';
import { useClientOrgUserActions } from '@features/user/userClientOrg/actions/useActions';
import { ClientOrgUserLifeCycle } from '@features/user/userClientOrg/lifecycle/types';
import {
  ClientOrgUserActions,
  ClientOrgUserActionTableType,
  EClientOrgUserActionType,
} from '@features/user/userClientOrg/types';
import { getClientOrgUserTableActions } from '@features/user/userClientOrg/utils/actions';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useDataTableFilterAdapter, { DataFilterAdapter } from '../../../../../hooks/useDataFilterAdapter';
import { EClientOrgUserTableFilterItem } from '../../filterUtils';
import { ClientOrgUserTable } from '../component';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  clientOrgUsersClientOrgUsersSelector,
  clientOrgUsersFilterSelector,
  clientOrgUsersGuidSelector,
  clientOrgUsersSelectedSelector,
  clientOrgUsersSortColumnSelector,
  clientOrgUsersSortDirectionSelector,
} from '../store/selectors';
import { ClientOrgUserActionCall, EClientOrgUserTableColumn } from '../utils';

interface ClientOrgUsersTableAdapterProps {
  readonly clientOrg: ClientOrg;
  readonly lifecycle: ClientOrgUserLifeCycle;
  readonly filterAdapter: DataFilterAdapter<EClientOrgUserTableColumn, EClientOrgUserTableFilterItem>;
  readonly tabActions: ClientOrgUserActions<ClientOrgUserActionTableType>;
}

export const ClientOrgUsersTableAdapter = (props: ClientOrgUsersTableAdapterProps) => {
  const { clientOrg, lifecycle, filterAdapter, tabActions } = props;

  const handlers = useContextHandlers();
  const guid = useSelector(clientOrgUsersGuidSelector);
  const sortColumn = useSelector(clientOrgUsersSortColumnSelector);
  const sortDirection = useSelector(clientOrgUsersSortDirectionSelector);
  const clientOrgUsers = useSelector(clientOrgUsersClientOrgUsersSelector);
  const filter = useSelector(clientOrgUsersFilterSelector);
  const selectedClientOrgUsers = useSelector(clientOrgUsersSelectedSelector);

  const {
    utils: {
      selectors: { isClientOrgUsersAnyChangedSelector },
    },
  } = useClientOrgUserActions();

  const needRefresh = useSelector(isClientOrgUsersAnyChangedSelector);

  const { metadata, onChangeMetadata } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: handlers.onChangeFilter,
    onSortReset: handlers.onResetSort,
  });

  const onAction = (call: ClientOrgUserActionCall) => {
    const { action, clientOrgUser } = call;

    switch (action) {
      case EClientOrgUserActionType.ResendWelcomeMail:
        handlers.onClientOrgUserResendWelcomeMail(clientOrg, [clientOrgUser]);
        break;
    }
  };

  useEffect(() => {
    if (needRefresh > 0) {
      handlers.onRefresh();
    }
  }, [needRefresh, handlers.onRefresh]);

  return (
    <ClientOrgUserTable
      metadata={metadata}
      onChangeMetadata={onChangeMetadata}
      sort={{ column: sortColumn, direction: sortDirection }}
      clientOrgUsers={clientOrgUsers}
      selectedClientOrgUsers={selectedClientOrgUsers}
      getActions={clientOrgUser => getClientOrgUserTableActions(lifecycle, clientOrgUser, tabActions)}
      onRequestSort={handlers.onChangeSort}
      onClientOrgUserAction={onAction}
      onAllClientOrgUserSelect={handlers.onAllClientOrgUserSelect}
      onClientOrgUserSelect={(clientOrgUser, selected) => handlers.onClientOrgUserSelect(clientOrgUser, selected)}
    />
  );
};
