import { UserFieldView } from '@components/common/userInfoView';
import { ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { AppUserSpecific } from '@/domain';
import { UserAvatar } from '@components/common/avatars';

interface MainMenuItemUserProps {
  readonly user: AppUserSpecific;
  readonly selected: boolean;
  readonly onClick: () => void;
}

export const MainMenuItemUser = ({ user, selected, onClick }: MainMenuItemUserProps) => {
  return (
    <ListItem
      button
      selected={selected}
      onClick={onClick}
    >
      <ListItemAvatar>
        <UserAvatar
          user={user}
          size='micro'
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          <UserFieldView
            user={user}
            field={'fullNameReverse'}
          />
        }
        primaryTypographyProps={{ variant: 'body1' }}
      />
    </ListItem>
  );
};
