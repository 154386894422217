import {
  ECmsBannerLinkObjectType,
  ECmsCollectionLinkObjectType,
  EOfferStatus,
  EOfferType,
  SportOptionTyped,
} from '@/domain';
import CmsCategoryMultipleCollectionContainer from '@features/cms/container/create/collection/category/multiple';
import { getCmsBannerViewLinkedObjectType, getCmsBannerViewLinkedObjectTypes } from '@features/cms/container/utils';
import { cmsBannerViewLinkedObjectType } from '@features/cms/utils';
import { FormHelperText, Grid, Typography } from '@mui/material';
import { MPFormInput } from '@ui-kit/input';
import { MPMenuItem } from '@ui-kit/menu/item';
import { MPSelect } from '@ui-kit/select';
import { ValidationResult } from 'presentation/utils/validation';
import { useCallback, useMemo } from 'react';
import FileInfo from '../../../../../../components/common/files/info';
import SectionTitle from '../../../../../../components/common/sectionTitle';
import Splitter from '../../../../../../components/common/splitter';
import useTechConfig from '../../../../../../hooks/useTechConfig';
import CmsCategorySelector from '../../../../components/category';
import { CmsContainerView } from '../../../../types';
import { CmsLinkedObjectBannerType, ECmsBannerViewLinkObjectType } from '../../../../types/banner';
import { CmsLinkedObjectCollectionTypes } from '../../../../types/collection';
import { CmsFeatureContainerCommonProps } from '../../../types';
import {
  CmsContainerEditBannerImageSelectorDesktop,
  CmsContainerEditBannerImageSelectorMobile,
} from '../../attributes/bannerImage';
import CmsCorpOfferMultipleCollectionContainer from '../../collection/corpOffer/multiple';
import CmsCorpOfferSingleCollectionContainer from '../../collection/corpOffer/single';
import CmsTradeOfferMultipleCollectionContainer from '../../collection/tradeOffer/multiple';
import CmsTradeOfferSingleCollectionContainer from '../../collection/tradeOffer/single';
import { ColumnWrapper, Content, Wrapper } from './controls';
import useCmsContainerCreateBannerChange from './useBannerChange';

interface CmsContainerCreateStepBannerContainerProps extends CmsFeatureContainerCommonProps {
  readonly guid: UUID;
  readonly offerType: EOfferType;
  readonly cmsBanner: CmsLinkedObjectBannerType;
  readonly cmsContainer: CmsContainerView;
  readonly validation: Nullable<ValidationResult<CmsLinkedObjectBannerType>>;
  readonly onChangeBanner: (data: CmsLinkedObjectBannerType) => void;
}

const offerStatuses: EOfferStatus[] = [EOfferStatus.Active, EOfferStatus.Upcoming];

const CmsContainerCreateStepBanner = (props: CmsContainerCreateStepBannerContainerProps) => {
  const { guid, cmsBanner, cmsContainer, cmsContext, validation, onChangeBanner } = props;

  const { type } = cmsContainer;
  const { linkObjectType } = cmsBanner;

  const activateImageEditor = useTechConfig().getFeatureOptions('cms')?.bannerImageCrop ?? false;

  const cmsBannerLinkedObjectTypes = useMemo(() => Object.values(cmsBannerViewLinkedObjectType), []);

  const bannerLinkedObjectTypes = useMemo(
    () =>
      getCmsBannerViewLinkedObjectTypes(
        cmsBannerLinkedObjectTypes,
        cmsContainer.type?.code ?? null,
        cmsBanner,
        cmsContainer.offerType ?? EOfferType.Trade
      ),
    [cmsBannerLinkedObjectTypes, cmsContainer.type?.code, cmsContainer.offerType]
  );

  const linkObjectViewType = useMemo(() => getCmsBannerViewLinkedObjectType(cmsBanner), [cmsBanner]);

  const bannerLinkedObjectType = bannerLinkedObjectTypes.find(lot => lot.id === linkObjectViewType) ?? '';

  const isObjectTypeDisabled =
    bannerLinkedObjectTypes.length <= 1 &&
    bannerLinkedObjectType !== '' &&
    bannerLinkedObjectTypes?.[0]?.id === bannerLinkedObjectType.id;
  const isLinkType = linkObjectType === ECmsBannerLinkObjectType.Link;

  const { onChangeBannerAttribute, onChangeBannerLinkedObject } = useCmsContainerCreateBannerChange({
    cmsBanner,
    onChangeBanner,
  });

  const onChangeBannerCollectionLinkedObject = useCallback(
    (cmsCollection: CmsLinkedObjectCollectionTypes) => {
      onChangeBannerLinkedObject({
        linkObjectType: ECmsBannerLinkObjectType.Collection,
        linkedObject: cmsCollection,
      });
    },
    [onChangeBannerLinkedObject]
  );

  return (
    <>
      <Wrapper>
        <ColumnWrapper>
          <Content>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <Typography variant='h3'>Баннер</Typography>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Splitter
                  variant='horizontal'
                  size={1}
                />
                <SectionTitle>Для сайта</SectionTitle>
                <Grid
                  container
                  spacing={1}
                  direction='column'
                >
                  {cmsBanner.desktopImage ? (
                    <Grid item>
                      <FileInfo
                        id={cmsBanner.desktopImage.path}
                        onRemove={() => onChangeBannerAttribute('desktopImage', null)}
                      />
                    </Grid>
                  ) : (
                    <Grid item>
                      <CmsContainerEditBannerImageSelectorDesktop
                        cmsContainerType={type!.code}
                        cmsBanner={cmsBanner}
                        validation={validation?.desktopImage}
                        activateImageEditor={activateImageEditor}
                        onUpload={image => onChangeBannerAttribute('desktopImage', image)}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Splitter
                  variant='horizontal'
                  size={1}
                />
                <SectionTitle>Для мобильной версии</SectionTitle>
                <Grid
                  container
                  spacing={1}
                  direction='column'
                >
                  {cmsBanner.mobileImage ? (
                    <Grid item>
                      <FileInfo
                        id={cmsBanner.mobileImage.path}
                        onRemove={() => onChangeBannerAttribute('mobileImage', null)}
                      />
                    </Grid>
                  ) : (
                    <Grid item>
                      <CmsContainerEditBannerImageSelectorMobile
                        cmsContainerType={type!.code}
                        cmsBanner={cmsBanner}
                        validation={validation?.mobileImage}
                        activateImageEditor={activateImageEditor}
                        onUpload={image => onChangeBannerAttribute('mobileImage', image)}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Splitter
                  variant='horizontal'
                  size={1}
                />
                <SectionTitle>Переход по баннеру</SectionTitle>
                <Splitter
                  variant='horizontal'
                  size={1}
                />
                {isLinkType ? (
                  <MPFormInput
                    value={cmsBanner.linkedObject}
                    label='URL ссылка'
                    error={validation?.linkObjectType?.hasError}
                    helperText={validation?.linkObjectType?.message}
                    onChange={lot => {
                      onChangeBannerLinkedObject({
                        linkObjectType,
                        linkedObject: lot.target.value ?? null,
                      });
                    }}
                  />
                ) : (
                  <MPSelect<SportOptionTyped<ECmsBannerViewLinkObjectType>>
                    value={bannerLinkedObjectType}
                    label='Переход на'
                    error={validation?.linkObjectType?.hasError}
                    helperText={validation?.linkObjectType?.message}
                    disabled={isObjectTypeDisabled}
                    onChange={lot => onChangeBannerAttribute('linkObjectType', lot.id)}
                  >
                    {bannerLinkedObjectTypes.map(lot => (
                      <MPMenuItem
                        key={lot.id}
                        value={lot}
                      >
                        <Typography variant='body1'>{lot.name}</Typography>
                      </MPMenuItem>
                    ))}
                  </MPSelect>
                )}
              </Grid>
              {linkObjectType === ECmsBannerLinkObjectType.CorpOffer && (
                <CmsCorpOfferSingleCollectionContainer
                  guid={guid}
                  cmsContainer={cmsContainer}
                  cmsContext={cmsContext}
                  offer={cmsBanner.linkedObject}
                  statuses={offerStatuses}
                  onChangeLinkedObject={onChangeBannerLinkedObject}
                />
              )}
              {linkObjectType === ECmsBannerLinkObjectType.TradeOffer && (
                <CmsTradeOfferSingleCollectionContainer
                  guid={guid}
                  cmsContainer={cmsContainer}
                  cmsContext={cmsContext}
                  offer={cmsBanner.linkedObject}
                  statuses={offerStatuses}
                  onChangeLinkedObject={onChangeBannerLinkedObject}
                />
              )}
              {(linkObjectType === ECmsBannerLinkObjectType.CorpOfferCategory ||
                linkObjectType === ECmsBannerLinkObjectType.TradeOfferCategory) && (
                <CmsCategorySelector
                  cmsContext={cmsContext}
                  category={cmsBanner.linkedObject}
                  statuses={offerStatuses}
                  linkObjectType={cmsBanner.linkObjectType}
                  onChangeLinkedObject={onChangeBannerLinkedObject}
                />
              )}
              {linkObjectType === ECmsBannerLinkObjectType.Collection &&
                (cmsBanner.linkedObject?.linkObjectType === ECmsCollectionLinkObjectType.CorpOfferCategory ||
                  cmsBanner.linkedObject?.linkObjectType === ECmsCollectionLinkObjectType.TradeOfferCategory) && (
                  <CmsCategoryMultipleCollectionContainer
                    cmsContext={cmsContext}
                    categories={cmsBanner.linkedObject.linkedObject ?? []}
                    statuses={offerStatuses}
                    linkObjectType={cmsBanner.linkedObject.linkObjectType}
                    onChangeLinkedObject={onChangeBannerCollectionLinkedObject}
                  />
                )}
              {linkObjectType === ECmsBannerLinkObjectType.Collection &&
                cmsBanner.linkedObject?.linkObjectType === ECmsCollectionLinkObjectType.CorpOffer && (
                  <CmsCorpOfferMultipleCollectionContainer
                    guid={guid}
                    cmsContainer={cmsContainer}
                    cmsContext={cmsContext}
                    statuses={offerStatuses}
                    offers={cmsBanner.linkedObject.linkedObject ?? []}
                    onChangeLinkedObject={onChangeBannerCollectionLinkedObject}
                  />
                )}
              {linkObjectType === ECmsBannerLinkObjectType.Collection &&
                cmsBanner.linkedObject?.linkObjectType === ECmsCollectionLinkObjectType.TradeOffer && (
                  <CmsTradeOfferMultipleCollectionContainer
                    guid={guid}
                    cmsContainer={cmsContainer}
                    cmsContext={cmsContext}
                    statuses={offerStatuses}
                    offers={cmsBanner.linkedObject.linkedObject ?? []}
                    onChangeLinkedObject={onChangeBannerCollectionLinkedObject}
                  />
                )}

              {validation?.linkedObject?.hasError && (
                <Grid
                  item
                  xs={12}
                >
                  <FormHelperText error>{validation?.linkedObject.message}</FormHelperText>
                </Grid>
              )}
            </Grid>
          </Content>
        </ColumnWrapper>
      </Wrapper>
    </>
  );
};

export default CmsContainerCreateStepBanner;
