import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { UUID } from '@/domain';
import { DataFilterAdapter } from '../../../hooks/useDataFilterAdapter';
import { nsiDataSelector } from '../../general/nsi/store/selectors';
import {
  ESportsmansTableFilterItem,
  getSportsmanTableFilterStrategy,
  sportsmansTableFilterItems,
} from '../filterUtils';
import SportsmanTableContainer from './container';
import { SportsmansTableConfigContext } from './context';
import { useSportsmansTableConfig } from './hooks/useSportsmansTableConfig';
import { ESportsmanTableColumn, getSportsmansTableAdapterKey, getSportsmansTableColumns } from './utils';

interface SportsmansTableEntryProps {
  readonly guid: UUID;
}

const SportsmanTableEntry = ({ guid }: SportsmansTableEntryProps) => {
  // получаем максимально возможный состав колонок
  const sourceColumns = getSportsmansTableColumns();

  const config = useSportsmansTableConfig();

  const { roads, userGenders } = useSelector(nsiDataSelector);

  // готовим адаптер для хука
  const adapter = useMemo<DataFilterAdapter<ESportsmanTableColumn, ESportsmansTableFilterItem>>(
    () => ({
      ...getSportsmansTableAdapterKey(),
      sourceColumns,
      filterItems: sportsmansTableFilterItems,
      getDataFilterStrategy: (filterItem, values) => {
        return getSportsmanTableFilterStrategy(filterItem, values, userGenders, roads);
      },
    }),
    [sourceColumns, roads, userGenders]
  );

  return (
    <SportsmansTableConfigContext.Provider value={config}>
      <SportsmanTableContainer
        guid={guid}
        filterAdapter={adapter}
        tab={config.defaultTab}
      />
    </SportsmansTableConfigContext.Provider>
  );
};

export default SportsmanTableEntry;
