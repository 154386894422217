import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault, fetchableFailed, fetchableFetched, fetchableFetching } from '@/data/store/types';
import {
  ApprovalOfferRegistryRequest,
  CorpOffer,
  CorpOfferStoreResponsiblePerson,
  ECorpPersonalPromotionType,
  EOfferStatus,
  EPartnerDiscriminator,
  EPartnerOwnershipType,
  EPartnerStatus,
  EPartnerType,
  EPaymentType,
  ETargetType,
  ETaxationSystemType,
  Nullable,
  PartnerDesk,
  PartnerResponsiblePersonRequest,
  PartnerShort,
  SportOption,
  Target,
  UUID,
} from '@/domain';
import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { createEmptyDraftAddress } from '../../../../utils/address';
import offerServices from '../../../general/offer/services';
import { getCorpOfferCreateEmptyTarget, getDefaultActivationExpiryDays } from '../utils';

interface CorpOfferCreateByIdFetchProps {
  readonly id: Nullable<UUID>;
  readonly data: CorpOffer;
  readonly partner: Nullable<PartnerShort>;
  readonly desk: Nullable<PartnerDesk>;
  readonly offerCount: Nullable<number>;
  readonly notUsedOfferCount: Nullable<number>;
  readonly corpOfferStatus: EOfferStatus;
  readonly rejectionReasonType: Nullable<SportOption>;
  readonly lastStatusComment: Nullable<string>;
}

export const corpOfferCreateViewed = createAsyncThunk<void, UUID, AppThunkAPIConfig>(
  'corpOffer/create/viewed',
  async id => {
    try {
      await offerServices.common.makeViewed({ id });
    } catch (e: any) {
      console.error(`Error at call user event`, e);
    }
  }
);

export const corpOfferCreateByIdFetch = createAsyncThunk<
  CorpOfferCreateByIdFetchProps,
  { id: Nullable<UUID>; partnerId: Nullable<UUID> },
  AppThunkAPIConfig
>('corpOffer/create/byId/fetch', async ({ id, partnerId }, { rejectWithValue, signal, dispatch }) => {
  try {
    let partner: Nullable<PartnerShort> = null;
    let desk: Nullable<PartnerDesk> = null;

    if (partnerId) {
      partner = (await Api.partner.one({ id: partnerId, signal })).data;
      desk = (await Api.partner.desk({ partnerId, signal })).data;
    }

    if (!id) {
      const result: CorpOfferCreateByIdFetchProps = {
        id,
        data: {
          id: '',
          status: EOfferStatus.Draft,
          code: '',
          lastStatusDate: null,
          lastStatusAuthor: null,
          lastStatusComment: null,
          offerCount: 0,
          notUsedOfferCount: 0,
          partner: partner || {
            id: '',
            status: EPartnerStatus.Enabled,
            type: EPartnerType.External,
            clientOrg: null,
            code: 0,
            creator: {
              id: '',
              name: '',
              code: null,
              image: null,
            },
            owner: null,
            partnerReviewer: null,
            permissions: null,
            lastStatusComment: null,
            lastStatusDate: null,

            name: '',
            inn: '',
            innCert: {
              path: '',
            },
            checkingAccount: '',
            bic: '',
            taxSystem: ETaxationSystemType.OSNO,
            usnDoc: {
              path: '',
            },
            ownerFullName: '',
            email: '',
            website: '',
            phone: '',
            address: createEmptyDraftAddress(),
            corpPartnershipAgreement: true,
            partnershipAgreementDoc: {
              path: '',
            },
            regInfo: {
              orgType: EPartnerOwnershipType.IndividualEntrepreneur,
              discriminator: EPartnerDiscriminator.PartnerIndividualData,
              ogrnip: '',
              ogrnipCert: { path: '' },
              passportCopy: { path: '' },
            },
            agreements: [],
            logo: {
              path: '',
            },
          },
          approvingAdmin: null,
          moderator: null,
          pausedReason: null,
          createdAt: '',
          createdBy: '',
          updatedAt: null,
          updatedBy: null,

          name: null,
          images: null,
          partnerId,
          partnerLink: null,
          categories: [],
          startDate: null,
          endDate: null,
          conditions: null,
          description: null,
          externalId: null,
          paymentType: EPaymentType.BenefitRzdPoint,
          price: null,
          originalPrice: null,
          promotionType: partner?.type === EPartnerType.Inner ? null : ECorpPersonalPromotionType.Certificate,
          externalActivationLink: null,
          target: getCorpOfferCreateEmptyTarget(),
          externalCorpOfferType: null,
          costLimit: null,
          rejectionReasonType: null,
          hasNdfl: null,
          ndflDescription: null,
          approvalRegistry: null,
          activationExpiryDays: null,
          responsiblePerson: null,
          storeResponsiblePerson:
            partner?.type === EPartnerType.Inner
              ? {
                  responsiblePersonLink: { id: null },
                  fullName: null,
                  phone: null,
                  email: null,
                  schedule: 'ЧЧ:ММ-ЧЧ:ММ день недели',
                }
              : null,
        },
        partner,
        desk,
        offerCount: null,
        notUsedOfferCount: null,
        corpOfferStatus: EOfferStatus.Draft,
        rejectionReasonType: null,
        lastStatusComment: null,
      };
      return {
        ...result,
        data: {
          ...result.data,
          activationExpiryDays:
            result.data.partner.type === EPartnerType.Inner
              ? null
              : getDefaultActivationExpiryDays(result.data.partner.type),
        },
      };
    } else {
      const data = await offerServices.corp.one({ id, signal });
      dispatch(corpOfferCreateViewed(id));
      if (data.partnerId) {
        partner = (await Api.partner.one({ id: data.partnerId, signal })).data;
        desk = (await Api.partner.desk({ partnerId: data.partnerId, signal })).data;
      }

      const { offerCount, notUsedOfferCount, status: corpOfferStatus, rejectionReasonType, lastStatusComment } = data;
      return {
        id,
        data,
        offerCount,
        notUsedOfferCount,
        corpOfferStatus,
        rejectionReasonType,
        lastStatusComment,
        partner,
        desk,
      };
    }
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);

    return rejectWithValue(e.response.data);
  }
});

export const corpOfferCreatePromotionsCountFetch = createAsyncThunk<CorpOffer, { id: UUID }, AppThunkAPIConfig>(
  'corpOffer/create/promotionsCount/fetch',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await offerServices.corp.one({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const corpOfferCreateSave = createAsyncThunk<
  CorpOffer,
  {
    id: UUID;
    corpOffer: CorpOffer;
    approvalRegistry: Nullable<ApprovalOfferRegistryRequest>;
    responsiblePerson: Nullable<PartnerResponsiblePersonRequest>;
  },
  AppThunkAPIConfig
>('corpOffer/create/save', async ({ id, corpOffer, approvalRegistry, responsiblePerson }, { rejectWithValue }) => {
  try {
    return await offerServices.corp.applyData({
      data: corpOffer,
      approvalRegistry,
      responsiblePerson,
    });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const corpOfferCreateRestore = createAsyncThunk<void, CorpOffer, AppThunkAPIConfig>(
  'corpOffer/create/restore',
  async (corpOffer, { rejectWithValue }) => {
    try {
      await offerServices.corp.save(corpOffer);
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const corpOfferCreatePublish = createAsyncThunk<CorpOffer, CorpOffer, AppThunkAPIConfig>(
  'corpOffer/create/publish',
  async (corpOffer, { rejectWithValue }) => {
    try {
      const savedData = await offerServices.corp.save(corpOffer);
      return await offerServices.corp.publish({ id: savedData.id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const corpOfferCreateDelete = createAsyncThunk<undefined, { id: UUID }, AppThunkAPIConfig>(
  'corpOffer/create/delete',
  async ({ id }, { rejectWithValue }) => {
    try {
      await offerServices.corp.delete({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const corpOfferCreateResume = createAsyncThunk<undefined, CorpOffer, AppThunkAPIConfig>(
  'corpOffer/create/resume',
  async (corpOffer, { rejectWithValue }) => {
    try {
      await offerServices.corp.save(corpOffer);
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const corpOfferCreateNotUsedCodesDelete = createAsyncThunk<CorpOffer, { id: UUID }, AppThunkAPIConfig>(
  'corpOffer/create/codesFile/delete',
  async ({ id }, { rejectWithValue }) => {
    try {
      await Api.personalPromotion.notUsedDelete({ offerId: id });
      return await offerServices.corp.one({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export interface CorpOfferCreateState {
  readonly byId: Fetchable & {
    readonly id: Nullable<UUID>;
    readonly data: Nullable<CorpOffer>;
    readonly loadedData: Nullable<CorpOffer>;
    readonly approvalRegistry: ApprovalOfferRegistryRequest;
    readonly responsiblePerson: PartnerResponsiblePersonRequest;
    readonly modified: boolean;
  };
  readonly partner: {
    readonly data: Nullable<PartnerShort>;
    readonly desk: Nullable<PartnerDesk>;
  };
  readonly offerCount: Nullable<number>;
  readonly notUsedOfferCount: Nullable<number>;
  readonly corpOfferStatus: EOfferStatus;
  readonly rejectionReasonType: Nullable<SportOption>;
  readonly lastStatusComment: Nullable<string>;
  readonly responsibleIsStoreResponsible: boolean;
  readonly notUsedCodesDelete: Fetchable;
  readonly save: Fetchable;
  readonly restore: Fetchable;
  readonly publish: Fetchable;
  readonly delete: Fetchable;
  readonly resume: Fetchable;
}

type Reducer<T = undefined> = CaseReducer<CorpOfferCreateState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<CorpOfferCreateState> {
  corpOfferCreateStateReset: Reducer;
  corpOfferCreateSetAttribute: Reducer<{ name: keyof CorpOffer; value: any }>;
  corpOfferCreateSetTargetAttribute: Reducer<{ name: keyof Target; value: any }>;
  corpOfferCreateSetResponsibleIsStoreResponsible: Reducer<boolean>;
  corpOfferCreateRemoveCategory: Reducer<UUID>;
  corpOfferCreateNotUsedCodesDeleteReset: Reducer;
  corpOfferCreateSetApprovalRegistryAttribute: Reducer<{ name: keyof ApprovalOfferRegistryRequest; value: any }>;
  corpOfferCreateSetResponsiblePersonAttribute: Reducer<{ name: keyof PartnerResponsiblePersonRequest; value: any }>;
  corpOfferCreateSetStoreResponsiblePersonAttribute: Reducer<{
    name: keyof CorpOfferStoreResponsiblePerson;
    value: any;
  }>;
}

const slice = createSlice<CorpOfferCreateState, Reducers, 'create'>({
  name: 'create',
  initialState: {
    byId: {
      ...fetchableDefault,
      id: null,
      data: null,
      loadedData: null,
      approvalRegistry: {
        number: null,
        date: null,
      },
      responsiblePerson: {
        fullName: null,
        phone: null,
        accountId: null,
      },
      modified: false,
    },
    partner: {
      data: null,
      desk: null,
    },
    offerCount: null,
    notUsedOfferCount: null,
    corpOfferStatus: EOfferStatus.Draft,
    rejectionReasonType: null,
    lastStatusComment: null,
    responsibleIsStoreResponsible: false,
    save: {
      ...fetchableDefault,
    },
    restore: {
      ...fetchableDefault,
    },
    publish: {
      ...fetchableDefault,
    },
    delete: {
      ...fetchableDefault,
    },
    resume: {
      ...fetchableDefault,
    },
    notUsedCodesDelete: {
      ...fetchableDefault,
    },
  },
  reducers: {
    corpOfferCreateStateReset: state => {
      state.partner.data = null;
      state.partner.desk = null;

      state.save = fetchableDefault;
      state.publish = fetchableDefault;
      state.delete = fetchableDefault;
      state.restore = fetchableDefault;
      state.resume = fetchableDefault;
      state.notUsedCodesDelete = { ...fetchableDefault };
    },
    corpOfferCreateSetAttribute: (state, { payload }) => {
      const { name, value } = payload;

      state.byId.modified = true;

      if (state.byId.data) {
        (state.byId.data[name] as keyof CorpOffer) = value;

        if (name === 'promotionType' && value === ECorpPersonalPromotionType.Certificate) {
          state.byId.data.externalActivationLink = null;
          state.byId.data.externalCorpOfferType = null;
          state.byId.data.costLimit = null;
          state.byId.data.activationExpiryDays = getDefaultActivationExpiryDays(state.byId.data.partner.type);
        }

        if (name === 'promotionType' && value === ECorpPersonalPromotionType.ExternalCertificate) {
          state.byId.data.activationExpiryDays = null;
        }

        if (name === 'hasNdfl' && !value) {
          state.byId.data.ndflDescription = null;
        }
      }
    },
    corpOfferCreateSetTargetAttribute: (state, { payload }) => {
      const { name, value } = payload;

      state.byId.modified = true;

      if (state.byId.data) {
        if (name === 'targetType') {
          const targetType = value as ETargetType;
          state.byId.data.target.targetType = targetType;
          switch (targetType) {
            case ETargetType.Geo:
              state.byId.data.target.targetOrgUnits = null;
              state.byId.data.target.targetRoads = null;
              break;
            case ETargetType.Corp:
              state.byId.data.target.targetLocalities = null;
              break;
          }
        } else {
          state.byId.data.target[name] = value;
        }
      }
    },
    corpOfferCreateSetResponsibleIsStoreResponsible: (state, { payload }) => {
      state.byId.modified = true;

      if (state.byId.data) {
        if (!state.byId.data.storeResponsiblePerson) {
          state.byId.data.storeResponsiblePerson = {
            responsiblePersonLink: null,
            fullName: null,
            phone: null,
            email: null,
            schedule: 'ЧЧ:ММ-ЧЧ:ММ день недели',
          };
        }
        state.byId.data.storeResponsiblePerson.fullName = null;
        state.byId.data.storeResponsiblePerson.phone = null;
        if (payload) {
          state.byId.data.storeResponsiblePerson.responsiblePersonLink = {
            id: state.byId.data.responsiblePerson?.id ?? null,
          };
        } else {
          state.byId.data.storeResponsiblePerson.responsiblePersonLink = null;
        }
      }
      state.responsibleIsStoreResponsible = payload;
    },
    corpOfferCreateRemoveCategory: (state, { payload }) => {
      if (state.byId.data?.categories) {
        state.byId.data.categories = state.byId.data.categories?.filter(category => category.id !== payload);
      }
    },
    corpOfferCreateNotUsedCodesDeleteReset: state => {
      state.notUsedCodesDelete = {
        ...fetchableDefault,
      };
    },
    corpOfferCreateSetApprovalRegistryAttribute: (state, { payload }) => {
      const { name, value } = payload;
      state.byId.approvalRegistry[name] = value;
    },
    corpOfferCreateSetResponsiblePersonAttribute: (state, { payload }) => {
      const { name, value } = payload;
      state.byId.responsiblePerson[name] = value;
    },
    corpOfferCreateSetStoreResponsiblePersonAttribute: (state, { payload }) => {
      const { name, value } = payload;
      if (state.byId.data) {
        state.byId.modified = true;

        if (!state.byId.data.storeResponsiblePerson) {
          state.byId.data.storeResponsiblePerson = {
            responsiblePersonLink: null,
            fullName: null,
            phone: null,
            email: null,
            schedule: 'ЧЧ:ММ-ЧЧ:ММ день недели',
          };
        }

        state.byId.data.storeResponsiblePerson[name] = value;
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(corpOfferCreateByIdFetch.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
        state.byId.data = null;
        state.byId.loadedData = null;
        state.byId.approvalRegistry = {
          number: null,
          date: null,
        };
        state.byId.responsiblePerson = {
          fullName: null,
          phone: null,
          accountId: null,
        };
        state.byId.modified = false;
        state.partner.data = null;
        state.partner.desk = null;

        state.offerCount = null;
        state.notUsedOfferCount = null;
      })
      .addCase(corpOfferCreateByIdFetch.fulfilled, (state, { payload }) => {
        const {
          id,
          data,
          offerCount,
          notUsedOfferCount,
          corpOfferStatus,
          rejectionReasonType,
          lastStatusComment,
          partner,
          desk,
        } = payload;
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;

        state.byId.id = id;
        state.byId.data = data;
        state.byId.loadedData = data;
        state.byId.approvalRegistry = {
          number: data.approvalRegistry?.number ?? null,
          date: data.approvalRegistry?.date ?? null,
        };
        state.byId.responsiblePerson = {
          fullName: data.responsiblePerson?.fullName ?? null,
          phone: data.responsiblePerson?.phone ?? null,
          accountId: data.responsiblePerson?.accountId ?? null,
        };
        state.byId.modified = false;

        state.partner.data = partner;
        state.partner.desk = desk;

        state.offerCount = offerCount;
        state.notUsedOfferCount = notUsedOfferCount;
        state.corpOfferStatus = corpOfferStatus;
        state.rejectionReasonType = rejectionReasonType;
        state.lastStatusComment = lastStatusComment;

        state.responsibleIsStoreResponsible = !!data.storeResponsiblePerson?.responsiblePersonLink;
      })
      .addCase(corpOfferCreateByIdFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = true;
        } else {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = false;
        }

        state.byId.data = null;
        state.byId.loadedData = null;
        state.byId.approvalRegistry = {
          number: null,
          date: null,
        };
        state.byId.responsiblePerson = {
          fullName: null,
          phone: null,
          accountId: null,
        };
        state.byId.modified = false;

        state.offerCount = null;
        state.notUsedOfferCount = null;
      })
      .addCase(corpOfferCreateNotUsedCodesDelete.pending, state => {
        state.notUsedCodesDelete = {
          ...fetchableFetching,
        };
      })
      .addCase(corpOfferCreateNotUsedCodesDelete.fulfilled, (state, { payload }) => {
        state.notUsedCodesDelete = {
          ...fetchableFetched,
        };
        state.byId.data = payload;

        state.offerCount = payload.offerCount;
        state.notUsedOfferCount = payload.notUsedOfferCount;
      })
      .addCase(corpOfferCreateNotUsedCodesDelete.rejected, state => {
        state.notUsedCodesDelete = {
          ...fetchableFailed,
        };
      })
      .addCase(corpOfferCreateSave.pending, state => {
        state.save = fetchableFetching;
      })
      .addCase(corpOfferCreateSave.fulfilled, (state, { payload }) => {
        state.save = fetchableFetched;

        state.byId.id = payload.id;
        state.byId.data = payload;
        state.byId.approvalRegistry = payload.approvalRegistry
          ? {
              number: payload.approvalRegistry.number,
              date: payload.approvalRegistry.date,
            }
          : {
              number: null,
              date: null,
            };
        state.byId.responsiblePerson = payload.responsiblePerson
          ? {
              fullName: payload.responsiblePerson.fullName,
              phone: payload.responsiblePerson.phone,
              accountId: payload.responsiblePerson.accountId,
            }
          : {
              fullName: null,
              phone: null,
              accountId: null,
            };
        state.corpOfferStatus = payload.status;
        state.offerCount = payload.offerCount;
        state.notUsedOfferCount = payload.notUsedOfferCount;
      })
      .addCase(corpOfferCreateSave.rejected, state => {
        state.save = fetchableFailed;
      })
      .addCase(corpOfferCreateRestore.pending, state => {
        state.restore = fetchableFetching;
      })
      .addCase(corpOfferCreateRestore.fulfilled, state => {
        state.restore = fetchableFetched;
      })
      .addCase(corpOfferCreateRestore.rejected, state => {
        state.restore = fetchableFailed;
      })
      .addCase(corpOfferCreatePromotionsCountFetch.fulfilled, (state, { payload }) => {
        state.offerCount = payload.offerCount;
        state.notUsedOfferCount = payload.notUsedOfferCount;
      })
      .addCase(corpOfferCreatePublish.pending, state => {
        state.publish = fetchableFetching;
      })
      .addCase(corpOfferCreatePublish.fulfilled, (state, { payload }) => {
        state.publish = fetchableFetched;

        state.byId.id = payload.id;
        state.byId.data = payload;

        state.corpOfferStatus = payload.status;
        state.offerCount = payload.offerCount;
        state.notUsedOfferCount = payload.notUsedOfferCount;
      })
      .addCase(corpOfferCreatePublish.rejected, state => {
        state.publish = fetchableFailed;
      })
      .addCase(corpOfferCreateResume.pending, state => {
        state.resume = fetchableFetching;
      })
      .addCase(corpOfferCreateResume.fulfilled, state => {
        state.resume = fetchableFetched;
      })
      .addCase(corpOfferCreateResume.rejected, state => {
        state.resume = fetchableFailed;
      })
      .addCase(corpOfferCreateDelete.pending, state => {
        state.delete = fetchableFetching;
      })
      .addCase(corpOfferCreateDelete.fulfilled, state => {
        state.delete = fetchableFetched;
      })
      .addCase(corpOfferCreateDelete.rejected, state => {
        state.delete = fetchableFailed;
      });
  },
});
export const {
  corpOfferCreateSetAttribute,
  corpOfferCreateRemoveCategory,
  corpOfferCreateStateReset,
  corpOfferCreateNotUsedCodesDeleteReset,
  corpOfferCreateSetResponsibleIsStoreResponsible,
  corpOfferCreateSetApprovalRegistryAttribute,
  corpOfferCreateSetResponsiblePersonAttribute,
  corpOfferCreateSetStoreResponsiblePersonAttribute,
  corpOfferCreateSetTargetAttribute,
} = slice.actions;

export default slice.reducer;
