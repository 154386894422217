import { ClientOrgUser, ENoticeStatus } from '@/domain';
import Notifier from '@/system/notifier';
import { EClientOrgUserActionType } from '@features/user/userClientOrg/types';
import { pluralize } from 'presentation/utils/pluralize';

const addSuccessNotice = (text: string) => {
  Notifier.getInstance().addNotice(ENoticeStatus.Success, text);
};

export const showClientOrgUserActionNotification = (
  action: EClientOrgUserActionType,
  clientOrgUsers: ClientOrgUser[]
) => {
  switch (action) {
    case EClientOrgUserActionType.ResendWelcomeMail: {
      addSuccessNotice(
        `Приглашение отправлено повторно ${clientOrgUsers.length} ${pluralize(clientOrgUsers.length, [
          'пользователю',
          'пользователям',
        ])}`
      );
      break;
    }
  }
};

export const showClientOrgUserUploadNotification = () => {
  addSuccessNotice(`Пользователи успешно добавлены`);
};
