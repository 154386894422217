import { ECmsLinkObjectType, EOfferType } from '@/domain';
import EntityMasterPreviewWrapper from '../../../../components/common/entityMaster/preview/wrapper';
import { ValidationResult } from '../../../../utils/validation';
import CmsPreviewByContainerType from '../../components/preview/byContainerType';
import { CmsLinkedObject, CmsLinkedObjectDataTypes, CmsLinkedObjectsTypes } from '../../types';
import { ColumnWrapper, PreviewWrapper, Wrapper } from './controls';
import CmsContainerCreateStepBanner from './steps/banner';
import CmsContainerCreateStepCollection from './steps/collection';
import CmsContainerCreateStepGeneral from './steps/general';
import { CmsContainerCreateComponentProps, CmsContainerCreateConfiguration } from './types';

interface CmsContainerCreateProps extends CmsContainerCreateComponentProps {
  readonly configuration: CmsContainerCreateConfiguration;
  readonly guid: UUID;
  readonly cmsLinkedObject: Nullable<CmsLinkedObject>;
  readonly cmsLinkedObjects: CmsLinkedObject[];
  readonly isValidGeneral: boolean;
  readonly validationCmsLinkedObject: Nullable<ValidationResult<CmsLinkedObjectsTypes>>;
  readonly onChangeLinkedObject: <T extends CmsLinkedObjectDataTypes>(data: T) => void;
}

const CmsContainerCreate = (props: CmsContainerCreateProps) => {
  const {
    configuration,
    guid,
    cmsContainer,
    cmsContext,
    cmsLinkedObject,
    cmsLinkedObjects,
    isValidGeneral,
    validation,
    validationCmsLinkedObject,
    dictionaries,
    onChange,
    onChangeLinkedObject,
  } = props;

  return (
    <Wrapper>
      <ColumnWrapper>
        {!cmsLinkedObject && (
          <CmsContainerCreateStepGeneral
            configuration={configuration}
            cmsContainer={cmsContainer}
            isValid={isValidGeneral}
            validation={validation}
            dictionaries={dictionaries}
            onChange={onChange}
          />
        )}
        {cmsLinkedObject?.type === ECmsLinkObjectType.Banner && (
          <CmsContainerCreateStepBanner
            guid={guid}
            offerType={cmsContainer.offerType ?? EOfferType.Trade}
            cmsBanner={cmsLinkedObject.banner}
            cmsContainer={cmsContainer}
            cmsContext={cmsContext}
            validation={validationCmsLinkedObject}
            onChangeBanner={onChangeLinkedObject}
          />
        )}
        {cmsLinkedObject?.type === ECmsLinkObjectType.Collection && (
          <CmsContainerCreateStepCollection
            guid={guid}
            offerType={cmsContainer.offerType ?? EOfferType.Trade}
            cmsCollection={cmsLinkedObject.collection}
            cmsContainer={cmsContainer}
            cmsContext={cmsContext}
            validation={validationCmsLinkedObject}
            onChangeCollection={onChangeLinkedObject}
          />
        )}
      </ColumnWrapper>

      {cmsContainer.type && (
        <ColumnWrapper>
          <PreviewWrapper>
            <EntityMasterPreviewWrapper label={'Предпросмотр'}>
              {({ mode }) => (
                <CmsPreviewByContainerType
                  mode={mode}
                  canActivateItem
                  type={cmsContainer.type!.code}
                  cmsLinkedObjects={cmsLinkedObjects}
                  activePreviewNumber={cmsLinkedObject && cmsLinkedObjects.indexOf(cmsLinkedObject)}
                />
              )}
            </EntityMasterPreviewWrapper>
          </PreviewWrapper>
        </ColumnWrapper>
      )}
    </Wrapper>
  );
};

export default CmsContainerCreate;
