import { RootState } from 'data/store/store';

export const partnerApplicationEditDataSelector = (store: RootState) => store.partnerApplication.edit.data;
export const partnerApplicationEditDataModifiedSelector = (store: RootState) => store.partnerApplication.edit.modified;
export const partnerApplicationEditPermissionsSelector = (store: RootState) =>
  store.partnerApplication.edit.permissions;
export const partnerApplicationEditPartnerTypeSelector = (store: RootState) =>
  store.partnerApplication.edit.partnerType;
export const partnerApplicationEditPartnerClientOrgSelector = (store: RootState) =>
  store.partnerApplication.edit.clientOrg;
export const partnerApplicationEditExtraDataSelector = (store: RootState) => store.partnerApplication.edit.extraData;
export const partnerApplicationEditAddressSelector = (store: RootState) => store.partnerApplication.edit.address;
export const partnerApplicationEditFetchSelector = (store: RootState) => store.partnerApplication.edit.fetch;
export const partnerApplicationEditSaveSelector = (store: RootState) => store.partnerApplication.edit.save;
export const partnerApplicationEditSendToVerificationSelector = (store: RootState) =>
  store.partnerApplication.edit.sendToVerification;
export const partnerApplicationEditApproveSelector = (store: RootState) => store.partnerApplication.edit.approve;
