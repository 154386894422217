import { Chip, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { BookingOffer } from '../../../../../domain/model/booking';
import { EAddressOption } from '../../../../../domain/model/enums';
import ImageGrid from '../../../../components/common/images/grid-dnd';
import SectionTitle from '../../../../components/common/sectionTitle';
import Splitter from '../../../../components/common/splitter';
import DangerouslyHtmlWrapper from '../../../../components/common/wrappers/dangerouslyHtml';
import { Field, FieldsGroup } from '../../../../components/fields';
import { AddressFullView } from '../../../address';

interface BookingOfferDetailsStepGeneralProps {
  readonly bookingOffer: BookingOffer;
}

const BookingOfferDetailsStepGeneral = (props: BookingOfferDetailsStepGeneralProps) => {
  const { bookingOffer } = props;

  return (
    <Grid
      container
      spacing={3}
    >
      {/* Секция заголовка */}
      <Grid item>
        <Typography variant={'h3'}>Основные данные</Typography>
      </Grid>

      {/* Секция ответственных */}
      <Grid
        item
        container
        spacing={2}
        xs={12}
      >
        <Grid item>
          <SectionTitle>Ответственные за объект</SectionTitle>
        </Grid>

        <Grid
          container
          item
          xs={12}
          spacing={2}
        >
          {bookingOffer.responsiblePersons?.map((responsiblePerson, index) => (
            <Grid
              key={index}
              item
              container
              xs={12}
            >
              <FieldsGroup label={`${index + 1}. Ответственный`}>
                <Field label={'ФИО ответственного'}>{responsiblePerson.fullName ?? '-'}</Field>
                <Field label={'Телефон'}>{responsiblePerson.phone ?? '-'}</Field>
                <Field label={'Электронная почта'}>{responsiblePerson.email ?? '-'}</Field>
              </FieldsGroup>
            </Grid>
          ))}
        </Grid>
      </Grid>

      {/* Секция категорий */}
      <Grid
        item
        xs={12}
      >
        <FieldsGroup label={'Категория объекта'}>
          <div>
            <Chip
              label={bookingOffer.category?.name ?? '-'}
              color={'primary'}
            />
          </div>
        </FieldsGroup>
      </Grid>

      {/* Секция адреса */}
      <Grid
        item
        xs={12}
      >
        <FieldsGroup label={'Адрес и название объекта'}>
          <Field label={'Адрес'}>
            <AddressFullView
              checkManual
              address={bookingOffer.address}
              options={[EAddressOption.PostalCode]}
            />
          </Field>
          <Field label={'Название'}>{bookingOffer.name ?? '-'}</Field>
        </FieldsGroup>
      </Grid>

      {/* Секция изображений */}
      <Splitter size={1} />
      <Grid
        item
        xs={12}
      >
        <FieldsGroup label={'Изображения'}>
          <ImageGrid
            images={bookingOffer.images}
            readOnly
          />
        </FieldsGroup>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <FieldsGroup label={'Описание'}>
          <Field label={'Описание'}>
            {bookingOffer.description && (
              <DangerouslyHtmlWrapper>
                <Typography
                  variant='body1'
                  dangerouslySetInnerHTML={{ __html: bookingOffer.description }}
                />
              </DangerouslyHtmlWrapper>
            )}
          </Field>
        </FieldsGroup>
      </Grid>
    </Grid>
  );
};

export default BookingOfferDetailsStepGeneral;
