import { useContext } from 'react';
import CustomerActionsContext, { CustomerActionsContextType } from './context';

export const useCustomerActions = (): CustomerActionsContextType => {
  return useContext<CustomerActionsContextType>(CustomerActionsContext);
};

export const useCustomerActionsSelectors = (): CustomerActionsContextType['utils']['selectors'] => {
  return useCustomerActions().utils.selectors;
};
