import { UserFieldView } from '@components/common/userInfoView';
import { Grid, ImageList, ImageListItem } from '@mui/material';
import moment from 'moment';
import { EDateFormat, EUserRole, SportUserProfile } from '@/domain';
import AppImage from '../../../../../components/common/images/common';
import { Field, FieldsGroup } from '@components/fields';
import { pluralize } from 'presentation/utils/pluralize';
import ActivityTypesFieldView from '../../../../activityType/components/fieldView';
import { SportsmanMediaWrapper, Wrapper } from './controls';

interface SportsmanTabGeneralProps {
  readonly sportsman: SportUserProfile;
}

const SportsmanTabGeneral = ({ sportsman }: SportsmanTabGeneralProps) => {
  const formattedSportsmanAge =
    sportsman.age > 0 ? ` (${sportsman.age} ${pluralize(sportsman.age, ['год', 'года', 'лет'])})` : '';

  const isCorpUser = sportsman?.roles?.some(r => r === EUserRole.CorpUser) ?? false;

  const getSportsmanBirthDate = (date: string): string => {
    return moment(date).format(EDateFormat.Human);
  };

  return (
    <Wrapper>
      <FieldsGroup>
        <Field label='Статус'>
          <UserFieldView
            user={sportsman}
            field={'statusText'}
          />
        </Field>
        <Field label='Описание'>
          <UserFieldView
            user={sportsman}
            field={'description'}
          />
        </Field>
        <Field label='Дата рождения'>
          {sportsman.birthDate && (
            <>
              {getSportsmanBirthDate(sportsman.birthDate)}
              {formattedSportsmanAge}
            </>
          )}
        </Field>
        {(sportsman.phone || sportsman.email) && (
          <Grid
            container
            spacing={2}
          >
            {sportsman.phone && (
              <Grid
                item
                xs={6}
              >
                <Field label='Номер телефона'>
                  <UserFieldView
                    user={sportsman}
                    field={'phone'}
                  />
                </Field>
              </Grid>
            )}
            {sportsman.email && (
              <Grid
                item
                xs={6}
              >
                <Field label='E-mail'>
                  <UserFieldView
                    user={sportsman}
                    field={'email'}
                  />
                </Field>
              </Grid>
            )}
          </Grid>
        )}
        {isCorpUser && (
          <>
            <Field label='Дорога'>
              <UserFieldView
                user={sportsman}
                field={'roadName'}
              />
            </Field>
            <Field label='Подразделение'>
              <UserFieldView
                user={sportsman}
                field={'departmentName'}
              />
            </Field>
          </>
        )}
        <Field>
          {sportsman.activityTypes && sportsman.activityTypes.length > 0 && (
            <ActivityTypesFieldView value={sportsman.activityTypes} />
          )}
        </Field>
        <Field label='Медиа'>
          {sportsman.rewards && sportsman.rewards.length > 0 && (
            <ImageList
              cols={3}
              gap={6}
            >
              {sportsman.rewards.map(reward => (
                <ImageListItem key={reward.path}>
                  <SportsmanMediaWrapper>
                    <AppImage src={reward} />
                  </SportsmanMediaWrapper>
                </ImageListItem>
              ))}
            </ImageList>
          )}
        </Field>
      </FieldsGroup>
    </Wrapper>
  );
};

export default SportsmanTabGeneral;
