import {
  ENoticeStatus,
  ESportEventLevel,
  ESportEventParticipationFormat,
  ESportEventStatus,
  EUserType,
} from '@/domain/model/enums';
import { ActivityEvent, ActivityEventRequest } from '@/domain/model/event';
import { Nullable } from '@/domain/model/types';
import Notifier from '../../../../../system/notifier';
import { CalendarIcon, ImageCoverIcon, ListItemsIcon, TextAlignLeftIcon } from '../../../../icons';
import { pluralize } from '../../../../utils/pluralize';
import { EValidationType, ValidationResult } from '../../../../utils/validation';
import { EventHelpWidgetField } from '../../components/helpWidgets';
import { ActivityEventCreateStepType, ActivityEventEditStepType, EEventStep } from '../../types';
import AddLinkIcon from '@mui/icons-material/AddLink';

export const activityEventDefaultLevel = ESportEventLevel.Hub;
export const activityEventDefaultUserType = EUserType.Any;

export const convertActivityEventToActivityEventRequest = (activityEvent: ActivityEvent): ActivityEventRequest => {
  const {
    id,
    name,
    description,
    status,
    scheduledDate,
    endDate,
    locationDescription,
    locationImage,
    participationFormat,
    onlineParticipationLink,
    onlineParticipationDescription,
    onlineParticipationInstruction,
    organizerEmail,
    organizerPhone,
    previewImage,
    programDescription,
    programFile,
    playground,
    level,
    road,
    admin,
    userType,
    activityTypes,
  } = activityEvent;

  return {
    id,
    name,
    description,
    status,
    scheduledDate,
    endDate,
    locationDescription,
    locationImage,
    participationFormat,
    onlineParticipationLink,
    onlineParticipationDescription,
    onlineParticipationInstruction,
    organizerEmail,
    organizerPhone,
    previewImage,
    programDescription,
    programFile,
    playground,
    level,
    road,
    admin,
    userType,
    activityTypes,
  };
};

export const createNewActivityEventRequest = (): ActivityEventRequest => {
  return {
    id: null,
    name: '',
    description: null,
    status: null,
    scheduledDate: null,
    endDate: null,
    locationDescription: null,
    locationImage: null,
    participationFormat: ESportEventParticipationFormat.Offline,
    onlineParticipationLink: null,
    onlineParticipationDescription: null,
    onlineParticipationInstruction: null,
    organizerEmail: null,
    organizerPhone: null,
    previewImage: null,
    programDescription: null,
    programFile: null,
    playground: null,
    level: null,
    road: null,
    admin: null,
    userType: null,
    activityTypes: null,
  };
};
export const getActivityEventCreateStepText = (
  step: ActivityEventCreateStepType | ActivityEventEditStepType
): string => {
  switch (step) {
    case EEventStep.General:
      return 'Основная информация';
    case EEventStep.Details:
      return 'Детали и контакты';
  }
};

/**
 * Первоначальный массив полей, которые будет отображать виджет.
 * Адаптер берет эти значения как по умолчанию, но создает свое локальное состояние
 */
export const activityEventFieldsListForHelpWidget: Partial<keyof ActivityEventRequest>[] = [
  'name',
  'description',
  'previewImage',
  'level',
  'road',
  'activityTypes',
  'scheduledDate',
  'endDate',
  'participationFormat',
  'playground',
  'onlineParticipationLink',
  'organizerPhone',
  'organizerEmail',
];

export const activityEventHelpWidgetGetFieldData = (
  field: Nullable<keyof ActivityEventRequest>
): Nullable<EventHelpWidgetField> => {
  const defaultDataField: EventHelpWidgetField = {
    name: null,
    icon: TextAlignLeftIcon,
  };

  switch (field) {
    case 'name': {
      return {
        ...defaultDataField,
        name: 'Название',
      };
    }
    case 'description': {
      return {
        ...defaultDataField,
        name: 'Описание',
      };
    }
    case 'activityTypes': {
      return {
        ...defaultDataField,
        name: 'Тематика мероприятия',
        icon: ListItemsIcon,
      };
    }
    case 'previewImage': {
      return {
        ...defaultDataField,
        name: 'Обложка',
        icon: ImageCoverIcon,
      };
    }
    case 'road': {
      return {
        ...defaultDataField,
        name: 'Железная дорога',
        icon: ListItemsIcon,
      };
    }
    case 'scheduledDate': {
      return {
        ...defaultDataField,
        name: 'Дата начала',
        icon: CalendarIcon,
      };
    }
    case 'endDate': {
      return {
        ...defaultDataField,
        name: 'Дата завершения',
        icon: CalendarIcon,
      };
    }
    case 'playground': {
      return {
        ...defaultDataField,
        name: 'Место проведения',
        icon: CalendarIcon,
      };
    }
    case 'onlineParticipationLink': {
      return {
        ...defaultDataField,
        name: 'Ссылка на мероприятие',
        icon: AddLinkIcon,
      };
    }
    case 'organizerPhone': {
      return {
        ...defaultDataField,
        name: 'Телефон для связи',
      };
    }
    case 'organizerEmail': {
      return {
        ...defaultDataField,
        name: 'Почта для связи',
      };
    }
    case 'level': {
      return {
        ...defaultDataField,
        name: 'Уровень проведения',
      };
    }
    case 'participationFormat': {
      return {
        ...defaultDataField,
        name: 'Формат участия',
      };
    }
    case null:
    case 'status':
    case 'id':
    case 'locationImage':
    case 'locationDescription':
    case 'onlineParticipationInstruction':
    case 'onlineParticipationDescription':
    case 'programFile':
    case 'programDescription':
    case 'admin':
    case 'userType': {
      return null;
    }
  }
};
const activityEventGetValidationCommonError = (
  step: ActivityEventCreateStepType,
  count: number
): Nullable<ValidationResult<any>> => {
  if (!count) {
    return null;
  }

  switch (step) {
    default:
      return {
        common: {
          type: EValidationType.Error,
          hasError: true,
          message: `Не заполнено ${count} ${pluralize(count, ['поле', 'поля', 'полей'])}`,
        },
      };
  }
};

export const activityEventCreateInitValidationStepper = (validation: ValidationResult<ActivityEventRequest>) => {
  const generalErrorsCount = [
    validation.name,
    validation.description,
    validation.previewImage,
    validation.level,
    validation.road,
    validation.userType,
    validation.activityTypes,
    validation.scheduledDate,
    validation.endDate,
  ].filter(item => !!item).length;

  const detailsErrorsCount = [
    validation.playground,
    validation.participationFormat,
    validation.onlineParticipationLink,
    validation.onlineParticipationDescription,
    validation.onlineParticipationInstruction,
    validation.locationDescription,
    validation.locationImage,
    validation.programDescription,
    validation.programFile,
    validation.admin,
    validation.organizerPhone,
    validation.organizerEmail,
  ].filter(item => !!item).length;

  return {
    [EEventStep.General]: activityEventGetValidationCommonError(EEventStep.General, generalErrorsCount),
    [EEventStep.Details]: activityEventGetValidationCommonError(EEventStep.Details, detailsErrorsCount),
  };
};

export const showActivityEventInvalidNotification = (
  event: ActivityEvent | ActivityEventRequest,
  nextStatus?: ESportEventStatus
) => {
  let text;

  if (!event.status && !nextStatus) {
    text = 'Заполните корректно поля, чтобы сохранить черновик';
  } else {
    switch (nextStatus) {
      case ESportEventStatus.WaitingForStart:
        text = 'Заполните все обязательные поля, чтобы опубликовать событие';
        break;
      default:
        text = 'Заполните все обязательные поля, чтобы сохранить изменения';
        break;
    }
  }

  Notifier.getInstance().addNotice(ENoticeStatus.Warning, text);
};
