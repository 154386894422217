import { EUserStatus } from '@/domain';
import { Typography } from '@mui/material';
import { ETagColors, MPTag } from '@ui-kit/tag';
import { useSelector } from 'react-redux';
import AppBreadcrumbs from '../../../../components/common/breadcrumbs';
import DefaultHeader from '../../../../components/common/header';
import { TitleWrapper } from '../controls';
import { customerDetailsCustomerStatusSelector } from '../store/selectors';

export const CustomerDetailsHeaderAdapter = () => {
  const customerStatus = useSelector(customerDetailsCustomerStatusSelector);

  return (
    <DefaultHeader
      sticky
      headline={
        <>
          <AppBreadcrumbs />
          {customerStatus === EUserStatus.Disabled && (
            <MPTag
              bold
              label={'Отключён'}
              color={ETagColors.Warning}
            />
          )}
        </>
      }
    >
      <TitleWrapper>
        <Typography variant='h2'>Профиль покупателя</Typography>
      </TitleWrapper>
    </DefaultHeader>
  );
};
