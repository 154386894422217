import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { ECustomerActionType } from '../types';
import { CustomerActionsOptimizerAdapter } from './adapters';
import CustomerActionsContext, { CustomerActionsContextType } from './context';
import {
  getCustomerActionsIsProcessingSelector,
  getIsCustomerAnyActionByTypeExecutedSelector,
  isCustomersAnyChangedSelector,
} from './store/selectors';
import { customerActionsActivate, customerActionsDeactivate, customerActionsSendProfileQuery } from './store/slice';
import { showCustomerActionNotification } from './utils';

interface CustomerActionsProviderProps {
  readonly children: ReactNode;
}

type CustomerActionsProviderType = (props: CustomerActionsProviderProps) => JSX.Element;

export const CustomerActionsProvider: CustomerActionsProviderType = ({ children }) => {
  const dispatch = useDispatch();

  const onActivate: CustomerActionsContextType['onActivate'] = async customer => {
    const response = await dispatch(customerActionsActivate({ id: customer.id })).unwrap();
    if (response) {
      showCustomerActionNotification(customer, ECustomerActionType.Activate);
    }
    return response;
  };

  const onDeactivate: CustomerActionsContextType['onDeactivate'] = async customer => {
    const response = await dispatch(customerActionsDeactivate({ id: customer.id })).unwrap();
    if (response) {
      showCustomerActionNotification(customer, ECustomerActionType.Deactivate);
    }
    return response;
  };

  const onSendProfilesQuery: CustomerActionsContextType['onSendProfilesQuery'] = async customer => {
    if (customer.pernr) {
      const response = await dispatch(
        customerActionsSendProfileQuery({
          id: customer.id,
          pernr: customer.pernr,
        })
      ).unwrap();
      if (response) {
        showCustomerActionNotification(customer, ECustomerActionType.SendProfileQuery);
      }
      return response;
    } else {
      return false;
    }
  };

  return (
    <CustomerActionsContext.Provider
      value={{
        onActivate,
        onDeactivate,
        onSendProfilesQuery,
        utils: {
          selectors: {
            getIsCustomerExecutedActionsSelector: getIsCustomerAnyActionByTypeExecutedSelector,
            getIsCustomerProcessingSelector: getCustomerActionsIsProcessingSelector,
            isCustomersAnyChangedSelector: isCustomersAnyChangedSelector(),
          },
        },
      }}
    >
      {children}
      <CustomerActionsOptimizerAdapter />
    </CustomerActionsContext.Provider>
  );
};
