import { UserFieldView } from '@components/common/userInfoView';
import { Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import { Field, FieldsGroup } from '@components/fields';
import { getCustomerDetailsRoute } from '../../../customer/entry';
import { LinkToObject, Wrapper } from './controls';
import { Nullable, MpUserShort } from '@/domain';
import { userInfoView } from '@/presentation/utils/userInfoView';

interface BookingOrderDetailsInfoProps {
  readonly customer: Nullable<MpUserShort>;
}

const BookingOrderDetailsInfo = (props: BookingOrderDetailsInfoProps) => {
  const { customer } = props;

  if (!customer) {
    return null;
  }

  const userInfo = userInfoView(customer);

  return (
    <Wrapper>
      <FieldsGroup>
        <Field label={'Клиент'}>
          <LinkToObject
            component={RouterLink}
            to={getCustomerDetailsRoute({ id: customer.id })}
            color='textPrimary'
          >
            <UserFieldView
              user={customer}
              field={'fullName'}
            />
          </LinkToObject>
        </Field>
        <Field label={'Телефон'}>
          {userInfo.phone ? (
            <Typography
              component={Link}
              href={`tel:${userInfo.phone}`}
              color='textPrimary'
            >
              <UserFieldView
                user={customer}
                field={'phone'}
              />
            </Typography>
          ) : (
            '-'
          )}
        </Field>
        <Field label={'Электронная почта'}>
          {userInfo.email ? (
            <Typography
              component={Link}
              href={`mailto:${userInfo.email}`}
              color='textPrimary'
            >
              <UserFieldView
                user={customer}
                field={'email'}
              />
            </Typography>
          ) : (
            '-'
          )}
        </Field>
      </FieldsGroup>
    </Wrapper>
  );
};

export default BookingOrderDetailsInfo;
