import { removeResendWelcomeMailActionRule } from './rules';
import * as schemas from './scheme';
import { ClientOrgUserLifeCycleRulesConfiguration } from './types';

/**
 * конфигурация правил админа
 */
export const commonAdminConfiguration: ClientOrgUserLifeCycleRulesConfiguration = {
  statusScheme: schemas.adminStatusScheme.statusScheme,
  actionsScheme: schemas.adminStatusScheme.actionsScheme,
  actionRules: [removeResendWelcomeMailActionRule],
};

/**
 * конфигурация правил партнёра
 */
export const commonPartnerConfiguration: ClientOrgUserLifeCycleRulesConfiguration = {
  statusScheme: schemas.partnerStatusScheme.statusScheme,
  actionsScheme: schemas.partnerStatusScheme.actionsScheme,
  actionRules: [removeResendWelcomeMailActionRule],
};
