import React from 'react';
import { PanelAction } from '../../../types';
import { CustomerLifeCycle, CustomerLifeCycleRules } from '../lifecycle/types';
import { CustomerActionDetailsType, CustomerActions } from '../types';
import { CustomerDetailsContainerProps } from './container';

export type CustomerDetailsParamsContextValue = CustomerDetailsContainerProps;

export const CustomerDetailsParamsContext = React.createContext<CustomerDetailsParamsContextValue>(
  {} as CustomerDetailsParamsContextValue
);

export type CustomerDetailsLifecycleContextValue = {
  readonly lifecycle: CustomerLifeCycle;
  readonly lifeCycleRules: CustomerLifeCycleRules;
  readonly actions: CustomerActions<CustomerActionDetailsType>;
  readonly onAction: (action: PanelAction<CustomerActionDetailsType>) => void;
};

export const CustomerDetailsLifecycleContext = React.createContext<CustomerDetailsLifecycleContextValue>(
  {} as CustomerDetailsLifecycleContextValue
);

export type CustomerDetailsHandlersContextValue = {
  readonly onCustomerRefetch: () => void;

  readonly onCustomerActivate: () => void;
  readonly onCustomerDeactivate: () => void;
};

export const CustomerDetailsHandlersContext = React.createContext<CustomerDetailsHandlersContextValue>(
  {} as CustomerDetailsHandlersContextValue
);
