import { UserFieldView } from '@components/common/userInfoView';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '@/data/store/store';
import { MpUser, UUID, Partner } from '@/domain';
import ContentLoader from '../../../../components/common/loader';
import useBreadcrumbsActions from '../../../general/breadcrumbs/useBreadcrumbsActions';
import CustomerManagementMenuContainer from '../menu/container';
import { ContentWrapper, Wrapper } from './controls';
import { customerManagementByPartnerGetData, customerManagementByPartnerIsMenuVisible } from './store/selectors';
import { customerManagementByPartnerCustomerByIdFetch } from './store/slice';

interface CustomerManagementByPartnerContainerProps {
  readonly partnerId: UUID;
  readonly customerId: UUID;
  readonly children: (partner: Partner, customer: MpUser) => React.ReactNode;
}

const CustomerManagementByPartnerContainer = ({
  partnerId,
  customerId,
  children,
}: CustomerManagementByPartnerContainerProps) => {
  const dispatch = useAppDispatch();
  const { putHeadBreadcrumbs } = useBreadcrumbsActions();

  const isMenuVisible = useSelector(customerManagementByPartnerIsMenuVisible);
  const { partner, customer, isFetching } = useSelector(customerManagementByPartnerGetData);

  useEffect(() => {
    const promise = dispatch(customerManagementByPartnerCustomerByIdFetch({ partnerId, customerId }));
    return () => {
      promise?.abort();
    };
  }, [dispatch, partnerId, customerId]);

  useEffect(() => {
    if (partner && customer) {
      putHeadBreadcrumbs([
        {
          label: 'Партнёры',
        },
        {
          label: partner.name,
        },
        {
          label: 'Покупатели',
        },
        {
          label: (
            <UserFieldView
              user={customer}
              field={'fullNameReverse'}
            />
          ),
        },
      ]);
      return () => {
        putHeadBreadcrumbs([]);
      };
    }
  }, [partner, customer]);

  return (
    <Wrapper>
      {partner && customer && (
        <>
          <CustomerManagementMenuContainer
            visible={isMenuVisible}
            customer={customer}
            partner={partner}
          />
          <ContentWrapper fullscreen={false}>{children(partner, customer)}</ContentWrapper>
        </>
      )}
      {isFetching && <ContentLoader size={75} />}
    </Wrapper>
  );
};

export default CustomerManagementByPartnerContainer;
