import { PartnerContactData, PartnerDesk } from '@/domain';
import { MPStepperStep } from '@components/common/stepper';
import validator from 'validator';
import { AddressHelper } from '../../../utils/address';
import {
  EValidationMessages,
  EValidationType,
  ValidationCollectionRules,
  validationCommonValidators,
  ValidationRules,
} from '../../../utils/validation';

export enum EPartnerDeskEditStep {
  Desk = 'desk',
}

export type PartnerDeskEditStep = MPStepperStep<EPartnerDeskEditStep>;

export const partnerDeskEditSteps: PartnerDeskEditStep[] = [
  {
    key: EPartnerDeskEditStep.Desk,
    label: 'Карточка компании',
  },
];

export const getPartnerDeskEditValidation = (step: EPartnerDeskEditStep): ValidationRules<PartnerDesk> => ({
  aboutCompany: {
    required: true,
  },
  image: {
    required: true,
  },
  contacts: {},
});

export const partnerContactEditValidation: ValidationCollectionRules<PartnerContactData> = {
  name: {
    required: true,
  },
  email: {
    validator: (object, value) => {
      if (!value && !object.feedbackLink) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: 'Заполните электронную почту или укажите ссылку на форму обратной связи',
        };
      }

      return validationCommonValidators.email(object, value);
    },
  },
  feedbackLink: {
    validator: (object, value) => {
      if (!value && !object.email) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: 'Заполните электронную почту или укажите ссылку на форму обратной связи',
        };
      }

      if (!value) return null;

      const isUrl = validator.isURL(value);
      if (!isUrl) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: EValidationMessages.URLInvalid,
        };
      } else {
        return null;
      }
    },
  },
  address: {
    required: false,
    validator: (object, addressObject) => {
      if (!object.address) {
        return null;
      }

      const isIncorrect = new AddressHelper(object.address).isEmptyStartingFromStead();
      if (isIncorrect) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: 'Укажите полный адрес',
        };
      }

      return {
        type: EValidationType.None,
        hasError: false,
      };
    },
  },
  validationSummary: {
    required: true,
    validator: object => {
      if (!object) {
        return null;
      }
      if (object.length === 0) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: EValidationMessages.Empty,
        };
      }
      return null;
    },
  },
};
