import {
  Address,
  ClientOrgShort,
  EPartnerOwnershipType,
  EPartnerPermission,
  EPartnerType,
  Partner,
  PartnerCompanyData,
  PartnerData,
  PartnerIndividualData,
} from '@/domain';
import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { AppThunkAPIConfig } from 'data/store/store';
import { Fetchable, fetchableDefault, fetchableFailed, fetchableFetched, fetchableFetching } from 'data/store/types';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { createEmptyDraftAddress } from '../../../../utils/address';

const emptyAddress: Address = createEmptyDraftAddress();

export const partnerEditAdminByIdFetch = createAsyncThunk<Partner, UUID, AppThunkAPIConfig>(
  'partner/edit/admin/byId/fetch',
  async (id, { rejectWithValue, signal }) => {
    try {
      const { data } = await Api.partner.one({ id, signal });
      return data;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);

      return rejectWithValue(e.response.data);
    }
  }
);

export const partnerEditAdminUpdate = createAsyncThunk<
  Partner,
  {
    id: UUID;
    partner: PartnerData;
    permissions: Nullable<EPartnerPermission[]>;
    type: EPartnerType;
    clientOrg: Nullable<ClientOrgShort>;
  },
  AppThunkAPIConfig
>('partner/edit/admin/update', async ({ id, partner, permissions, type, clientOrg }, { rejectWithValue }) => {
  try {
    await Api.partner.update({ id, data: partner });
    await Api.partner.updatePermissions({ id, permissions: permissions ?? [] });
    const { data } = await Api.partner.updateType({ id, type });
    // const { data } = await Api.partner.updateClientOrg({ id, clientOrg });
    // TODO companyOffer - у бэка нет метода установки clientOrg, возможно через updateClientOrg
    console.log('clientOrg', clientOrg);
    return { ...data, clientOrg };
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface PartnerEditState {
  readonly byId: Fetchable & {
    readonly data: Nullable<Partner>;
    readonly loadedData: Nullable<Partner>;
    readonly modified: boolean;
    readonly address: Address;
  };
  readonly update: Fetchable;
}

type Reducer<T = undefined> = CaseReducer<PartnerEditState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<PartnerEditState> {
  partnerEditAdminStateReset: Reducer;
  partnerEditAdminRestore: Reducer;
  partnerEditAdminSetAttribute: Reducer<{ name: keyof PartnerData; value: any }>;
  partnerEditAdminSetPermissions: Reducer<EPartnerPermission[]>;
  partnerEditAdminSetPartnerType: Reducer<EPartnerType>;
  partnerEditAdminSetClientOrg: Reducer<Nullable<ClientOrgShort>>;
  partnerEditAdminSetCompanyAttribute: Reducer<{ name: keyof PartnerCompanyData; value: any }>;
  partnerEditAdminSetIndividualAttribute: Reducer<{ name: keyof PartnerIndividualData; value: any }>;
  partnerEditAdminSetAddress: Reducer<Address>;
}

const slice = createSlice<PartnerEditState, Reducers, 'editAdmin'>({
  name: 'editAdmin',
  initialState: {
    byId: {
      ...fetchableDefault,
      data: null,
      loadedData: null,
      modified: false,
      address: emptyAddress,
    },
    update: {
      ...fetchableDefault,
    },
  },
  reducers: {
    partnerEditAdminStateReset: state => {
      state.byId = {
        ...fetchableDefault,
        data: null,
        loadedData: null,
        modified: false,
        address: emptyAddress,
      };
      state.update = fetchableDefault;
    },
    partnerEditAdminRestore: state => {
      state.byId.data = state.byId.loadedData;
      state.byId.address = state.byId.loadedData?.address ? state.byId.loadedData.address : emptyAddress;
      state.byId.modified = false;
    },
    partnerEditAdminSetAttribute: (state, { payload }) => {
      const { name, value } = payload;
      (state.byId.data![name] as keyof PartnerData) = value;
      state.byId.modified = true;
    },
    partnerEditAdminSetPermissions: (state, { payload }) => {
      state.byId.data!.permissions = payload;
      state.byId.modified = true;
    },
    partnerEditAdminSetPartnerType: (state, { payload }) => {
      state.byId.data!.type = payload;
      state.byId.data!.clientOrg = null;
      state.byId.modified = true;
    },
    partnerEditAdminSetClientOrg: (state, { payload }) => {
      state.byId.data!.clientOrg = payload;
      state.byId.modified = true;
    },
    partnerEditAdminSetCompanyAttribute: (state, { payload }) => {
      const { name, value } = payload;
      if (
        state.byId.data?.regInfo?.orgType &&
        state.byId.data.regInfo.orgType !== EPartnerOwnershipType.IndividualEntrepreneur
      ) {
        state.byId.data.regInfo[name] = value;
        state.byId.modified = true;
      }
    },
    partnerEditAdminSetIndividualAttribute: (state, { payload }) => {
      const { name, value } = payload;
      if (state.byId.data?.regInfo?.orgType === EPartnerOwnershipType.IndividualEntrepreneur) {
        state.byId.data.regInfo[name] = value;
        state.byId.modified = true;
      }
    },
    partnerEditAdminSetAddress: (state, { payload }) => {
      state.byId.address = payload;
      state.byId.data!.address = payload;
      state.byId.modified = true;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(partnerEditAdminByIdFetch.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
        state.byId.data = null;
        state.byId.loadedData = null;
        state.byId.modified = false;
      })
      .addCase(partnerEditAdminByIdFetch.fulfilled, (state, { payload }) => {
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;

        state.byId.data = payload;
        state.byId.loadedData = payload;
        state.byId.modified = false;
        state.byId.address = payload.address;
      })
      .addCase(partnerEditAdminByIdFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = true;

          state.byId.data = null;
          state.byId.loadedData = null;
          state.byId.modified = false;
        }
      })
      .addCase(partnerEditAdminUpdate.pending, state => {
        state.update = fetchableFetching;
      })
      .addCase(partnerEditAdminUpdate.fulfilled, state => {
        state.update = fetchableFetched;
      })
      .addCase(partnerEditAdminUpdate.rejected, state => {
        state.update = fetchableFailed;
      });
  },
});

export const {
  partnerEditAdminSetAttribute,
  partnerEditAdminSetPermissions,
  partnerEditAdminSetPartnerType,
  partnerEditAdminSetClientOrg,
  partnerEditAdminSetCompanyAttribute,
  partnerEditAdminSetIndividualAttribute,
  partnerEditAdminSetAddress,
  partnerEditAdminStateReset,
  partnerEditAdminRestore,
} = slice.actions;

export default slice.reducer;
