import { ENoticeStatus } from '@/domain';
import useCurrentUser from '@features/user/hooks/useCurrentUser';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Notifier from '../../../../system/notifier';
import ContentLoader from '../../../components/common/loader';
import { nsiSelector } from '../nsi/store/selectors';
import { nsiPrivilegeFetch, nsiSportFetch } from '../nsi/store/slice';
import { Wrapper } from './controls';

const MetadataLoaderContainer = ({ children }: { children: any }) => {
  const dispatch = useDispatch();
  const { isFetched, isFailed } = useSelector(nsiSelector);
  const { accessMatrix } = useCurrentUser();
  const { isSportUser } = accessMatrix;

  useEffect(() => {
    if (isSportUser) {
      dispatch(nsiSportFetch());
    } else {
      dispatch(nsiPrivilegeFetch());
    }
  }, []);

  useEffect(() => {
    if (isFailed) Notifier.getInstance().addNotice(ENoticeStatus.Error, 'Не удалось загрузить справочную информацию');
  }, [isFailed]);

  if (!isFetched) {
    return (
      <Wrapper>
        <ContentLoader />
      </Wrapper>
    );
  }

  return <>{children}</>;
};

export default MetadataLoaderContainer;
