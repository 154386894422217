import { CmsCollection, ECmsCollectionLinkObjectType } from '@/domain';
import { ApiCancellable } from 'data/api/types';
import Api from '../../../../data/api';
import {
  CmsLinkedObjectCollectionCorpOfferCategoryType,
  CmsLinkedObjectCollectionCorpOfferType,
  CmsLinkedObjectCollectionPartnerType,
  CmsLinkedObjectCollectionTradeOfferCategoryType,
  CmsLinkedObjectCollectionTradeOfferType,
  CmsLinkedObjectCollectionTypes,
} from '../types/collection';
import cmsServices from './index';

type ByIdProps = ApiCancellable & {
  readonly id: UUID;
};

type GetByLinkedObjectIdProps = ApiCancellable & {
  readonly id: UUID;
  readonly ignoreData?: boolean;
};

export type CmsCollectionService = {
  readonly byId: (props: ByIdProps) => Promise<CmsCollection>;
  readonly getCollectionByLinkObjectId: (props: GetByLinkedObjectIdProps) => Promise<CmsLinkedObjectCollectionTypes>;
  readonly getCollectionCorpOfferByLinkObjectId: (
    props: GetByLinkedObjectIdProps
  ) => Promise<CmsLinkedObjectCollectionCorpOfferType>;
  readonly getCollectionTradeOfferByLinkObjectId: (
    props: GetByLinkedObjectIdProps
  ) => Promise<CmsLinkedObjectCollectionTradeOfferType>;
  readonly getCollectionCorpOfferCategoryByLinkObjectId: (
    props: Omit<GetByLinkedObjectIdProps, 'ignoreData'>
  ) => Promise<CmsLinkedObjectCollectionCorpOfferCategoryType>;
  readonly getCollectionTradeOfferCategoryByLinkObjectId: (
    props: Omit<GetByLinkedObjectIdProps, 'ignoreData'>
  ) => Promise<CmsLinkedObjectCollectionTradeOfferCategoryType>;
  readonly getCollectionPartnerByLinkObjectId: (
    props: Omit<GetByLinkedObjectIdProps, 'ignoreData'>
  ) => Promise<CmsLinkedObjectCollectionPartnerType>;
};

const cmsCollectionService: CmsCollectionService = {
  byId: async ({ id, signal }) => {
    const { data } = await Api.cms.collection.one({ id, signal });
    return data;
  },
  getCollectionByLinkObjectId: async ({ id, ignoreData, signal }) => {
    const { data: cmsCollection } = await Api.cms.collection.one({
      id,
      signal,
    });

    let cmsLinkedObjectCollection: CmsLinkedObjectCollectionTypes;
    switch (cmsCollection.linkObjectType) {
      case ECmsCollectionLinkObjectType.CorpOffer:
        cmsLinkedObjectCollection = await cmsServices.collection.getCollectionCorpOfferByLinkObjectId({
          id,
          ignoreData,
          signal,
        });
        break;
      case ECmsCollectionLinkObjectType.TradeOffer:
        cmsLinkedObjectCollection = await cmsServices.collection.getCollectionTradeOfferByLinkObjectId({
          id,
          ignoreData,
          signal,
        });
        break;
      case ECmsCollectionLinkObjectType.CorpOfferCategory:
        cmsLinkedObjectCollection = await cmsServices.collection.getCollectionCorpOfferCategoryByLinkObjectId({
          id,
          signal,
        });
        break;
      case ECmsCollectionLinkObjectType.TradeOfferCategory:
        cmsLinkedObjectCollection = await cmsServices.collection.getCollectionTradeOfferCategoryByLinkObjectId({
          id,
          signal,
        });
        break;
      case ECmsCollectionLinkObjectType.Partner:
        cmsLinkedObjectCollection = await cmsServices.collection.getCollectionPartnerByLinkObjectId({
          id,
          signal,
        });
        break;
    }

    return cmsLinkedObjectCollection;
  },
  getCollectionCorpOfferByLinkObjectId: async ({ id, ignoreData, signal }) => {
    const linkedObject = ignoreData ? null : (await Api.corpOffer.collection({ id, signal })).data;
    return {
      linkObjectType: ECmsCollectionLinkObjectType.CorpOffer,
      linkedObject,
    };
  },
  getCollectionTradeOfferByLinkObjectId: async ({ id, ignoreData, signal }) => {
    const linkedObject = ignoreData ? null : (await Api.tradeOffer.collection({ id, signal })).data;
    return {
      linkObjectType: ECmsCollectionLinkObjectType.TradeOffer,
      linkedObject,
    };
  },
  getCollectionCorpOfferCategoryByLinkObjectId: async ({ id, signal }) => {
    const linkedObject = (await Api.category.collection({ categoryId: id, signal })).data;
    return {
      linkObjectType: ECmsCollectionLinkObjectType.CorpOfferCategory,
      linkedObject,
    };
  },
  getCollectionTradeOfferCategoryByLinkObjectId: async ({ id, signal }) => {
    const linkedObject = (await Api.category.collection({ categoryId: id, signal })).data;
    return {
      linkObjectType: ECmsCollectionLinkObjectType.TradeOfferCategory,
      linkedObject,
    };
  },
  getCollectionPartnerByLinkObjectId: async ({ id, signal }) => {
    const linkedObject = (await Api.partner.collection({ id, signal })).data;
    return {
      linkObjectType: ECmsCollectionLinkObjectType.Partner,
      linkedObject,
    };
  },
};

export default cmsCollectionService;
