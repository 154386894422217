import { EUserStatus, MpCustomer } from '@/domain';
import { PanelAction, PanelActions } from '../../types';

export enum ECustomerActionType {
  Activate = 'activate',
  Deactivate = 'deactivate',
  SendProfileQuery = 'sendProfileQuery',
}

export type CustomerActionDetailsType = Exclude<ECustomerActionType, ECustomerActionType.SendProfileQuery>;

export type CustomerActionTableType = ECustomerActionType;

export type CustomerAction<A extends string = ECustomerActionType, P = any> = PanelAction<A, P>;

export type CustomerActions<A extends string = ECustomerActionType> = PanelActions<A>;

export type CustomerCounterByStatus = {
  [name in EUserStatus]?: number;
};

export type ActionCustomerType = MpCustomer;
