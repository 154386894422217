import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { BookingOfferShort } from 'domain/model/booking';
import { userInfoView } from 'presentation/utils/userInfoView';
import { useState } from 'react';
import { EUserRole, EUserStatus, Nullable, MpUser } from '@/domain';
import ConfirmDialog from '../../../../../components/common/dialogs/confirm';
import ContentLoader from '../../../../../components/common/loader';
import { MPAutocompleteSingleSelect } from '@ui-kit/autocomplete/single';
import { MPButton } from '@ui-kit/button';
import { AddressHelper } from 'presentation/utils/address';
import { getUserCheckedRoles, getUserRoleShortName } from 'presentation/utils/roles';
import UserMpSuggestionContainer from '../../../../user/userMp/search/container';
import BookingOfferPreview from '../../../components/preview';

export interface BookingOfferChangeModeratorDialogProps {
  readonly offer: Nullable<BookingOfferShort>;
  readonly isExecuting: boolean;
  readonly onExecute: (bookingOffer: BookingOfferShort, user: MpUser) => void;
  readonly onClose: () => void;
  readonly preview?: boolean;
}

const BookingOfferChangeModeratorDialog = (props: BookingOfferChangeModeratorDialogProps) => {
  const { offer, isExecuting, preview = true, onExecute, onClose } = props;

  const [selectedModerator, setSelectedModerator] = useState<Nullable<MpUser>>(null);
  const [searchedModerator, setSearchedModerator] = useState<Nullable<MpUser>>(null);
  const [searchValue, setSearchValue] = useState<Nullable<string>>(null);
  const [error, setError] = useState<Nullable<string>>(null);

  const onModeratorChange = () => {
    if (selectedModerator) {
      onExecute(offer!, selectedModerator);
    } else {
      setError('Обязательно для заполнения');
    }
  };

  const getBookingOfferModeratorPreview = (user: MpUser, checkedRoles: EUserRole[]): string => {
    return `${userInfoView(user).fullName} (${getUserCheckedRoles(user.roles, checkedRoles)
      .map(role => getUserRoleShortName(role))
      .join()})`;
  };

  if (!offer) {
    return null;
  }

  return (
    <ConfirmDialog
      open
      onClose={onClose}
      title={'Назначение модератора'}
      dialogButtons={
        <Grid
          container
          spacing={3}
        >
          <Grid item>
            <MPButton
              fullWidth={false}
              disabled={isExecuting}
              onClick={onModeratorChange}
            >
              Назначить
              {isExecuting && <ContentLoader />}
            </MPButton>
          </Grid>
          <Grid item>
            <MPButton
              variant='outlined'
              fullWidth={false}
              disabled={isExecuting}
              onClick={onClose}
            >
              Отмена
            </MPButton>
          </Grid>
        </Grid>
      }
    >
      <Grid
        container
        spacing={2}
        wrap='nowrap'
        direction='column'
      >
        <Grid item>
          <Typography>Выберите администратора, на которого будет назначена проверка</Typography>
        </Grid>
        {preview && (
          <Grid item>
            <BookingOfferPreview
              border
              name={offer.name || ''}
              address={offer.address ? new AddressHelper(offer.address).getLastLocalityShortPath() : null}
              image={offer.images?.[0] ?? null}
              servicePrice={offer.displayPriceItem?.price ?? null}
              serviceUnit={offer.displayPriceItem?.unit?.name ?? null}
              logo={offer.partner?.logo ?? null}
            />
          </Grid>
        )}
        <Grid item>
          <UserMpSuggestionContainer
            roles={[EUserRole.AdminMp, EUserRole.AdminMpCorp]}
            statuses={[EUserStatus.Enabled]}
          >
            {({ onSearch, users, isFetching }) => (
              <MPAutocompleteSingleSelect
                label={'Администратор объектов ДиО'}
                isLoading={isFetching}
                error={!!error}
                helperText={error}
                value={
                  searchedModerator && {
                    ...searchedModerator,
                    id: '',
                    name: searchValue ?? '',
                  }
                }
                options={
                  users?.map(user => ({
                    ...user,
                    name: getBookingOfferModeratorPreview(user, [EUserRole.AdminMp, EUserRole.AdminPartner]),
                  })) ?? []
                }
                onSearchValue={value => {
                  onSearch(value);
                  setSearchValue(value);
                  setSelectedModerator(null);
                }}
                onChangeValue={value => {
                  setSelectedModerator(value);
                  setSearchedModerator(value);
                  setError(null);
                }}
              />
            )}
          </UserMpSuggestionContainer>
        </Grid>
      </Grid>
    </ConfirmDialog>
  );
};
export { BookingOfferChangeModeratorDialog };
