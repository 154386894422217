import {
  AppUserSpecific,
  CommonUserData,
  EUserRole,
  EUserStatus,
  MpUser,
  MpUserData,
  MpUserShort,
  Nullable,
  SportOption,
  SportUserProfile,
  SportUserProfileShort,
} from '@/domain';

export type UserTypeProxy = (
  | MpUser
  | MpUserData
  | SportUserProfile
  | MpUserShort
  | SportUserProfileShort
  | CommonUserData
  | AppUserSpecific
) & {
  readonly email?: Nullable<string>;
  readonly road?: Nullable<SportOption>;
  readonly department?: Nullable<SportOption>;
};

const labelDeletedAccount = 'Аккаунт удален';
const labelUndefinedName = 'не определено';

// Карта типов для полей
export type UserFieldTypes<U extends UserTypeProxy> = {
  [K in keyof U]: U[K]; // Добавляем все остальные поля из типа U
};

export type UserInfoProxy<U> = { [K in keyof U]: U[K] } & {
  readonly roadName: Nullable<string>;
  readonly departmentName: Nullable<string>;
  readonly fullName: string;
  readonly fullNameReverse: string;
  readonly shortName: string;
  readonly shortNameReverse: string;
};

/**
 * Прокси для объекта пользователя
 * Нужен для вывода данных с учетом статусов пользователя и различных полей типа fullName
 * Не использовать внутри сервисов и стора, предназначен для адаптеров, компонентов и все места presentation
 */
export const userInfoView = <U extends UserTypeProxy, K extends keyof U>(user: UserFieldTypes<U>): UserInfoProxy<U> => {
  return new Proxy(
    { ...user },
    {
      /**
       * Обработчик Object.getOwnPropertyNames
       */
      ownKeys(target) {
        const customKeys = ['fullName', 'fullNameReverse', 'shortName', 'shortNameReverse'];
        // Возвращаем кастомные ключи вместе с ключами оригинального объекта
        return [...customKeys, ...Reflect.ownKeys(target)];
      },
      /**
       * Позволяем определять свойства на оригинальном объекте
       */
      defineProperty(target, prop, descriptor) {
        return Reflect.defineProperty(target, prop, descriptor);
      },

      /**
       * Обработка геттеров
       */
      get: (obj: U, prop, receiver) => {
        const key = prop as keyof UserInfoProxy<U>;
        const value = Reflect.get(obj, prop, receiver);
        const isDeleted = 'status' in obj && obj.status === EUserStatus.Deleted;
        const isCorpUser = 'roles' in obj && obj.roles?.some(r => r === EUserRole.CorpUser);

        switch (key) {
          case 'email':
            if (!value) return null;
            return isDeleted ? null : value;
          case 'roadName':
            if (!isCorpUser) {
              return null;
            }
            return obj?.road?.name || null;
          case 'departmentName':
            if (!isCorpUser) {
              return null;
            }
            return obj?.department?.name || null;
          case 'fullName':
            if (isDeleted) return labelDeletedAccount;
            return (
              [user.lastName, user.firstName, user.middleName].filter(item => !!item).join(' ') || labelUndefinedName
            );
          case 'fullNameReverse':
            if (isDeleted) return labelDeletedAccount;
            return (
              [user.firstName, user.middleName, user.lastName].filter(item => !!item).join(' ') || labelUndefinedName
            );
          case 'shortName':
            if (isDeleted) return labelDeletedAccount;
            return [user.lastName, user.firstName].filter(item => !!item).join(' ') || labelUndefinedName;
          case 'shortNameReverse':
            if (isDeleted) return labelDeletedAccount;
            return [user.firstName, user.lastName].filter(item => !!item).join(' ') || labelUndefinedName;
          default:
            return value;
        }
      },
    }
  ) as UserInfoProxy<U>;
};
