import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@/data/store/store';
import { ESortDirection, Nullable, Team } from '@/domain';
import { ETeamTableColumn } from '../../table/utils';

export const teamsSearchGuidSelector = (store: RootState) => store.team.search.guid;

export const teamsSearchTeamsSelector = (store: RootState) => store.team.search.data;
export const teamsSearchSearchSelector = (store: RootState) => store.team.search.search;
export const teamsSearchNeedRefreshWatcherSelector = (store: RootState) => store.team.search.needRefreshWatcher;
export const teamsSearchTotalCountSelector = (store: RootState) => store.team.search.totalCount;
export const teamsSearchPageCountSelector = (store: RootState) => store.team.search.pageCount;
export const teamsSearchPageNumberSelector = (store: RootState) => store.team.search.pageNumber;

export const teamsSearchFilterSelector = (store: RootState) => store.team.search.filter;

export const teamsSearchSelectedSelector = (store: RootState) => store.team.search.selected;

export const teamsSearchIsFetchingSelector = (store: RootState) => store.team.search.isFetching;

export const teamsSearchSelectRestrictionsSelector = (store: RootState) => store.team.search.selectRestrictions;

export const teamsSearchSortColumnSelector = (store: RootState) =>
  (store.team.search.search.sort?.split(',') as [ETeamTableColumn, ESortDirection])?.[0] ?? '';

export const teamsSearchSortDirectionSelector = (store: RootState) =>
  (store.team.search.search.sort?.split(',') as [ETeamTableColumn, ESortDirection])?.[1] ?? '';

export const teamsSearchTeamIndexSelector = (store: RootState, index: number) => index;

const createTeamsSearchTeamByIndexSelector = createSelector(
  teamsSearchTeamsSelector,
  teamsSearchTeamIndexSelector,
  (teams, index): Nullable<Team> => teams?.[index] ?? null
);

export const teamsSearchTeamByIndexSelector = (index: number) => (store: RootState) =>
  createTeamsSearchTeamByIndexSelector(store, index);
