import { EPartnerPermission, EPartnerType, Partner, SportOptionTyped } from '@/domain';
import { Checkbox } from '@components/common/checkboxPanel/controls';
import { FormControlLabel, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { EAppFeature } from 'presentation/types';
import FileInfo from '../../../../../components/common/files/info';
import Splitter from '../../../../../components/common/splitter';
import useTechConfig from '../../../../../hooks/useTechConfig';

interface PartnerDetailsInfoPermissionsProps {
  readonly partner: Partner;
  readonly partnerTypes: SportOptionTyped<EPartnerType>[];
}

const PartnerDetailsInfoPermissions = ({ partner, partnerTypes }: PartnerDetailsInfoPermissionsProps) => {
  const { permissions, agreements, clientOrg } = partner;

  const { hasFeature } = useTechConfig();
  const productOffers = hasFeature(EAppFeature.ProductOffer);
  const bookingOffers = hasFeature(EAppFeature.Booking);

  const tradeOffersAllowed: boolean = permissions?.includes(EPartnerPermission.TradeOfferPublic) ?? false;
  const corpOffersAllowed: boolean = permissions?.includes(EPartnerPermission.CorpOfferPublic) ?? false;
  const productOffersAllowed: boolean = permissions?.includes(EPartnerPermission.ProductOfferPublic) ?? false;
  const bookingOffersAllowed: boolean = permissions?.includes(EPartnerPermission.BookingOfferPublic) ?? false;

  const partnerTypeName = partnerTypes.find(pt => pt.id === partner.type)?.name ?? 'Не определен';

  return (
    <Grid
      container
      direction='column'
    >
      <Grid item>
        <Typography variant='h3'>Доступ</Typography>
        <Splitter variant='horizontal' />
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              disabled
              color='primary'
              checked={tradeOffersAllowed}
            />
          }
          label={<Typography color='textSecondary'>Торговые предложения</Typography>}
        />
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              disabled
              color='primary'
              checked={corpOffersAllowed}
            />
          }
          label={<Typography color='textSecondary'>Корпоративные предложения</Typography>}
        />
      </Grid>
      {productOffers && (
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                disabled
                color='primary'
                checked={productOffersAllowed}
              />
            }
            label={<Typography color='textSecondary'>Товарные предложения</Typography>}
          />
        </Grid>
      )}

      {bookingOffers && (
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                disabled
                color='primary'
                checked={bookingOffersAllowed}
              />
            }
            label={<Typography color='textSecondary'>Объекты досуга и отдыха</Typography>}
          />
        </Grid>
      )}

      <Grid item>
        <Splitter
          variant='horizontal'
          size={3}
        />
        <Typography variant='subtitle2'>Вид партнёра</Typography>
        <Splitter variant='horizontal' />
        <Typography>{partnerTypeName}</Typography>
      </Grid>
      {hasFeature(EAppFeature.CompanyOffer) && clientOrg && (
        <Grid item>
          <Splitter
            variant='horizontal'
            size={3}
          />
          <Typography variant='subtitle2'>Разрешения на компанию</Typography>
          <Splitter variant='horizontal' />
          <Typography>{clientOrg.name}</Typography>
        </Grid>
      )}
      <Grid item>
        <Splitter
          variant='horizontal'
          size={3}
        />
        <Typography variant='subtitle2'>Договоры и соглашения</Typography>
        <Splitter variant='horizontal' />
      </Grid>
      <Grid
        item
        container
        direction='column'
        spacing={1}
      >
        {agreements?.map(agreement => (
          <Grid
            item
            key={agreement.path}
          >
            <FileInfo
              key={agreement.path}
              id={agreement.path}
            />
          </Grid>
        ))}
        {agreements?.length === 0 && (
          <Grid item>
            <Typography color='error'>файлы не загружены</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default PartnerDetailsInfoPermissions;
