import {
  ActivityType,
  EUserType,
  Address,
  SportOption,
  Nullable,
  DataFilterQueryDslOperator,
  DataFilterStrategyAdapterTreeMultipleEditAdapter,
  DataFilterStrategyBase,
  DataFilterStrategyListItem,
  DataFilterStrategyView,
  DataFilterValueItem,
  EDataFilterType,
} from '@/domain';
import { AddressHelper } from '../../utils/address';
import ActivityTypeFilterEditAdapter from '../activityType/adapters/filterEdit/container';
import { ETeamTableColumn } from './table/utils';

export enum ETeamTableFilterItem {
  Name = 'name',
  ActivityTypes = 'activityTypes',
  Locality = 'locality',
  UserType = 'userType',
}

export const teamTableFilterItems: Record<ETeamTableColumn, ETeamTableFilterItem[]> = {
  [ETeamTableColumn.Logo]: [],
  [ETeamTableColumn.Name]: [ETeamTableFilterItem.Name],
  [ETeamTableColumn.ActivityTypes]: [ETeamTableFilterItem.ActivityTypes],
  [ETeamTableColumn.Locality]: [ETeamTableFilterItem.Locality, ETeamTableFilterItem.UserType],
  [ETeamTableColumn.Captain]: [],
};

export type TeamTableFilterEditStrategy = DataFilterStrategyBase<ETeamTableFilterItem>;
export type TeamTableFilterViewStrategy = DataFilterStrategyView<ETeamTableFilterItem>;

export type TeamTableFilterValues = {
  readonly [ETeamTableFilterItem.Name]?: DataFilterValueItem<Nullable<string>>;
  readonly [ETeamTableFilterItem.ActivityTypes]?: DataFilterValueItem<Nullable<ActivityType[]>>;
  readonly [ETeamTableFilterItem.Locality]?: DataFilterValueItem<Nullable<Address>>;
  readonly [ETeamTableFilterItem.UserType]?: DataFilterValueItem<Nullable<EUserType>>;
};

export const getTeamTableFilterStrategy = (
  filterItem: ETeamTableFilterItem,
  values: TeamTableFilterValues,
  userTypes: SportOption[]
): Nullable<TeamTableFilterEditStrategy> => {
  const userTypeItems: DataFilterStrategyListItem[] = (userTypes ?? []).map(({ id, name }) => ({ id, name }));
  switch (filterItem) {
    case ETeamTableFilterItem.Name:
      return {
        type: EDataFilterType.String,
        key: ETeamTableFilterItem.Name,
        label: 'Название',
        preview: values[ETeamTableFilterItem.Name]?.value || null,
        value: values[ETeamTableFilterItem.Name]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case ETeamTableFilterItem.Locality:
      return {
        type: EDataFilterType.AddressLocalitySingle,
        key: ETeamTableFilterItem.Locality,
        label: 'Населённый пункт',
        preview: values[ETeamTableFilterItem.Locality]?.value
          ? new AddressHelper(values[ETeamTableFilterItem.Locality]!.value!).getLocalityFullPath()
          : null,
        value: values[ETeamTableFilterItem.Locality]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Equals,
        },
      };
    case ETeamTableFilterItem.ActivityTypes:
      return {
        type: EDataFilterType.AdapterTreeMultiple,
        key: ETeamTableFilterItem.ActivityTypes,
        label: 'Интересы',
        preview: `Интересы (${values[ETeamTableFilterItem.ActivityTypes]?.value?.length})`,
        previews: values[ETeamTableFilterItem.ActivityTypes]?.value?.map(t => t.name),
        value: values[ETeamTableFilterItem.ActivityTypes]?.value || null,
        editAdapter:
          ActivityTypeFilterEditAdapter as DataFilterStrategyAdapterTreeMultipleEditAdapter<ETeamTableFilterItem>,
      };
    case ETeamTableFilterItem.UserType:
      return {
        type: EDataFilterType.ListSingle,
        key: ETeamTableFilterItem.UserType,
        label: 'Тип пользователя',
        preview:
          `Тип пользователя (${
            userTypeItems.find(g => g.id === values[ETeamTableFilterItem.UserType]?.value)?.name
          })` || null,
        value: values[ETeamTableFilterItem.UserType]?.value || null,
        items: userTypeItems,
        querydsl: {
          operator: DataFilterQueryDslOperator.Equals,
        },
      };
  }
};
