import { ClientOrgShort } from '@/domain';
import { MPAutocompleteSingleSelect } from '@ui-kit/autocomplete/single';
import debounce from 'lodash/debounce';
import { useRef, useState } from 'react';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';

interface OrgStructureClientOrgSingleSelectorProps {
  readonly label: string;
  readonly value: Nullable<ClientOrgShort>;
  readonly helperText?: string;
  readonly error?: boolean;
  readonly disabled?: boolean;
  readonly onChange: (orgUnit: Nullable<ClientOrgShort>) => void;
}

const OrgStructureClientOrgSingleSelector = (props: OrgStructureClientOrgSingleSelectorProps) => {
  const { label, value, helperText, error, disabled, onChange } = props;

  const [searchedObjects, setSearchedObjects] = useState<ClientOrgShort[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handlerSearch = useRef(debounce(value => search(value), 500)).current;

  const search = (query: string) => {
    if (!query) {
      return;
    }

    setIsLoading(true);
    Api.clientOrg
      .all({
        page: 1,
        pageSize: 25,
        sort: 'name',
        query,
      })
      .then(({ data }) => {
        setSearchedObjects(data);
      })
      .catch(e => {
        console.error(e);
        ErrorHandler.handleHttpError(e, e.response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <MPAutocompleteSingleSelect<ClientOrgShort>
      value={value}
      label={label}
      helperText={helperText}
      error={error}
      isLoading={isLoading}
      options={searchedObjects}
      disabled={disabled}
      disableCloseOnSelect
      onSearchValue={value => handlerSearch(value)}
      onChangeValue={onChange}
    />
  );
};

export default OrgStructureClientOrgSingleSelector;
