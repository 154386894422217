import { MpUserData } from '../../../../domain/model/user';
import { validationCommonValidators, ValidationRules } from '../../../utils/validation';
import { EPartnerEmployeeStep } from '../utils';

export const getPartnerEmployeeCreateStepText = (step: EPartnerEmployeeStep): string => {
  switch (step) {
    case EPartnerEmployeeStep.Profile:
      return 'Профиль';
    case EPartnerEmployeeStep.Grants:
      return 'Доступ';
    default:
      throw new Error(`Unknown EPartnerEmployeeStep: ${step}`);
  }
};

export const partnerEmployeeCreateProfileStepValidation: ValidationRules<MpUserData> = {
  lastName: {
    required: true,
  },
  firstName: {
    required: true,
  },
  middleName: {
    required: true,
  },
  email: {
    required: true,
    validator: validationCommonValidators.email,
  },
  phone: {
    required: true,
  },
};
