import { CustomerLifeCycleRules } from '../../lifecycle/types';
import { CustomerActionDetailsType, CustomerActions } from '../../types';
import { filterCustomerDetailsActions } from '../../utils/actions';

type UseCustomerDetailsActions = CustomerActions<CustomerActionDetailsType>;

interface UseCustomerDetailsActionsProps {
  readonly lifeCycleRules: CustomerLifeCycleRules;
}

const useCustomerDetailsActions = (props: UseCustomerDetailsActionsProps): UseCustomerDetailsActions => {
  const { lifeCycleRules } = props;

  const { nextActions } = lifeCycleRules;

  return filterCustomerDetailsActions(nextActions);
};

export default useCustomerDetailsActions;
