import { ESortDirection, MpCustomer, Nullable, UUID } from '@/domain';
import { addSearchParamToLocation } from '@/routing/globalRouting';
import { useCustomerActions } from '@features/customer/actions/useActions';
import {
  CustomersFilterEditStrategy,
  CustomersFilterValues,
  ECustomersFilterItem,
} from '@features/customer/table/filterUtils';
import { ECustomerTableTab } from '@features/customer/table/utils';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import store from '../../../../../data/store/store';
import { PaginationSize } from '../../../../types';
import { getDataFilterValuesByStrategies } from '../../../../utils/filtering';
import { ECustomerUrlParam } from '../../entry';
import { customersGuidSelector } from '../store/selectors';
import {
  customersDataReset,
  customersNeedRefreshWatcherIncrement,
  customersSetFilter,
  customersSetPage,
  customersSetPageSize,
  customersSetSort,
  customersSortReset,
} from '../store/slice';

export type UseCustomersTable = {
  readonly onChangeTab: (newTab: ECustomerTableTab) => void;
  readonly onChangeFilter: (strategies: CustomersFilterEditStrategy[]) => void;
  readonly onChangeSort: (name: string, direction: ESortDirection) => void;
  readonly onResetSort: () => void;
  readonly onRefresh: () => void;
  readonly onChangePageNumber: (page: number) => void;
  readonly onChangePageSize: (pageSize: PaginationSize) => void;

  readonly onCustomerActivate: (customer: MpCustomer) => void;
  readonly onCustomerDeactivate: (customer: MpCustomer) => void;
  readonly onCustomerSendProfileQuery: (customer: MpCustomer) => void;
};

type UseCustomersTableHandlersProps = {
  readonly tab: ECustomerTableTab;
};

export function useCustomersTableHandlers({ tab }: UseCustomersTableHandlersProps): UseCustomersTable {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { onActivate, onDeactivate, onSendProfilesQuery } = useCustomerActions();

  const getCustomersGuidFromState = useCallback((): Nullable<UUID> => {
    const state = store.getState();
    return customersGuidSelector(state);
  }, [store]);

  const onChangeTab = useCallback<UseCustomersTable['onChangeTab']>(
    newTab => {
      if (newTab !== tab) {
        const guid = getCustomersGuidFromState();
        dispatch(customersDataReset());
        history.replace(
          addSearchParamToLocation({
            location,
            param: ECustomerUrlParam.Tab,
            value: newTab,
            state: { guid },
          })
        );
      }
    },
    [dispatch, history, location, tab, getCustomersGuidFromState]
  );

  const onChangeFilter = useCallback<UseCustomersTable['onChangeFilter']>(
    strategies => {
      dispatch(
        customersSetFilter(getDataFilterValuesByStrategies<ECustomersFilterItem, CustomersFilterValues>(strategies))
      );
    },
    [dispatch]
  );

  const onChangeSort = useCallback<UseCustomersTable['onChangeSort']>(
    (name, direction) => {
      dispatch(
        customersSetSort({
          sort: `${name},${direction}`,
        })
      );
    },
    [dispatch]
  );

  const onChangePageNumber = useCallback<UseCustomersTable['onChangePageNumber']>(
    pageNumber => {
      dispatch(customersSetPage({ pageNumber }));
    },
    [dispatch]
  );

  const onChangePageSize = useCallback<UseCustomersTable['onChangePageSize']>(
    pageSize => {
      dispatch(customersSetPageSize({ pageSize }));
    },
    [dispatch]
  );

  const onResetSort = useCallback<UseCustomersTable['onResetSort']>(() => dispatch(customersSortReset()), [dispatch]);

  const onRefresh = useCallback(() => {
    dispatch(customersNeedRefreshWatcherIncrement());
  }, [dispatch]);

  const onCustomerActivate = onActivate;

  const onCustomerDeactivate = onDeactivate;

  const onCustomerSendProfileQuery = onSendProfilesQuery;

  return {
    onChangeTab,
    onChangeFilter,
    onChangeSort,
    onResetSort,
    onRefresh,
    onChangePageNumber,
    onChangePageSize,
    onCustomerActivate,
    onCustomerDeactivate,
    onCustomerSendProfileQuery,
  };
}
