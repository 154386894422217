import { AxiosStatic, CancelToken } from 'axios';
import activation from './activation';
import activityType from './activityType';
import adCampaign from './adCampaign';
import analytics from './analytics';
import banner from './banner';
import bonus from './bonus';
import booking from './booking';
import bookingDictionary from './bookingDictionary';
import category from './category';
import chat from './chat';
import clientOrg from './clientOrg';
import clientOrgUser from './clientOrgUser';
import cms from './cms';
import common from './common';
import complaint from './complaint';
import corpOffer from './corpOffer';
import customer from './customer';
import event from './event';
import files from './files';
import notification from './notification';
import nsi from './nsi';
import offerApprovalRegistry from './offerApprovalRegistry';
import order from './order';
import orgStructure from './orgStructure';
import partner from './partner';
import playground from './playground';
import productOffers from './productOffer';
import product from './products';
import personalPromotion from './promotion';
import report from './report';
import socialPackage from './socialPackage';
import socialPackageApplication from './socialPackageApplication';
import support from './support';
import team from './team';
import tradeOffer from './tradeOffer';
import user from './user';

const Api = {
  nsi,
  files,
  playground,
  team,
  event,
  user,
  bonus,
  order,
  chat,
  tradeOffer,
  category,
  corpOffer,
  booking,
  bookingDictionary,
  complaint,
  partner,
  personalPromotion,
  notification,
  report,
  banner,
  orgStructure,
  activation,
  offerApprovalRegistry,
  productOffers,
  product,
  common,
  cms,
  activityType,
  analytics,
  clientOrg,
  clientOrgUser,
  customer,
  adCampaign,
  socialPackage,
  socialPackageApplication,
  support,
};

export const createCancelToken = (axios: AxiosStatic, signal: AbortSignal): CancelToken => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  return source.token;
};

export default Api;
