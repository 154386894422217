import Api from '@/data/api';
import { SportOption } from '@/domain/model';
import { Address } from '@/domain/model/address';
import { pageSizeAll } from '@/domain/model/constants';
import { EUserRole } from '@/domain/model/enums';
import {
  DataFilterQueryDslOperator,
  DataFilterStrategyBase,
  DataFilterStrategyListItem,
  DataFilterValueItem,
  EDataFilterType,
} from '@/domain/model/filter';
import { Nullable } from '@/domain/model/types';
import { userInfoView } from 'presentation/utils/userInfoView';
import { AddressHelper } from '../../utils/address';
import bookingServices from './services';
import { EBookingOfferTableColumn } from './types';
import { AppConfigurator } from '@/system/appConfigurator';
import { EAppFeature } from '@/presentation/types';

export enum EBookingOfferTableFilterItem {
  Query = 'query',
  Code = 'code',
  Name = 'name',
  Address = 'addressObjectId',
  Partner = 'partnerDesk.partner.id',
  Categories = 'category.id',
  PartnerType = 'partnerDesk.partner.type',
  ResponsibleFullName = 'responsiblePersons.fullName',
  Moderator = 'moderator.id',
  RzdSocialPackage = 'rzdSocialPackage',
}

const isCSP = AppConfigurator.getInstance().hasFeature(EAppFeature.Csp);

export const bookingOfferTableFilterItems: Record<EBookingOfferTableColumn, EBookingOfferTableFilterItem[]> = {
  [EBookingOfferTableColumn.Name]: [EBookingOfferTableFilterItem.Name],
  [EBookingOfferTableColumn.Code]: [EBookingOfferTableFilterItem.Code],
  [EBookingOfferTableColumn.Address]: [EBookingOfferTableFilterItem.Address],
  [EBookingOfferTableColumn.Partner]: [EBookingOfferTableFilterItem.Partner],
  [EBookingOfferTableColumn.PartnerType]: [EBookingOfferTableFilterItem.PartnerType],
  [EBookingOfferTableColumn.Category]: [EBookingOfferTableFilterItem.Categories],
  [EBookingOfferTableColumn.ResponsibleFullName]: [EBookingOfferTableFilterItem.ResponsibleFullName],
  [EBookingOfferTableColumn.Moderator]: [EBookingOfferTableFilterItem.Moderator],
  [EBookingOfferTableColumn.Image]: [],
  [EBookingOfferTableColumn.Date]: [],
  [EBookingOfferTableColumn.ResponsiblePhone]: [],
  [EBookingOfferTableColumn.ResponsibleEmail]: [],
  [EBookingOfferTableColumn.RzdSocialPackage]: isCSP ? [EBookingOfferTableFilterItem.RzdSocialPackage] : [],
};

export type BookingOfferTableFilterEditStrategy = DataFilterStrategyBase<EBookingOfferTableFilterItem>;

export type BookingOfferTableFilterValues = {
  readonly [EBookingOfferTableFilterItem.Query]?: DataFilterValueItem<Nullable<string>>;
  readonly [EBookingOfferTableFilterItem.Code]?: DataFilterValueItem<Nullable<string>>;
  readonly [EBookingOfferTableFilterItem.Name]?: DataFilterValueItem<Nullable<string>>;
  readonly [EBookingOfferTableFilterItem.Address]?: DataFilterValueItem<Nullable<Address>>;
  readonly [EBookingOfferTableFilterItem.Partner]?: DataFilterValueItem<Nullable<DataFilterStrategyListItem[]>>;
  readonly [EBookingOfferTableFilterItem.PartnerType]?: DataFilterValueItem<Nullable<string[]>>;
  readonly [EBookingOfferTableFilterItem.Categories]?: DataFilterValueItem<Nullable<DataFilterStrategyListItem[]>>;
  readonly [EBookingOfferTableFilterItem.ResponsibleFullName]?: DataFilterValueItem<Nullable<string>>;
  readonly [EBookingOfferTableFilterItem.Moderator]?: DataFilterValueItem<Nullable<DataFilterStrategyListItem[]>>;
  readonly [EBookingOfferTableFilterItem.RzdSocialPackage]?: DataFilterValueItem<Nullable<boolean>>;
};

export const getBookingOfferTableFilterStrategy = (
  filterItem: EBookingOfferTableFilterItem,
  values: BookingOfferTableFilterValues,
  partnerTypes: SportOption[]
): Nullable<BookingOfferTableFilterEditStrategy> => {
  try {
    switch (filterItem) {
      case EBookingOfferTableFilterItem.Query:
        return {
          type: EDataFilterType.String,
          key: EBookingOfferTableFilterItem.Query,
          label: 'Поиск по ключевому слову',
          preview: values[EBookingOfferTableFilterItem.Query]?.value || null,
          value: values[EBookingOfferTableFilterItem.Query]?.value || null,
        };
      case EBookingOfferTableFilterItem.Code:
        return {
          type: EDataFilterType.String,
          key: EBookingOfferTableFilterItem.Code,
          label: 'Код объекта',
          preview: values[EBookingOfferTableFilterItem.Code]?.value || null,
          value: values[EBookingOfferTableFilterItem.Code]?.value || null,
          querydsl: {
            operator: DataFilterQueryDslOperator.Like,
          },
        };
      case EBookingOfferTableFilterItem.Name:
        return {
          type: EDataFilterType.String,
          key: EBookingOfferTableFilterItem.Name,
          label: 'Объект',
          preview: values[EBookingOfferTableFilterItem.Name]?.value || null,
          value: values[EBookingOfferTableFilterItem.Name]?.value || null,
          querydsl: {
            operator: DataFilterQueryDslOperator.Like,
          },
        };
      case EBookingOfferTableFilterItem.Address:
        return {
          type: EDataFilterType.AddressSingle,
          key: EBookingOfferTableFilterItem.Address,
          label: 'Адрес',
          preview: values[EBookingOfferTableFilterItem.Address]?.value
            ? new AddressHelper(values[EBookingOfferTableFilterItem.Address]!.value!).getFullPath()
            : null,
          value: values[EBookingOfferTableFilterItem.Address]?.value || null,
        };
      case EBookingOfferTableFilterItem.Partner:
        return {
          type: EDataFilterType.AutocompleteMultiple,
          key: EBookingOfferTableFilterItem.Partner,
          label: 'Партнёр',
          value: values[EBookingOfferTableFilterItem.Partner]?.value || null,
          preview: `Партнер (${values[EBookingOfferTableFilterItem.Partner]?.value?.length})` || null,
          previews: values[EBookingOfferTableFilterItem.Partner]?.value?.map(t => t.name) ?? [],
          source: (name: string) =>
            name
              ? Api.partner
                  .all({
                    query: name,
                    sort: 'name,asc',
                    page: 1,
                    pageSize: pageSizeAll,
                  })
                  .then(response => response.data)
              : Promise.resolve([]),
          querydsl: {
            operator: DataFilterQueryDslOperator.In,
            valueAttribute: 'id',
          },
        };
      case EBookingOfferTableFilterItem.PartnerType:
        return {
          type: EDataFilterType.ListMultiple,
          key: EBookingOfferTableFilterItem.PartnerType,
          label: 'Вид партнера',
          preview: `Вид партнера (${values[EBookingOfferTableFilterItem.PartnerType]?.value?.length})`,
          previews: partnerTypes
            .filter(t => values[EBookingOfferTableFilterItem.PartnerType]?.value?.some(v => v === t.id))
            .map(t => t.name),
          value: values[EBookingOfferTableFilterItem.PartnerType]?.value || null,
          items: partnerTypes.map(t => ({ id: t.id, name: t.name })),
          querydsl: {
            operator: DataFilterQueryDslOperator.In,
          },
        };
      case EBookingOfferTableFilterItem.Categories:
        return {
          type: EDataFilterType.AutocompleteMultiple,
          key: EBookingOfferTableFilterItem.Categories,
          label: 'Категории',
          value: values[EBookingOfferTableFilterItem.Categories]?.value || null,
          preview: `Категории (${values[EBookingOfferTableFilterItem.Categories]?.value?.length})`,
          loadOnOpening: true,
          previews: values[EBookingOfferTableFilterItem.Categories]?.value?.map(t => t.name) ?? [],
          source: (name: string) =>
            bookingServices.offer
              .categories({
                query: name,
              })
              .then(data =>
                data.map(item => ({
                  ...item,
                  name: item.name,
                }))
              ),
          querydsl: {
            operator: DataFilterQueryDslOperator.In,
            valueAttribute: 'id',
          },
        };
      case EBookingOfferTableFilterItem.ResponsibleFullName:
        return {
          type: EDataFilterType.String,
          key: EBookingOfferTableFilterItem.ResponsibleFullName,
          label: 'Ответственный за объект',
          preview: `Ответственный ${values[EBookingOfferTableFilterItem.ResponsibleFullName]?.value}` || null,
          value: values[EBookingOfferTableFilterItem.ResponsibleFullName]?.value || null,
          querydsl: {
            operator: DataFilterQueryDslOperator.Like,
          },
        };

      case EBookingOfferTableFilterItem.Moderator:
        return {
          type: EDataFilterType.AutocompleteMultiple,
          key: EBookingOfferTableFilterItem.Moderator,
          label: 'Модератор',
          value: values[EBookingOfferTableFilterItem.Moderator]?.value || null,
          preview: `Модератор (${values[EBookingOfferTableFilterItem.Moderator]?.value?.length})` || null,
          previews: values[EBookingOfferTableFilterItem.Moderator]?.value?.map(t => t.name) ?? [],
          source: (name: string) =>
            name
              ? Api.user.mp
                  .all({
                    query: name,
                    page: 1,
                    pageSize: pageSizeAll,
                    sort: ['lastName,asc', 'firstName,asc', 'middleName,asc'],
                    roles: [EUserRole.AdminMp],
                  })
                  .then(response =>
                    response.data.map(item => ({
                      ...item,
                      name: userInfoView(item).fullName,
                    }))
                  )
              : Promise.resolve([]),
          querydsl: {
            operator: DataFilterQueryDslOperator.In,
            valueAttribute: 'id',
          },
        };
      case EBookingOfferTableFilterItem.RzdSocialPackage:
        return {
          type: EDataFilterType.BooleanFlag,
          key: EBookingOfferTableFilterItem.RzdSocialPackage,
          label: 'Компенсация КСП',
          preview: values[EBookingOfferTableFilterItem.RzdSocialPackage]?.value ? 'Компенсация КСП' : null,
          value: values[EBookingOfferTableFilterItem.RzdSocialPackage]?.value || null,
          querydsl: {
            operator: DataFilterQueryDslOperator.Equals,
          },
        };
      default:
        return null;
    }
  } catch (e) {
    console.error(e);
    return null;
  }
};
