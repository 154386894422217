import _ from 'lodash';
import { ESportEventStatus, ESportEventType } from '@/domain';
import { DataTableColumns, ETableColumnAlign } from '@components/common/table';

export enum ESportEventTableTab {
  All = 'all',
  Draft = 'draft',
  Announced = 'announced',
  ApplicationsCollecting = 'applicationCollecting',
  InvitationsDistribution = 'invitationsDistribution',
  WaitingForStart = 'waitingForStart',
  InProgress = 'inProgress',
  SummingUp = 'summingUp',
  Summarizing = 'summarizing',
  Completed = 'completed',
  Cancelled = 'canceled',
}

export type SportEventTableTabsCounter = {
  [name in ESportEventTableTab]?: number;
};

/** Колонки таблицы для мероприятий */
export enum ESportEventTableColumn {
  /** Название */
  Name = 'name',
  /** Тип мероприятия */
  Type = 'type',
  /** Тематика */
  ActivityTypes = 'activityTypes',
  /** Дата начала */
  ScheduledDate = 'scheduledDate',
  /** Организатор */
  Admin = 'admin',
  /** Населенный пункт */
  PlaygroundCityName = 'locality',
  /** Железная дорога */
  Road = 'road',
  /** Статус */
  Status = 'status',
  /** Формат участия */
  ParticipationFormat = 'participationFormat',
}

export const sportEventTableColumns: DataTableColumns<ESportEventTableColumn> = {
  [ESportEventTableColumn.Name]: {
    title: 'Название',
    sortable: true,
    width: '12rem',
    wrap: true,
  },
  [ESportEventTableColumn.Type]: {
    title: 'Тип мероприятия',
    width: '10rem',
    sortable: true,
  },
  [ESportEventTableColumn.ParticipationFormat]: {
    title: 'Формат участия',
    width: '10rem',
    sortable: true,
  },
  [ESportEventTableColumn.ActivityTypes]: {
    title: 'Тематика',
    width: '15rem',
    sortable: true,
    wrap: true,
  },
  [ESportEventTableColumn.ScheduledDate]: {
    title: 'Дата начала',
    width: '11rem',
    sortable: true,
  },
  [ESportEventTableColumn.Admin]: {
    title: 'Организатор',
    width: '12rem',
    sortable: true,
    wrap: true,
  },
  [ESportEventTableColumn.PlaygroundCityName]: {
    title: 'Населенный пункт',
    width: '11rem',
    sortable: true,
    wrap: true,
  },
  [ESportEventTableColumn.Road]: {
    title: 'Железная дорога',
    width: '11rem',
    sortable: true,
    wrap: true,
  },
  [ESportEventTableColumn.Status]: {
    title: 'Статус',
    width: '12rem',
    sortable: true,
    align: ETableColumnAlign.Right,
  },
};

export const getSportEventTableAdapterKey = (tab: ESportEventTableTab) => {
  return {
    key: `events-${tab}`,
  };
};

export const getSportEventColumns = (tab: ESportEventTableTab): DataTableColumns<ESportEventTableColumn> => {
  const tabColumns: ESportEventTableColumn[] = [];

  switch (tab) {
    case ESportEventTableTab.All:
      tabColumns.push(
        ESportEventTableColumn.Name,
        ESportEventTableColumn.Type,
        ESportEventTableColumn.ParticipationFormat,
        ESportEventTableColumn.ActivityTypes,
        ESportEventTableColumn.ScheduledDate,
        ESportEventTableColumn.Admin,
        ESportEventTableColumn.PlaygroundCityName,
        ESportEventTableColumn.Road,
        ESportEventTableColumn.Status
      );
      break;
    default:
      tabColumns.push(
        ESportEventTableColumn.Name,
        ESportEventTableColumn.Type,
        ESportEventTableColumn.ParticipationFormat,
        ESportEventTableColumn.ActivityTypes,
        ESportEventTableColumn.ScheduledDate,
        ESportEventTableColumn.Admin,
        ESportEventTableColumn.PlaygroundCityName,
        ESportEventTableColumn.Road
      );
  }
  return tabColumns.reduce<DataTableColumns<ESportEventTableColumn>>((prev, column) => {
    return {
      ...prev,
      [column]: {
        ...sportEventTableColumns[column],
      },
    };
  }, {});
};

export const getSportEventTableStatusesByTab = (tab: ESportEventTableTab): ESportEventStatus[] => {
  switch (tab) {
    case ESportEventTableTab.All:
      return [];
    case ESportEventTableTab.Draft:
      return [ESportEventStatus.Draft];
    case ESportEventTableTab.Announced:
      return [ESportEventStatus.Announced];
    case ESportEventTableTab.ApplicationsCollecting:
      return [ESportEventStatus.ApplicationsCollecting];
    case ESportEventTableTab.InvitationsDistribution:
      return [ESportEventStatus.InvitationsDistribution];
    case ESportEventTableTab.WaitingForStart:
      return [ESportEventStatus.WaitingForStart];
    case ESportEventTableTab.InProgress:
      return [ESportEventStatus.InProgress];
    case ESportEventTableTab.SummingUp:
      return [ESportEventStatus.SummingUp];
    case ESportEventTableTab.Summarizing:
      return [ESportEventStatus.Summarizing];
    case ESportEventTableTab.Completed:
      return [ESportEventStatus.Completed];
    case ESportEventTableTab.Cancelled:
      return [ESportEventStatus.Cancelled];
  }
};

export const getSportEventTabByStatus = (status: ESportEventStatus): ESportEventTableTab => {
  switch (status) {
    case ESportEventStatus.Draft:
      return ESportEventTableTab.Draft;
    case ESportEventStatus.Announced:
      return ESportEventTableTab.Announced;
    case ESportEventStatus.ApplicationsCollecting:
      return ESportEventTableTab.ApplicationsCollecting;
    case ESportEventStatus.InvitationsDistribution:
      return ESportEventTableTab.InvitationsDistribution;
    case ESportEventStatus.WaitingForStart:
      return ESportEventTableTab.WaitingForStart;
    case ESportEventStatus.InProgress:
      return ESportEventTableTab.InProgress;
    case ESportEventStatus.SummingUp:
      return ESportEventTableTab.SummingUp;
    case ESportEventStatus.Summarizing:
      return ESportEventTableTab.Summarizing;
    case ESportEventStatus.Completed:
      return ESportEventTableTab.Completed;
    case ESportEventStatus.Cancelled:
      return ESportEventTableTab.Cancelled;
  }
};

export const getSportEventTableTabName = (tab: ESportEventTableTab): string => {
  switch (tab) {
    case ESportEventTableTab.All:
      return 'Все мероприятия';
    case ESportEventTableTab.Draft:
      return 'Черновик';
    case ESportEventTableTab.Announced:
      return 'Анонс';
    case ESportEventTableTab.ApplicationsCollecting:
      return 'Сбор заявок';
    case ESportEventTableTab.InvitationsDistribution:
      return 'Рассылка приглашений';
    case ESportEventTableTab.WaitingForStart:
      return 'Ожидание начала';
    case ESportEventTableTab.InProgress:
      return 'В процессе проведения';
    case ESportEventTableTab.SummingUp:
      return 'Подведение итогов';
    case ESportEventTableTab.Summarizing:
      return 'Публикация результатов';
    case ESportEventTableTab.Completed:
      return 'Завершено';
    case ESportEventTableTab.Cancelled:
      return 'Отменено';
  }
};

const sportEventTableTabsByTypes: Record<ESportEventType, ESportEventTableTab[]> = {
  [ESportEventType.FriendlyMatch]: [
    ESportEventTableTab.All,
    ESportEventTableTab.Draft,
    ESportEventTableTab.InvitationsDistribution,
    ESportEventTableTab.WaitingForStart,
    ESportEventTableTab.InProgress,
    ESportEventTableTab.Completed,
    ESportEventTableTab.Cancelled,
  ],
  [ESportEventType.Tournament]: [
    ESportEventTableTab.All,
    ESportEventTableTab.Draft,
    ESportEventTableTab.Announced,
    ESportEventTableTab.ApplicationsCollecting,
    ESportEventTableTab.WaitingForStart,
    ESportEventTableTab.InProgress,
    ESportEventTableTab.Summarizing,
    ESportEventTableTab.SummingUp,
    ESportEventTableTab.Completed,
    ESportEventTableTab.Cancelled,
  ],
  [ESportEventType.TournamentIndividual]: [
    ESportEventTableTab.All,
    ESportEventTableTab.Draft,
    ESportEventTableTab.Announced,
    ESportEventTableTab.ApplicationsCollecting,
    ESportEventTableTab.WaitingForStart,
    ESportEventTableTab.InProgress,
    ESportEventTableTab.Summarizing,
    ESportEventTableTab.SummingUp,
    ESportEventTableTab.Completed,
    ESportEventTableTab.Cancelled,
  ],
  [ESportEventType.SportsFest]: [
    ESportEventTableTab.All,
    ESportEventTableTab.Draft,
    ESportEventTableTab.Announced,
    ESportEventTableTab.WaitingForStart,
    ESportEventTableTab.InProgress,
    ESportEventTableTab.Summarizing,
    ESportEventTableTab.SummingUp,
    ESportEventTableTab.Completed,
    ESportEventTableTab.Cancelled,
  ],
  [ESportEventType.ActivityEvent]: [
    ESportEventTableTab.All,
    ESportEventTableTab.Draft,
    ESportEventTableTab.WaitingForStart,
    ESportEventTableTab.InProgress,
    ESportEventTableTab.Completed,
    ESportEventTableTab.Cancelled,
  ],
};

export const getSportEventTableTabsByTypes = (types: ESportEventType[]): ESportEventTableTab[] => {
  const typeArrays = types.map(type => sportEventTableTabsByTypes[type]);
  return _.union(...typeArrays);
};
