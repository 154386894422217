import { RootState } from '@/data/store/store';
import { MpCustomer } from '@/domain';
import { createContext } from 'react';
import { ECustomerActionType } from '../types';

export interface CustomerActionsSelectors {
  readonly getIsCustomerProcessingSelector: (id: UUID) => (store: RootState) => boolean;
  readonly getIsCustomerExecutedActionsSelector: (
    id: UUID,
    ...actions: ECustomerActionType[]
  ) => (store: RootState) => Nullable<ECustomerActionType>;
  readonly isCustomersAnyChangedSelector: (store: RootState) => number;
}

export type CustomerActionsContextType = {
  readonly onActivate: (customer: MpCustomer) => Promise<boolean>;
  readonly onDeactivate: (customer: MpCustomer) => Promise<boolean>;
  readonly onSendProfilesQuery: (customer: MpCustomer) => Promise<boolean>;

  readonly utils: {
    readonly selectors: CustomerActionsSelectors;
  };
};

const CustomerActionsContext = createContext<CustomerActionsContextType>({} as CustomerActionsContextType);

export default CustomerActionsContext;
