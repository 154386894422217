import { MpCustomer } from '@/domain';
import { useCustomerActions } from '@features/customer/actions/useActions';
import { CustomerLifeCycle } from '@features/customer/lifecycle/types';
import { ECustomersFilterItem } from '@features/customer/table/filterUtils';
import { CustomerActions, CustomerActionTableType, ECustomerActionType } from '@features/customer/types';
import { getCustomerTableActions } from '@features/customer/utils/actions';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useDataTableFilterAdapter, { DataFilterAdapter } from '../../../../hooks/useDataFilterAdapter';
import CustomerTable from '../component';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  customersCustomersSelector,
  customersFilterSelector,
  customersGuidSelector,
  customersSortColumnSelector,
  customersSortDirectionSelector,
} from '../store/selectors';
import { CustomerActionTableCall, ECustomerTableColumn } from '../utils';

interface CustomerTableAdapterProps {
  readonly lifecycle: CustomerLifeCycle;
  readonly filterAdapter: DataFilterAdapter<ECustomerTableColumn, ECustomersFilterItem>;
  readonly tabActions: CustomerActions<CustomerActionTableType>;
  readonly onCustomerClick: (customer: MpCustomer) => void;
}

export const CustomerTableAdapter = (props: CustomerTableAdapterProps) => {
  const { lifecycle, filterAdapter, tabActions, onCustomerClick } = props;

  const handlers = useContextHandlers();

  const guid = useSelector(customersGuidSelector);
  const customers = useSelector(customersCustomersSelector);

  const sortColumn = useSelector(customersSortColumnSelector);
  const sortDirection = useSelector(customersSortDirectionSelector);
  const filter = useSelector(customersFilterSelector);

  const {
    utils: {
      selectors: { isCustomersAnyChangedSelector },
    },
  } = useCustomerActions();

  const needRefresh = useSelector(isCustomersAnyChangedSelector);

  const onCustomerAction = (call: CustomerActionTableCall) => {
    const { action, customer } = call;
    switch (action) {
      case ECustomerActionType.Activate:
        handlers.onCustomerActivate(customer);
        break;
      case ECustomerActionType.Deactivate:
        handlers.onCustomerDeactivate(customer);
        break;
      case ECustomerActionType.SendProfileQuery:
        handlers.onCustomerSendProfileQuery(customer);
        break;
      default:
        break;
    }
  };

  const { metadata, onChangeMetadata } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: handlers.onChangeFilter,
    onSortReset: handlers.onResetSort,
  });

  useEffect(() => {
    if (needRefresh > 0) {
      handlers.onRefresh();
    }
  }, [needRefresh, handlers.onRefresh]);

  return (
    <CustomerTable
      metadata={metadata}
      sort={{ column: sortColumn, direction: sortDirection }}
      customers={customers}
      getActions={customer => getCustomerTableActions(lifecycle, customer, tabActions)}
      onRequestSort={handlers.onChangeSort}
      onChangeMetadata={onChangeMetadata}
      onCustomerAction={onCustomerAction}
      onCustomerClick={onCustomerClick}
    />
  );
};
