import { UserFieldTypes, UserInfoProxy, userInfoView, UserTypeProxy } from 'presentation/utils/userInfoView';
import { ReactNode } from 'react';

type UserFullNameProps<U extends UserTypeProxy> = {
  readonly user?: Nullable<UserFieldTypes<U>>;
  readonly field: keyof UserInfoProxy<U>;
  readonly empty?: ReactNode;
};

export const UserFieldView = <U extends UserTypeProxy>(props: UserFullNameProps<U>) => {
  const { user, field, empty = '-' } = props;

  if (!user) return empty;

  const value = userInfoView(user)[field] || empty;

  return <>{value}</>;
};
