import { Checkbox, Chip } from '@mui/material';
import debounce from 'lodash/debounce';
import { useCallback, useState } from 'react';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import {
  DataFilterStrategyAutocompleteMultiple,
  DataFilterStrategyListItem,
} from '../../../../../../domain/model/filter';
import { Nullable } from '../../../../../../domain/model/types';
import { MPAutocompleteMultipleSelect } from '../../../../../theme/ui-kit/autocomplete/multiple';
import { DataFilterEditItemProps } from '../index';

type ValueType<T extends string> = DataFilterStrategyAutocompleteMultiple<T>['value'];

interface DataFilterEditItemAutocompleteMultipleProps<T extends string>
  extends DataFilterEditItemProps<T, Nullable<ValueType<T>>> {
  readonly strategy: DataFilterStrategyAutocompleteMultiple<T>;
}

function DataFilterEditItemAutocompleteMultiple<T extends string>(
  props: DataFilterEditItemAutocompleteMultipleProps<T>
) {
  const { strategy, onChange } = props;

  const { label, value, readOnly, source } = strategy;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<Nullable<DataFilterStrategyListItem[]>>(null);

  const handlerSearch = debounce(newValue => search(newValue), 500);

  const onSubmitValue = (newValue: Nullable<DataFilterStrategyListItem[]>) => {
    onChange(strategy, newValue && newValue.length === 0 ? null : newValue);
  };

  const search = (searchQuery: string, force?: boolean) => {
    //загружаем только если force=true или есть текст для поиска + не было предзагрузки
    if (force || (!strategy.loadOnOpening && searchQuery)) {
      setIsLoading(true);
      setOptions(null);
      source(searchQuery)
        .then(setOptions)
        .catch(e => {
          console.error(e);
          ErrorHandler.handleHttpError(e, e.response);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleOpen = useCallback(() => {
    if (strategy.loadOnOpening) {
      search('', true);
    }

    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <MPAutocompleteMultipleSelect<DataFilterStrategyListItem>
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      value={value ?? []}
      options={options ?? []}
      label={label}
      disabled={readOnly}
      isLoading={isLoading}
      noOptionsText={!isLoading && options === null && 'начните ввод для поиска...'}
      disableCloseOnSelect
      onChangeValue={onSubmitValue}
      renderOption={(renderProps, option, { selected }) => (
        <li {...renderProps}>
          <Checkbox
            color='primary'
            checked={selected}
          />
          {option.name}
        </li>
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            component={'div' as any}
            selected
            label={option.name}
            color='primary'
            {...getTagProps({ index })}
            key={option.id}
          />
        ))
      }
      onSearchValue={handlerSearch}
    />
  );
}

export default DataFilterEditItemAutocompleteMultiple;
