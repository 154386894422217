import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { Pageable, Team, Nullable, UUID } from '@/domain';
import { PaginationSize } from '../../../../types';
import { TeamTableFilterValues } from '../../filterUtils';
import teamServices from '../../services';
import { AllProps } from '../../services/common';
import { ETeamTableColumn } from '../../table/utils';
import { SearchedTeam, TeamSelectRestriction } from '@features/team/search/types';
import { teamsSearchSelectRestrictionsSelector } from '@features/team/search/store/selectors';

const defaultSort = `${ETeamTableColumn.Name},asc`;

export type TeamsSearchFetchProps = Omit<AllProps, 'signal'>;

export const teamsSearchFetch = createAsyncThunk<Pageable<Team>, TeamsSearchFetchProps, AppThunkAPIConfig>(
  'team/search/fetch',
  async (payload, { getState, rejectWithValue, signal }) => {
    try {
      const state = getState();
      const selectRestrictions = teamsSearchSelectRestrictionsSelector(state);

      const result = await teamServices.common.all({ ...payload, signal });
      return {
        ...result,
        data: result.data.map(team => {
          const selectRestriction = selectRestrictions.find(s => s.id === team.id);
          return {
            ...team,
            notSelectableReason: selectRestriction && (selectRestriction.reason ?? 'Выбор невозможен'),
          };
        }),
      };
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);

      return rejectWithValue(e.response.data);
    }
  }
);

export interface TeamSearchState extends Fetchable, Pageable<SearchedTeam> {
  readonly guid: Nullable<UUID>;
  readonly needRefreshWatcher: number;
  readonly pageNumber: number;
  readonly search: {
    readonly sort: string;
    readonly pageSize: PaginationSize;
  };
  readonly filter: TeamTableFilterValues;
  readonly selected: SearchedTeam[];
  readonly selectRestrictions: TeamSelectRestriction[];
}

type Reducer<T = undefined> = CaseReducer<TeamSearchState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<TeamSearchState> {
  teamsSearchStartSession: Reducer<{ guid: UUID; selected: Team[]; selectRestrictions: TeamSelectRestriction[] }>;
  teamsSearchSetSearch: Reducer<{ sort: string; pageSize: PaginationSize }>;
  teamsSearchSortReset: Reducer;
  teamsSearchSetPage: Reducer<{ pageNumber: number }>;
  teamsSearchSelect: Reducer<Team>;
  teamsSearchAllSelect: Reducer<Nullable<number>>;
  teamsSearchAllUnselect: Reducer;
  teamsSearchDataReset: Reducer;
  teamsSearchSetFilter: Reducer<TeamTableFilterValues>;
  teamsSearchSetPageSize: Reducer<{ pageSize: PaginationSize }>;
  teamsSearchSetSort: Reducer<{ sort: string }>;
  teamsSearchUnselect: Reducer<Team>;
  teamsSearchNeedRefreshWatcherReset: Reducer;
}

const slice = createSlice<TeamSearchState, Reducers, 'search'>({
  name: 'search',
  initialState: {
    ...fetchableDefault,
    guid: null,
    selected: [],
    data: [],
    selectRestrictions: [],
    totalCount: 0,
    pageCount: 0,
    pageNumber: 1,
    needRefreshWatcher: 0,
    search: {
      sort: defaultSort,
      pageSize: 15,
    },
    filter: {},
  },
  reducers: {
    teamsSearchStartSession: (state, { payload }) => {
      const { guid, selected, selectRestrictions } = payload;
      state.guid = guid;

      state.isFetching = false;
      state.isFetched = false;
      state.isFailed = false;

      state.data = [];
      state.totalCount = 0;
      state.pageCount = 0;
      state.pageNumber = 1;
      state.needRefreshWatcher = 0;
      state.search = {
        sort: defaultSort,
        pageSize: 10,
      };
      state.filter = {};

      state.selected = selected.map(team => {
        const selectRestriction = selectRestrictions.find(s => s.id === team.id);
        return {
          ...team,
          notSelectableReason: selectRestriction && (selectRestriction.reason ?? 'Выбор невозможен'),
        };
      });
      state.selectRestrictions = selectRestrictions;
    },
    teamsSearchSetSearch: (state, { payload }) => {
      const { sort, pageSize } = payload;

      state.search = {
        ...state.search,
        sort,
        pageSize,
      };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    teamsSearchSetPage: (state, { payload }) => {
      const { pageNumber } = payload;
      state.pageNumber = pageNumber;
      state.needRefreshWatcher++;
    },
    teamsSearchSortReset: state => {
      state.search = {
        ...state.search,
        sort: defaultSort,
      };
      state.pageNumber = 1;
      state.selected = [];
      state.needRefreshWatcher++;
    },
    teamsSearchSelect: (state, { payload }) => {
      const selected = [...state.selected];
      const existedIndex = selected.findIndex(s => s.id === payload.id);

      if (existedIndex !== -1) {
        selected.splice(existedIndex, 1);
      }

      state.selected = [...selected, payload];
    },
    teamsSearchAllSelect: (state, { payload }) => {
      console.log(payload);
      if (payload) {
        state.selected = [...state.data.filter(item => !item.notSelectableReason).slice(0, payload)];
      } else {
        state.selected = state.data.filter(item => !item.notSelectableReason);
      }
    },
    teamsSearchAllUnselect: state => {
      state.selected = [];
    },
    teamsSearchDataReset: state => {
      state.data = [];
      state.totalCount = 0;
      state.pageCount = 0;
      state.pageNumber = 1;
      state.selected = [];
    },
    teamsSearchSetFilter: (state, { payload }) => {
      state.filter = payload;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    teamsSearchSetPageSize: (state, { payload }) => {
      const { pageSize } = payload;

      state.pageNumber = 1;
      state.search.pageSize = pageSize;
      state.needRefreshWatcher++;
    },
    teamsSearchSetSort: (state, { payload }) => {
      const { sort } = payload;

      state.search.sort = sort;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
    },
    teamsSearchUnselect: (state, { payload }) => {
      const existedIndex = state.selected.findIndex(s => s.id === payload.id);

      if (existedIndex !== -1) {
        state.selected.splice(existedIndex, 1);
      }
    },
    teamsSearchNeedRefreshWatcherReset: state => {
      state.needRefreshWatcher = 0;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(teamsSearchFetch.pending, state => {
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;
      })
      .addCase(teamsSearchFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;

        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;
        state.data = data;
        state.totalCount = totalCount;
        state.pageCount = pageCount;
      })
      .addCase(teamsSearchFetch.rejected, state => {
        state.isFetching = false;
        state.isFetched = false;
        state.isFailed = true;
        state.data = [];
      });
  },
});

export const {
  teamsSearchSetSearch,
  teamsSearchSetPage,
  teamsSearchSelect,
  teamsSearchStartSession,
  teamsSearchAllSelect,
  teamsSearchAllUnselect,
  teamsSearchDataReset,
  teamsSearchSetFilter,
  teamsSearchSetPageSize,
  teamsSearchSetSort,
  teamsSearchSortReset,
  teamsSearchUnselect,
  teamsSearchNeedRefreshWatcherReset,
} = slice.actions;

export default slice.reducer;
