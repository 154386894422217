import { ClientOrg } from '@/domain/model/clientOrg';
import { fileTypes } from '@/presentation/constants';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClientOrgUserFileData, ClientOrgUserImportDialog } from '../components/importDialog';
import {
  clientOrgUserActionsDialogImportSelector,
  getClientOrgUserActionsUserIsProcessingSelector,
} from '../store/selectors';
import { useClientOrgUserActions } from '../useActions';

export const ClientOrgUserActionsDialogImportAdapter = () => {
  const dispatch = useDispatch();
  const handlers = useClientOrgUserActions();

  const object = useSelector(clientOrgUserActionsDialogImportSelector);

  const [logUrl, setLogUrl] = useState<Nullable<string>>(null);

  const isProcessing = useSelector(getClientOrgUserActionsUserIsProcessingSelector(null));

  const onCloseDialog = useCallback(() => handlers.onChangeDialogState('import', null), [handlers.onChangeDialogState]);

  const onImport = useCallback(
    async (clientOrg: ClientOrg, data: ClientOrgUserFileData) => {
      const result = await handlers.onImport({
        clientOrgId: clientOrg.id,
        file: data.file,
      });

      setLogUrl(result);
    },
    [dispatch, handlers.onImport]
  );

  useEffect(() => {
    if (!object && logUrl !== null) {
      setLogUrl(null);
    }

    if (logUrl && object) {
      onCloseDialog();
      const a = document.createElement('a');
      a.href = logUrl;
      a.download = `log_import_${object.name}${fileTypes.xls.ext}`;

      document.body.appendChild(a);
      a.click();

      // Удаление ссылки из документа
      setTimeout(() => {
        URL.revokeObjectURL(logUrl);
        document.body.removeChild(a);
      }, 0);
    }
  }, [object, logUrl]);

  return (
    object && (
      <ClientOrgUserImportDialog
        clientOrg={object}
        isFetching={isProcessing}
        onClose={onCloseDialog}
        onImport={onImport}
      />
    )
  );
};
