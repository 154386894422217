import { ECmsCollectionLinkObjectType, EOfferCategoryType, EOfferStatus, Nullable, OfferCategory } from '@/domain';
import CmsCategoryMultipleCollectionComponent from '@features/cms/components/collection/categories/multiple';
import { ECollectionSearchDialogTag } from '@features/cms/container/create/collection/types';
import useCmsCollectionCategoryMultiple from '@features/cms/container/create/collection/useChangeCategoriesMultiple';
import { CmsFeatureContainerCommonProps } from '@features/cms/container/types';
import { CmsLinkedObjectCollectionTypes } from '@features/cms/types/collection';
import { CorpOfferCategoriesSelectTopPushContainer } from '@features/dictionary/corpOfferCategory/select/containerTopPush';
import { TradeOfferCategoriesSelectTopPushContainer } from '@features/dictionary/tradeOfferCategory/select/containerTopPush';
import useDialogInHistory from 'presentation/hooks/useDialogInHistory';

interface CmsContainerCreateStepBannerCategory extends CmsFeatureContainerCommonProps {
  readonly categories: Nullable<OfferCategory[]>;
  readonly statuses?: EOfferStatus[];
  readonly linkObjectType:
    | ECmsCollectionLinkObjectType.TradeOfferCategory
    | ECmsCollectionLinkObjectType.CorpOfferCategory;
  readonly onChangeLinkedObject: (collection: CmsLinkedObjectCollectionTypes) => void;
}

const CmsCategoryMultipleCollectionContainer = (props: CmsContainerCreateStepBannerCategory) => {
  const { cmsContext, categories, statuses, linkObjectType, onChangeLinkedObject } = props;

  const {
    open: isSearchDialogOpened,
    onOpen: onOpenSearchDialog,
    onClose: onCloseSearchDialog,
  } = useDialogInHistory({ tag: ECollectionSearchDialogTag.Category, params: {} });

  const { categoryType, onSelect, onDelete, onClear } = useCmsCollectionCategoryMultiple({
    selected: categories,
    linkObjectType,
    onChangeLinkedObject,
    onCloseSearchDialog,
  });

  return (
    <>
      <CmsCategoryMultipleCollectionComponent
        categoryType={categoryType}
        categories={categories ?? []}
        onShowSearch={onOpenSearchDialog}
        onClear={onClear}
        onDelete={onDelete}
      />

      {categoryType === EOfferCategoryType.Corp && (
        <CorpOfferCategoriesSelectTopPushContainer
          onlyUsed
          statuses={statuses}
          partnerId={cmsContext?.partner?.id}
          selected={categories}
          open={isSearchDialogOpened}
          levelSelectFrom={0}
          onClose={selected => {
            if (selected) {
              onSelect(selected);
            }
            onCloseSearchDialog();
          }}
        />
      )}

      {categoryType === EOfferCategoryType.Trade && (
        <TradeOfferCategoriesSelectTopPushContainer
          onlyUsed
          statuses={statuses}
          partnerId={cmsContext?.partner?.id}
          selected={categories}
          open={isSearchDialogOpened}
          levelSelectFrom={0}
          onClose={selected => {
            if (selected) {
              onSelect(selected);
            }
            onCloseSearchDialog();
          }}
        />
      )}
    </>
  );
};

export default CmsCategoryMultipleCollectionContainer;
