import { UserFieldView } from '@components/common/userInfoView';
import AdminMpTableCell, { AdminMpTableCellCommonProps } from './index';

const AdminMpTableCellPhone = (props: AdminMpTableCellCommonProps) => {
  const { admin } = props;

  return (
    <AdminMpTableCell
      {...props}
      value={
        <UserFieldView
          user={admin}
          field={'phone'}
        />
      }
    />
  );
};

export default AdminMpTableCellPhone;
