import { ClientOrg } from '@/domain';
import ActionsComponent from '@components/common/actions';
import { DefaultFooterWrapper } from '@components/common/wrappers/footer';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import TablePagination from '../../../../components/common/table/pagination';
import { ClientOrgUserLifeCycle } from '../lifecycle/types';
import {
  ClientOrgUserAction,
  ClientOrgUserActions,
  ClientOrgUserActionTableCollectionType,
  ClientOrgUserActionTableType,
  ClientOrgUserCollectionActionPayload,
  EClientOrgUserActionType,
} from '../types';
import useClientOrgUserCollectionActions from './hooks/useCollectionActions';
import { useContextHandlers } from './hooks/useContextHandlers';
import {
  clientOrgUsersPageCountSelector,
  clientOrgUsersPageNumberSelector,
  clientOrgUsersSearchSelector,
  clientOrgUsersSelectedSelector,
  clientOrgUsersTotalCountSelector,
} from './store/selectors';

interface ClientOrgUserTableFooterContainerProps {
  readonly clientOrg: ClientOrg;
  readonly lifecycle: ClientOrgUserLifeCycle;
  readonly allowedActions: ClientOrgUserActions<ClientOrgUserActionTableType>;
}

const ClientOrgUserTableFooterContainer = forwardRef(
  ({ clientOrg, lifecycle, allowedActions }: ClientOrgUserTableFooterContainerProps, ref: any) => {
    const handlers = useContextHandlers();

    const pageNumber = useSelector(clientOrgUsersPageNumberSelector);
    const search = useSelector(clientOrgUsersSearchSelector);
    const totalCount = useSelector(clientOrgUsersTotalCountSelector);
    const pageCount = useSelector(clientOrgUsersPageCountSelector);

    const selectedClientOrgUsers = useSelector(clientOrgUsersSelectedSelector);

    const onPanelAction = (
      action: ClientOrgUserAction<ClientOrgUserActionTableCollectionType, ClientOrgUserCollectionActionPayload>
    ) => {
      const { type, payload } = action;
      const clientOrgUsers = payload?.clientOrgUsers ?? [];
      switch (type) {
        case EClientOrgUserActionType.ResendWelcomeMail:
          const clientOrgUsersResendWelcomeMail = clientOrgUsers.filter(clientOrgUser =>
            lifecycle
              .build({
                obj: clientOrgUser,
                allowedActions,
              })
              .can(EClientOrgUserActionType.ResendWelcomeMail)
          );
          handlers.onClientOrgUserResendWelcomeMail(clientOrg, clientOrgUsersResendWelcomeMail);
          break;
      }
    };

    const collectionActions = useClientOrgUserCollectionActions({
      lifecycle,
      clientOrgUsers: selectedClientOrgUsers,
      allowedActions,
    });

    const actionsPanel = collectionActions.length > 0 && (
      <ActionsComponent<ClientOrgUserActionTableCollectionType>
        actions={collectionActions.map(action => ({ ...action, payload: { clientOrgUsers: selectedClientOrgUsers } }))}
        onAction={onPanelAction}
        navAdapter={null}
      />
    );

    return (
      <div ref={ref}>
        <TablePagination
          page={pageNumber}
          pageSize={search.pageSize}
          pageCount={pageCount}
          objectsCount={totalCount}
          objectsLabel='пользователей'
          onChangePageSize={handlers.onChangePageSize}
          onChangePage={handlers.onChangePage}
        />
        {actionsPanel && <DefaultFooterWrapper>{actionsPanel}</DefaultFooterWrapper>}
      </div>
    );
  }
);

export default ClientOrgUserTableFooterContainer;
