import { TradeOfferCategoriesSelectDictionaryAdapter } from './adapters';
import { TradeOfferCategoriesSelectCommonProps } from './types';

export type TradeOfferCategoriesSelectContainerProps = TradeOfferCategoriesSelectCommonProps;

const TradeOfferCategoriesSelectContainer = (props: TradeOfferCategoriesSelectContainerProps) => {
  const { access, maxCount, levelSelectFrom } = props;

  return (
    <TradeOfferCategoriesSelectDictionaryAdapter
      access={access}
      maxCount={maxCount}
      levelSelectFrom={levelSelectFrom}
    />
  );
};

export default TradeOfferCategoriesSelectContainer;
