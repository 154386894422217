import { useMemo } from 'react';
import { AddressLocalityFullPathView } from '../../../address';
import TeamTableCell, { TeamTableCellCommonProps } from './index';

const TeamTableCellLocality = (props: TeamTableCellCommonProps) => {
  const {
    team: { locality, notSelectableReason: disabled },
  } = props;

  const value = useMemo(() => <AddressLocalityFullPathView address={locality} />, [locality]);

  return (
    <TeamTableCell
      {...props}
      disabled={!!disabled}
      value={value}
    />
  );
};

export default TeamTableCellLocality;
