import { EDateFormat, EDateTimeFormat, EPeriod, MOSCOW_TIME_ZONE, Nullable } from '@/domain';
import moment, { Moment } from 'moment-timezone';

export const getPastPeriodDates = (period: EPeriod): [Moment, Moment] => {
  const end = moment().endOf('day');

  switch (period) {
    case EPeriod.Week:
      return [moment().add(-1, 'week').add(1, 'day').startOf('day'), end];
    case EPeriod.Month:
      return [moment().add(-1, 'month').add(1, 'day').startOf('day'), end];
    case EPeriod.Quarter:
      return [moment().add(-1, 'quarter').add(1, 'day').startOf('day'), end];
    case EPeriod.Year:
      return [moment().add(-1, 'year').add(1, 'day').startOf('day'), end];
  }
};

export const getHumanDateFormat = (date: Nullable<string>) =>
  !date || moment(date).year() === moment().year() ? EDateFormat.HumanShort : EDateFormat.Human;

export const utcToLocalTimeZone = (dateTime: string | Moment): Moment => moment.utc(dateTime).local();

export const utcToMoscowTimeZone = (dateTime: string | Moment): Moment => moment.utc(dateTime).tz(MOSCOW_TIME_ZONE);

export const moscowTimeZoneToUtc = (dateTime: string | Moment): Moment => moment.tz(dateTime, MOSCOW_TIME_ZONE).utc();

export const formatDateHuman = (date: Moment): string =>
  date && (date.year() === moment().year() ? date.format(EDateFormat.HumanShort) : date.format(EDateFormat.Human));

export const formatDateTimeHuman = (date: Moment): string =>
  date &&
  (date.year() === moment().year() ? date.format(EDateTimeFormat.HumanShort) : date.format(EDateTimeFormat.Human));

export const convertSecondsToHumanView = (seconds: number) => {
  if (seconds === 24 * 60 * 60) {
    return 'сутки';
  }

  return `${seconds} секунд`;
};
