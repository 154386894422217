import {
  Tournament,
  TournamentClassificationResults,
  TournamentClassificationResultsRequest,
  TournamentResults,
  TournamentResultsRequest,
} from '@/domain';
import {
  SportEventFieldCommonProps,
  SportEventFieldViewProps,
  SportEventViewStep,
  TournamentViewStepType,
} from '../../types';

export enum ETournamentViewUiOption {
  ApplicationsVisible = 'applicationsVisible',
  ChatVisible = 'chatVisible',
  ChatEdit = 'chatEdit',
  ResultsVisible = 'resultsVisible',
}

export type TournamentViewAccess = {
  readonly canEdit?: boolean;
  readonly canCreate?: boolean;
  readonly canCancel?: boolean;
  readonly canEditResults?: boolean;
  readonly canPublishResults?: boolean;
  readonly canEditMembers?: boolean;
};

export type TournamentViewStep = SportEventViewStep<TournamentViewStepType>;

export interface TournamentViewUiState {
  readonly steps: TournamentViewStep[];
  readonly options: ETournamentViewUiOption[];
}

export type TournamentViewFieldCommonProps<Key extends keyof Tournament> = SportEventFieldViewProps<Tournament, Key>;

export type TournamentViewFieldResultsProps<Key extends keyof TournamentResults> = SportEventFieldViewProps<
  TournamentResults,
  Key
>;

export type TournamentViewFieldClassificationResultsProps<Key extends keyof TournamentClassificationResults> =
  SportEventFieldViewProps<TournamentClassificationResults, Key>;

export type TournamentEditFieldResultsProps<Key extends keyof TournamentResultsRequest> = SportEventFieldCommonProps<
  TournamentResultsRequest,
  Key
>;

export type TournamentEditFieldClassificationResultsProps<Key extends keyof TournamentClassificationResultsRequest> =
  SportEventFieldCommonProps<TournamentClassificationResultsRequest, Key>;

export enum ETournamentViewApplicationsTabs {
  All = 'ALL',
  New = 'NEW',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
}

export type TournamentViewApplicationsTabsCounter = {
  [name in ETournamentViewApplicationsTabs]?: number;
};
