import { UserFieldView } from '@components/common/userInfoView';
import CorpActivationTableCell, { CorpActivationTableCellCommonProps } from './index';

const CorpActivationTableCellCustomerPernr = (props: CorpActivationTableCellCommonProps) => {
  const {
    corpActivation: { customer },
  } = props;

  return (
    <CorpActivationTableCell
      {...props}
      value={
        <UserFieldView
          user={customer}
          field={'pernr'}
        />
      }
    />
  );
};

export default CorpActivationTableCellCustomerPernr;
