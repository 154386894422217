import { ECmsLinkObjectType, ENoticeStatus } from '@/domain';
import { useMemo, useState } from 'react';
import Notifier from '../../../../../system/notifier';
import useValidationMasterSteps from '../../../../hooks/validation/useValidationMasterSteps';
import { ValidationResult, ValidationRules } from '../../../../utils/validation';
import { CmsContainerView, CmsLinkedObject, CmsLinkedObjectsTypes } from '../../types';
import { CmsContainerStep } from '../types';
import { getCmsContainerViewValidationRules, getCmsLinkedObjectValidationRules } from './validation';

type CmsContainerTypeToValidation = CmsContainerView;

type CmsLinkObjectsTypeToValidation = Nullable<CmsLinkedObjectsTypes>;

type TypeToValidation = [container: CmsContainerTypeToValidation, ...linkedObjects: CmsLinkObjectsTypeToValidation[]];

type UseCmsContainerCreateValidation = {
  readonly isValidGeneral: boolean;
  readonly isValidCmsLinkedObject: boolean;
  readonly validationResultGeneral: Nullable<ValidationResult<CmsContainerTypeToValidation>>;
  readonly validationResultLinkedObject: Nullable<ValidationResult<CmsLinkObjectsTypeToValidation>>;
  readonly validateDraft: () => boolean;
  readonly validateCurrentStep: () => boolean;
  readonly validateAll: (onChangeStep: (step: CmsContainerStep) => void) => boolean;
};

interface UseCmsContainerCreateValidationProps {
  readonly currentStepIndex: number;
  readonly cmsContainer: CmsContainerView;
  readonly cmsLinkedObjects: CmsLinkedObject[];
}

const useCmsContainerCreateValidation = (
  props: UseCmsContainerCreateValidationProps
): UseCmsContainerCreateValidation => {
  const { currentStepIndex, cmsContainer, cmsLinkedObjects } = props;

  const [validateOnChange, setValidateOnChange] = useState<boolean>(false);

  const cmsContainerToValidation = useMemo<TypeToValidation>(
    () => [
      cmsContainer,
      ...cmsLinkedObjects.map(lo => {
        switch (lo.type) {
          case ECmsLinkObjectType.Banner:
            return lo.banner;
          case ECmsLinkObjectType.Collection:
            return lo.collection;
          case null:
            return null;
        }
      }),
    ],
    [cmsContainer, cmsLinkedObjects]
  );

  const cmsLinkedObjectsValidationRules: ValidationRules<CmsLinkedObjectsTypes>[] = useMemo(
    () => cmsLinkedObjects.map(cmsLinkedObject => getCmsLinkedObjectValidationRules(cmsLinkedObject)),
    [cmsLinkedObjects]
  );

  const cmsContainerViewValidationRules: ValidationRules<CmsContainerView> = useMemo(
    () => getCmsContainerViewValidationRules(cmsContainer),
    [cmsContainer.type?.code]
  );

  const { getValidationResultByIndex, validateCurrent, validate, validateAll, isValid } =
    useValidationMasterSteps<TypeToValidation>({
      currentIndex: currentStepIndex,
      data: cmsContainerToValidation,
      validateOnChange,
      getRules: stepIndex => [cmsContainerViewValidationRules, ...cmsLinkedObjectsValidationRules][stepIndex] as any, //todo upgrade,
    });

  const validationResultGeneral = getValidationResultByIndex(0);
  const validationResultLinkedObject = useMemo(
    () => (currentStepIndex !== 0 ? getValidationResultByIndex(currentStepIndex) : null),
    [currentStepIndex, getValidationResultByIndex]
  );

  const isValidGeneral = isValid(0);
  const isValidCmsLinkedObject = currentStepIndex > 0 ? isValid(currentStepIndex) : true;

  const validateGeneral = () => {
    const result = validate(0);
    if (!result) {
      setValidateOnChange(true);
    } else {
      setValidateOnChange(false);
    }
    return result;
  };

  const validateCurrentStepInternal = () => {
    const result = validateCurrent();
    if (!result) {
      setValidateOnChange(true);
    } else {
      setValidateOnChange(false);
    }
    return result;
  };

  const validateAllInternal = (onChangeStep: (step: CmsContainerStep) => void) => {
    const result = validateAll();
    if (!result.isValid) {
      setValidateOnChange(true);
      Notifier.getInstance().addNotice(ENoticeStatus.Error, 'Обнаружены ошибки при заполнении');
      const firstNotNull = result?.results?.find(item => item !== null);

      if (firstNotNull) {
        const indexStep = result.results.indexOf(firstNotNull);
        if (indexStep !== -1) {
          onChangeStep({
            key: indexStep,
            label: '',
          });
        }
      }
    } else {
      setValidateOnChange(false);
    }
    return result.isValid;
  };

  return {
    isValidGeneral,
    isValidCmsLinkedObject,
    validationResultGeneral,
    validationResultLinkedObject,
    validateDraft: validateGeneral,
    validateCurrentStep: validateCurrentStepInternal,
    validateAll: validateAllInternal,
  };
};
export default useCmsContainerCreateValidation;
