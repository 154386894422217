import { UserAvatar } from '@components/common/avatars';
import { SportsmanTableCellCommonProps } from './index';

const SportsmanTableCellLogo = (props: SportsmanTableCellCommonProps) => {
  const { sportsman: value } = props;

  return (
    <UserAvatar
      size='small'
      user={value}
    />
  );
};

export default SportsmanTableCellLogo;
