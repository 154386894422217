/*
 * простые условия
 */
import { CustomerLifeCycleCondition } from '../types';

export const isCustomerPersonnelNumberEmpty: CustomerLifeCycleCondition = {
  description: 'Проверка табельного номера пользователя',
  algorithm: ['Табельный номер пользователя не заполнен'],
  call: ({ obj }) => {
    const { pernr } = obj;

    return !pernr;
  },
};
