import { UserFieldView } from '@components/common/userInfoView';
import { Divider, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Tooltip } from '@mui/material';
import React from 'react';
import { TeamMember, ETeamMemberType } from '@/domain';
import { UserAvatar } from '@components/common/avatars';
import { Captain as CaptainIcon } from '../../../../../icons';
import { List, Wrapper } from './controls';

interface TeamTabMembersProps {
  readonly members: TeamMember[];
  readonly onMemberClick: (member: TeamMember) => void;
}

const TeamTabMembers = ({ members, onMemberClick }: TeamTabMembersProps) => {
  return (
    <Wrapper>
      <List>
        {members.map((member, index, array) => {
          return (
            <React.Fragment key={member.id}>
              <ListItem
                button
                alignItems='center'
                onClick={() => onMemberClick(member)}
              >
                <ListItemAvatar>
                  <UserAvatar user={member.userProfile} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <UserFieldView
                      user={member.userProfile}
                      field={'fullNameReverse'}
                    />
                  }
                  secondary={member.locality?.name}
                />
                {member.memberType === ETeamMemberType.TeamLeader && (
                  <ListItemSecondaryAction>
                    <Tooltip
                      title='Капитан команды'
                      arrow
                    >
                      <CaptainIcon color='primary' />
                    </Tooltip>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
              {array.length - 1 !== index && (
                <Divider
                  key={`divider${member.id}`}
                  variant='inset'
                />
              )}
            </React.Fragment>
          );
        })}
      </List>
    </Wrapper>
  );
};

export default TeamTabMembers;
