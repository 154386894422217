import { DataTableLoader } from '@components/common/table/loader';
import { ESortDirection, Team } from '@/domain';
import DataTable, { DataTableMetadata } from '../../../components/common/table';
import TeamTableCellActivityTypes from '../components/tableCell/activityTypes';
import TeamTableCellCaptain from '../components/tableCell/captain';
import TeamTableCellLocality from '../components/tableCell/locality';
import TeamTableCellLogo from '../components/tableCell/logo';
import TeamTableCellName from '../components/tableCell/name';
import TeamsTableCellAdapter from './adapters/cell';
import { Wrapper } from './controls';
import { teamsIsFetchingSelector } from './store/selectors';
import { ETeamTableColumn } from './utils';

interface TeamsTableProps {
  readonly metadata: DataTableMetadata<ETeamTableColumn>;
  readonly teams: Team[];
  readonly sort: {
    readonly column: string;
    readonly direction: ESortDirection;
  };
  readonly onRequestSort: (column: string, direction: ESortDirection) => void;
  readonly onClick: (team: Team) => void;
}

function TeamsTable(props: TeamsTableProps) {
  const { metadata, sort, teams, onRequestSort, onClick } = props;

  const rows = teams.map((team, index) => ({
    [ETeamTableColumn.Logo]: (
      <TeamsTableCellAdapter
        index={index}
        component={TeamTableCellLogo}
      />
    ),
    [ETeamTableColumn.Name]: (
      <TeamsTableCellAdapter
        index={index}
        component={TeamTableCellName}
      />
    ),
    [ETeamTableColumn.ActivityTypes]: (
      <TeamsTableCellAdapter
        index={index}
        component={TeamTableCellActivityTypes}
      />
    ),
    [ETeamTableColumn.Locality]: (
      <TeamsTableCellAdapter
        index={index}
        component={TeamTableCellLocality}
      />
    ),
    [ETeamTableColumn.Captain]: (
      <TeamsTableCellAdapter
        index={index}
        component={TeamTableCellCaptain}
      />
    ),
    data: team,
  }));

  return (
    <Wrapper>
      <DataTable<Team, ETeamTableColumn>
        metadata={metadata}
        rows={rows}
        sort={{ column: sort.column, direction: sort.direction }}
        loader={<DataTableLoader selector={teamsIsFetchingSelector} />}
        onRowClick={(event, cell, { data }) => onClick(data)}
        onSort={(event, column, direction) => onRequestSort(column, direction)}
      />
    </Wrapper>
  );
}

export default TeamsTable;
