import { MpCustomer, UserAccessPartitionCustomers } from '@/domain';
import { CustomerLifeCycle } from '@features/customer/lifecycle/types';
import { EPanelActionPosition } from 'presentation/types';
import {
  CustomerAction,
  CustomerActionDetailsType,
  CustomerActions,
  CustomerActionTableType,
  ECustomerActionType,
} from '../types';

export const removeCustomerAction = (actions: CustomerActions, type: ECustomerActionType) => {
  const index = actions.findIndex(action => action.type === type);
  if (index !== -1) {
    actions.splice(index, 1);
  }
};

/**
 * Получение экшенов для таблицы
 * @param lifecycle
 * @param customer
 * @param allowedActions
 */
export const getCustomerTableActions = (
  lifecycle: CustomerLifeCycle,
  customer: MpCustomer,
  allowedActions: CustomerActions<CustomerActionTableType>
) => {
  return lifecycle.build({
    obj: customer,
    allowedActions,
  }).nextActions as CustomerActions<CustomerActionTableType>;
};

export type GetCustomerActionsByPermissionsProps = UserAccessPartitionCustomers;

export const getCustomerActionName = (type: ECustomerActionType): string => {
  switch (type) {
    case ECustomerActionType.Activate:
      return 'Включить';
    case ECustomerActionType.Deactivate:
      return 'Отключить';
    case ECustomerActionType.SendProfileQuery:
      return 'Обновить данные по АСУТР';
  }
};

// получение разрешенных действий с покупателем с учетом разрешений
export const getCustomerActionsConfigByPermissions = (
  props: GetCustomerActionsByPermissionsProps
): CustomerActions<ECustomerActionType> => {
  const { changeStatus, sendProfileQuery } = props;

  const actions: CustomerActions<ECustomerActionType> = [];

  let onActivate: Nullable<CustomerAction<ECustomerActionType>> = null;
  let onDeactivate: Nullable<CustomerAction<ECustomerActionType>> = null;
  let onSendProfileQuery: Nullable<CustomerAction<ECustomerActionType>> = null;

  if (changeStatus) {
    onActivate = {
      type: ECustomerActionType.Activate,
      disabled: false,
      label: getCustomerActionName(ECustomerActionType.Activate),
      position: [EPanelActionPosition.Menu],
    };
    actions.push(onActivate);
  }

  if (changeStatus) {
    onDeactivate = {
      type: ECustomerActionType.Deactivate,
      disabled: false,
      label: getCustomerActionName(ECustomerActionType.Deactivate),
      position: [EPanelActionPosition.Menu],
    };
    actions.push(onDeactivate);
  }

  if (sendProfileQuery) {
    onSendProfileQuery = {
      type: ECustomerActionType.SendProfileQuery,
      disabled: false,
      label: getCustomerActionName(ECustomerActionType.SendProfileQuery),
      position: [EPanelActionPosition.Menu],
    };
    actions.push(onSendProfileQuery);
  }

  return actions;
};

export const filterCustomerDetailsActions = (actions: CustomerActions): CustomerActions<CustomerActionDetailsType> => {
  const detailsActions: CustomerActions<CustomerActionDetailsType> = [];

  actions.forEach(action => {
    switch (action.type) {
      case ECustomerActionType.Activate:
      case ECustomerActionType.Deactivate:
        detailsActions.push({
          type: action.type,
          disabled: action.disabled,
          label: action.label,
          position: action.position,
        });
        break;
      case ECustomerActionType.SendProfileQuery:
        break;
    }
  });

  return detailsActions;
};
