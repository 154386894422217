import { Address } from '../../../domain/model/address';
import { ESportEventApplicationStatus, ESportEventStatus } from '../../../domain/model/enums';
import {
  ActivityEvent,
  ActivityEventRequest,
  AnySportEvent,
  SportContestData,
  SportEvent,
  SportsFest,
  SportsFestCombinedTeamRequest,
  SportsFestRequest,
  SportsFestResultsRequest,
  Tournament,
  TournamentClassificationResults,
  TournamentClassificationResultsRequest,
  TournamentIndividualGenderResultsRequest,
  TournamentIndividualResults,
  TournamentIndividualResultsRequest,
  TournamentRequest,
  TournamentResults,
  TournamentResultsRequest,
} from '../../../domain/model/event';
import { Nullable, UUID } from '../../../domain/model/types';
import { MPStepperStep } from '../../components/common/stepper';
import { OnChangeObjectAttribute, PanelAction, PanelActions } from '../../types';
import { ValidationItemResult } from '../../utils/validation';
import { ETournamentApplicationState } from './tournamentApplication/types';

export enum EEventUrlParam {
  Step = 'step',
  Type = 'type',
}

export enum EEventsUrlParam {
  Tab = 'tab',
}

export enum EEventCreateUrlParam {
  Id = 'id',
  Guid = 'guid',
  Type = 'type',
}

export enum EEventStep {
  General = 'general',
  Details = 'details',
  Applications = 'applications',
  Results = 'results',
  Chat = 'chat',
  Requirements = 'requirements',
  Invites = 'invites',
  Members = 'members',
  Contests = 'contests',
}

export enum ESportsFestMembersColumn {
  Name = 'name',
  Status = 'status',
  ResponsibleUser = 'responsibleUser',
}

export type SportsFestCreateStepType =
  | EEventStep.General
  | EEventStep.Details
  | EEventStep.Contests
  | EEventStep.Requirements
  | EEventStep.Members;

export type TournamentCreateStepType =
  | EEventStep.General
  | EEventStep.Details
  | EEventStep.Requirements
  | EEventStep.Invites;

export type TournamentIndividualCreateStepType = TournamentCreateStepType;

export type ActivityEventViewStepType = EEventStep.General;

export type ActivityEventCreateStepType = EEventStep.General | EEventStep.Details;

export type TournamentEditStepType = EEventStep.General | EEventStep.Details;

export type TournamentViewStepType =
  | EEventStep.General
  | EEventStep.Applications
  | EEventStep.Results
  | EEventStep.Chat;

export type TournamentIndividualViewStepType = TournamentViewStepType;

export type SportsFestViewStepType = EEventStep.General | EEventStep.Members | EEventStep.Results | EEventStep.Chat;

export type SportsFestEditStepType = EEventStep.General;

export type ActivityEventEditStepType = EEventStep.General;

export type SportEventCreateStep<T extends TournamentCreateStepType | SportsFestCreateStepType> = MPStepperStep<T>;
export type SportEventViewStep<T extends TournamentViewStepType | SportsFestViewStepType> = MPStepperStep<T>;
export type SportEventEditStep<T extends TournamentEditStepType | SportsFestEditStepType> = MPStepperStep<T>;

export enum ESportEventActionType {
  Save = 'save',
  Publish = 'publish',
  PublishAnnounce = 'publishAnnounce',
  Cancel = 'cancel',
  SaveResults = 'saveResults',
  PublishResults = 'publishResults',
  Edit = 'edit',
  InviteTeams = 'inviteTeams',
  InviteSportsmans = 'inviteSportsmans',
  CreateApplicationsTeam = 'createApplicationsTeam',
  CreateApplicationsSportsman = 'createApplicationsSportsman',
}

export type SportEventActionCreateType =
  | ESportEventActionType.Save
  | ESportEventActionType.Publish
  | ESportEventActionType.PublishAnnounce;

export type TournamentActionCreateType = SportEventActionCreateType;

export type SportsFestActionCreateType = SportEventActionCreateType;

export type ActivityEventActionCreateType = Exclude<SportEventActionCreateType, ESportEventActionType.PublishAnnounce>;

export type SportEventActionViewType =
  | ESportEventActionType.Cancel
  | ESportEventActionType.SaveResults
  | ESportEventActionType.PublishResults
  | ESportEventActionType.Edit;

export type TournamentActionViewType = SportEventActionViewType | ESportEventActionType.InviteTeams | ESportEventActionType.CreateApplicationsTeam;

export type TournamentIndividualActionViewType = SportEventActionViewType | ESportEventActionType.InviteSportsmans | ESportEventActionType.CreateApplicationsSportsman;

export type SportsFestActionViewType = SportEventActionViewType;

export type ActivityEventActionViewType = Exclude<
  SportEventActionViewType,
  ESportEventActionType.SaveResults | ESportEventActionType.PublishResults
>;

export type ActionSportEventType =
  | SportEvent
  | SportsFestRequest
  | SportsFest
  | TournamentRequest
  | Tournament
  | ActivityEvent
  | ActivityEventRequest;

export type SportEventAction<A extends string = ESportEventActionType> = PanelAction<A>;

export type SportEventActions<A extends string = ESportEventActionType> = PanelActions<A>;

export interface EventApplicationsFilter {
  readonly search: Nullable<string>;
  readonly locality: Nullable<Address>;
  readonly status: Nullable<ESportEventApplicationStatus>;
  readonly sort: string;
}

export interface ApplicationState {
  readonly id: UUID;
  readonly state: ETournamentApplicationState;
}

export interface EventApplicationsFilterState {
  readonly search: Nullable<string>;
  readonly locality: Nullable<Address>;
  readonly status: Nullable<ESportEventApplicationStatus>;
  readonly sort: string;
}

type SportEventTypesForField =
  | AnySportEvent
  | TournamentResults
  | TournamentIndividualResults
  | TournamentClassificationResults
  | TournamentResultsRequest
  | TournamentIndividualResultsRequest
  | TournamentIndividualGenderResultsRequest
  | SportsFestResultsRequest
  | SportsFestCombinedTeamRequest
  | TournamentClassificationResultsRequest;

export interface SportEventFieldCommonProps<T extends SportEventTypesForField, Key extends keyof T = any> {
  readonly value: T[Key];
  readonly disabled?: boolean;
  readonly validation: Nullable<ValidationItemResult>;
  readonly onChangeAttribute: OnChangeObjectAttribute<T>;
  readonly onValidateAttribute: (name: keyof T) => void;
}

export type SportEventCounterByStatus = {
  [name in ESportEventStatus]?: number;
};

export interface SportEventFieldViewProps<T extends SportEventTypesForField, Key extends keyof T = any> {
  readonly value: T[Key];
}

export type SportContestViewFieldCommonProps<Key extends keyof (Tournament | SportsFest)> = SportEventFieldViewProps<
  Tournament | SportsFest,
  Key
>;

export type SportContestCreateFieldCommonProps<
  T extends SportContestData,
  Key extends keyof T
> = SportEventFieldCommonProps<T, Key>;
