import {
  EAdCampaignStatus,
  EAdCampaignType,
  EClientOrgStatus,
  ECmsBannerLinkObjectType,
  ECmsContainerStatus,
  EComplaintObjectType,
  EComplaintStatus,
  ECorpPersonalPromotionType,
  EExternalCorpOfferType,
  EMPUserGroup,
  EOfferStatus,
  EOfferType,
  EPartnerOwnershipType,
  EPartnerType,
  EPaymentType,
  EPlaygroundModerationStatus,
  EPrivilegeActorType,
  EProductCountStatus,
  EServiceOrderByDateType,
  ESocialPackageAsutrStatus,
  ESportEventApplicationRejectReason,
  ESportEventApplicationStatus,
  ESportEventClassificationType,
  ESportEventParticipationFormat,
  ETaxationSystemType,
  ETradeOfferType,
  EUserGender,
  EUserType,
  SportOptionTyped,
  TradePaymentType,
} from '@/domain';

export const complaintType: Record<EComplaintObjectType, SportOptionTyped<EComplaintObjectType>> = {
  [EComplaintObjectType.Team]: {
    id: EComplaintObjectType.Team,
    name: 'Команда',
  },
  [EComplaintObjectType.User]: {
    id: EComplaintObjectType.User,
    name: 'Спортсмен',
  },
  [EComplaintObjectType.Community]: {
    id: EComplaintObjectType.Community,
    name: 'Сообщества',
  },
  [EComplaintObjectType.Tournament]: {
    id: EComplaintObjectType.Tournament,
    name: 'Турнир',
  },
  [EComplaintObjectType.SportsFest]: {
    id: EComplaintObjectType.SportsFest,
    name: 'Спартакиада',
  },
  [EComplaintObjectType.ActivityEvent]: {
    id: EComplaintObjectType.ActivityEvent,
    name: 'Событие',
  },
  [EComplaintObjectType.FriendlyMatch]: {
    id: EComplaintObjectType.FriendlyMatch,
    name: 'Товарищеский матч',
  },
  [EComplaintObjectType.Chat]: {
    id: EComplaintObjectType.Chat,
    name: 'Чат',
  },
  [EComplaintObjectType.Playground]: {
    id: EComplaintObjectType.Playground,
    name: 'Площадка',
  },
};
export const tradeOfferType: { [name in ETradeOfferType]: SportOptionTyped<ETradeOfferType> } = {
  [ETradeOfferType.PublicPromoCode]: {
    id: ETradeOfferType.PublicPromoCode,
    name: 'Промокод общий',
  },
  [ETradeOfferType.PromoCode]: {
    id: ETradeOfferType.PromoCode,
    name: 'Уникальный промокод',
  },
  [ETradeOfferType.AccessCode]: {
    id: ETradeOfferType.AccessCode,
    name: 'Индивидуальный код доступа',
  },
  [ETradeOfferType.Voucher]: {
    id: ETradeOfferType.Voucher,
    name: 'Ваучер',
  },
  [ETradeOfferType.ReferralLink]: {
    id: ETradeOfferType.ReferralLink,
    name: 'Реферальная ссылка',
  },
  [ETradeOfferType.Widget]: {
    id: ETradeOfferType.Widget,
    name: 'Виджет',
  },
  [ETradeOfferType.Asp]: {
    id: ETradeOfferType.Asp,
    name: 'АСП Сертификат',
  },
  [ETradeOfferType.DiGift]: {
    id: ETradeOfferType.DiGift,
    name: 'АСП Digift',
  },
};

export const tradePaymentType: { [name in TradePaymentType]: SportOptionTyped<TradePaymentType> } = {
  [EPaymentType.AspRzdRub]: {
    id: EPaymentType.AspRzdRub,
    name: 'Рубль АСП',
  },
  [EPaymentType.Free]: {
    id: EPaymentType.Free,
    name: 'Без оплаты',
  },
  [EPaymentType.CorpCurrency]: {
    id: EPaymentType.CorpCurrency,
    name: 'Бонусы',
  },
};

export const corpPersonalPromotionType: {
  [name in ECorpPersonalPromotionType]: SportOptionTyped<ECorpPersonalPromotionType>;
} = {
  [ECorpPersonalPromotionType.ExternalCertificate]: {
    id: ECorpPersonalPromotionType.ExternalCertificate,
    name: 'Сервисный портал работника РЖД',
  },
  [ECorpPersonalPromotionType.Certificate]: {
    id: ECorpPersonalPromotionType.Certificate,
    name: 'Витрина',
  },
};

export const externalCorpOfferType: {
  [name in EExternalCorpOfferType]: SportOptionTyped<EExternalCorpOfferType>;
} = {
  [EExternalCorpOfferType.Fitness]: {
    id: EExternalCorpOfferType.Fitness,
    name: 'Фитнес',
  },
  [EExternalCorpOfferType.Spa]: {
    id: EExternalCorpOfferType.Spa,
    name: 'Санаторно-курортный отдых',
  },
  [EExternalCorpOfferType.Child]: {
    id: EExternalCorpOfferType.Child,
    name: 'Детский отдых',
  },
};

export const taxationSystemType: { [name in ETaxationSystemType]: SportOptionTyped<ETaxationSystemType> } = {
  [ETaxationSystemType.OSNO]: {
    id: ETaxationSystemType.OSNO,
    name: 'ОСНО (общая система налогообложения)',
  },
  [ETaxationSystemType.USN]: {
    id: ETaxationSystemType.USN,
    name: 'УСН (упрощённая система налогообложения)',
  },
};

export const userGender: { [name in EUserGender]: SportOptionTyped<EUserGender> } = {
  [EUserGender.Male]: {
    id: EUserGender.Male,
    name: 'Мужской',
  },
  [EUserGender.Female]: {
    id: EUserGender.Female,
    name: 'Женский',
  },
};

export const eventClassification: {
  [name in ESportEventClassificationType]: SportOptionTyped<ESportEventClassificationType>;
} = {
  [ESportEventClassificationType.Male]: {
    id: ESportEventClassificationType.Male,
    name: 'Мужской',
  },
  [ESportEventClassificationType.Female]: {
    id: ESportEventClassificationType.Female,
    name: 'Женский',
  },
  [ESportEventClassificationType.Mixed]: {
    id: ESportEventClassificationType.Mixed,
    name: 'Смешанный',
  },
};

export const complaintStatus: { [name in EComplaintStatus]: SportOptionTyped<EComplaintStatus> } = {
  [EComplaintStatus.Rejected]: {
    id: EComplaintStatus.Rejected,
    name: 'Отклонено',
  },
  [EComplaintStatus.Accepted]: {
    id: EComplaintStatus.Accepted,
    name: 'Одобрено',
  },
  [EComplaintStatus.UnderConsideration]: {
    id: EComplaintStatus.UnderConsideration,
    name: 'На рассмотрении',
  },
  [EComplaintStatus.New]: {
    id: EComplaintStatus.New,
    name: 'Новая',
  },
};
export const productCountStatus: { [name in EProductCountStatus]: SportOptionTyped<EProductCountStatus> } = {
  [EProductCountStatus.InStock]: {
    id: EProductCountStatus.InStock,
    name: 'В наличии',
  },
  [EProductCountStatus.WillEndSoon]: {
    id: EProductCountStatus.WillEndSoon,
    name: 'Скоро закончится',
  },
  [EProductCountStatus.OutOfStock]: {
    id: EProductCountStatus.OutOfStock,
    name: 'Закончился',
  },
};
export const playgroundModerationStatus: {
  [name in EPlaygroundModerationStatus]: SportOptionTyped<EPlaygroundModerationStatus>;
} = {
  [EPlaygroundModerationStatus.Draft]: {
    id: EPlaygroundModerationStatus.Draft,
    name: 'Ожидает подтверждения',
  },
  [EPlaygroundModerationStatus.Active]: {
    id: EPlaygroundModerationStatus.Active,
    name: 'Активна',
  },
  [EPlaygroundModerationStatus.Blocked]: {
    id: EPlaygroundModerationStatus.Blocked,
    name: 'Площадка заблокирована',
  },
};

export const partnerOwnershipType: { [name in EPartnerOwnershipType]: SportOptionTyped<EPartnerOwnershipType> } = {
  [EPartnerOwnershipType.IndividualEntrepreneur]: {
    id: EPartnerOwnershipType.IndividualEntrepreneur,
    name: 'Индивидуальный предприниматель',
    code: 'ИП',
  },
  [EPartnerOwnershipType.LimitedLiabilityCompany]: {
    id: EPartnerOwnershipType.LimitedLiabilityCompany,
    name: 'Общество с ограниченной ответственностью',
    code: 'ООО',
  },
  [EPartnerOwnershipType.StockCompany]: {
    id: EPartnerOwnershipType.StockCompany,
    name: 'Акционерное общество',
    code: 'АО',
  },
  [EPartnerOwnershipType.PublicJoinStockCompany]: {
    id: EPartnerOwnershipType.PublicJoinStockCompany,
    name: 'Публичное акционерное общество',
    code: 'ПАО',
  },
  [EPartnerOwnershipType.PK]: {
    id: EPartnerOwnershipType.PK,
    name: 'Потребительский кооператив',
    code: 'ПК',
  },
  [EPartnerOwnershipType.UP]: {
    id: EPartnerOwnershipType.UP,
    name: 'Унитарное предприятие',
    code: 'УП',
  },
  [EPartnerOwnershipType.FGBNU]: {
    id: EPartnerOwnershipType.FGBNU,
    name: 'Федеральное государственное бюджетное научное учреждение',
    code: 'ФГБНУ',
  },
  [EPartnerOwnershipType.NonProfitOrganization]: {
    id: EPartnerOwnershipType.NonProfitOrganization,
    name: 'Некоммерческая организация',
    code: 'НКО',
  },
  [EPartnerOwnershipType.ClosedJoinStockCompany]: {
    id: EPartnerOwnershipType.ClosedJoinStockCompany,
    name: 'Закрытое акционерное общество',
    code: 'ЗАО',
  },
  [EPartnerOwnershipType.JoinStockCompany]: {
    id: EPartnerOwnershipType.JoinStockCompany,
    name: 'Открытое акционерное общество',
    code: 'ОАО',
  },
  [EPartnerOwnershipType.NAO]: {
    id: EPartnerOwnershipType.NAO,
    name: 'Непубличное акционерное общество',
    code: 'НАО',
  },
  [EPartnerOwnershipType.AutonomousNonProfitOrganization]: {
    id: EPartnerOwnershipType.AutonomousNonProfitOrganization,
    name: 'Автономная некоммерческая организация',
    code: 'АНО',
  },
};
export const userType: { [name in EUserType]: SportOptionTyped<EUserType> } = {
  [EUserType.Any]: {
    id: EUserType.Any,
    name: 'Все',
  },
  [EUserType.Corporate]: {
    id: EUserType.Corporate,
    name: 'Только работники ОАО «РЖД»',
  },
};

export const userMpGroup: { [name in EMPUserGroup]: SportOptionTyped<EMPUserGroup> } = {
  [EMPUserGroup.All]: {
    id: EMPUserGroup.All,
    name: 'Все пользователи',
  },
  [EMPUserGroup.RZD]: {
    id: EMPUserGroup.RZD,
    name: 'Сотрудники ОАО РЖД',
  },
  [EMPUserGroup.External]: {
    id: EMPUserGroup.External,
    name: 'Внешние пользователи',
  },
  [EMPUserGroup.Corp]: {
    id: EMPUserGroup.Corp,
    name: 'Компании-клиенты',
  },
  [EMPUserGroup.Guest]: {
    id: EMPUserGroup.Guest,
    name: 'Гости',
  },
};

export const eventApplicationStatus: {
  [name in ESportEventApplicationStatus]: SportOptionTyped<ESportEventApplicationStatus>;
} = {
  [ESportEventApplicationStatus.New]: {
    id: ESportEventApplicationStatus.New,
    name: 'На рассмотрении',
  },
  [ESportEventApplicationStatus.Accepted]: {
    id: ESportEventApplicationStatus.Accepted,
    name: 'Принято',
  },
  [ESportEventApplicationStatus.Rejected]: {
    id: ESportEventApplicationStatus.Rejected,
    name: 'Отказано',
  },
  [ESportEventApplicationStatus.Cancelled]: {
    id: ESportEventApplicationStatus.Cancelled,
    name: 'Отозвано',
  },
};

export const partnerType: Record<EPartnerType, SportOptionTyped<EPartnerType>> = {
  [EPartnerType.Inner]: {
    id: EPartnerType.Inner,
    name: 'Внутренний',
  },
  [EPartnerType.External]: {
    id: EPartnerType.External,
    name: 'Внешний',
  },
};

export const privilegeActorType: Record<EPrivilegeActorType, SportOptionTyped<EPrivilegeActorType>> = {
  [EPrivilegeActorType.System]: {
    id: EPrivilegeActorType.System,
    name: 'Система',
  },
  [EPrivilegeActorType.Admin]: {
    id: EPrivilegeActorType.Admin,
    name: 'Администратор',
  },
  [EPrivilegeActorType.Partner]: {
    id: EPrivilegeActorType.Partner,
    name: 'Партнер',
  },
  [EPrivilegeActorType.User]: {
    id: EPrivilegeActorType.User,
    name: 'Пользователь',
  },
};

export const productStatus: Record<EOfferStatus, SportOptionTyped<EOfferStatus>> = {
  [EOfferStatus.Draft]: {
    id: EOfferStatus.Draft,
    name: 'Черновик',
  },
  [EOfferStatus.WaitingForModeration]: {
    id: EOfferStatus.WaitingForModeration,
    name: 'Ожидает проверки',
  },
  [EOfferStatus.OnModeration]: {
    id: EOfferStatus.OnModeration,
    name: 'На проверке',
  },
  [EOfferStatus.Rejected]: {
    id: EOfferStatus.Rejected,
    name: 'Отклонен',
  },
  [EOfferStatus.ReadyForSale]: {
    id: EOfferStatus.ReadyForSale,
    name: 'Готов к продаже',
  },
  [EOfferStatus.Active]: {
    id: EOfferStatus.Active,
    name: 'Продается',
  },
  [EOfferStatus.Paused]: {
    id: EOfferStatus.Paused,
    name: 'Приостановлен',
  },
  [EOfferStatus.Archived]: {
    id: EOfferStatus.Archived,
    name: 'В архиве',
  },
  [EOfferStatus.Upcoming]: {
    id: EOfferStatus.Upcoming,
    name: 'Одобрено',
  },
};

export const offerStatus: Record<EOfferStatus, SportOptionTyped<EOfferStatus>> = {
  [EOfferStatus.Active]: {
    id: EOfferStatus.Active,
    name: 'Активно',
  },
  [EOfferStatus.Archived]: {
    id: EOfferStatus.Archived,
    name: 'В архиве',
  },
  [EOfferStatus.Draft]: {
    id: EOfferStatus.Draft,
    name: 'Черновик',
  },
  [EOfferStatus.OnModeration]: {
    id: EOfferStatus.OnModeration,
    name: 'На модерации',
  },
  [EOfferStatus.Paused]: {
    id: EOfferStatus.Paused,
    name: 'Приостановлено',
  },
  [EOfferStatus.Rejected]: {
    id: EOfferStatus.Rejected,
    name: 'Отклонено',
  },
  [EOfferStatus.Upcoming]: {
    id: EOfferStatus.Upcoming,
    name: 'Одобрено',
  },
  [EOfferStatus.WaitingForModeration]: {
    id: EOfferStatus.WaitingForModeration,
    name: 'Ожидает модерацию',
  },
  [EOfferStatus.ReadyForSale]: {
    id: EOfferStatus.ReadyForSale,
    name: 'Готов к продаже',
  },
};

export const cmsContainerStatus: Record<ECmsContainerStatus, SportOptionTyped<ECmsContainerStatus>> = {
  [ECmsContainerStatus.Active]: {
    id: ECmsContainerStatus.Active,
    name: 'Активно',
  },
  [ECmsContainerStatus.Archived]: {
    id: ECmsContainerStatus.Archived,
    name: 'В архиве',
  },
  [ECmsContainerStatus.Draft]: {
    id: ECmsContainerStatus.Draft,
    name: 'Черновик',
  },
  [ECmsContainerStatus.Paused]: {
    id: ECmsContainerStatus.Paused,
    name: 'Приостановлено',
  },
  [ECmsContainerStatus.Upcoming]: {
    id: ECmsContainerStatus.Upcoming,
    name: 'Одобрено',
  },
};

export const offerType: Record<EOfferType, SportOptionTyped<EOfferType>> = {
  [EOfferType.Corp]: {
    id: EOfferType.Corp,
    name: 'Корпоративные предложения',
  },
  [EOfferType.Trade]: {
    id: EOfferType.Trade,
    name: 'Торговые предложения',
  },
  [EOfferType.Product]: {
    // todo  - проверить чтобы ничего не сломалось
    id: EOfferType.Product,
    name: 'Товарные предложения',
  },
  [EOfferType.Booking]: {
    // todo  - проверить чтобы ничего не сломалось
    id: EOfferType.Booking,
    name: 'Досуг и отдых',
  },
};

export const eventApplicationRejectReason: {
  [name in ESportEventApplicationRejectReason]: SportOptionTyped<ESportEventApplicationRejectReason>;
} = {
  [ESportEventApplicationRejectReason.Requirement]: {
    id: ESportEventApplicationRejectReason.Requirement,
    name: 'Не соответствует требованиям',
  },
  [ESportEventApplicationRejectReason.Other]: {
    id: ESportEventApplicationRejectReason.Other,
    name: 'Другое',
  },
};

export const cmsBannerLinkedObjectType: Record<ECmsBannerLinkObjectType, SportOptionTyped<ECmsBannerLinkObjectType>> = {
  [ECmsBannerLinkObjectType.TradeOffer]: {
    id: ECmsBannerLinkObjectType.TradeOffer,
    name: 'Карточку торгового предложения',
  },
  [ECmsBannerLinkObjectType.CorpOffer]: {
    id: ECmsBannerLinkObjectType.CorpOffer,
    name: 'Карточку корпоративного предложения',
  },
  [ECmsBannerLinkObjectType.CorpOfferCategory]: {
    id: ECmsBannerLinkObjectType.CorpOfferCategory,
    name: 'Категорию',
  },
  [ECmsBannerLinkObjectType.TradeOfferCategory]: {
    id: ECmsBannerLinkObjectType.TradeOfferCategory,
    name: 'Категорию',
  },
  [ECmsBannerLinkObjectType.Collection]: {
    id: ECmsBannerLinkObjectType.Collection,
    name: 'Страницу подборки',
  },
  [ECmsBannerLinkObjectType.Link]: {
    id: ECmsBannerLinkObjectType.Link,
    name: 'Внешний ресурс',
  },
};

export const serviceOrderByDateType: {
  [name in EServiceOrderByDateType]: SportOptionTyped<EServiceOrderByDateType>;
} = {
  [EServiceOrderByDateType.Period]: {
    id: EServiceOrderByDateType.Period,
    name: 'Можно выбрать период',
  },
  [EServiceOrderByDateType.Days]: {
    id: EServiceOrderByDateType.Days,
    name: 'Можно выбрать по дням',
  },
  [EServiceOrderByDateType.None]: {
    id: EServiceOrderByDateType.None,
    name: 'Нельзя выбрать даты',
  },
};

export const eventParticipationFormats: {
  [name in ESportEventParticipationFormat]: SportOptionTyped<ESportEventParticipationFormat>;
} = {
  [ESportEventParticipationFormat.Online]: {
    id: ESportEventParticipationFormat.Online,
    name: 'Онлайн (дистанционный)',
  },
  [ESportEventParticipationFormat.Offline]: {
    id: ESportEventParticipationFormat.Offline,
    name: 'Офлайн',
  },
};

export const adCampaignStatus: Record<EAdCampaignStatus, SportOptionTyped<EAdCampaignStatus>> = {
  [EAdCampaignStatus.Active]: {
    id: EAdCampaignStatus.Active,
    name: 'Активно',
  },
  [EAdCampaignStatus.Archived]: {
    id: EAdCampaignStatus.Archived,
    name: 'В архиве',
  },
  [EAdCampaignStatus.Paused]: {
    id: EAdCampaignStatus.Paused,
    name: 'Приостановлено',
  },
  [EOfferStatus.Upcoming]: {
    id: EAdCampaignStatus.Upcoming,
    name: 'Предстоящее',
  },
};

export const adCampaignType: Record<EAdCampaignType, SportOptionTyped<EAdCampaignType>> = {
  [EAdCampaignType.Search]: {
    id: EAdCampaignType.Search,
    name: 'Поиск',
  },
  [EAdCampaignType.Catalog]: {
    id: EAdCampaignType.Catalog,
    name: 'Каталог',
  },
};

export const clientOrgStatus: Record<EClientOrgStatus, SportOptionTyped<EClientOrgStatus>> = {
  [EClientOrgStatus.Active]: {
    id: EClientOrgStatus.Active,
    name: 'Активно',
  },
  [EClientOrgStatus.Blocked]: {
    id: EClientOrgStatus.Blocked,
    name: 'Приостановлено',
  },
};

export const socialPackageAsutrStatus: Record<
  ESocialPackageAsutrStatus,
  SportOptionTyped<ESocialPackageAsutrStatus>
> = {
  [ESocialPackageAsutrStatus.Appointed]: {
    id: ESocialPackageAsutrStatus.Appointed,
    name: `${ESocialPackageAsutrStatus.Appointed} - назначено КСП`,
  },
  [ESocialPackageAsutrStatus.Approved]: {
    id: ESocialPackageAsutrStatus.Approved,
    name: `${ESocialPackageAsutrStatus.Approved} - КСП утверждено (работник согласился)`,
  },
  [ESocialPackageAsutrStatus.RejectedByDisciplinaryAction]: {
    id: ESocialPackageAsutrStatus.RejectedByDisciplinaryAction,
    name: `${ESocialPackageAsutrStatus.RejectedByDisciplinaryAction} - отказ по дисц. взысканию`,
  },
  [ESocialPackageAsutrStatus.RejectedByMaternityLeave]: {
    id: ESocialPackageAsutrStatus.RejectedByMaternityLeave,
    name: `${ESocialPackageAsutrStatus.RejectedByMaternityLeave} - отказ по декрету`,
  },
  [ESocialPackageAsutrStatus.RejectedByDismissalAfterCsp]: {
    id: ESocialPackageAsutrStatus.RejectedByDismissalAfterCsp,
    name: `${ESocialPackageAsutrStatus.RejectedByDismissalAfterCsp} - отказ по причине увольнения работника`,
  },
  [ESocialPackageAsutrStatus.RejectedByDismissalBeforeCsp]: {
    id: ESocialPackageAsutrStatus.RejectedByDismissalBeforeCsp,
    name: `${ESocialPackageAsutrStatus.RejectedByDismissalBeforeCsp} - отказ по причине увольнения работника до выдачи извещения`,
  },
  [ESocialPackageAsutrStatus.TransferredToAnotherBranch]: {
    id: ESocialPackageAsutrStatus.TransferredToAnotherBranch,
    name: `${ESocialPackageAsutrStatus.TransferredToAnotherBranch} - перевод работника в другой филиал`,
  },
  [ESocialPackageAsutrStatus.TransferredToPositionWithoutCsp]: {
    id: ESocialPackageAsutrStatus.TransferredToPositionWithoutCsp,
    name: `${ESocialPackageAsutrStatus.TransferredToPositionWithoutCsp} - перевод работника на должность без КСП`,
  },
  [ESocialPackageAsutrStatus.RejectedByOthersReason]: {
    id: ESocialPackageAsutrStatus.RejectedByOthersReason,
    name: `${ESocialPackageAsutrStatus.RejectedByOthersReason} - отказ по прочим причинам`,
  },
  [ESocialPackageAsutrStatus.RejectedByEmployee]: {
    id: ESocialPackageAsutrStatus.RejectedByEmployee,
    name: `${ESocialPackageAsutrStatus.RejectedByEmployee} - отказ работника от КСП`,
  },
};
