import { ECmsCollectionLinkObjectType, EOfferCategoryType, Nullable, OfferCategory } from '@/domain';
import { CmsLinkedObjectCollectionTypes } from '@features/cms/types/collection';
import { useCallback } from 'react';

type UseCmsCollectionCategoryMultiple = {
  readonly categoryType: EOfferCategoryType;
  readonly onSelect: (categories: Nullable<OfferCategory[]>) => void;
  readonly onDelete: (category: OfferCategory) => void;
  readonly onClear: () => void;
};

type UseCmsCollectionCategoryMultipleProps = {
  readonly selected: Nullable<OfferCategory[]>;
  readonly linkObjectType:
    | ECmsCollectionLinkObjectType.TradeOfferCategory
    | ECmsCollectionLinkObjectType.CorpOfferCategory;
  readonly onCloseSearchDialog: () => void;
  readonly onChangeLinkedObject: (collection: CmsLinkedObjectCollectionTypes) => void;
};

const useCmsCollectionCategoryMultiple = (
  props: UseCmsCollectionCategoryMultipleProps
): UseCmsCollectionCategoryMultiple => {
  const { selected, linkObjectType, onCloseSearchDialog, onChangeLinkedObject } = props;

  const categoryType =
    linkObjectType === ECmsCollectionLinkObjectType.CorpOfferCategory
      ? EOfferCategoryType.Corp
      : EOfferCategoryType.Trade;

  const onSelect = useCallback(
    (categories: Nullable<OfferCategory[]>) => {
      onChangeLinkedObject({ linkObjectType, linkedObject: categories });
      onCloseSearchDialog();
    },
    [linkObjectType, onChangeLinkedObject, onCloseSearchDialog]
  );

  const onDelete = useCallback(
    (offerCategory: OfferCategory) => {
      onChangeLinkedObject({
        linkObjectType,
        linkedObject: selected?.filter(category => category.id !== offerCategory.id) ?? null,
      });
    },
    [linkObjectType, selected, onChangeLinkedObject]
  );

  const onClear = useCallback(() => {
    onChangeLinkedObject({
      linkObjectType,
      linkedObject: null,
    });
  }, [linkObjectType, onChangeLinkedObject]);

  return {
    categoryType,
    onSelect,
    onDelete,
    onClear,
  };
};

export default useCmsCollectionCategoryMultiple;
