import { Grid } from '@mui/material';
import { Team, Nullable, SportUserProfileShort, AppFile, FileDescription, SportOption } from '@/domain';
import { CoverImageEdit } from '@components/common/images/cover';
import { filesUploadLimit, imageFileMaxSize } from '@components/common/images/utils';
import { userInfoView } from 'presentation/utils/userInfoView';
import ContentLoader from '../../../../components/common/loader';
import { FormItem } from '@components/forms';
import { MPButton } from '@ui-kit/button';
import { MPFormInput, MPFormTextArea } from '@ui-kit/input';
import { OnChangeObjectAttribute } from '../../../../types';
import ActivityTypeEditControlTreeMultipleContainer from '../../../activityType/editControl/treeMultiple';
import { AddressLocalitySingleSelector } from '../../../address';
import { convertSportUserProfileInSportUserProfileShort } from '../../../event/utils/common';
import SportsmanEditControlContainer from '../../../sportsman/editControls/dialogControl';
import { teamNameMaxLength, teamNicknameMaxLength } from '../../utils';
import { Content, Footer } from './controls';

interface TeamCreateContentProps {
  readonly team: Team;
  readonly errors: { [key: string]: string | boolean };
  readonly isSaving: boolean;
  readonly onChangeAttribute: OnChangeObjectAttribute<Team>;
  readonly onChangeCaptain: (captain: Nullable<SportUserProfileShort>) => void;
  readonly onSave: () => void;
}

const TeamCreateContent = ({
  team,
  errors,
  isSaving,
  onChangeAttribute,
  onChangeCaptain,
  onSave,
}: TeamCreateContentProps) => {
  const onAddImage = (image: AppFile) => {
    const media: FileDescription[] = [...(team.media ?? [])];

    media.unshift({ path: image.path });
    onChangeAttribute('media', media);
  };

  // Ограничение на загрузку медиа файлов
  const isUploadDisabled = team.media?.length >= filesUploadLimit;

  const onDeleteImage = (image: SportOption) => {
    const media: FileDescription[] = [...(team.media ?? [])];
    const index = media.findIndex(item => item.path === image.id);

    if (index !== -1) {
      media.splice(index, 1);
    }

    onChangeAttribute('media', media);
  };

  const imageOptions: SportOption[] =
    team.media && team.media.length > 0
      ? team.media.map(item => ({
          id: item.path,
          name: '',
          image: item.path,
        }))
      : [];

  return (
    <>
      <Content>
        <Grid
          container
          spacing={4}
          direction='column'
        >
          <Grid item>
            <FormItem
              title='Основная информация'
              description='Заполните основную информацию о вашей команде.'
            >
              <MPFormInput
                label='Название команды'
                value={team.name}
                disabled={isSaving}
                error={!!errors.name}
                helperText={errors.name}
                inputProps={{ maxLength: teamNameMaxLength }}
                onChange={event => onChangeAttribute('name', event.target.value)}
              />

              <MPFormInput
                label='Никнейм'
                value={team.nickname}
                disabled={isSaving}
                error={!!errors.nickname}
                helperText={errors.nickname}
                inputProps={{ maxLength: teamNicknameMaxLength }}
                onChange={event => onChangeAttribute('nickname', event.target.value)}
              />

              <AddressLocalitySingleSelector
                value={team.locality}
                label='Населенный пункт'
                disabled={isSaving}
                error={!!errors.city}
                helperText={errors.city ? (errors.city as string) : null}
                onChange={locality => onChangeAttribute('locality', locality)}
              />

              <SportsmanEditControlContainer
                headerDialog={'Капитан'}
                selectedMaxCount={1}
                onChange={value => onChangeCaptain(convertSportUserProfileInSportUserProfileShort(value))}
                control={
                  <MPFormInput
                    label='Капитан'
                    value={team?.captain?.userProfile ? userInfoView(team.captain?.userProfile).fullName : null}
                    error={!!errors.captain}
                    helperText={errors.captain}
                    disabled={isSaving}
                  />
                }
              />
            </FormItem>
          </Grid>

          <Grid item>
            <FormItem
              title='Интересы'
              description='Укажите интересы, которые вас объединяют.'
            >
              <ActivityTypeEditControlTreeMultipleContainer
                filterNotValidValues
                value={team.activityTypes ?? []}
                error={!!errors?.activityTypes}
                helperText={typeof errors?.activityTypes === 'string' ? errors?.activityTypes : null}
                onChange={values => onChangeAttribute('activityTypes', values)}
              />
            </FormItem>
          </Grid>

          <Grid item>
            <FormItem
              title='Расскажите о вашей команде'
              description='Опишите в одном-двух предложениях, чем она интересуется.'
            >
              <MPFormTextArea
                rows={3}
                label='Описание'
                value={team.description}
                disabled={isSaving}
                onChange={event => onChangeAttribute('description', event.target.value)}
              />
            </FormItem>
          </Grid>

          <Grid item>
            <FormItem
              title='Медиа'
              description='Будет здорово, если вы добавите фотографии вашей команды с прошлых мероприятий, в которых она принимала участие.'
            >
              <CoverImageEdit
                text='Формат JPG или PNG'
                multiple
                disabled={isUploadDisabled}
                error={errors.media}
                value={imageOptions}
                maxSize={imageFileMaxSize}
                onAdd={onAddImage}
                onDelete={onDeleteImage}
              />
            </FormItem>
          </Grid>
        </Grid>
      </Content>
      <Footer>
        <Grid
          container
          spacing={2}
          direction='column'
        >
          <Grid item>
            <MPButton
              onClick={onSave}
              disabled={isSaving}
            >
              {isSaving && <ContentLoader />}Создать команду
            </MPButton>
          </Grid>
        </Grid>
      </Footer>
    </>
  );
};

export default TeamCreateContent;
