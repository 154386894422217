import { UserFieldView } from '@components/common/userInfoView';
import TradeActivationTableCell, { TradeActivationTableCellCommonProps } from './index';

const TradeActivationTableCellCustomerPhone = (props: TradeActivationTableCellCommonProps) => {
  const {
    tradeActivation: { customer },
  } = props;

  return (
    <TradeActivationTableCell
      {...props}
      value={
        <UserFieldView
          user={customer}
          field={'phone'}
        />
      }
    />
  );
};

export default TradeActivationTableCellCustomerPhone;
