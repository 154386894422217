import { ENoticeStatus, MpCustomer } from '@/domain';
import Notifier from '@/system/notifier';
import { userInfoView } from 'presentation/utils/userInfoView';
import { ECustomerActionType } from '../types';

export const addSuccessNotice = (text: string) => {
  Notifier.getInstance().addNotice(ENoticeStatus.Success, text);
};

export const showCustomerActionNotification = (customer: MpCustomer, action: ECustomerActionType) => {
  switch (action) {
    case ECustomerActionType.SendProfileQuery:
      addSuccessNotice(
        `Для пользователя с табельным номером ${customer.pernr} послан запрос в АСУТР на обновление профиля`
      );
      break;
    case ECustomerActionType.Deactivate:
      addSuccessNotice(`Пользователь ${userInfoView(customer).fullName} заблокирован`);
      break;
    case ECustomerActionType.Activate:
      addSuccessNotice(`Пользователь ${userInfoView(customer).fullName} разблокирован`);
      break;
  }
};
