import {
  showClientOrgUserActionNotification,
  showClientOrgUserUploadNotification,
} from '@features/user/userClientOrg/actions/utils';
import { EClientOrgUserActionType } from '@features/user/userClientOrg/types';
import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { ClientOrgUserActionsDialogsAdapter, ClientOrgUserActionsOptimizerAdapter } from './adapters';
import ClientOrgUserActionsContext, { ClientOrgUserActionsContextType } from './context';
import {
  getClientOrgUserActionsUserIsProcessingSelector,
  getIsClientOrgUserAnyActionByTypeExecutedSelector,
  isClientOrgUserAnyChangedSelector,
} from './store/selectors';
import {
  clientOrgUserActionsChangeDialogState,
  clientOrgUserActionsImport,
  clientOrgUserActionsResendWelcomeMail,
} from './store/slice';

interface ClientOrgActionsProviderProps {
  readonly children: ReactNode;
}

type ClientOrgActionsProviderType = (props: ClientOrgActionsProviderProps) => JSX.Element;

export const ClientOrgUserActionsProvider: ClientOrgActionsProviderType = ({ children }) => {
  const dispatch = useDispatch();

  const onChangeDialogState: ClientOrgUserActionsContextType['onChangeDialogState'] = (name, data) => {
    dispatch(clientOrgUserActionsChangeDialogState({ name, data }));
  };

  const onImport: ClientOrgUserActionsContextType['onImport'] = async ({ clientOrgId, file }) => {
    const response = await dispatch(clientOrgUserActionsImport({ id: clientOrgId, file })).unwrap();

    if (response) {
      showClientOrgUserUploadNotification();
    }

    return response;
  };

  const onResendWelcomeMail: ClientOrgUserActionsContextType['onResendWelcomeMail'] = async (
    clientOrg,
    clientOrgUsers
  ) => {
    const response = await dispatch(
      clientOrgUserActionsResendWelcomeMail({
        id: clientOrg.id,
        clientOrgUsers,
      })
    ).unwrap();

    if (response) {
      showClientOrgUserActionNotification(EClientOrgUserActionType.ResendWelcomeMail, clientOrgUsers);
    }

    return response;
  };

  const onTryImport: ClientOrgUserActionsContextType['onTryImport'] = clientOrg => {
    onChangeDialogState('import', clientOrg);
  };

  return (
    <ClientOrgUserActionsContext.Provider
      value={{
        onImport,
        onResendWelcomeMail,
        onTryImport,

        onChangeDialogState,
        utils: {
          selectors: {
            getIsClientOrgUserExecutedActionsSelector: getIsClientOrgUserAnyActionByTypeExecutedSelector,
            getIsClientOrgUserProcessingSelector: getClientOrgUserActionsUserIsProcessingSelector,
            isClientOrgUsersAnyChangedSelector: isClientOrgUserAnyChangedSelector,
          },
        },
      }}
    >
      {children}
      <ClientOrgUserActionsOptimizerAdapter />
      <ClientOrgUserActionsDialogsAdapter />
    </ClientOrgUserActionsContext.Provider>
  );
};
