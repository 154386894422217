import { CmsBanner, ECmsContainerType, Nullable, SportOptionTyped } from '@/domain';
import { ECmsBannerViewLinkObjectType } from '@features/cms/types/banner';
import { fileSizeInBytes, fileTypes } from '../../constants';
import { EEntityPreviewMode } from '../../types';
import { ContentTargetComponentsType } from '../general/contentTarget/types';
import { cmsBannerSizes1, cmsBannerSizes2, cmsBannerSizes3 } from './container/utils/constants';
import { CmsPreviewImageRestrictions, CmsPreviewParams } from './types';

// < --- настройки фронта, который будет отображать контент cms

//размер отступов контента для мобилы суммарно с обоих сторон
const mobileMinPaddingPx = 0;
const mobileMaxPaddingPx = 0;

//ширина минимального и минимального поддерживаемого дисплея для мобилы
const mobileMinWidthSupportedPx = 440;
const mobileMaxWidthSupportedPx = 440;

//ширина минимального поддерживаемого дисплея, за минусом отступов, то есть это ширина зоны для контента
const mobileMinContentWidthPx = mobileMinWidthSupportedPx - mobileMinPaddingPx;

//брекпойнт перехода в десктопное отображение
const mobileMaxContentWidthPx = mobileMaxWidthSupportedPx - mobileMaxPaddingPx;

/**
 * при загрузке картинок нужно чтобы они подходили под ширину экрана [mobileMinWidthSupportedPx, mobileMaxWidthSupportedPx] и не теряли в качестве
 * поэтому рассчитываем коэффициенты для увеличения требований к размерам картинок и далее будем его натягивать на эталонные размеры
 *
 * необходимость отпала, коэф-т multiplier стал не нужен, код остался для страховки
 */
const mobileSizeMultiplier = mobileMaxContentWidthPx / mobileMinContentWidthPx;
// --- >

export const getCmsPreviewParams = (
  type: ECmsContainerType,
  mode: EEntityPreviewMode,
  banner?: Nullable<CmsBanner>
): CmsPreviewParams => {
  const restrictions = getCmsBannerPreviewRestrictions(type, mode, banner);
  const mobileAdaptedRestrictions = mobileAdaptCmsBannerPreviewRestrictions(mode, restrictions);
  const { recommendedWidthInPx, recommendedHeightInPx } = mobileAdaptedRestrictions;
  return {
    ratio: recommendedWidthInPx / recommendedHeightInPx,
    rowElementsCount: calculateCmsPreviewRowElementsCount(type, mode),
    restrictions: mobileAdaptedRestrictions,
  };
};

const calculateCmsPreviewRowElementsCount = (type: ECmsContainerType, mode: EEntityPreviewMode): number => {
  switch (type) {
    case ECmsContainerType.Banner1:
    case ECmsContainerType.Banner1Resizable:
    case ECmsContainerType.Banner1LinkResizable:
    case ECmsContainerType.Banner3Carousel:
      return 1;

    case ECmsContainerType.Banner2:
      return 2;

    case ECmsContainerType.Banner2MobileWide:
      switch (mode) {
        case EEntityPreviewMode.Desktop:
          return 2;
        case EEntityPreviewMode.Mobile:
          return 1;
      }
      break;

    case ECmsContainerType.Banner3:
      switch (mode) {
        case EEntityPreviewMode.Desktop:
          return 3;
        case EEntityPreviewMode.Mobile:
          return 1;
      }
      break;

    case ECmsContainerType.Banner3Resizable:
      return 3;

    case ECmsContainerType.Banner4:
    case ECmsContainerType.Banner4High:
    case ECmsContainerType.Category4Offer:
      switch (mode) {
        case EEntityPreviewMode.Desktop:
          return 4;
        case EEntityPreviewMode.Mobile:
          return 2;
      }
      break;

    case ECmsContainerType.CompilationOffer:
      switch (mode) {
        case EEntityPreviewMode.Desktop:
          return 4;
        case EEntityPreviewMode.Mobile:
          return 1;
      }
      break;

    case ECmsContainerType.CompilationPartner:
      return 4;
  }
};

const getCmsBannerPreviewRestrictions = (
  type: ECmsContainerType,
  mode: EEntityPreviewMode,
  banner?: Nullable<CmsBanner>
): CmsPreviewImageRestrictions => {
  const fileType = [fileTypes.png.mimeType, fileTypes.jpg.mimeType, fileTypes.jpeg.mimeType];
  const fileAccept = [fileTypes.png.ext, fileTypes.jpg.ext, fileTypes.jpeg.ext];

  const maxFileSizeInBytesDesktopDefault = fileSizeInBytes._1MB;
  const maxFileSizeInBytesMobileDefault = fileSizeInBytes._1MB;

  const standard = getCmsBannerPreviewStandardRestrictions(type, mode);

  if (banner?.width && banner?.height) {
    const desktopWidthInPx = parseInt(banner.width);
    const desktopHeightInPx = parseInt(banner.height);
    let mobileWidthInPx = parseInt(banner.width);
    let mobileHeightInPx = parseInt(banner.height);

    //для баннеров настраиваемых размеров находим правильную настройку
    switch (type) {
      case ECmsContainerType.Banner1Resizable:
      case ECmsContainerType.Banner3Resizable:
      case ECmsContainerType.Banner1LinkResizable:
        const sizePreset = [...cmsBannerSizes2, ...cmsBannerSizes1, ...cmsBannerSizes3].find(
          s => s.desktop[0] === banner.width && s.desktop[1] === banner.height
        );
        if (sizePreset) {
          mobileWidthInPx = parseInt(sizePreset.mobile[0]);
          mobileHeightInPx = parseInt(sizePreset.mobile[1]);
        }
        break;
      default:
        break;
    }

    switch (mode) {
      case EEntityPreviewMode.Desktop:
        return {
          fileType,
          fileAccept,
          maxFileSizeInBytes: maxFileSizeInBytesDesktopDefault,
          recommendedWidthInPx: desktopWidthInPx,
          recommendedHeightInPx: desktopHeightInPx,
        };
      case EEntityPreviewMode.Mobile:
        return {
          fileType,
          fileAccept,
          maxFileSizeInBytes: maxFileSizeInBytesMobileDefault,
          recommendedWidthInPx: mobileWidthInPx,
          recommendedHeightInPx: mobileHeightInPx,
        };
    }
  }

  return standard;
};

const mobileAdaptCmsBannerPreviewRestrictions = (
  mode: EEntityPreviewMode,
  source: CmsPreviewImageRestrictions
): CmsPreviewImageRestrictions => {
  switch (mode) {
    case EEntityPreviewMode.Desktop:
      return source;
    case EEntityPreviewMode.Mobile:
      return {
        ...source,
        recommendedWidthInPx: Math.round(source.recommendedWidthInPx * mobileSizeMultiplier),
        recommendedHeightInPx: Math.round(source.recommendedHeightInPx * mobileSizeMultiplier),
      };
  }
};

const getCmsBannerPreviewStandardRestrictions = (
  type: ECmsContainerType,
  mode: EEntityPreviewMode
): CmsPreviewImageRestrictions => {
  const fileType = [fileTypes.png.mimeType, fileTypes.jpg.mimeType, fileTypes.jpeg.mimeType];
  const fileAccept = [fileTypes.png.ext, fileTypes.jpg.ext, fileTypes.jpeg.ext];

  const maxFileSizeInBytesDesktopDefault = fileSizeInBytes._1MB;
  const maxFileSizeInBytesMobileDefault = fileSizeInBytes._1MB;

  switch (type) {
    case ECmsContainerType.Banner2:
      switch (mode) {
        case EEntityPreviewMode.Desktop:
          return {
            fileType,
            fileAccept,
            maxFileSizeInBytes: maxFileSizeInBytesDesktopDefault,
            recommendedWidthInPx: 628,
            recommendedHeightInPx: 240,
          };
        case EEntityPreviewMode.Mobile:
          return {
            fileType,
            fileAccept,
            maxFileSizeInBytes: maxFileSizeInBytesMobileDefault,
            recommendedWidthInPx: 216,
            recommendedHeightInPx: 100,
          };
      }
      break;
    case ECmsContainerType.Banner2MobileWide:
      switch (mode) {
        case EEntityPreviewMode.Desktop:
          return {
            fileType,
            fileAccept,
            maxFileSizeInBytes: maxFileSizeInBytesDesktopDefault,
            recommendedWidthInPx: 628,
            recommendedHeightInPx: 240,
          };
        case EEntityPreviewMode.Mobile:
          return {
            fileType,
            fileAccept,
            maxFileSizeInBytes: maxFileSizeInBytesMobileDefault,
            recommendedWidthInPx: 216,
            recommendedHeightInPx: 100,
          };
      }
      break;
    case ECmsContainerType.Banner1Resizable:
      const presetSizeBanner1 = cmsBannerSizes2[cmsBannerSizes2.length - 1];
      switch (mode) {
        case EEntityPreviewMode.Desktop:
          return {
            fileType,
            fileAccept,
            maxFileSizeInBytes: maxFileSizeInBytesDesktopDefault,
            recommendedWidthInPx: parseInt(presetSizeBanner1.desktop[0]),
            recommendedHeightInPx: parseInt(presetSizeBanner1.desktop[1]),
          };
        case EEntityPreviewMode.Mobile:
          return {
            fileType,
            fileAccept,
            maxFileSizeInBytes: maxFileSizeInBytesMobileDefault,
            recommendedWidthInPx: parseInt(presetSizeBanner1.mobile[0]),
            recommendedHeightInPx: parseInt(presetSizeBanner1.mobile[1]),
          };
      }
      break;
    case ECmsContainerType.Banner1LinkResizable:
      const presetSizeBanner1Link = cmsBannerSizes3[cmsBannerSizes3.length - 1];
      switch (mode) {
        case EEntityPreviewMode.Desktop:
          return {
            fileType,
            fileAccept,
            maxFileSizeInBytes: maxFileSizeInBytesDesktopDefault,
            recommendedWidthInPx: parseInt(presetSizeBanner1Link.desktop[0]),
            recommendedHeightInPx: parseInt(presetSizeBanner1Link.desktop[1]),
          };
        case EEntityPreviewMode.Mobile:
          return {
            fileType,
            fileAccept,
            maxFileSizeInBytes: maxFileSizeInBytesMobileDefault,
            recommendedWidthInPx: parseInt(presetSizeBanner1Link.mobile[0]),
            recommendedHeightInPx: parseInt(presetSizeBanner1Link.mobile[1]),
          };
      }
      break;
    case ECmsContainerType.Banner1:
      switch (mode) {
        case EEntityPreviewMode.Desktop:
          return {
            fileType,
            fileAccept,
            maxFileSizeInBytes: maxFileSizeInBytesDesktopDefault,
            recommendedWidthInPx: 1280,
            recommendedHeightInPx: 350,
          };
        case EEntityPreviewMode.Mobile:
          return {
            fileType,
            fileAccept,
            maxFileSizeInBytes: maxFileSizeInBytesMobileDefault,
            recommendedWidthInPx: 280,
            recommendedHeightInPx: 150,
          };
      }
      break;
    case ECmsContainerType.Banner3:
      switch (mode) {
        case EEntityPreviewMode.Desktop:
          return {
            fileType,
            fileAccept,
            maxFileSizeInBytes: maxFileSizeInBytesDesktopDefault,
            recommendedWidthInPx: 406,
            recommendedHeightInPx: 280,
          };
        case EEntityPreviewMode.Mobile:
          return {
            fileType,
            fileAccept,
            maxFileSizeInBytes: maxFileSizeInBytesMobileDefault,
            recommendedWidthInPx: 280,
            recommendedHeightInPx: 80,
          };
      }
      break;
    case ECmsContainerType.Banner3Resizable:
      const presetSizeBanner3 = cmsBannerSizes1[cmsBannerSizes1.length - 1];
      switch (mode) {
        case EEntityPreviewMode.Desktop:
          return {
            fileType,
            fileAccept,
            maxFileSizeInBytes: maxFileSizeInBytesDesktopDefault,
            recommendedWidthInPx: parseInt(presetSizeBanner3.desktop[0]),
            recommendedHeightInPx: parseInt(presetSizeBanner3.desktop[1]),
          };
        case EEntityPreviewMode.Mobile:
          return {
            fileType,
            fileAccept,
            maxFileSizeInBytes: maxFileSizeInBytesMobileDefault,
            recommendedWidthInPx: parseInt(presetSizeBanner3.mobile[0]),
            recommendedHeightInPx: parseInt(presetSizeBanner3.mobile[0]),
          };
      }
      break;
    case ECmsContainerType.Banner3Carousel:
      switch (mode) {
        case EEntityPreviewMode.Desktop:
          return {
            fileType,
            fileAccept,
            maxFileSizeInBytes: maxFileSizeInBytesDesktopDefault,
            recommendedWidthInPx: 1280,
            recommendedHeightInPx: 380,
          };
        case EEntityPreviewMode.Mobile:
          return {
            fileType,
            fileAccept,
            maxFileSizeInBytes: maxFileSizeInBytesMobileDefault,
            recommendedWidthInPx: 448,
            recommendedHeightInPx: 180,
          };
      }
      break;
    case ECmsContainerType.Banner4:
    case ECmsContainerType.Category4Offer:
    case ECmsContainerType.CompilationOffer:
    case ECmsContainerType.CompilationPartner:
      switch (mode) {
        case EEntityPreviewMode.Desktop:
          return {
            fileType,
            fileAccept,
            maxFileSizeInBytes: maxFileSizeInBytesDesktopDefault,
            recommendedWidthInPx: 302,
            recommendedHeightInPx: 220,
          };
        case EEntityPreviewMode.Mobile:
          return {
            fileType,
            fileAccept,
            maxFileSizeInBytes: maxFileSizeInBytesMobileDefault,
            recommendedWidthInPx: 216,
            recommendedHeightInPx: 84,
          };
      }
      break;
    case ECmsContainerType.Banner4High:
      switch (mode) {
        case EEntityPreviewMode.Desktop:
          return {
            fileType,
            fileAccept,
            maxFileSizeInBytes: maxFileSizeInBytesDesktopDefault,
            recommendedWidthInPx: 302,
            recommendedHeightInPx: 600,
          };
        case EEntityPreviewMode.Mobile:
          return {
            fileType,
            fileAccept,
            maxFileSizeInBytes: maxFileSizeInBytesMobileDefault,
            recommendedWidthInPx: 216,
            recommendedHeightInPx: 160,
          };
      }
      break;
  }
};

export const cmsTradeTargetByLocalitiesAttributes: ContentTargetComponentsType = {
  localities: true,
  externalUsers: true,
  clientOrgs: true,
};

export const cmsCorpTargetByLocalitiesAttributes: ContentTargetComponentsType = {
  localities: true,
  gender: true,
  tradeUnionMembersOnly: true,
  havingChildFamilyMemberOnly: true,
  familyMemberOnly: true,
};

export const cmsCorpTargetByCorpStructureAttributes: ContentTargetComponentsType = {
  gender: true,
  roads: true,
  orgUnits: true,
  tradeUnionMembersOnly: true,
  havingChildFamilyMemberOnly: true,
  familyMemberOnly: true,
};

export const cmsBannerViewLinkedObjectType: Record<
  ECmsBannerViewLinkObjectType,
  SportOptionTyped<ECmsBannerViewLinkObjectType>
> = {
  [ECmsBannerViewLinkObjectType.TradeOffer]: {
    id: ECmsBannerViewLinkObjectType.TradeOffer,
    name: 'Карточку торгового предложения',
  },
  [ECmsBannerViewLinkObjectType.CorpOffer]: {
    id: ECmsBannerViewLinkObjectType.CorpOffer,
    name: 'Карточку корпоративного предложения',
  },
  [ECmsBannerViewLinkObjectType.CorpCategory]: {
    id: ECmsBannerViewLinkObjectType.CorpCategory,
    name: 'Категорию',
  },
  [ECmsBannerViewLinkObjectType.TradeCategory]: {
    id: ECmsBannerViewLinkObjectType.TradeCategory,
    name: 'Категорию',
  },
  [ECmsBannerViewLinkObjectType.TradeOfferCollection]: {
    id: ECmsBannerViewLinkObjectType.TradeOfferCollection,
    name: 'Страницу подборки',
  },
  [ECmsBannerViewLinkObjectType.CorpOfferCollection]: {
    id: ECmsBannerViewLinkObjectType.CorpOfferCollection,
    name: 'Страницу подборки',
  },
  [ECmsBannerViewLinkObjectType.TradeCategoryCollection]: {
    id: ECmsBannerViewLinkObjectType.TradeCategoryCollection,
    name: 'Группу категорий',
  },
  [ECmsBannerViewLinkObjectType.CorpCategoryCollection]: {
    id: ECmsBannerViewLinkObjectType.CorpCategoryCollection,
    name: 'Группу категорий',
  },
  [ECmsBannerViewLinkObjectType.Link]: {
    id: ECmsBannerViewLinkObjectType.Link,
    name: 'Внешний ресурс',
  },
};

export const cmsBannerRequiredCategoryCount = 2;
