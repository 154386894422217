import { ESortDirection } from '@/domain';

export enum ESocialPackageApplicationTableColumn {
  /** Дата создания заявки */
  CreatedAt = 'createdAt',
  /** Код заявки */
  Code = 'code',
  /** Месяц отдыхы */
  RestMonth = 'month',
  /** ФИО пользователя */
  EmployeeFullName = 'employee.lastName',
  /** Табельный номер пользователя */
  EmployeePernr = 'employee.pernr',
  /** Телефон пользователя */
  EmployeePhone = 'employee.phone',
  /** Email пользователя */
  EmployeeEmail = 'employee.email',
  /** Количество членов семьи с кем будет отдых   */
  FamilyMembersCount = 'familyMembersCount',
}

export type SocialPackageApplicationTableSort = {
  readonly column: ESocialPackageApplicationTableColumn;
  readonly direction: ESortDirection;
};
