import { FCC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CustomerDetailsContainerProps } from './container';
import { customerDetailsByIdFetch, customerDetailsStateReset } from './store/slice';

type CustomerDetailsInitializerProps = CustomerDetailsContainerProps;

export const CustomerDetailsInitializer: FCC<CustomerDetailsInitializerProps> = ({ id, children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const promise = dispatch(customerDetailsByIdFetch(id));

    return () => {
      dispatch(customerDetailsStateReset());
      promise?.abort();
    };
  }, [dispatch, id]);

  return <>{children}</>;
};
