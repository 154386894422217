import { MpUser, Partner } from '@/domain';
import { CustomerLifeCycleFactory } from '@features/customer/lifecycle/lifecycle';
import { viewShowCustomerManagementByPartnerMenu } from '@features/customer/management/byPartner/store/slice';
import { viewHideMainMenu } from '@features/main/container/store/slice';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ForbiddenComponent from '../../components/common/forbidden';
import CustomerDetailsContainer from '../../features/customer/details/container';
import useCurrentUser from '../../features/user/hooks/useCurrentUser';

interface CustomerManagementByPartnerScreenProps {
  readonly customer: MpUser;
  readonly partner: Partner;
}

const CustomerManagementByPartnerScreen = (props: CustomerManagementByPartnerScreenProps) => {
  const { customer } = props;

  const dispatch = useDispatch();
  const {
    accessMatrix: { customers, isPartnerUser },
    userSpecific,
  } = useCurrentUser();

  useEffect(() => {
    dispatch(viewHideMainMenu());
    dispatch(viewShowCustomerManagementByPartnerMenu());
  }, [dispatch]);

  if (!customers?.view?.details) {
    return <ForbiddenComponent />;
  }

  return (
    <CustomerDetailsContainer
      id={customer.id}
      lifecycle={CustomerLifeCycleFactory().create({ isPartnerUser, userId: userSpecific.id })}
      access={customers}
    />
  );
};

export default CustomerManagementByPartnerScreen;
