import _ from 'lodash';
import TeamTableCell, { TeamTableCellCommonProps } from './index';

const TeamTableCellActivityTypes = (props: TeamTableCellCommonProps) => {
  const {
    team: { activityTypes, notSelectableReason: disabled },
  } = props;

  const parentNames = activityTypes?.map(item => item.name);
  const value = _.uniq(parentNames)?.join(', ') || '-';

  return (
    <TeamTableCell
      {...props}
      disabled={!!disabled}
      value={value}
    />
  );
};

export default TeamTableCellActivityTypes;
