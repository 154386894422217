import { ClientOrgUser, ESortDirection } from '@/domain';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'data/store/store';
import { EClientOrgUserTableColumn } from '../utils';

export const clientOrgUsersIsEmptySelector = (store: RootState) =>
  store.user.userClientOrg.list.allClientOrgUsers.isFetched &&
  store.user.userClientOrg.list.allClientOrgUsers.count === 0;

export const clientOrgUsersIsPreloadingSelector = (store: RootState) =>
  store.user.userClientOrg.list.allClientOrgUsers.count === null;

export const clientOrgUsersGuidSelector = (store: RootState) => store.user.userClientOrg.list.guid;

export const clientOrgUsersClientOrgUsersSelector = (store: RootState) => store.user.userClientOrg.list.data;
export const clientOrgUsersSelectedSelector = (store: RootState) => store.user.userClientOrg.list.selected;

export const clientOrgUsersPageNumberSelector = (store: RootState) => store.user.userClientOrg.list.pageNumber;

export const clientOrgUsersTotalCountSelector = (store: RootState) => store.user.userClientOrg.list.totalCount;

export const clientOrgUsersPageCountSelector = (store: RootState) => store.user.userClientOrg.list.pageCount;

export const clientOrgUsersSearchSelector = (store: RootState) => store.user.userClientOrg.list.search;

export const clientOrgUsersTabsCounterSelector = (store: RootState) => store.user.userClientOrg.list.tabsCounter;

export const clientOrgUsersNeedRefreshWatcherSelector = (store: RootState) =>
  store.user.userClientOrg.list.needRefreshWatcher;

export const clientOrgUsersFilterSelector = (store: RootState) => store.user.userClientOrg.list.filter;
export const clientOrgUsersClientOrgSelector = (store: RootState) => store.user.userClientOrg.list.clientOrg;

export const clientOrgUsersIsFetchingSelector = (store: RootState) => store.user.userClientOrg.list.isFetching;

export const clientOrgUsersCountNeedRefreshWatcherSelector = (store: RootState) =>
  store.user.userClientOrg.list.allClientOrgUsers.needRefreshWatcher;

export const clientOrgUsersSortColumnSelector = (store: RootState) =>
  (store.user.userClientOrg.list.search.sort?.split(',') as [EClientOrgUserTableColumn, ESortDirection])?.[0] ?? '';

export const clientOrgUsersSortDirectionSelector = (store: RootState) =>
  (store.user.userClientOrg.list.search.sort?.split(',') as [EClientOrgUserTableColumn, ESortDirection])?.[1] ?? '';

export const clientOrgUsersClientOrgUserIndexSelector = (store: RootState, index: number) => index;

const createClientOrgUsersTableClientOrgUserByIndexSelector = createSelector(
  clientOrgUsersClientOrgUsersSelector,
  clientOrgUsersClientOrgUserIndexSelector,
  (clientOrgUsers, index): Nullable<ClientOrgUser> => clientOrgUsers?.[index] ?? null
);

export const clientOrgUsersClientOrgUserByIndexSelector = (index: number) => (store: RootState) =>
  createClientOrgUsersTableClientOrgUserByIndexSelector(store, index);
