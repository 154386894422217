import Axios from 'axios';
import Api from '../../../../data/api';
import { ApiCancellable, ApiRequestListDiscriminator } from '../../../../data/api/types';
import { SportAllProps as ApiAllProps } from '../../../../data/api/user';
import { Pageable } from '../../../../domain/model';
import { Nullable, UUID } from '../../../../domain/model/types';
import { SportUserProfile } from '../../../../domain/model/user';
import { PaginationSize } from '../../../types';
import { ESportsmansTableFilterItem, SportsmansTableFilterValues } from '../filterUtils';
import { ESportsmanTableTab, SportsmanTableTabsCounter } from '../table/utils';
import { SportsmansFilter } from '../types';

export type SearchProps = ApiCancellable & {
  readonly search: {
    readonly pageSize: PaginationSize;
    readonly sort: Nullable<string>;
    readonly name: Nullable<string>;
  };
  readonly filter: SportsmansFilter;
  readonly pageNumber: number;
  readonly teamId?: UUID;
};

export type AllProps = ApiCancellable & {
  readonly search: {
    readonly pageSize: PaginationSize;
    readonly sort: Nullable<string>;
  };
  readonly filter: SportsmansTableFilterValues;
  readonly pageNumber: number;
  readonly teamId?: UUID;
};

export type OneProps = ApiCancellable & {
  readonly id: UUID;
};

export type CountProps = Omit<AllProps, 'pageNumber'>;

type CountsByTabsProps = CountProps & {
  readonly tabs: ESportsmanTableTab[];
};

export type SportsmanCommonService = {
  readonly buildListQueryParams: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: AllProps
  ) => ApiAllProps<D>;
  readonly search: (props: SearchProps) => Promise<Pageable<SportUserProfile>>;
  readonly all: (props: AllProps) => Promise<Pageable<SportUserProfile>>;
  readonly one: (props: OneProps) => Promise<SportUserProfile>;
  readonly count: (props: CountProps) => Promise<number>;
  readonly countsByTabs: (
    props: CountsByTabsProps
  ) => Promise<{ counts: SportsmanTableTabsCounter; errors: Array<string> }>;
};

const service: SportsmanCommonService = {
  buildListQueryParams: ({ search, filter, pageNumber, signal, teamId }) => {
    const { pageSize, sort } = search;
    const name = filter[ESportsmansTableFilterItem.Name]?.value;
    const ageMin = filter[ESportsmansTableFilterItem.AgeMin]?.value;
    const ageMax = filter[ESportsmansTableFilterItem.AgeMax]?.value;
    const addressObjectId = filter[ESportsmansTableFilterItem.Locality]?.value?.id;
    const activityTypes = filter[ESportsmansTableFilterItem.ActivityTypes]?.value;
    const roads = filter[ESportsmansTableFilterItem.Road]?.value;
    const gender = filter[ESportsmansTableFilterItem.Gender]?.value;

    return {
      pageSize: pageSize,
      page: pageNumber,
      sort,
      addressObjectId,
      ageMin,
      ageMax,
      gender,
      name,
      activityTypes,
      road: roads,
      teamId,
      signal,
    };
  },
  /** @deprecated перейти на buildListQueryParams после переделки всех фичей спортсменов на вид привилегий */
  search: async ({ search, filter, pageNumber, signal, teamId }) => {
    const { pageSize, sort, name } = search;

    const activityTypes = filter.activityTypes;
    const roads = filter.roads;
    const addressObjectId = filter.locality?.id;
    const gender = filter.gender;
    const ageMin = filter.ageMin;
    const ageMax = filter.ageMax;

    const { data: response } = await Api.user.sport.all({
      page: pageNumber,
      pageSize,
      name,
      sort,
      addressObjectId,
      activityTypes,
      road: roads?.map(item => item.id),
      gender: gender?.id,
      ageMin,
      ageMax,
      signal,
      teamId,
    });

    const { content: data, totalElements: totalCount, totalPages: pageCount, number: page } = response;
    return { data, totalCount, pageCount, pageNumber: page };
  },
  all: async props => {
    const { data: response } = await Api.user.sport.all(service.buildListQueryParams(props));
    const { content: data, totalElements: totalCount, totalPages: pageCount, number: page } = response;

    return { data, totalCount, pageCount, pageNumber: page };
  },
  one: async ({ id, signal }) => {
    const { data } = await Api.user.sport.one({ id, signal });
    return data;
  },
  count: async props => {
    const { data: response } = await Api.user.sport.all({
      ...service.buildListQueryParams({ ...props, pageNumber: 1 }),
      discriminator: ApiRequestListDiscriminator.Count,
    });

    return response.count;
  },
  countsByTabs: async ({ tabs, signal, ...props }) => {
    const errors: string[] = [];
    const counts: SportsmanTableTabsCounter = {};

    //табы для которых нет статусов
    const requests = tabs.map(() => {
      return service.count({ ...props, signal });
    });

    const responses = await Promise.allSettled(requests);

    const parseResponse = (response: (typeof responses)[0], tab: ESportsmanTableTab) => {
      if (response.status === 'fulfilled') {
        counts[tab] = response.value;
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество пользователей '${tab}': ${response.reason}`);
        }
      }
    };

    tabs.forEach((tab, index) => parseResponse(responses[index], tab));

    return { counts, errors };
  },
};

export default service;
