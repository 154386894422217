import { EClientOrgUserTableTab } from '../../types';
import { getClientOrgUsersTableTabs, getClientOrgUsersTableTabsForCounts } from '../utils';

export type UseClientOrgUsersTableConfig = {
  readonly tabs: EClientOrgUserTableTab[];
  readonly tabsForCounts: EClientOrgUserTableTab[];
  readonly defaultTab: EClientOrgUserTableTab;
};

export function useClientOrgUsersTableConfig(): UseClientOrgUsersTableConfig {
  const tabs = getClientOrgUsersTableTabs();
  const tabsForCounts = getClientOrgUsersTableTabsForCounts();
  const defaultTab = EClientOrgUserTableTab.Enabled;

  return {
    tabs,
    tabsForCounts,
    defaultTab,
  };
}
