import { MpCustomer } from '@/domain';
import { FCC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import store from '../../../../../data/store/store';
import { useCustomerActions } from '../../actions/useActions';
import { CustomerDetailsContainerProps } from '../container';
import { CustomerDetailsHandlersContext, CustomerDetailsHandlersContextValue } from '../context';
import { customerDetailsCustomerSelector } from '../store/selectors';
import { customerDetailsByIdFetch } from '../store/slice';

export const CustomerDetailHandlersProvider: FCC<CustomerDetailsContainerProps> = ({ children }) => {
  const dispatch = useDispatch();

  const { onActivate, onDeactivate } = useCustomerActions();

  /**
   * Получение Покупателя
   */
  const getCustomerFromState = useCallback((): Nullable<MpCustomer> => {
    const state = store.getState();
    return customerDetailsCustomerSelector(state);
  }, [store]);

  /**
   * Обновить данные Покупателя
   */
  const onCustomerRefetch = useCallback<CustomerDetailsHandlersContextValue['onCustomerRefetch']>(() => {
    const customer = getCustomerFromState();
    if (customer) {
      dispatch(customerDetailsByIdFetch(customer.id));
    }
  }, [dispatch]);

  /**
   * Активировать Покупателя
   */
  const onCustomerActivate = useCallback<CustomerDetailsHandlersContextValue['onCustomerActivate']>(() => {
    const customer = getCustomerFromState();

    if (customer) {
      onActivate(customer);
    }
  }, [getCustomerFromState]);

  /**
   * Деактивировать покупателя
   */
  const onCustomerDeactivate = useCallback<CustomerDetailsHandlersContextValue['onCustomerDeactivate']>(() => {
    const customer = getCustomerFromState();

    if (customer) {
      onDeactivate(customer);
    }
  }, [getCustomerFromState]);

  const value: CustomerDetailsHandlersContextValue = {
    onCustomerRefetch,
    onCustomerActivate,
    onCustomerDeactivate,
  };

  return <CustomerDetailsHandlersContext.Provider value={value}>{children}</CustomerDetailsHandlersContext.Provider>;
};
