import { UserFieldView } from '@components/common/userInfoView';
import CorpActivationTableCell, { CorpActivationTableCellCommonProps } from './index';

const CorpActivationTableCellGivenBy = (props: CorpActivationTableCellCommonProps) => {
  const {
    corpActivation: { givenBy },
  } = props;

  return (
    <CorpActivationTableCell
      {...props}
      value={
        <UserFieldView
          user={givenBy}
          field={'fullName'}
        />
      }
    />
  );
};

export default CorpActivationTableCellGivenBy;
