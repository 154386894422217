import OfferFieldHistoryTableCell, { OfferFieldHistoryTableCellCommonProps } from './index';
import { UserFieldView } from '@components/common/userInfoView';

const OfferFieldHistoryTableCellUpdatedBy = (props: OfferFieldHistoryTableCellCommonProps) => {
  const {
    fieldHistory: { updatedBy },
  } = props;

  return (
    <OfferFieldHistoryTableCell
      {...props}
      value={
        <UserFieldView
          user={updatedBy}
          field={'shortName'}
        />
      }
    />
  );
};

export default OfferFieldHistoryTableCellUpdatedBy;
