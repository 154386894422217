import { CmsBanner, ECmsBannerLinkObjectType, ECmsCollectionLinkObjectType } from '@/domain';
import { OnChangeObjectAttribute } from 'presentation/types';
import { useCallback } from 'react';
import {
  CmsLinkedObjectBannerType,
  CmsLinkedObjectBannerTypes,
  ECmsBannerViewLinkObjectType,
} from '../../../../types/banner';

type CmsBannerView = Omit<CmsBanner, 'linkObjectType'> & {
  readonly linkObjectType: ECmsBannerViewLinkObjectType;
};

type UseCmsContainerCreateBannerChange = {
  readonly onChangeBannerAttribute: OnChangeObjectAttribute<CmsBannerView>;
  readonly onChangeBannerLinkedObject: (newCmsBanner: CmsLinkedObjectBannerTypes) => void;
};

interface useCmsContainerCreateBannerChangeProps {
  readonly cmsBanner: CmsLinkedObjectBannerType;
  readonly onChangeBanner: (data: CmsLinkedObjectBannerType) => void;
}

const useCmsContainerCreateBannerChange = (
  props: useCmsContainerCreateBannerChangeProps
): UseCmsContainerCreateBannerChange => {
  const { cmsBanner, onChangeBanner } = props;

  const onChangeBannerAttribute: OnChangeObjectAttribute<CmsBannerView> = useCallback(
    (name, value) => {
      if (name === 'linkObjectType') {
        //если меняем тип контента за баннером, то формируем пустой вложенный объект нужного типа
        switch (value) {
          case ECmsBannerViewLinkObjectType.CorpOfferCollection:
            onChangeBanner({
              ...cmsBanner,
              linkObjectId: null,
              linkObjectType: ECmsBannerLinkObjectType.Collection,
              linkedObject: {
                linkObjectType: ECmsCollectionLinkObjectType.CorpOffer,
                linkedObject: [],
              },
            });
            break;
          case ECmsBannerViewLinkObjectType.TradeOfferCollection:
            onChangeBanner({
              ...cmsBanner,
              linkObjectId: null,
              linkObjectType: ECmsBannerLinkObjectType.Collection,
              linkedObject: {
                linkObjectType: ECmsCollectionLinkObjectType.TradeOffer,
                linkedObject: [],
              },
            });
            break;

          case ECmsBannerViewLinkObjectType.CorpCategoryCollection:
            onChangeBanner({
              ...cmsBanner,
              linkObjectId: null,
              linkObjectType: ECmsBannerLinkObjectType.Collection,
              linkedObject: {
                linkObjectType: ECmsCollectionLinkObjectType.CorpOfferCategory,
                linkedObject: [],
              },
            });
            break;
          case ECmsBannerViewLinkObjectType.TradeCategoryCollection:
            onChangeBanner({
              ...cmsBanner,
              linkObjectId: null,
              linkObjectType: ECmsBannerLinkObjectType.Collection,
              linkedObject: {
                linkObjectType: ECmsCollectionLinkObjectType.TradeOfferCategory,
                linkedObject: [],
              },
            });
            break;

          case ECmsBannerViewLinkObjectType.CorpOffer:
            onChangeBanner({
              ...cmsBanner,
              linkObjectId: null,
              linkObjectType: ECmsBannerLinkObjectType.CorpOffer,
              linkedObject: null,
            });
            break;
          case ECmsBannerViewLinkObjectType.TradeOffer:
            onChangeBanner({
              ...cmsBanner,
              linkObjectId: null,
              linkObjectType: ECmsBannerLinkObjectType.TradeOffer,
              linkedObject: null,
            });
            break;

          case ECmsBannerViewLinkObjectType.CorpCategory:
            onChangeBanner({
              ...cmsBanner,
              linkObjectId: null,
              linkObjectType: ECmsBannerLinkObjectType.CorpOfferCategory,
              linkedObject: null,
            });
            break;
          case ECmsBannerViewLinkObjectType.TradeCategory:
            onChangeBanner({
              ...cmsBanner,
              linkObjectId: null,
              linkObjectType: ECmsBannerLinkObjectType.TradeOfferCategory,
              linkedObject: null,
            });
            break;

          case ECmsBannerViewLinkObjectType.Link:
            onChangeBanner({
              ...cmsBanner,
              linkObjectId: null,
              linkObjectType: ECmsBannerLinkObjectType.Link,
              linkedObject: null,
            });
            break;
          case null:
            break;
          default:
            throw new Error(`Not implemented yet: ${value}`);
        }
      } else {
        onChangeBanner({ ...cmsBanner, [name]: value });
      }
    },
    [cmsBanner, onChangeBanner]
  );

  const onChangeBannerLinkedObject = useCallback(
    (newCmsBanner: CmsLinkedObjectBannerTypes) => {
      if (newCmsBanner.linkObjectType === cmsBanner.linkObjectType) {
        const temp: typeof cmsBanner = {
          ...cmsBanner,
          linkedObject: newCmsBanner.linkedObject as any,
        };
        onChangeBanner(temp);
      } else {
        console.error(
          `Inner cmsBanner linkObjectType '${cmsBanner.linkObjectType}' and '${newCmsBanner.linkObjectType}' not equal`
        );
      }
    },
    [cmsBanner]
  );

  return {
    onChangeBannerAttribute,
    onChangeBannerLinkedObject,
  };
};

export default useCmsContainerCreateBannerChange;
