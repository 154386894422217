import {
  CmsBanner,
  CmsComponentData,
  CorpOffer,
  ECmsBannerLinkObjectType,
  ECmsLinkObjectType,
  Nullable,
  OfferCategory,
  TradeOffer,
} from '@/domain';
import { CmsLinkedObjectCollectionTypes } from './collection';

type CmsLinkedObjectBannerTypeTemplate<T extends ECmsBannerLinkObjectType | null, O> = {
  readonly linkObjectType: T;
  readonly linkedObject: Nullable<O>;
};

export type CmsLinkedObjectBannerEmptyType = CmsLinkedObjectBannerTypeTemplate<null, null>;

export type CmsLinkedObjectBannerCollectionType = CmsLinkedObjectBannerTypeTemplate<
  ECmsBannerLinkObjectType.Collection,
  CmsLinkedObjectCollectionTypes
>;

export type CmsLinkedObjectBannerCorpOfferType = CmsLinkedObjectBannerTypeTemplate<
  ECmsBannerLinkObjectType.CorpOffer,
  CorpOffer
>;

export type CmsLinkedObjectBannerTradeOfferType = CmsLinkedObjectBannerTypeTemplate<
  ECmsBannerLinkObjectType.TradeOffer,
  TradeOffer
>;

export type CmsLinkedObjectBannerCategoryType = CmsLinkedObjectBannerTypeTemplate<
  ECmsBannerLinkObjectType.CorpOfferCategory | ECmsBannerLinkObjectType.TradeOfferCategory,
  OfferCategory
>;

export type CmsLinkedObjectBannerLinkType = CmsLinkedObjectBannerTypeTemplate<ECmsBannerLinkObjectType.Link, string>;

export type CmsLinkedObjectBannerTypes =
  | CmsLinkedObjectBannerEmptyType
  | CmsLinkedObjectBannerCollectionType
  | CmsLinkedObjectBannerCorpOfferType
  | CmsLinkedObjectBannerTradeOfferType
  | CmsLinkedObjectBannerCategoryType
  | CmsLinkedObjectBannerLinkType;

export type CmsLinkedObjectBannerType = Omit<CmsBanner, 'linkObjectType'> &
  CmsComponentData &
  CmsLinkedObjectBannerTypes;

export type CmsLinkedObjectBanner = {
  readonly type: ECmsLinkObjectType.Banner;
  readonly banner: CmsLinkedObjectBannerType;
};

export enum ECmsBannerViewLinkObjectType {
  TradeOffer = 'TradeOffer',
  TradeOfferCollection = 'TradeOfferCollection',
  TradeCategory = 'TradeCategory',
  TradeCategoryCollection = 'TradeCategoryCollection',
  CorpOffer = 'CorpOffer',
  CorpOfferCollection = 'CorpOfferCollection',
  CorpCategory = 'CorpCategory',
  CorpCategoryCollection = 'CorpCategoryCollection',
  Link = 'Link',
}
