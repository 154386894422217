import { CommonLifeCycleInstance } from '../../../utils/lifecycle';
import { commonAdminConfiguration, commonPartnerConfiguration } from './configurations';
import {
  CustomerLifeCycle,
  CustomerLifeCycleFactoryProps,
  CustomerLifeCycleProps,
  CustomerLifeCycleType,
} from './types';

/**
 * жизненный цикл Покупателя
 */
const CustomerLifeCycleInstance = (props: CustomerLifeCycleProps<CustomerLifeCycleType>): CustomerLifeCycle => {
  const { configuration, userId, isPartnerUser } = props;

  const commonLifecycle = CommonLifeCycleInstance(props);

  return {
    configuration,
    build: ({ allowedActions, obj }) => {
      const commonLifecycleBuild = commonLifecycle.build({ allowedActions, obj });

      return {
        ...commonLifecycleBuild,
        canEdit: false,
      };
    },
    isPartnerUser,
    userId,
  };
};

/**
 * жизненный цикл Покупателя - основная модель
 */
export const CustomerLifeCycleFactory = () => {
  return {
    create: ({ isPartnerUser, userId, debug }: CustomerLifeCycleFactoryProps): CustomerLifeCycle => {
      if (isPartnerUser) {
        return CustomerLifeCycleInstance({
          userId,
          configuration: commonPartnerConfiguration,
          debug,
          isPartnerUser,
        });
      } else {
        return CustomerLifeCycleInstance({
          userId,
          configuration: commonAdminConfiguration,
          debug,
          isPartnerUser,
        });
      }
    },
  };
};
