import { ESortDirection } from '@/domain/model/enums';
import { ClientOrgUser } from '@/domain/model/user';
import TableActionsContainer from '@components/common/table/actions/container';
import { DataTableLoader } from '@components/common/table/loader';
import { useClientOrgUserActionsSelectors } from '@features/user/userClientOrg/actions/useActions';
import ClientOrgUserTableCellEmail from '@features/user/userClientOrg/components/tableCell/email';
import ClientOrgUserTableCellPhone from '@features/user/userClientOrg/components/tableCell/phone';
import { useCallback } from 'react';
import DataTable, { DataTableMetadata, DataTableRow } from '../../../../components/common/table';
import ClientOrgUserTableCellDate from '../components/tableCell/date';
import ClientOrgUserTableCellName from '../components/tableCell/name';
import { ClientOrgUserActions, ClientOrgUserActionTableType } from '../types';
import ClientOrgUsersTableCellAdapter from './adapters/cell';
import { clientOrgUsersIsFetchingSelector } from './store/selectors';
import { ClientOrgUserActionCall, EClientOrgUserTableColumn } from './utils';

interface ClientOrgUserTableProps {
  readonly metadata: DataTableMetadata<EClientOrgUserTableColumn>;
  readonly sort: {
    readonly column: string;
    readonly direction: ESortDirection;
  };
  readonly clientOrgUsers: ClientOrgUser[];
  readonly selectedClientOrgUsers: ClientOrgUser[];
  readonly getActions: (clientOrgUser: ClientOrgUser) => ClientOrgUserActions<ClientOrgUserActionTableType>;
  readonly onRequestSort: (column: string, direction: ESortDirection) => void;
  readonly onClientOrgUserClick?: (clientOrgUser: ClientOrgUser) => void;
  readonly onChangeMetadata?: (metadata: DataTableMetadata<EClientOrgUserTableColumn>) => void;
  readonly onClientOrgUserAction: (call: ClientOrgUserActionCall) => void;
  readonly onClientOrgUserSelect: (clientOrgUser: ClientOrgUser, selected: boolean) => void;
  readonly onAllClientOrgUserSelect: (selected: boolean) => void;
}

export const ClientOrgUserTable = (props: ClientOrgUserTableProps) => {
  const {
    clientOrgUsers,
    selectedClientOrgUsers,
    sort,
    metadata,
    getActions,
    onChangeMetadata,
    onRequestSort,
    onClientOrgUserAction,
    onClientOrgUserSelect,
    onAllClientOrgUserSelect,
  } = props;

  const { getIsClientOrgUserProcessingSelector } = useClientOrgUserActionsSelectors();

  const rows: DataTableRow<ClientOrgUser, EClientOrgUserTableColumn>[] = clientOrgUsers?.map((clientOrgUser, index) => {
    return {
      [EClientOrgUserTableColumn.Date]: (
        <ClientOrgUsersTableCellAdapter
          index={index}
          component={ClientOrgUserTableCellDate}
        />
      ),
      [EClientOrgUserTableColumn.Name]: (
        <ClientOrgUsersTableCellAdapter
          index={index}
          component={ClientOrgUserTableCellName}
        />
      ),
      [EClientOrgUserTableColumn.Phone]: (
        <ClientOrgUsersTableCellAdapter
          index={index}
          component={ClientOrgUserTableCellPhone}
        />
      ),
      [EClientOrgUserTableColumn.Email]: (
        <ClientOrgUsersTableCellAdapter
          index={index}
          component={ClientOrgUserTableCellEmail}
        />
      ),
      data: clientOrgUser,
    };
  });

  const onTableAction = useCallback(
    (action: ClientOrgUserActionTableType, clientOrgUser: ClientOrgUser) =>
      onClientOrgUserAction({
        action,
        clientOrgUser,
      }),
    [onClientOrgUserAction]
  );

  return (
    <DataTable<ClientOrgUser, EClientOrgUserTableColumn>
      width='auto'
      overflowX='inherit'
      metadata={metadata}
      rows={rows}
      sort={{ column: sort.column, direction: sort.direction }}
      loader={<DataTableLoader selector={clientOrgUsersIsFetchingSelector} />}
      selectable
      rowActions
      selected={selectedClientOrgUsers}
      onAllRowsSelect={(event, selected) => onAllClientOrgUserSelect(selected)}
      onRowSelect={(event, { data }, selected) => onClientOrgUserSelect(data, selected)}
      onChangeMetadata={onChangeMetadata}
      onSort={(event, column, direction) => onRequestSort(column, direction)}
      getRowActions={clientOrgUser => (
        <TableActionsContainer<ClientOrgUser, ClientOrgUserActionTableType>
          entityLink={clientOrgUser}
          actions={getActions(clientOrgUser)}
          onExecute={onTableAction}
          isFetchingSelector={getIsClientOrgUserProcessingSelector(clientOrgUser.id)}
        />
      )}
    />
  );
};
