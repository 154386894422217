import { ClientOrg, UserAccessPartitionClientOrgUsers } from '@/domain';
import { forwardRef } from 'react';
import { DataFilterAdapter } from '../../../../hooks/useDataFilterAdapter';
import TableCommonLayout from '../../../../layouts/tableCommon/container';
import TableLoaderCommonLayout from '../../../../layouts/tableLoaderCommon/container';
import { EClientOrgUserTableFilterItem } from '../filterUtils';
import { ClientOrgUserLifeCycle } from '../lifecycle/types';
import { EClientOrgUserTableTab } from '../types';
import { ClientOrgUserBehaviorAdapter } from './adapters/behavior';
import ClientOrgUsersTableFilterAdapter from './adapters/filters';
import ClientOrgUsersTableHeaderAdapter from './adapters/header';
import { ClientOrgUsersTableAdapter } from './adapters/table';
import { ClientOrgUsersTableTabsAdapter } from './adapters/tabs';
import { ClientOrgUsersTableContext } from './context';
import ClientOrgUserTableFooterContainer from './footerContainer';
import { useClientOrgUsersTableHandlers } from './hooks/useClientOrgUsersTable';
import { clientOrgUsersIsPreloadingSelector } from './store/selectors';
import { EClientOrgUserTableColumn, getClientOrgUserTableTabActions } from './utils';

interface ClientOrgUserTableContainerProps {
  readonly guid: UUID;
  readonly clientOrg: ClientOrg;
  readonly tab: EClientOrgUserTableTab;
  readonly lifecycle: ClientOrgUserLifeCycle;
  readonly filterAdapter: DataFilterAdapter<EClientOrgUserTableColumn, EClientOrgUserTableFilterItem>;
  readonly access: UserAccessPartitionClientOrgUsers;
}

const ClientOrgUserTableContainer = (props: ClientOrgUserTableContainerProps) => {
  const { guid, tab, lifecycle, filterAdapter, access, clientOrg } = props;

  const handlers = useClientOrgUsersTableHandlers({ guid, tab });
  const tabActions = getClientOrgUserTableTabActions(access, tab);

  return (
    <ClientOrgUsersTableContext.Provider value={handlers}>
      <ClientOrgUserBehaviorAdapter
        guid={guid}
        clientOrg={clientOrg}
        tab={tab}
      />
      <TableLoaderCommonLayout selector={clientOrgUsersIsPreloadingSelector}>
        <TableCommonLayout
          filter={<ClientOrgUsersTableFilterAdapter filterAdapter={filterAdapter} />}
          header={
            <ClientOrgUsersTableHeaderAdapter
              access={access}
              clientOrg={clientOrg}
            />
          }
          tabs={<ClientOrgUsersTableTabsAdapter tab={tab} />}
          table={
            <ClientOrgUsersTableAdapter
              clientOrg={clientOrg}
              lifecycle={lifecycle}
              filterAdapter={filterAdapter}
              tabActions={tabActions}
            />
          }
          footer={forwardRef((props, ref: any) => (
            <ClientOrgUserTableFooterContainer
              clientOrg={clientOrg}
              lifecycle={lifecycle}
              ref={ref}
              allowedActions={tabActions}
            />
          ))}
        ></TableCommonLayout>
      </TableLoaderCommonLayout>
    </ClientOrgUsersTableContext.Provider>
  );
};

export default ClientOrgUserTableContainer;
