import { UserFieldView } from '@components/common/userInfoView';
import BookingOrderTableCell, { BookingOrderTableCellCommonProps } from './index';

const BookingOrderTableCellCustomerName = (props: BookingOrderTableCellCommonProps) => {
  const {
    bookingOrder: { customer },
  } = props;

  return (
    <BookingOrderTableCell
      {...props}
      value={
        <UserFieldView
          user={customer}
          field={'fullName'}
        />
      }
    />
  );
};

export default BookingOrderTableCellCustomerName;
