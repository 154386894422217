import { UserFieldView } from '@components/common/userInfoView';
import ClientOrgUserTableCell, { ClientOrgUserTableCellCommonProps } from './index';

const ClientOrgUserTableCellEmail = (props: ClientOrgUserTableCellCommonProps) => {
  const { clientOrgUser } = props;

  return (
    <ClientOrgUserTableCell
      {...props}
      value={
        <UserFieldView
          user={clientOrgUser}
          field={'email'}
        />
      }
    />
  );
};

export default ClientOrgUserTableCellEmail;
