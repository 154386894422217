import { ReactNode } from 'react';
import DataTableCellTextFormat, { DataTableCellTextFormatProps } from '@components/common/table/cell/textFormat';
import { SearchedSportsmanProfile } from '../../search/types';

export interface SportsmanTableCellCommonProps {
  readonly sportsman: SearchedSportsmanProfile;
}

interface SportsmanTableCellProps extends SportsmanTableCellCommonProps, DataTableCellTextFormatProps {
  readonly value: ReactNode;
}

const SportsmanTableCell = ({ value, ...other }: SportsmanTableCellProps) => {
  return <DataTableCellTextFormat {...other}>{value}</DataTableCellTextFormat>;
};

export default SportsmanTableCell;
