import { ClientOrgUser } from '@/domain';
import { PanelAction, PanelActions } from 'presentation/types';
import { ReactElement } from 'react';

export enum EClientOrgUserTableTab {
  Enabled = 'enabled',
  Invited = 'invited',
  Disabled = 'disabled',
}

export type ClientOrgUserTableStubAdapterProps = {
  readonly children: (props: ClientOrgUserTableStubAdapterResult) => ReactElement<any, any>;
};

export type ClientOrgUserTableStubAdapterResult = {
  readonly canViewTable: boolean;
  readonly stub?: Nullable<JSX.Element>;
};

export type ClientOrgUserTableStubAdapterType = (props: ClientOrgUserTableStubAdapterProps) => ReactElement<any, any>;

export enum EClientOrgUserActionType {
  /** Переотправка приветственного письма */
  ResendWelcomeMail = 'resendWelcomeMail',
}

export type ClientOrgUserActionTableType = EClientOrgUserActionType.ResendWelcomeMail;
export type ClientOrgUserActionTableCollectionType = ClientOrgUserActionTableType;

export type ClientOrgUserAction<A extends string = EClientOrgUserActionType, P = any> = PanelAction<A, P>;
export type ClientOrgUserActions<A extends string = EClientOrgUserActionType> = PanelActions<A>;

export type ClientOrgUserCollectionActionPayload = {
  readonly clientOrgUsers: ClientOrgUser[];
};

export type ActionClientOrgUserType = ClientOrgUser;
