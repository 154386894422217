import { ClientOrg } from '@/domain/model/clientOrg';
import { EClientOrgStatus } from '@/domain/model/enums';
import { DataTableColumns } from '@/presentation/components/common/table';
import { ClientOrgLifeCycle } from '@features/clientOrg/lifecycle/types';
import { filterClientOrgTableActions, getClientOrgActionsConfigByPermissions } from '@features/clientOrg/utils';
import {
  ClientOrgActions,
  ClientOrgActionTableType,
  EClientOrgTableTab,
  GetClientOrgActionsByPermissions,
} from '../types';

export type ClientOrgActionCall = {
  readonly action: ClientOrgActionTableType;
  readonly clientOrg: ClientOrg;
};

export enum EClientOrgTableColumn {
  Date = 'createdAt',
  Code = 'code',
  Name = 'name',
  Domain = 'domain',
}

export type ClientOrgTableTabsCounter = {
  [name in EClientOrgTableTab]?: number;
};

export type ClientOrgCounterByStatus = {
  [name in EClientOrgStatus]?: number;
};

export const clientOrgsTableColumns: DataTableColumns<EClientOrgTableColumn> = {
  [EClientOrgTableColumn.Date]: {
    title: 'Дата',
    width: '10rem',
    sortable: true,
  },
  [EClientOrgTableColumn.Code]: {
    title: 'Код компании',
    width: '12rem',
    sortable: true,
    wrap: true,
  },
  [EClientOrgTableColumn.Name]: {
    title: 'Название компании',
    width: '22rem',
    sortable: true,
  },
  [EClientOrgTableColumn.Domain]: {
    title: 'Доменное имя',
    width: '10rem',
    sortable: false,
  },
};

const clientOrgTableDefaultColumns = [
  EClientOrgTableColumn.Date,
  EClientOrgTableColumn.Code,
  EClientOrgTableColumn.Name,
  EClientOrgTableColumn.Domain,
];

export const getClientOrgTableDefaultColumns = (): DataTableColumns<EClientOrgTableColumn> => {
  const defaultColumns = clientOrgTableDefaultColumns;

  const allColumns: EClientOrgTableColumn[] = [];

  allColumns.push(
    EClientOrgTableColumn.Date,
    EClientOrgTableColumn.Code,
    EClientOrgTableColumn.Name,
    EClientOrgTableColumn.Domain
  );

  return allColumns.reduce<DataTableColumns<EClientOrgTableColumn>>((prev, column) => {
    return {
      ...prev,
      [column]: {
        ...clientOrgsTableColumns[column],
        hidden: !defaultColumns.includes(column),
      },
    };
  }, {});
};

export const getStatesFilterForClientOrgTableTab = (
  tab: EClientOrgTableTab,
  defaultStatuses: EClientOrgStatus[] = []
): EClientOrgStatus[] => {
  switch (tab) {
    case EClientOrgTableTab.Active:
      return [EClientOrgStatus.Active];

    case EClientOrgTableTab.Blocked:
      return [EClientOrgStatus.Blocked];

    default: {
      return defaultStatuses;
    }
  }
};

export const getClientOrgsTableTabs = (): EClientOrgTableTab[] => [
  EClientOrgTableTab.Active,
  EClientOrgTableTab.Blocked,
];

export const getClientOrgsTableTabsForCounts = (): EClientOrgTableTab[] => [
  EClientOrgTableTab.Active,
  EClientOrgTableTab.Blocked,
];

export const getClientOrgsTableTabName = (tab: EClientOrgTableTab): string => {
  switch (tab) {
    case EClientOrgTableTab.Blocked:
      return 'Приостановленные';
    case EClientOrgTableTab.Active:
      return 'Активные';
  }
};

/**
 * Получение экшенов для таблицы
 */
export const getClientOrgTableActions = (
  lifecycle: ClientOrgLifeCycle,
  clientOrg: ClientOrg,
  allowedActions: ClientOrgActions<ClientOrgActionTableType>
) => {
  return lifecycle.build({ obj: clientOrg, allowedActions }).nextActions as ClientOrgActions<ClientOrgActionTableType>;
};

/**
 * Получение экшенов с фильтрацией по табам
 */
export const getClientOrgsTableTabActions = (
  permissions: GetClientOrgActionsByPermissions,
  tab: EClientOrgTableTab
): ClientOrgActions<ClientOrgActionTableType> => {
  const allowActions = getClientOrgActionsConfigByPermissions(permissions);
  return filterClientOrgTableActions(allowActions);
};
