import { CmsSitePage, ECmsContainerType } from '@/domain';
import { MPStepperLabelStrategy } from '@components/common/stepper';
import { DefaultContentWrapper } from '@components/common/wrappers/content';
import { DefaultFooterWrapper } from '@components/common/wrappers/footer';
import { Fade, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MasterActionsComponent from '../../../../components/common/actions/master';
import useNavAdapter from '../../../../components/common/actions/navAdapter/hooks';
import AppBreadcrumbs from '../../../../components/common/breadcrumbs';
import ErrorComponent from '../../../../components/common/error';
import DefaultHeader from '../../../../components/common/header';
import ContentLoader from '../../../../components/common/loader';
import { PanelAction } from '../../../../types';
import { nsiDataSelector } from '../../../general/nsi/store/selectors';
import StepperContainer from '../../../general/stepper/container';
import { ContainerWrapper, ContentContainer, LoaderWrapper, TitleWrapper } from '../create/controls';
import useCmsContainerStepper from '../hooks/useStepper';
import { CmsContainerLifeCycle } from '../lifecycle/types';
import {
  CmsContainerActionDetailsType,
  CmsContainerStep,
  CmsFeatureContainerCommonProps,
  ECmsContainerActionType,
} from '../types';
import { getCmsContainerActionsConfigByPermissions } from '../utils/actions';
import CmsContainerDetails from './component';
import {
  cmsContainerDetailsArchiveSelector,
  cmsContainerDetailsByIdSelector,
  cmsContainerDetailsComponentsSelector,
  cmsContainerDetailsCurrentLinkedObjectSelector,
  cmsContainerDetailsDeleteSelector,
  cmsContainerDetailsDuplicateSelector,
  cmsContainerDetailsLinkedObjectsSelector,
  cmsContainerDetailsPauseSelector,
  cmsContainerDetailsResumeSelector,
} from './store/selectors';
import {
  cmsContainerDetailsArchive,
  cmsContainerDetailsDelete,
  cmsContainerDetailsDuplicate,
  cmsContainerDetailsFetch,
  cmsContainerDetailsPause,
  cmsContainerDetailsReset,
  cmsContainerDetailsResume,
} from './store/slice';
import { CmsContainerDetailsComponentDictionaries, CmsContainerDetailsConfiguration } from './types';
import useCmsContainerDetailsActions from './useActions';

interface CmsContainerDetailsContainerProps extends CmsFeatureContainerCommonProps {
  readonly cmsSitePage: CmsSitePage;
  readonly id: UUID;
  readonly guid: UUID;
  readonly step: number;
  readonly type: ECmsContainerType;
  readonly lifecycle: CmsContainerLifeCycle;
  readonly canCreate?: boolean;
  readonly canEdit?: boolean;
  readonly canPause?: boolean;
  readonly canResume?: boolean;
  readonly canArchive?: boolean;
  readonly canDelete?: boolean;
  readonly canChangeOfferIconVisible?: boolean;
  readonly onClose: () => void;
  readonly onCmsContainerEdit: (id: UUID) => void;
}

const CmsContainerDetailsContainer = (props: CmsContainerDetailsContainerProps) => {
  const {
    //    cmsContext,
    cmsSitePage,
    id,
    guid,
    step,
    type,
    lifecycle,
    canCreate,
    canEdit,
    canPause,
    canResume,
    canArchive,
    canDelete,
    canChangeOfferIconVisible,
    onClose,
    onCmsContainerEdit,
  } = props;

  const dispatch = useDispatch();

  const { cmsContainerTypes, cmsContainerStatuses, userGenders: genders, offerTypes } = useSelector(nsiDataSelector);

  const dictionaries = useMemo<CmsContainerDetailsComponentDictionaries>(
    () => ({
      offerTypes,
      genders,
    }),
    [offerTypes, genders]
  );

  const configuration = useMemo<CmsContainerDetailsConfiguration>(
    () => ({ canChangeOfferIconVisible }),
    [canChangeOfferIconVisible]
  );

  const cmsContainerType = cmsContainerTypes.find(item => item.code === type) ?? cmsContainerTypes?.[0];

  const currentLinkedObjectIndex = step - 1;

  const { isFetching: isPausing } = useSelector(cmsContainerDetailsPauseSelector);
  const { isFetching: isResuming } = useSelector(cmsContainerDetailsResumeSelector);
  const { isFetching: isDeleting } = useSelector(cmsContainerDetailsDeleteSelector);
  const { isFetching: isDuplicating } = useSelector(cmsContainerDetailsDuplicateSelector);
  const { isFetching: isArchiving } = useSelector(cmsContainerDetailsArchiveSelector);
  const { data: cmsContainer, isFetching, isFailed } = useSelector(cmsContainerDetailsByIdSelector);
  const cmsComponents = useSelector(cmsContainerDetailsComponentsSelector);
  const cmsLinkedObjects = useSelector(cmsContainerDetailsLinkedObjectsSelector);
  const cmsLinkedObject = useSelector(cmsContainerDetailsCurrentLinkedObjectSelector(currentLinkedObjectIndex));

  const statusName: string = cmsContainer
    ? cmsContainerStatuses?.find(cs => cs.id === cmsContainer.status)?.name ?? ''
    : '';
  const isLoading = isFetching || isPausing || isResuming || isDeleting || isDuplicating || isArchiving;

  const { currentStep, steps, isLastStep, openStep, currentStepIndex, openPrevStep } = useCmsContainerStepper({
    currentStepKey: step,
    cmsComponents,
  });

  const onChangeStep = (nextStep: CmsContainerStep) => {
    openStep(nextStep);
  };

  const onNextStep = () => onChangeStep(steps[step + 1]);

  const onPanelAction = (action: PanelAction<CmsContainerActionDetailsType>) => {
    switch (action.type) {
      case ECmsContainerActionType.Pause:
        dispatch(cmsContainerDetailsPause({ id }));
        break;
      case ECmsContainerActionType.Resume:
        dispatch(cmsContainerDetailsResume({ id }));
        break;
      case ECmsContainerActionType.Archive:
        dispatch(cmsContainerDetailsArchive({ id }));
        break;
      case ECmsContainerActionType.Delete:
        dispatch(cmsContainerDetailsDelete({ id })).unwrap().then(onClose);
        break;
      case ECmsContainerActionType.Duplicate:
        dispatch(cmsContainerDetailsDuplicate({ cmsSitePage, id }))
          .unwrap()
          .then(newContainer => onCmsContainerEdit(newContainer.id));
        break;
      case ECmsContainerActionType.Edit:
        onCmsContainerEdit(id);
        break;
    }
  };

  useEffect(() => {
    dispatch(cmsContainerDetailsFetch({ id }));
    return () => {
      dispatch(cmsContainerDetailsReset());
    };
  }, [dispatch]);

  const allowedActions = useMemo(
    () =>
      getCmsContainerActionsConfigByPermissions({
        canCreate,
        canEdit,
        canPause,
        canResume,
        canArchive,
        canDelete,
      }),
    [canCreate, canEdit, canPause, canResume, canArchive, canDelete]
  );

  const actions = useMemo(
    () =>
      useCmsContainerDetailsActions({
        allowedActions,
        cmsContainer,
        lifecycle,
        isLastStep,
      }),
    [allowedActions, lifecycle, cmsContainer, isLastStep]
  );
  const { adapter: navAdapter, actions: navActions } = useNavAdapter({
    openPrevStep,
    currentStepIndex,
    openNextStep: onNextStep,
    stepsCount: steps.length,
  });

  const actionsPanel = (
    <MasterActionsComponent<CmsContainerActionDetailsType>
      show={!!actions.length || !!navActions.length}
      actions={actions}
      navAdapter={navAdapter}
      onAction={onPanelAction}
      wrapper={DefaultFooterWrapper}
    />
  );

  return (
    <Fade in>
      <ContainerWrapper>
        <StepperContainer<number>
          flow={false}
          step={currentStep}
          steps={steps}
          labelStrategy={MPStepperLabelStrategy.FromZero}
          onClick={onChangeStep}
        />
        <ContentContainer>
          <DefaultContentWrapper
            type='details'
            stickyHeader
            fullHeight
            footer={actionsPanel}
          >
            <DefaultHeader
              sticky
              headline={
                <AppBreadcrumbs>
                  <Typography color='textSecondary'>{statusName}</Typography>
                </AppBreadcrumbs>
              }
              onClose={onClose}
            >
              <TitleWrapper>
                <Typography variant='h2'>{`Блок «${cmsContainerType.name}»`}</Typography>
              </TitleWrapper>
            </DefaultHeader>
            {cmsContainer && (
              <CmsContainerDetails
                configuration={configuration}
                guid={guid}
                cmsContainer={cmsContainer}
                cmsLinkedObject={cmsLinkedObject}
                cmsLinkedObjects={cmsLinkedObjects}
                dictionaries={dictionaries}
              />
            )}
          </DefaultContentWrapper>
        </ContentContainer>
        {isLoading && (
          <LoaderWrapper>
            <ContentLoader
              size={75}
              alpha
            />
          </LoaderWrapper>
        )}
        {isFailed && <ErrorComponent />}
      </ContainerWrapper>
    </Fade>
  );
};

export default CmsContainerDetailsContainer;
