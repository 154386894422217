import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isCustomersAnyChangedSelector } from '../store/selectors';
import { customerActionsOptimize } from '../store/slice';

export const CustomerActionsOptimizerAdapter = () => {
  const dispatch = useDispatch();
  const needOptimize = useSelector(isCustomersAnyChangedSelector());

  useEffect(() => {
    if (needOptimize > 0) {
      dispatch(customerActionsOptimize());
    }
  }, [needOptimize]);

  return null;
};
