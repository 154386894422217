import Api from '@/data/api';
import { SportOptionTyped } from '@/domain/model';
import { pageSizeAll } from '@/domain/model/constants';
import { ECorpPersonalPromotionType, EOfferActivationStatus, EUserRole } from '@/domain/model/enums';
import {
  DataFilterQueryDslOperator,
  DataFilterStrategyBase,
  DataFilterStrategyListItem,
  DataFilterValueItem,
  EDataFilterType,
} from '@/domain/model/filter';
import { EDateFormat } from '@/domain/model/formats';
import { Nullable } from '@/domain/model/types';
import { formatDateRange } from '@/presentation/utils';
import { ECorpActivationTableColumn } from '@features/corpActivation/types';
import { getCorpOfferActivationStatusText } from '@features/corpActivation/utils/common';
import { TradeActivationsFilterValues } from '@features/tradeActivation/utils/filter';
import moment from 'moment-timezone';
import { userInfoView } from 'presentation/utils/userInfoView';

export enum ECorpActivationsFilterItem {
  /** Строка запроса */
  Query = 'query',
  /** Дата создания */
  CreatedAt = 'createdAt',
  /** Код КП */
  OfferCode = 'offer.code',
  /** Название КП */
  OfferName = 'offer.name',
  /** Статус выдачи - одобрен, не одобрен */
  Status = 'status',
  /** Тип промоужен - внешний, внутренний сертификат */
  PromotionType = 'promotionType',
  /** Партнер */
  Partner = 'offer.partner.id',
  /** Присвоенный код */
  PromotionCode = 'promotion.code',
  /** Табельный номер */
  CustomerPernr = 'customer.pernr',
  /** Фамилия сотрудника */
  CustomerLastName = 'customer.lastName',
  CustomerFirstName = 'customer.firstName',
  CustomerMiddleName = 'customer.middleName',
  /** Кем выдано */
  GivenBy = 'givenBy.id',
}

export const corpActivationsFilterItems: Record<ECorpActivationTableColumn, ECorpActivationsFilterItem[]> = {
  [ECorpActivationTableColumn.CreateAt]: [ECorpActivationsFilterItem.CreatedAt],
  [ECorpActivationTableColumn.OfferCode]: [ECorpActivationsFilterItem.OfferCode],
  [ECorpActivationTableColumn.OfferName]: [ECorpActivationsFilterItem.OfferName],
  [ECorpActivationTableColumn.Status]: [ECorpActivationsFilterItem.Status],
  [ECorpActivationTableColumn.PromotionType]: [ECorpActivationsFilterItem.PromotionType],
  [ECorpActivationTableColumn.Partner]: [ECorpActivationsFilterItem.Partner],
  [ECorpActivationTableColumn.PromotionCode]: [ECorpActivationsFilterItem.PromotionCode],
  [ECorpActivationTableColumn.Price]: [],
  [ECorpActivationTableColumn.CustomerPernr]: [ECorpActivationsFilterItem.CustomerPernr],
  [ECorpActivationTableColumn.CustomerName]: [
    ECorpActivationsFilterItem.CustomerLastName,
    ECorpActivationsFilterItem.CustomerFirstName,
    ECorpActivationsFilterItem.CustomerMiddleName,
  ],
  [ECorpActivationTableColumn.CustomerEmail]: [],
  [ECorpActivationTableColumn.CustomerPhone]: [],
  [ECorpActivationTableColumn.GivenBy]: [ECorpActivationsFilterItem.GivenBy],
  [ECorpActivationTableColumn.GivenAt]: [],
};

export type CorpActivationsFilterEditStrategy = DataFilterStrategyBase<ECorpActivationsFilterItem>;

export type CorpActivationsFilterValues = {
  readonly [ECorpActivationsFilterItem.Query]?: DataFilterValueItem<Nullable<string>>;
  readonly [ECorpActivationsFilterItem.CreatedAt]?: DataFilterValueItem<Nullable<[Nullable<string>, Nullable<string>]>>;
  readonly [ECorpActivationsFilterItem.OfferCode]?: DataFilterValueItem<Nullable<string>>;
  readonly [ECorpActivationsFilterItem.OfferName]?: DataFilterValueItem<Nullable<string>>;
  readonly [ECorpActivationsFilterItem.Status]?: DataFilterValueItem<Nullable<EOfferActivationStatus>>;
  readonly [ECorpActivationsFilterItem.CustomerPernr]?: DataFilterValueItem<Nullable<string>>;
  readonly [ECorpActivationsFilterItem.CustomerLastName]?: DataFilterValueItem<Nullable<string>>;
  readonly [ECorpActivationsFilterItem.CustomerFirstName]?: DataFilterValueItem<Nullable<string>>;
  readonly [ECorpActivationsFilterItem.CustomerMiddleName]?: DataFilterValueItem<Nullable<string>>;
  readonly [ECorpActivationsFilterItem.PromotionType]?: DataFilterValueItem<Nullable<ECorpPersonalPromotionType[]>>;
  readonly [ECorpActivationsFilterItem.PromotionCode]?: DataFilterValueItem<Nullable<string>>;
  readonly [ECorpActivationsFilterItem.Partner]?: DataFilterValueItem<Nullable<DataFilterStrategyListItem[]>>;
  readonly [ECorpActivationsFilterItem.GivenBy]?: DataFilterValueItem<Nullable<DataFilterStrategyListItem[]>>;
};

const getCreatedDatePreview = (
  date: CorpActivationsFilterValues[ECorpActivationsFilterItem.CreatedAt]
): Nullable<string> => {
  const range = formatDateRange(date?.value?.[0] ?? null, date?.value?.[1] ?? null, (v: Nullable<string>) =>
    moment(v).format(EDateFormat.DisplayDefault)
  );
  return range ? 'Создан ' + range : null;
};

const getCustomerFullName = (values: TradeActivationsFilterValues): Nullable<string> =>
  [
    values[ECorpActivationsFilterItem.CustomerLastName]?.value,
    values[ECorpActivationsFilterItem.CustomerFirstName]?.value,
    values[ECorpActivationsFilterItem.CustomerMiddleName]?.value,
  ]
    .filter(v => !!v)
    .join(' ')
    .trim() || null;

export const getCorpActivationFilterStrategy = (
  filterItem: ECorpActivationsFilterItem,
  values: CorpActivationsFilterValues,
  promotionTypes: SportOptionTyped<ECorpPersonalPromotionType>[],
  partnerId: Nullable<UUID>
): Nullable<CorpActivationsFilterEditStrategy> => {
  const givenStatuses: SportOptionTyped<EOfferActivationStatus>[] = [
    {
      id: EOfferActivationStatus.Given,
      name: getCorpOfferActivationStatusText(EOfferActivationStatus.Given),
    },
    {
      id: EOfferActivationStatus.Approved,
      name: getCorpOfferActivationStatusText(EOfferActivationStatus.Approved),
    },
  ];
  switch (filterItem) {
    case ECorpActivationsFilterItem.Query:
      return {
        type: EDataFilterType.String,
        key: ECorpActivationsFilterItem.Query,
        label: 'Поиск по ключевому слову',
        preview: values[ECorpActivationsFilterItem.Query]?.value || null,
        value: values[ECorpActivationsFilterItem.Query]?.value || null,
      };
    case ECorpActivationsFilterItem.CreatedAt:
      return {
        type: EDataFilterType.DatePeriod,
        key: ECorpActivationsFilterItem.CreatedAt,
        label: '',
        labelPeriod: {
          from: 'Создан с',
          to: 'по',
        },
        preview: getCreatedDatePreview(values[ECorpActivationsFilterItem.CreatedAt]),
        value: values[ECorpActivationsFilterItem.CreatedAt]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Between,
        },
      };
    case ECorpActivationsFilterItem.OfferCode:
      return {
        type: EDataFilterType.String,
        key: ECorpActivationsFilterItem.OfferCode,
        label: 'Код КП',
        preview: values[ECorpActivationsFilterItem.OfferCode]?.value || null,
        value: values[ECorpActivationsFilterItem.OfferCode]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case ECorpActivationsFilterItem.OfferName:
      return {
        type: EDataFilterType.String,
        key: ECorpActivationsFilterItem.OfferName,
        label: 'Название',
        preview: values[ECorpActivationsFilterItem.OfferName]?.value || null,
        value: values[ECorpActivationsFilterItem.OfferName]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case ECorpActivationsFilterItem.PromotionCode:
      return {
        type: EDataFilterType.String,
        key: ECorpActivationsFilterItem.PromotionCode,
        label: 'Присвоенный код',
        preview: values[ECorpActivationsFilterItem.PromotionCode]?.value || null,
        value: values[ECorpActivationsFilterItem.PromotionCode]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case ECorpActivationsFilterItem.CustomerPernr:
      return {
        type: EDataFilterType.String,
        key: ECorpActivationsFilterItem.CustomerPernr,
        label: 'Табельный №',
        preview: values[ECorpActivationsFilterItem.CustomerPernr]?.value || null,
        value: values[ECorpActivationsFilterItem.CustomerPernr]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case ECorpActivationsFilterItem.CustomerLastName:
      return {
        type: EDataFilterType.String,
        key: ECorpActivationsFilterItem.CustomerLastName,
        label: 'Фамилия',
        group: 'customer_full_name',
        preview: getCustomerFullName(values),
        value: values[ECorpActivationsFilterItem.CustomerLastName]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case ECorpActivationsFilterItem.CustomerFirstName:
      return {
        type: EDataFilterType.String,
        key: ECorpActivationsFilterItem.CustomerFirstName,
        label: 'Имя',
        group: 'customer_full_name',
        preview: getCustomerFullName(values),
        value: values[ECorpActivationsFilterItem.CustomerFirstName]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case ECorpActivationsFilterItem.CustomerMiddleName:
      return {
        type: EDataFilterType.String,
        key: ECorpActivationsFilterItem.CustomerMiddleName,
        label: 'Отчество',
        group: 'customer_full_name',
        preview: getCustomerFullName(values),
        value: values[ECorpActivationsFilterItem.CustomerMiddleName]?.value || null,
        querydsl: {
          operator: DataFilterQueryDslOperator.Like,
        },
      };
    case ECorpActivationsFilterItem.Partner:
      return {
        type: EDataFilterType.AutocompleteMultiple,
        key: ECorpActivationsFilterItem.Partner,
        label: 'Партнёр',
        value: values[ECorpActivationsFilterItem.Partner]?.value || null,
        preview: `Партнер (${values[ECorpActivationsFilterItem.Partner]?.value?.length})` || null,
        previews: values[ECorpActivationsFilterItem.Partner]?.value?.map(t => t.name) ?? [],
        source: (name: string) =>
          name
            ? Api.partner
                .all({
                  query: name,
                  sort: 'name,asc',
                  page: 1,
                  pageSize: pageSizeAll,
                })
                .then(response => response.data)
            : Promise.resolve([]),
        querydsl: {
          operator: DataFilterQueryDslOperator.In,
          valueAttribute: 'id',
        },
      };
    case ECorpActivationsFilterItem.PromotionType:
      return {
        type: EDataFilterType.ListMultiple,
        key: ECorpActivationsFilterItem.PromotionType,
        label: 'Способ получения',
        preview: `Способ получения (${values[ECorpActivationsFilterItem.PromotionType]?.value?.length})`,
        previews: promotionTypes
          .filter(g => values[ECorpActivationsFilterItem.PromotionType]?.value?.some(v => v === g.id))
          .map(t => t.name),
        value: values[ECorpActivationsFilterItem.PromotionType]?.value || null,
        items: promotionTypes,
        querydsl: {
          operator: DataFilterQueryDslOperator.Equals,
        },
      };
    case ECorpActivationsFilterItem.Status:
      return {
        type: EDataFilterType.ListSingle,
        key: ECorpActivationsFilterItem.Status,
        label: 'Статус выдачи',
        preview: givenStatuses.find(g => g.id === values[ECorpActivationsFilterItem.Status]?.value)?.name || null,
        value: values[ECorpActivationsFilterItem.Status]?.value || null,
        items: givenStatuses,
        querydsl: {
          operator: DataFilterQueryDslOperator.Equals,
        },
      };
    case ECorpActivationsFilterItem.GivenBy:
      return {
        type: EDataFilterType.AutocompleteMultiple,
        key: ECorpActivationsFilterItem.GivenBy,
        label: 'Кем выдано',
        value: values[ECorpActivationsFilterItem.GivenBy]?.value || null,
        preview: `Кем выдано (${values[ECorpActivationsFilterItem.GivenBy]?.value?.length})` || null,
        previews: values[ECorpActivationsFilterItem.GivenBy]?.value?.map(t => t.name) ?? [],
        source: (name: string) =>
          name
            ? (partnerId
                ? Api.partner.employees({
                    partnerId,
                    query: name,
                    sort: ['lastName,asc', 'firstName,asc', 'middleName,asc'],
                    page: 1,
                    pageSize: pageSizeAll,
                  })
                : Api.user.mp.all({
                    query: name,
                    roles: [EUserRole.AdminMp, EUserRole.AdminMpCorp, EUserRole.AdminPartner, EUserRole.ManagerPartner],
                    sort: ['lastName,asc', 'firstName,asc', 'middleName,asc'],
                    page: 1,
                    pageSize: pageSizeAll,
                  })
              ).then(response => response.data.map(item => ({ ...item, name: userInfoView(item).fullName })))
            : Promise.resolve([]),
        querydsl: {
          operator: DataFilterQueryDslOperator.In,
          valueAttribute: 'id',
        },
      };
  }
};
