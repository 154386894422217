import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault } from '@/data/store/types';
import {
  BannerPlace,
  CustomerOrderCancellationReasonType,
  EAdCampaignStatus,
  EAdCampaignType,
  EChatType,
  EClientOrgStatus,
  ECmsBannerLinkObjectType,
  ECmsContainerStatus,
  ECmsContainerType,
  EComplaintObjectType,
  ECorpPersonalPromotionType,
  EExternalCorpOfferType,
  EMPUserGroup,
  EOfferStatus,
  EOfferType,
  EPartnerOwnershipType,
  EPartnerType,
  EPlaygroundModerationStatus,
  EPlaygroundStatus,
  EPrivilegeActorType,
  EServiceOrderByDateType,
  ESocialPackageAsutrStatus,
  ESportEventApplicationRejectReason,
  ESportEventApplicationStatus,
  ESportEventClassificationType,
  ESportEventLevel,
  ESportEventParticipationFormat,
  ESportEventStatus,
  ESportEventType,
  ETaxationSystemType,
  ETradeOfferType,
  EUserGender,
  EUserType,
  OfferPausedReason,
  OrderCancellationReasonType,
  SportEnumOption,
  SportOption,
  SportOptionTyped,
  TradePaymentType,
} from '@/domain';
import { createAsyncThunk, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import Api from '../../../../../data/api';
import ErrorHandler from '../../../../../data/network/errorHandler';
import {
  adCampaignStatus,
  adCampaignType,
  clientOrgStatus,
  cmsBannerLinkedObjectType,
  cmsContainerStatus,
  complaintType,
  corpPersonalPromotionType,
  eventApplicationRejectReason,
  eventApplicationStatus,
  eventClassification,
  eventParticipationFormats,
  externalCorpOfferType,
  offerStatus,
  offerType,
  partnerOwnershipType,
  partnerType,
  playgroundModerationStatus,
  privilegeActorType,
  productCountStatus,
  productStatus,
  serviceOrderByDateType,
  socialPackageAsutrStatus,
  taxationSystemType,
  tradeOfferType,
  tradePaymentType,
  userGender,
  userMpGroup,
  userType,
} from '../index';

interface NsiSportData {
  /** Типы площадок */
  readonly playgroundTypes: SportOption[];
  /** Статусы площадок */
  readonly playgroundStatuses: SportOptionTyped<EPlaygroundStatus>[];
  /** Статусы модерирования площадки */
  readonly playgroundModerationStatuses: SportOptionTyped<EPlaygroundModerationStatus>[];
  /** Типы мероприятий */
  readonly eventTypes: SportOptionTyped<ESportEventType>[];
  /** Уровни мероприятий */
  readonly eventLevels: SportOptionTyped<ESportEventLevel>[];
  /** Статусы мероприятий */
  readonly eventStatuses: SportOptionTyped<ESportEventStatus>[];
  /** Дороги */
  readonly roads: SportOption[];
  /** Классификация мероприятий */
  readonly eventClassifications: SportOptionTyped<ESportEventClassificationType>[];
  /** Статусы заявок для мероприятий */
  readonly eventApplicationStatuses: SportOptionTyped<ESportEventApplicationStatus>[];
  /** Причины заявок для мероприятий */
  readonly eventApplicationRejectReasons: SportOptionTyped<ESportEventApplicationRejectReason>[];
  /** Список жалоб */
  readonly complaintTypes: SportOptionTyped<EComplaintObjectType>[];
  /** Список причин жалоб */
  readonly complaintCauses: SportOption[];
  /** Форматы участия в мероприятии */
  readonly participationFormats: SportOptionTyped<ESportEventParticipationFormat>[];
  /** Типы чатов */
  readonly chatTypes: SportOptionTyped<EChatType>[];
}

interface NsiPrivilegeData {
  readonly productStatuses: SportOptionTyped<EOfferStatus>[];
  readonly productCountStatuses: SportOption[];
  readonly promotionTypes: SportOptionTyped<ETradeOfferType>[];
  readonly corpPromotionTypes: SportOptionTyped<ECorpPersonalPromotionType>[];
  readonly tradePaymentTypes: SportOptionTyped<TradePaymentType>[];
  readonly externalCorpOfferTypes: SportOptionTyped<EExternalCorpOfferType>[];

  readonly partnerOwnerShipTypes: SportOptionTyped<EPartnerOwnershipType>[];
  readonly taxationSystemTypes: SportOptionTyped<ETaxationSystemType>[];
  readonly partnerRejectReasons: SportOption[];
  readonly offerRejectReasons: SportOption[];
  readonly offerPauseReasons: OfferPausedReason[];
  readonly orderCancelReasons: OrderCancellationReasonType[];
  readonly customerOrderCancelReasons: CustomerOrderCancellationReasonType[];
  readonly userMpGroups: SportOptionTyped<EMPUserGroup>[];
  readonly bannerPlaces: BannerPlace[];
  readonly partnerTypes: SportOptionTyped<EPartnerType>[];
  readonly offerStatuses: SportOptionTyped<EOfferStatus>[];
  readonly privilegeActorTypes: SportOptionTyped<EPrivilegeActorType>[];
  readonly cmsContainerTypes: SportEnumOption<ECmsContainerType>[];
  readonly cmsContainerStatuses: SportOptionTyped<ECmsContainerStatus>[];
  readonly offerTypes: SportOptionTyped<EOfferType>[];
  readonly cmsBannerLinkedObjectTypes: SportOptionTyped<ECmsBannerLinkObjectType>[];
  readonly serviceOrderByDateTypes: SportOptionTyped<EServiceOrderByDateType>[];
  readonly adCampaignStatuses: SportOptionTyped<EAdCampaignStatus>[];
  readonly adCampaignTypes: SportOptionTyped<EAdCampaignType>[];
  readonly clientOrgStatuses: SportOptionTyped<EClientOrgStatus>[];
  readonly socialPackageRejectReasons: SportOption[];
  readonly socialPackageAsutrStatuses: SportOptionTyped<ESocialPackageAsutrStatus>[];
}

type NsiData = NsiSportData &
  NsiPrivilegeData & {
    /** Пользовательские гендеры */
    readonly userGenders: SportOptionTyped<EUserGender>[];
    /** Типы пользователей */
    readonly userTypes: SportOptionTyped<EUserType>[];
  };

interface NsiState extends Fetchable {
  data: NsiData;
}

const initialDataState: NsiData = {
  userGenders: Object.values(userGender),
  userTypes: Object.values(EUserType).map(item => ({
    ...userType[item],
    code: userType[item].id,
  })),
  playgroundTypes: [],
  playgroundStatuses: [],
  playgroundModerationStatuses: [],
  eventTypes: [],
  eventLevels: [],
  eventStatuses: [],
  eventApplicationStatuses: [],
  eventClassifications: [],
  productStatuses: [],
  productCountStatuses: [],
  chatTypes: [],
  promotionTypes: [],
  corpPromotionTypes: [],
  tradePaymentTypes: [],
  externalCorpOfferTypes: [],
  complaintTypes: [],
  complaintCauses: [],
  partnerOwnerShipTypes: [],
  taxationSystemTypes: [],
  roads: [],
  partnerRejectReasons: [],
  offerRejectReasons: [],
  offerPauseReasons: [],
  orderCancelReasons: [],
  customerOrderCancelReasons: [],
  socialPackageRejectReasons: [],
  userMpGroups: [],
  bannerPlaces: [],
  partnerTypes: [],
  offerStatuses: [],
  privilegeActorTypes: [],
  cmsContainerTypes: [],
  cmsContainerStatuses: [],
  offerTypes: [],
  eventApplicationRejectReasons: [],
  cmsBannerLinkedObjectTypes: [],
  serviceOrderByDateTypes: [],
  participationFormats: [],
  adCampaignStatuses: [],
  adCampaignTypes: [],
  clientOrgStatuses: [],
  socialPackageAsutrStatuses: [],
};

export const nsiSportFetch = createAsyncThunk<NsiSportData, undefined, AppThunkAPIConfig>(
  'nsi/sport/fetch',
  async (props, { rejectWithValue }) => {
    try {
      const [
        playgroundStatusesResponse,
        eventTypesResponse,
        playgroundTypesResponse,
        chatTypesResponse,
        complaintCausesResponse,
        roadsResponse,
        eventLevelsResponse,
        eventStatusesResponse,
      ] = await Promise.allSettled([
        Api.nsi.playgroundStatuses(),
        Api.nsi.eventTypes(),
        Api.nsi.playgroundTypes(),
        Api.nsi.chatTypes(),
        Api.nsi.complaintCauses(),
        Api.nsi.roads(),
        Api.nsi.eventLevels(),
        Api.nsi.eventStatuses(),
      ]);

      const playgroundStatuses: SportOptionTyped<EPlaygroundStatus>[] = [];
      if (playgroundStatusesResponse.status === 'fulfilled') {
        try {
          playgroundStatuses.push(
            ...playgroundStatusesResponse.value.data.map(item => ({
              id: item.code,
              name: item.displayName,
            }))
          );
        } catch (e: any) {
          console.warn('Nsi prepare error', e);
        }
      }

      const eventTypes: SportOptionTyped<ESportEventType>[] = [];
      if (eventTypesResponse.status === 'fulfilled') {
        try {
          eventTypes.push(
            ...eventTypesResponse.value.data.map(item => ({
              code: item.code,
              id: item.code,
              name: item.displayName,
            }))
          );
        } catch (e: any) {
          console.warn('Nsi prepare error', e);
        }
      }

      const playgroundTypes: SportOption[] = [];
      if (playgroundTypesResponse.status === 'fulfilled') {
        try {
          playgroundTypes.push(...playgroundTypesResponse.value.data);
        } catch (e: any) {
          console.warn('Nsi prepare error', e);
        }
      }

      const chatTypes: SportOptionTyped<EChatType>[] = [];
      if (chatTypesResponse.status === 'fulfilled') {
        try {
          chatTypes.push(
            ...chatTypesResponse.value.data.map(item => ({
              code: item.code,
              id: item.code,
              name: item.displayName,
            }))
          );
        } catch (e: any) {
          console.warn('Nsi prepare error', e);
        }
      }

      const complaintCauses: SportOption[] = [];
      if (complaintCausesResponse.status === 'fulfilled') {
        try {
          complaintCauses.push(
            ...complaintCausesResponse.value.data.map(item => ({
              id: item.id,
              code: item.code,
              name: item.name,
            }))
          );
        } catch (e: any) {
          console.warn('Nsi prepare error', e);
        }
      }

      const roads: SportOption[] = [];
      if (roadsResponse.status === 'fulfilled') {
        try {
          roads.push(
            ...roadsResponse.value.data
              .map(item => ({
                id: item.id,
                code: item.code,
                name: item.name,
              }))
              .sort((a, b) => (a.name > b.name ? 1 : -1))
          );
        } catch (e: any) {
          console.warn('Nsi prepare error', e);
        }
      }

      const eventLevels: SportOptionTyped<ESportEventLevel>[] = [];
      if (eventLevelsResponse.status === 'fulfilled') {
        try {
          eventLevels.push(
            ...eventLevelsResponse.value.data.map(item => ({
              ...item,
              id: item.code,
              name: item.displayName,
            }))
          );
        } catch (e: any) {
          console.warn('Nsi prepare error', e);
        }
      }

      const eventStatuses: SportOptionTyped<ESportEventStatus>[] = [];
      if (eventStatusesResponse.status === 'fulfilled') {
        try {
          eventStatuses.push(
            ...eventStatusesResponse.value.data.map(item => ({
              ...item,
              id: item.code,
              name: item.displayName,
            }))
          );
        } catch (e: any) {
          console.warn('Nsi prepare error', e);
        }
      }

      const eventApplicationRejectReasons: SportOptionTyped<ESportEventApplicationRejectReason>[] = Object.values(
        ESportEventApplicationRejectReason
      ).map(item => ({
        ...eventApplicationRejectReason[item],
        code: eventApplicationRejectReason[item].id,
      }));

      const complaintTypes: NsiSportData['complaintTypes'] = Object.values(complaintType);
      const playgroundModerationStatuses: SportOptionTyped<EPlaygroundModerationStatus>[] =
        Object.values(playgroundModerationStatus);
      const eventClassifications: NsiSportData['eventClassifications'] = Object.values(eventClassification);
      const participationFormats: NsiSportData['participationFormats'] = Object.values(
        ESportEventParticipationFormat
      ).map(item => ({
        ...eventParticipationFormats[item],
        code: eventParticipationFormats[item].id,
      }));
      const eventApplicationStatuses: NsiSportData['eventApplicationStatuses'] = Object.values(
        ESportEventApplicationStatus
      ).map(item => ({
        ...eventApplicationStatus[item],
        code: eventApplicationStatus[item].id,
      }));

      return {
        playgroundStatuses,
        playgroundTypes,
        complaintCauses,
        eventStatuses,
        roads,
        eventTypes,
        eventLevels,
        chatTypes,
        eventApplicationRejectReasons,
        complaintTypes,
        eventClassifications,
        playgroundModerationStatuses,
        participationFormats,
        eventApplicationStatuses,
      } satisfies NsiSportData;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const nsiPrivilegeFetch = createAsyncThunk<NsiPrivilegeData, undefined, AppThunkAPIConfig>(
  'nsi/privilege/fetch',
  async (props, { rejectWithValue }) => {
    try {
      const [
        partnerRejectReasonsResponse,
        offerRejectReasonsResponse,
        offerPauseReasonsResponse,
        orderCancelReasonsResponse,
        customerOrderCancelReasonsResponse,
        bannerPlacesResponse,
        cmsContainerTypesResponse,
        socialPackageRejectReasonsResponse,
      ] = await Promise.allSettled([
        Api.nsi.partnerRejectReasons(),
        Api.nsi.offerRejectReasons(),
        Api.nsi.offerPauseReasons(),
        Api.nsi.orderCancelReasons(),
        Api.nsi.customerOrderCancelReasons(),
        Api.nsi.bannerPlaces(),
        Api.nsi.cmsContainerTypes(),
        Api.nsi.socialPackageRejectReasons(),
      ]);

      const bannerPlaces: BannerPlace[] = [];
      if (bannerPlacesResponse.status === 'fulfilled') {
        try {
          bannerPlaces.push(...bannerPlacesResponse.value.data);
        } catch (e: any) {
          console.warn('Nsi prepare error', e);
        }
      }

      const cmsContainerTypes: SportEnumOption<ECmsContainerType>[] = [];
      if (cmsContainerTypesResponse.status === 'fulfilled') {
        try {
          cmsContainerTypes.push(...cmsContainerTypesResponse.value.data);
        } catch (e: any) {
          console.warn('Nsi prepare error', e);
        }
      }

      const partnerRejectReasons: SportOption[] = [];
      if (partnerRejectReasonsResponse.status === 'fulfilled') {
        try {
          partnerRejectReasons.push(
            ...partnerRejectReasonsResponse.value.data.map(item => ({
              id: item.id,
              code: item.code,
              name: item.name,
            }))
          );
        } catch (e: any) {
          console.warn('Nsi prepare error', e);
        }
      }

      const offerRejectReasons: SportOption[] = [];
      if (offerRejectReasonsResponse.status === 'fulfilled') {
        try {
          offerRejectReasons.push(
            ...offerRejectReasonsResponse.value.data.map(item => ({
              id: item.id,
              code: item.code,
              name: item.name,
            }))
          );
        } catch (e: any) {
          console.warn('Nsi prepare error', e);
        }
      }

      const offerPauseReasons: OfferPausedReason[] = [];
      if (offerPauseReasonsResponse.status === 'fulfilled') {
        try {
          offerPauseReasons.push(
            ...offerPauseReasonsResponse.value.data.map(item => ({
              id: item.id,
              code: item.code,
              name: item.name,
              system: item.system,
            }))
          );
        } catch (e: any) {
          console.warn('Nsi prepare error', e);
        }
      }

      const socialPackageRejectReasons: SportOption[] = [];
      if (socialPackageRejectReasonsResponse.status === 'fulfilled') {
        try {
          socialPackageRejectReasons.push(
            ...socialPackageRejectReasonsResponse.value.data.map(item => ({
              id: item.id,
              code: item.code,
              name: item.name,
            }))
          );
        } catch (e: any) {
          console.warn('Nsi prepare error', e);
        }
      }

      const orderCancelReasons: OrderCancellationReasonType[] = [];
      if (orderCancelReasonsResponse.status === 'fulfilled') {
        try {
          orderCancelReasons.push(
            ...orderCancelReasonsResponse.value.data.map(item => ({
              id: item.id,
              offerType: item.offerType,
              name: item.name,
            }))
          );
        } catch (e: any) {
          console.warn('Nsi prepare error', e);
        }
      }

      const customerOrderCancelReasons: CustomerOrderCancellationReasonType[] = [];
      if (customerOrderCancelReasonsResponse.status === 'fulfilled') {
        try {
          customerOrderCancelReasons.push(
            ...customerOrderCancelReasonsResponse.value.data.map(item => ({
              id: item.id,
              offerType: item.offerType,
              name: item.name,
            }))
          );
        } catch (e: any) {
          console.warn('Nsi prepare error', e);
        }
      }

      const userMpGroups: SportOptionTyped<EMPUserGroup>[] = Object.values(EMPUserGroup).map(item => ({
        ...userMpGroup[item],
        code: userMpGroup[item].id,
      }));

      const productStatuses: any = Object.values(productStatus);
      const productCountStatuses: any = Object.values(productCountStatus);
      const promotionTypes: SportOptionTyped<ETradeOfferType>[] = Object.values(tradeOfferType);
      const tradePaymentTypes: SportOptionTyped<TradePaymentType>[] = Object.values(tradePaymentType);
      const corpPromotionTypes: SportOptionTyped<ECorpPersonalPromotionType>[] =
        Object.values(corpPersonalPromotionType);
      const externalCorpOfferTypes: SportOptionTyped<EExternalCorpOfferType>[] = Object.values(externalCorpOfferType);
      const partnerOwnerShipTypes: SportOptionTyped<EPartnerOwnershipType>[] = Object.values(partnerOwnershipType);
      const taxationSystemTypes: SportOptionTyped<ETaxationSystemType>[] = Object.values(taxationSystemType);
      const partnerTypes: SportOptionTyped<EPartnerType>[] = Object.values(partnerType);
      const offerStatuses: SportOptionTyped<EOfferStatus>[] = Object.values(offerStatus);
      const privilegeActorTypes: SportOptionTyped<EPrivilegeActorType>[] = Object.values(privilegeActorType);
      const cmsContainerStatuses: SportOptionTyped<ECmsContainerStatus>[] = Object.values(cmsContainerStatus);
      const offerTypes: SportOptionTyped<EOfferType>[] = Object.values(offerType);
      const cmsBannerLinkedObjectTypes: SportOptionTyped<ECmsBannerLinkObjectType>[] =
        Object.values(cmsBannerLinkedObjectType);
      const serviceOrderByDateTypes: SportOptionTyped<EServiceOrderByDateType>[] =
        Object.values(serviceOrderByDateType);
      const adCampaignStatuses: SportOptionTyped<EAdCampaignStatus>[] = Object.values(adCampaignStatus);
      const adCampaignTypes: SportOptionTyped<EAdCampaignType>[] = Object.values(adCampaignType);
      const clientOrgStatuses: SportOptionTyped<EClientOrgStatus>[] = Object.values(clientOrgStatus);
      const socialPackageAsutrStatuses: SportOptionTyped<ESocialPackageAsutrStatus>[] =
        Object.values(socialPackageAsutrStatus);

      return {
        bannerPlaces,
        cmsContainerTypes,
        partnerRejectReasons,
        offerRejectReasons,
        offerPauseReasons,
        socialPackageRejectReasons,
        orderCancelReasons,
        customerOrderCancelReasons,
        userMpGroups,
        productStatuses,
        productCountStatuses,
        promotionTypes,
        tradePaymentTypes,
        corpPromotionTypes,
        externalCorpOfferTypes,
        partnerOwnerShipTypes,
        taxationSystemTypes,
        partnerTypes,
        offerStatuses,
        privilegeActorTypes,
        cmsContainerStatuses,
        offerTypes,
        cmsBannerLinkedObjectTypes,
        serviceOrderByDateTypes,
        adCampaignStatuses,
        adCampaignTypes,
        clientOrgStatuses,
        socialPackageAsutrStatuses,
      } satisfies NsiPrivilegeData;
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

const slice = createSlice<NsiState, SliceCaseReducers<NsiState>, 'nsi'>({
  name: 'nsi',
  initialState: {
    ...fetchableDefault,
    data: initialDataState,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(nsiSportFetch.pending, state => {
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;
        state.data = initialDataState;
      })
      .addCase(nsiSportFetch.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;
        state.data = {
          ...state.data,
          ...payload,
        };
      })
      .addCase(nsiSportFetch.rejected, state => {
        state.isFetching = false;
        state.isFetched = false;
        state.isFailed = true;
        state.data = initialDataState;
      })
      .addCase(nsiPrivilegeFetch.pending, state => {
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;
        state.data = initialDataState;
      })
      .addCase(nsiPrivilegeFetch.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;
        state.data = {
          ...state.data,
          ...payload,
        };
      })
      .addCase(nsiPrivilegeFetch.rejected, state => {
        state.isFetching = false;
        state.isFetched = false;
        state.isFailed = true;
        state.data = initialDataState;
      });
  },
});

export default slice.reducer;
