import { Nullable, RzdRestSocialPackageApplication } from '@/domain';
import axios, { AxiosResponse } from 'axios';
import { createCancelToken } from 'data/api/index';
import {
  ApiCancellable,
  ApiQueryDsl,
  ApiRequestListDiscriminator,
  ApiRequestPageable,
  ApiResponseMaBeCounters,
} from './types';
import { appendQueryDslParams, getComServicesEndpoint } from './utils';

type AllCommandProps<D extends ApiRequestListDiscriminator> = ApiRequestPageable &
  ApiCancellable & {
    readonly discriminator?: D;
    readonly querydsl?: Nullable<ApiQueryDsl>;
  };

type SocialPackageApplicationApi = {
  readonly all: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: AllCommandProps<D>
  ) => Promise<AxiosResponse<ApiResponseMaBeCounters<D, RzdRestSocialPackageApplication[]>>>;
};

const socialPackageApplication: SocialPackageApplicationApi = {
  all: props => {
    const { discriminator, page, pageSize, querydsl, sort, signal } = props;
    const params = new URLSearchParams();

    if (sort) {
      if (typeof sort === 'string') {
        params.append('sort', sort);
      } else {
        sort.forEach(item => params.append('sort', item));
      }
    }

    params.append('page', (page - 1).toString(10));
    params.append('size', pageSize.toString(10));

    if (discriminator) {
      params.append('resultType', discriminator);
    }

    if (querydsl) {
      appendQueryDslParams(params, querydsl);
    }

    return axios.get(`${getComServicesEndpoint()}/social-packages/applications`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
};

export default socialPackageApplication;
