import { useEffect, useState } from 'react';
import { ValidationResult, ValidationRules } from '../../utils/validation';
import validateObject from './utils';

export type ResultsValidateObjectType<T extends any[] = []> = {
  isValid: boolean;
  results: ValidationResult<T | null>[];
};

export interface UseValidationMasterStepsProps<T extends any[]> {
  readonly currentIndex: number;
  readonly data: Nullable<T>;
  readonly validateOnChange?: boolean;
  readonly getRules: <Index extends number>(stepIndex: Index) => ValidationRules<T[Index]>;
}

export type UseValidationMasterSteps<T extends any[]> = {
  readonly getValidationResultByIndex: <Index extends number>(
    stepIndex: Index
  ) => Nullable<ValidationResult<Nullable<T[Index]>>>;
  readonly validateAll: () => ResultsValidateObjectType<T>;
  readonly validateCurrent: () => boolean;
  readonly validate: (index: number) => boolean;
  readonly isValid: (index: number) => boolean;
  readonly result: any;
};

function useValidationMasterSteps<T extends any[]>(
  props: UseValidationMasterStepsProps<T>
): UseValidationMasterSteps<T> {
  const { currentIndex, data, validateOnChange, getRules } = props;

  const [result, setResult] = useState<Nullable<ValidationResult<Nullable<T>>[]>>(null);

  const getValidationResultByIndex = (index: number) => result?.[index] ?? null;

  const validateStepInternal = (index: number): Nullable<ReturnType<typeof validateObject>> => {
    const stepData = data?.[index];
    if (stepData) {
      const rules = getRules(index);
      return validateObject<T>(stepData, rules);
    } else {
      return null;
    }
  };

  const isValid = (index: number): boolean => Object.keys(result?.[index] ?? {}).length === 0;

  const validate = (index: number): boolean => {
    const results: typeof result = [...(result ?? [])];

    const validateStepResult = validateStepInternal(index);
    if (validateStepResult) {
      results[index] = validateStepResult.results;
    } else {
      results[index] = null;
    }

    setResult(results);
    return validateStepResult?.isValid ?? true;
  };

  const validateAll = () => {
    if (data === null) {
      setResult(null);
      return { isValid: true, results: [] };
    }

    const results: typeof result = [];
    let hasErrors = false;

    data?.forEach((data, index) => {
      const validateStepResult = validateStepInternal(index);
      if (validateStepResult) {
        results.push(validateStepResult.results);
        if (!validateStepResult.isValid) {
          hasErrors = true;
        }
      } else {
        results.push(null);
      }
    });

    if (Object.keys(results).length === 0) {
      setResult(null);
      return { isValid: true, results: [] };
    }

    setResult(results);
    return { isValid: !hasErrors, results };
  };

  const validateCurrent = (): boolean => {
    const results: typeof result = [...(result ?? [])];

    const validateStepResult = validateStepInternal(currentIndex);
    if (validateStepResult) {
      results[currentIndex] = validateStepResult.results;
    } else {
      results[currentIndex] = null;
    }

    setResult(results);
    return validateStepResult?.isValid ?? true;
  };

  useEffect(() => {
    if (validateOnChange) validateCurrent();
  }, [data, validateOnChange]);

  useEffect(() => {
    setResult(null);
  }, [currentIndex]);

  return {
    getValidationResultByIndex,
    validateAll,
    validateCurrent,
    validate,
    isValid,
    result,
  };
}

export default useValidationMasterSteps;
