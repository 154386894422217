import { UserFieldView } from '@components/common/userInfoView';
import PartnerTableCell, { PartnerTableCellCommonProps } from './index';

const PartnerTableCellReviewer = (props: PartnerTableCellCommonProps) => {
  const {
    partner: { partnerReviewer },
  } = props;

  return (
    <PartnerTableCell
      {...props}
      value={
        <UserFieldView
          user={partnerReviewer}
          field={'fullName'}
        />
      }
    />
  );
};

export default PartnerTableCellReviewer;
