import { ReactNode, useMemo } from 'react';
import DataTableCellTextFormat, { DataTableCellTextFormatProps } from '@components/common/table/cell/textFormat';
import { SearchedTeam } from '../../search/types';

export interface TeamTableCellCommonProps {
  readonly team: SearchedTeam;
}

interface TeamTableCellProps extends TeamTableCellCommonProps, DataTableCellTextFormatProps {
  readonly value: ReactNode;
}

const TeamTableCell = ({ value, ...other }: TeamTableCellProps) => {
  return useMemo(() => <DataTableCellTextFormat {...other}>{value}</DataTableCellTextFormat>, [value]);
};

export default TeamTableCell;
