import { SportUserData } from '../../../../../domain/model/user';
import { MPStepperStep } from '../../../../components/common/stepper';
import { validationCommonValidators, ValidationRules } from '../../../../utils/validation';

export enum EUserSportEditStep {
  Profile = 'profile',
}

export type UserSportStep = MPStepperStep<EUserSportEditStep>;

export const userSportEditSteps: UserSportStep[] = [
  {
    key: EUserSportEditStep.Profile,
    label: 'Профиль',
  },
];

export const userSportEditValidation: ValidationRules<SportUserData> = {
  lastName: {
    required: true,
  },
  firstName: {
    required: true,
  },
  email: {
    required: true,
    validator: validationCommonValidators.email,
  },
};
