import { EOfferCategoryType, OfferCategory } from '@/domain';
import { ButtonLink } from '@components/common/buttons/link';
import SectionTitle from '@components/common/sectionTitle';
import Splitter from '@components/common/splitter';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { Chip, Grid, Typography } from '@mui/material';
import { RefreshIcon } from 'presentation/icons';
import { CollectionActionsContainer, ContentWrapper } from './controls';

interface CmsCategoryMultipleCollectionComponentProps {
  readonly categoryType: EOfferCategoryType;
  readonly categories: OfferCategory[];

  readonly onShowSearch?: () => void;
  readonly onClear?: () => void;
  readonly onDelete?: (category: OfferCategory) => void;
}

const CmsCategoryMultipleCollectionComponent = (props: CmsCategoryMultipleCollectionComponentProps) => {
  const { categoryType, categories, onShowSearch, onClear, onDelete } = props;

  const typeText = categoryType === EOfferCategoryType.Corp ? 'корпоративных' : 'торговых';

  return (
    <Grid
      item
      xs={12}
    >
      <Splitter
        variant='horizontal'
        size={1}
      />
      <SectionTitle>Категории {typeText} предложения</SectionTitle>
      <ContentWrapper>
        {!!categories?.length &&
          categories.map(category => (
            <Chip
              key={category.id}
              label={category.name}
              color='primary'
              onDelete={onDelete ? () => onDelete(category) : undefined}
            />
          ))}
      </ContentWrapper>

      <CollectionActionsContainer>
        {onShowSearch && (
          <ButtonLink onClick={() => onShowSearch()}>
            <AddBoxOutlinedIcon />
            <Typography>Выбрать категории</Typography>
          </ButtonLink>
        )}

        {onClear && !!categories?.length && (
          <ButtonLink onClick={() => onClear()}>
            <RefreshIcon />
            <Typography>Очистить список</Typography>
          </ButtonLink>
        )}
      </CollectionActionsContainer>
    </Grid>
  );
};

export default CmsCategoryMultipleCollectionComponent;
