import { MpUserData } from '../../../../../domain/model/user';
import { validationCommonValidators, ValidationRules } from '../../../../utils/validation';
import { EAdminMpStep } from '../types';

export const getAdminMpCreateStepText = (step: EAdminMpStep): string => {
  switch (step) {
    case EAdminMpStep.Profile:
      return 'Профиль';
    case EAdminMpStep.Grants:
      return 'Доступ';
    default:
      throw new Error(`Unknown EAdminMpStep: ${step}`);
  }
};

export const adminMpCreateProfileStepValidation: ValidationRules<MpUserData> = {
  lastName: {
    required: true,
  },
  firstName: {
    required: true,
  },
  email: {
    required: true,
    validator: validationCommonValidators.email,
  },
  phone: {
    required: true,
  },
};
