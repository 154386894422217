/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  GenMainAsutrSocialPackageCommand,
  GenMainCounter,
  GenMainFieldAuditItem,
  GenMainPredicate,
  GenMainResultType,
  GenMainRzdEmployeeCategory,
  GenMainRzdEmployeeCategoryResponse,
  GenMainRzdSocialPackage,
  GenMainRzdSocialPackageResponse,
  GenMainSocialPackageAdminAction,
  GenMainSocialPackageId,
  GenMainSocialPackagesAdminAction,
  GenMainXLSXImportResult,
} from './data-contracts';
import { ContentType, createRequest } from './http-client';

export const SocialPackageApi = {
  /**
   * No description
   * * @tags SocialPackage
   * @name ListSocialPackages
   * @summary Получение списка соц пакетов для админов
   * @request GET:/social-packages
   * @secure
   */
  listSocialPackages: (
    baseURL: string,
    query: {
      /** тип возвращаемого значения -- список либо каунтер */
      resultType?: GenMainResultType | null;
      /**
       * необходимая страница
       * @format int32
       * @min 0
       */
      page: number;
      /**
       * Количество элементов на страницу
       * если указать больше maximum, то будет size=maximum
       * независимо от size в запросе
       * @format int32
       * @min 1
       * @max 1000
       * @default 100
       */
      size: number;
      /** сортировка вида firstName,asc */
      sort?: string[] | null;
      /** фильтр dsl */
      filter?: GenMainPredicate[] | null;
      /**
       * Фильтр на то что лимиты по отдыху соц. пакета распределены
       * true - есть либо лимит >0 на свой отдых, либо лимит на отдых семьи)
       * false - лимит на свой отдых и лимит на отдых семьи == 0
       */
      restLimitsExist?: boolean | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainRzdSocialPackageResponse[], void>({
      baseURL,
      path: `/social-packages`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * @description добавлен динамически для типизации ответа по дискриминатору GenMainResultType
   * * @tags SocialPackage
   * @name ListSocialPackages
   * @summary Получение списка соц пакетов для админов
   * @request GET:/social-packages
   * @secure
   */
  listSocialPackagesTyped: <ResultType extends GenMainResultType>(
    baseURL: string,
    query: {
      /** тип возвращаемого значения -- список либо каунтер */
      resultType?: ResultType | null;
      /**
       * необходимая страница
       * @format int32
       * @min 0
       */
      page: number;
      /**
       * Количество элементов на страницу
       * если указать больше maximum, то будет size=maximum
       * независимо от size в запросе
       * @format int32
       * @min 1
       * @max 1000
       * @default 100
       */
      size: number;
      /** сортировка вида firstName,asc */
      sort?: string[] | null;
      /** фильтр dsl */
      filter?: GenMainPredicate[] | null;
      /**
       * Фильтр на то что лимиты по отдыху соц. пакета распределены
       * true - есть либо лимит >0 на свой отдых, либо лимит на отдых семьи)
       * false - лимит на свой отдых и лимит на отдых семьи == 0
       */
      restLimitsExist?: boolean | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<ResultType extends GenMainResultType.Count ? GenMainCounter[] : GenMainRzdSocialPackage[], void>({
      baseURL,
      path: `/social-packages`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * @description Если пользователя с таким pernr нет - то будет создан новый "преюзер" Если пакета в этом году(endDate год) нет, то создастся новый пакет Если пакет есть в этом году(endDate год), то будет обновление данных - если поменялся asutr статус 0-->1 то это означает что согласие работника с КСП получено - если asutr статус > 1, то пакет уходи в архив - если asutr статус был >1 а стал 0 или 1 , то пакет уходит из Архива в статус APPOINTED - если поменялся лимит maxLimit, то пакет уходит в статус APPOINTED(требуется ли повторное согласие работника?) Если работника с таким табельным номером еще нет на Витрине, то нужно заполнить email(необязательно), lastName, firstName, middleName(необязательно), departmentEkasutrId(это ИД из асутр места работы, например 35016676)! Если работник с таким табельным номером есть + у него есть эккаунт на Витрине, то поля email, lastName, firstName, middleName, departmentEkasutrId заполнять не надо - они игнорируются. Если пользователь по табельнику есть, но он без  эккаунта на Витрины, то данные обновляются если заполнены.
   * * @tags SocialPackage
   * @name ProcessSocialPackageCommand
   * @summary Эмуляция сообщения о соц пакете от АСУТР(для отладки/тестирования и как запасной механизм)
   * @request POST:/social-packages
   * @secure
   */
  processSocialPackageCommand: (baseURL: string, data: GenMainAsutrSocialPackageCommand, signal?: AbortSignal) =>
    createRequest<GenMainRzdSocialPackage, GenMainAsutrSocialPackageCommand>({
      baseURL,
      path: `/social-packages`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      signal,
    }),

  /**
   * No description
   * * @tags SocialPackage
   * @name ListEmployeeCategories
   * @summary Получение списка категорий работников РЖД
   * @request GET:/rzd-employee-categories
   * @secure
   */
  listEmployeeCategories: (
    baseURL: string,
    query: {
      /** тип возвращаемого значения -- список либо каунтер */
      resultType?: GenMainResultType | null;
      /**
       * необходимая страница
       * @format int32
       * @min 0
       */
      page: number;
      /**
       * Количество элементов на страницу
       * если указать больше maximum, то будет size=maximum
       * независимо от size в запросе
       * @format int32
       * @min 1
       * @max 1000
       * @default 100
       */
      size: number;
      /** сортировка вида firstName,asc */
      sort?: string[] | null;
      /** фильтр dsl */
      filter?: GenMainPredicate[] | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainRzdEmployeeCategoryResponse[], void>({
      baseURL,
      path: `/rzd-employee-categories`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * @description добавлен динамически для типизации ответа по дискриминатору GenMainResultType
   * * @tags SocialPackage
   * @name ListEmployeeCategories
   * @summary Получение списка категорий работников РЖД
   * @request GET:/rzd-employee-categories
   * @secure
   */
  listEmployeeCategoriesTyped: <ResultType extends GenMainResultType>(
    baseURL: string,
    query: {
      /** тип возвращаемого значения -- список либо каунтер */
      resultType?: ResultType | null;
      /**
       * необходимая страница
       * @format int32
       * @min 0
       */
      page: number;
      /**
       * Количество элементов на страницу
       * если указать больше maximum, то будет size=maximum
       * независимо от size в запросе
       * @format int32
       * @min 1
       * @max 1000
       * @default 100
       */
      size: number;
      /** сортировка вида firstName,asc */
      sort?: string[] | null;
      /** фильтр dsl */
      filter?: GenMainPredicate[] | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<ResultType extends GenMainResultType.Count ? GenMainCounter[] : GenMainRzdEmployeeCategory[], void>({
      baseURL,
      path: `/rzd-employee-categories`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags SocialPackage
   * @name ExportEmployeeCategoriesLimitRules
   * @summary Получение правил распределения лимитов по категориям работников РЖД
   * @request GET:/rzd-employee-categories/limit-rules/export
   * @secure
   */
  exportEmployeeCategoriesLimitRules: (baseURL: string, signal?: AbortSignal) =>
    createRequest<File, void>({
      baseURL,
      path: `/rzd-employee-categories/limit-rules/export`,
      method: 'GET',
      signal,
    }),

  /**
   * No description
   * * @tags SocialPackage
   * @name ImportEmployeeCategoriesLimitRules
   * @summary Импорт правил распределения лимитов по категориям работников РЖД из xlsx (в одной транзакции, импортируются либо все лимиты, либо ни одного)
   * @request POST:/rzd-employee-categories/limit-rules/import
   * @secure
   */
  importEmployeeCategoriesLimitRules: (
    baseURL: string,
    data: {
      /** @format binary */
      file: File | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<
      GenMainXLSXImportResult,
      {
        /** @format binary */
        file: File | null;
      }
    >({
      baseURL,
      path: `/rzd-employee-categories/limit-rules/import`,
      method: 'POST',
      body: data,
      type: ContentType.FormData,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags SocialPackage
   * @name HandleSocialPackagesCommand
   * @summary Массовые действия с соц. пакетами
   * @request POST:/social-packages/actions
   * @secure
   */
  handleSocialPackagesCommand: (baseURL: string, data: GenMainSocialPackagesAdminAction, signal?: AbortSignal) =>
    createRequest<void, GenMainSocialPackagesAdminAction>({
      baseURL,
      path: `/social-packages/actions`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      signal,
    }),

  /**
   * No description
   * * @tags SocialPackage
   * @name ListSocialPackageFieldHistory
   * @summary Получение истории изменения полей соц пакета
   * @request GET:/social-packages/{socialPackageId}/field-history
   * @secure
   */
  listSocialPackageFieldHistory: (baseURL: string, socialPackageId: GenMainSocialPackageId, signal?: AbortSignal) =>
    createRequest<GenMainFieldAuditItem[], void>({
      baseURL,
      path: `/social-packages/${socialPackageId}/field-history`,
      method: 'GET',
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags SocialPackage
   * @name GetSocialPackageById
   * @summary Получение соц пакета по id
   * @request GET:/social-packages/{socialPackageId}
   * @secure
   */
  getSocialPackageById: (baseURL: string, socialPackageId: GenMainSocialPackageId, signal?: AbortSignal) =>
    createRequest<GenMainRzdSocialPackage, void>({
      baseURL,
      path: `/social-packages/${socialPackageId}`,
      method: 'GET',
      signal,
    }),

  /**
   * No description
   * * @tags SocialPackage
   * @name HandleSocialPackageCommand
   * @summary Допустимые команды к сущности
   * @request POST:/social-packages/{socialPackageId}
   * @secure
   */
  handleSocialPackageCommand: (
    baseURL: string,
    socialPackageId: GenMainSocialPackageId,
    data: GenMainSocialPackageAdminAction,
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainRzdSocialPackage, GenMainSocialPackageAdminAction>({
      baseURL,
      path: `/social-packages/${socialPackageId}`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      signal,
    }),
};
