/*
 * сложные правила
 */

import { ECustomerActionType } from '../../types';
import { removeCustomerAction } from '../../utils/actions';
import { isCustomerPersonnelNumberEmpty } from '../conditions';
import { CustomerLifeCycleActionsRule } from '../types';

export const removeSendProfileQueryActionRule: CustomerLifeCycleActionsRule = {
  description: 'Удаление возможности запроса профиля покупаителя в АСУТР',
  algorithm: [...isCustomerPersonnelNumberEmpty.algorithm],
  call: props => {
    if (isCustomerPersonnelNumberEmpty.call(props)) {
      removeCustomerAction(props.nextActions, ECustomerActionType.SendProfileQuery);
      return true;
    }
    return false;
  },
};
