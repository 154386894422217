import { MpUserData } from '../../../../../domain/model/user';
import { MPStepperStep } from '../../../../components/common/stepper';
import { validationCommonValidators, ValidationRules } from '../../../../utils/validation';

export enum EUserMpEditStep {
  Profile = 'profile',
}

export type UserMpStep = MPStepperStep<EUserMpEditStep>;

export const userMpEditSteps: UserMpStep[] = [
  {
    key: EUserMpEditStep.Profile,
    label: 'Профиль',
  },
];

export const userMpEditValidation: ValidationRules<MpUserData> = {
  lastName: {
    required: true,
  },
  firstName: {
    required: true,
  },
  email: {
    required: true,
    validator: validationCommonValidators.email,
  },
};
