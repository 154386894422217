import { UserFieldView } from '@components/common/userInfoView';
import OfferHistoryTableCell, { OfferHistoryTableCellCommonProps } from './index';

const OfferHistoryTableCellEnteredBy = (props: OfferHistoryTableCellCommonProps) => {
  const {
    history: { enteredBy },
  } = props;

  return (
    <OfferHistoryTableCell
      {...props}
      value={
        <UserFieldView
          user={enteredBy}
          field={'shortName'}
        />
      }
    />
  );
};

export default OfferHistoryTableCellEnteredBy;
