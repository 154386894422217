import axios, { AxiosResponse } from 'axios';
import { getComServicesEndpoint } from 'data/api/utils';

type FetchFromAsutrCommand = {
  readonly pernrs: string[];
};

type SupportApi = {
  readonly fetchFromAsutr: (props: FetchFromAsutrCommand) => Promise<AxiosResponse<void>>;
};

const support: SupportApi = {
  fetchFromAsutr: data => {
    return axios.post(`${getComServicesEndpoint()}/support/asutr/pernrs`, data);
  },
};

export default support;
