import {
  ClientOrgLink,
  EPartnerPermission,
  EPartnerStatus,
  EPartnerType,
  EUserStatus,
  MpUser,
  MpUserData,
  pageSizeAll,
  Partner,
  PartnerAdminRequest,
  PartnerData,
  PartnerDataDraft,
  PartnerDesk,
  PartnerDeskData,
  PartnerDraft,
  PartnerResponsiblePerson,
  PartnerResponsiblePersonRequest,
  PartnerShort,
} from '@/domain';
import { comparePartnerFullNames } from '@features/partnerResponsible/utils';
import axios, { AxiosResponse } from 'axios';
import { createCancelToken } from './index';
import {
  ApiCancellable,
  ApiQueryDsl,
  ApiRequestListDiscriminator,
  ApiRequestPageable,
  ApiResponseMaBeCounters,
} from './types';
import { appendQueryDslParams, getComServicesEndpoint } from './utils';

export type PartnerAllProps<D extends ApiRequestListDiscriminator> = ApiCancellable &
  ApiRequestPageable & {
    readonly statuses?: Nullable<EPartnerStatus[]>;
    readonly query?: Nullable<string>;
    readonly ownerId?: Nullable<UUID>;
    readonly managerId?: Nullable<UUID>;
    readonly querydsl?: Nullable<ApiQueryDsl>;
    readonly discriminator?: D;
  };

type OneProps = ApiCancellable & {
  readonly id: UUID;
};

type CreateProps = PartnerAdminRequest;

type InviteProps = {
  readonly redirectUri?: string;
  readonly user: MpUserData;
};

type UnverifiedUpdateProps = {
  readonly partnerId: UUID;
  readonly data: PartnerDataDraft;
};

type UpdateProps = {
  readonly id: UUID;
  readonly data: PartnerData;
};

type UpdateDeskProps = {
  readonly id: UUID;
  readonly data: PartnerDeskData;
};

type ChangeDeskSortIndexProps = {
  readonly id: UUID;
  readonly sortIndex: number;
};

type CreateDeskProps = PartnerDeskData;

type DeskProps = ApiCancellable & {
  readonly partnerId: UUID;
};

type DesksProps = ApiCancellable &
  ApiRequestPageable & {
    readonly query?: Nullable<string>;
    readonly querydsl?: Nullable<ApiQueryDsl>;
  };

type DisabledProps = UUID;

type EnabledProps = UUID;

type EmployeesProps<D extends ApiRequestListDiscriminator> = ApiRequestPageable &
  ApiCancellable & {
    readonly partnerId: Nullable<UUID>;
    readonly statuses?: Nullable<EUserStatus[]>;
    readonly query?: Nullable<string>;
    readonly querydsl?: Nullable<ApiQueryDsl>;
    readonly discriminator?: D;
  };

type ResponsiblePersonsProps = ApiCancellable &
  ApiRequestPageable & {
    readonly partnerId?: Nullable<UUID>;
    readonly statuses?: Nullable<EUserStatus[]>;
    readonly query?: Nullable<string>;
    readonly querydsl?: Nullable<ApiQueryDsl>;
  };

type CreateManagerProps = {
  readonly partnerId: UUID;
  readonly partnerEmployee: MpUserData;
};

type SendToVerificationProps = UUID;

type AssignVerifierProps = {
  readonly partnerId: UUID;
  readonly userId: UUID;
};

type SetOwnerProps = {
  readonly partnerId: UUID;
  readonly userId: UUID;
};

type ApproveProps = UUID;

type RejectProps = {
  readonly partnerId: UUID;
  readonly rejectId: UUID;
  readonly comment?: string;
};

type DeleteProps = UUID;

type UpdatePermissionsProps = {
  readonly id: UUID;
  readonly permissions: EPartnerPermission[];
};

type UpdateTypeProps = {
  readonly id: UUID;
  readonly type: EPartnerType;
};

type UpdateClientOrgProps = {
  readonly id: UUID;
  readonly clientOrg: Nullable<ClientOrgLink>;
};

/*type ActivationsProps = ApiCancellable &
  ApiRequestPageable & {
    readonly partnerId: UUID;
    readonly querydsl?: Nullable<ApiQueryDsl>;
  };*/

type ResponsiblePersonCreateProps = PartnerResponsiblePersonRequest & {
  readonly partnerId: UUID;
};

type ResponsiblePersonUpdateProps = PartnerResponsiblePersonRequest & {
  readonly id: UUID;
  readonly partnerId: UUID;
};

type ResponsiblePersonFindProps = ApiCancellable & {
  readonly partnerId: UUID;
  readonly fullName: Nullable<string>;
};

type ResponsiblePersonGetOrCreateProps = ApiCancellable &
  PartnerResponsiblePersonRequest & {
    readonly partnerId: UUID;
  };

type ResponsiblePersonAllProps = ApiCancellable &
  ApiRequestPageable & {
    readonly partnerId: UUID;
    readonly query?: Nullable<string>;
  };

type PartnerApi = {
  readonly all: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: PartnerAllProps<D>
  ) => Promise<AxiosResponse<ApiResponseMaBeCounters<D, PartnerShort[]>>>;
  readonly collection: (props: OneProps) => Promise<AxiosResponse<PartnerShort[]>>;
  readonly one: (props: OneProps) => Promise<AxiosResponse<Partner>>;
  readonly oneDraft: (props: OneProps) => Promise<AxiosResponse<PartnerDraft>>;
  readonly create: (props: CreateProps) => Promise<AxiosResponse<Partner>>;
  readonly createForUser: () => Promise<AxiosResponse<PartnerDraft>>;
  readonly invite: (props: InviteProps) => Promise<AxiosResponse<MpUser>>;
  readonly unverifiedUpdate: (props: UnverifiedUpdateProps) => Promise<AxiosResponse<PartnerDraft>>;
  readonly update: (props: UpdateProps) => Promise<AxiosResponse<Partner>>;
  readonly desk: (props: DeskProps) => Promise<AxiosResponse<PartnerDesk>>;
  readonly desks: (props: DesksProps) => Promise<AxiosResponse<PartnerDesk[]>>;
  readonly changeDeskSortIndex: (props: ChangeDeskSortIndexProps) => Promise<AxiosResponse<void>>;
  readonly updateDesk: (props: UpdateDeskProps) => Promise<AxiosResponse<PartnerDesk>>;
  readonly createDesk: (props: CreateDeskProps) => Promise<AxiosResponse<PartnerDesk>>;
  readonly disabled: (props: DisabledProps) => Promise<AxiosResponse<Partner>>;
  readonly enabled: (props: EnabledProps) => Promise<AxiosResponse<Partner>>;
  readonly employees: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: EmployeesProps<D>
  ) => Promise<AxiosResponse<ApiResponseMaBeCounters<D, MpUser[]>>>;
  readonly responsiblePersons: (props: ResponsiblePersonsProps) => Promise<AxiosResponse<PartnerResponsiblePerson[]>>;
  readonly createManager: (props: CreateManagerProps) => Promise<AxiosResponse<MpUser>>;
  readonly sendToVerification: (props: SendToVerificationProps) => Promise<AxiosResponse<Partner>>;
  readonly assignVerifier: (props: AssignVerifierProps) => Promise<AxiosResponse<Partner>>;
  readonly setOwner: (props: SetOwnerProps) => Promise<AxiosResponse<void>>;
  readonly approve: (props: ApproveProps) => Promise<AxiosResponse<Partner>>;
  readonly reject: (props: RejectProps) => Promise<AxiosResponse<Partner>>;
  readonly delete: (props: DeleteProps) => Promise<AxiosResponse<void>>;
  readonly updatePermissions: (props: UpdatePermissionsProps) => Promise<AxiosResponse<Partner>>;
  readonly updateType: (props: UpdateTypeProps) => Promise<AxiosResponse<Partner>>;
  readonly updateClientOrg: (props: UpdateClientOrgProps) => Promise<AxiosResponse<Partner>>;
  readonly responsiblePerson: {
    readonly all: (props: ResponsiblePersonAllProps) => Promise<AxiosResponse<PartnerResponsiblePerson[]>>;
    readonly create: (props: ResponsiblePersonCreateProps) => Promise<AxiosResponse<PartnerResponsiblePerson>>;
    readonly update: (props: ResponsiblePersonUpdateProps) => Promise<AxiosResponse<PartnerResponsiblePerson>>;
    readonly find: (props: ResponsiblePersonFindProps) => Promise<AxiosResponse<Nullable<PartnerResponsiblePerson>>>;
    readonly getOrCreate: (
      props: ResponsiblePersonGetOrCreateProps
    ) => Promise<AxiosResponse<PartnerResponsiblePerson>>;
  };
};

/**
 * АПИ по работе с партнерами
 */
const partner: PartnerApi = {
  all: props => {
    const { sort, statuses, query, ownerId, managerId, page, pageSize, querydsl, discriminator, signal } = props;

    const params = new URLSearchParams();

    if (sort) {
      if (typeof sort === 'string') {
        params.append('sort', sort);
      } else {
        sort.forEach(item => params.append('sort', item));
      }
    }
    if (query) params.append('q', query);
    if (ownerId) params.append('ownerId', ownerId);
    if (managerId) params.append('managerId', managerId);
    if (querydsl) appendQueryDslParams(params, querydsl);

    params.append('page', (page - 1).toString(10));
    params.append('size', pageSize.toString(10));

    if (statuses) statuses.forEach(status => params.append('status', status));

    if (discriminator) {
      params.append('resultType', discriminator);
    }

    return axios.get(`${getComServicesEndpoint()}/partners`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  collection: ({ id, signal }) => {
    return axios.get(`${getComServicesEndpoint()}/partners/collections/${id}`, {
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  one: ({ id, signal }) => {
    return axios.get(`${getComServicesEndpoint()}/partners/${id}`, {
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  oneDraft: ({ id, signal }) => {
    return axios.get(`${getComServicesEndpoint()}/partners/${id}`, {
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  create: data => {
    return axios.post(`${getComServicesEndpoint()}/partners`, data);
  },
  update: ({ id, data }) => {
    return axios.put(`${getComServicesEndpoint()}/partners/${id}`, data);
  },
  desk: ({ partnerId, signal }) => {
    return axios.get(`${getComServicesEndpoint()}/partner-desks/${partnerId}`, {
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  desks: props => {
    const { sort, query, page, pageSize, querydsl, signal } = props;

    const params = new URLSearchParams();

    if (sort) {
      if (typeof sort === 'string') {
        params.append('sort', sort);
      } else {
        sort.forEach(item => params.append('sort', item));
      }
    }
    if (query) params.append('q', query);
    if (querydsl) appendQueryDslParams(params, querydsl);

    params.append('page', (page - 1).toString(10));
    params.append('size', pageSize.toString(10));

    return axios.get(`${getComServicesEndpoint()}/partner-desks`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  createDesk: data => {
    return axios.post(`${getComServicesEndpoint()}/partner-desks`, data);
  },
  updateDesk: ({ id, data }) => {
    return axios.put(`${getComServicesEndpoint()}/partner-desks/${id}`, data);
  },
  disabled: id => {
    return axios.patch(`${getComServicesEndpoint()}/partners/${id}/disabled`);
  },
  enabled: id => {
    return axios.patch(`${getComServicesEndpoint()}/partners/${id}/enabled`);
  },
  /** @deprecated */
  changeDeskSortIndex: ({ id, sortIndex }) => {
    return axios.patch(`${getComServicesEndpoint()}/partner-desks/${id}/sortIndex`, { sortIndex });
  },
  employees: props => {
    const { partnerId, sort, statuses, query, discriminator, querydsl, page, pageSize, signal } = props;

    const params = new URLSearchParams();

    if (sort) {
      if (typeof sort === 'string') {
        params.append('sort', sort);
      } else {
        sort.forEach(item => params.append('sort', item));
      }
    }
    if (query) params.append('q', query);
    statuses?.forEach((status: EUserStatus) => params.append('status', status));
    if (querydsl) appendQueryDslParams(params, querydsl);

    params.append('page', (page - 1).toString(10));
    params.append('size', pageSize.toString(10));

    if (discriminator) {
      params.append('resultType', discriminator);
    }

    return axios.get(`${getComServicesEndpoint()}/partners/${partnerId}/employees`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  responsiblePersons: props => {
    const { partnerId, sort, query, querydsl, page, pageSize, signal } = props;

    const params = new URLSearchParams();

    if (sort) {
      if (typeof sort === 'string') {
        params.append('sort', sort);
      } else {
        sort.forEach(item => params.append('sort', item));
      }
    }
    if (query) params.append('q', query);
    if (partnerId) params.append('partnerId', partnerId);
    if (querydsl) appendQueryDslParams(params, querydsl);

    params.append('page', (page - 1).toString(10));
    params.append('size', pageSize.toString(10));

    return axios.get(`${getComServicesEndpoint()}/partners/responsible-persons`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  createManager: props => {
    const { partnerId, partnerEmployee } = props;
    return axios.post(`${getComServicesEndpoint()}/partners/${partnerId}/managers`, partnerEmployee);
  },
  setOwner: ({ partnerId, userId }) => {
    return axios.patch(`${getComServicesEndpoint()}/partners/${partnerId}/owner/${userId}`);
  },
  createForUser: () => {
    return axios.post(`${getComServicesEndpoint()}/partners/unverified`, {});
  },
  invite: ({ redirectUri, user }) => {
    const params = new URLSearchParams();
    if (redirectUri) {
      params.append('redirect_uri', redirectUri);
    }

    return axios.post(`${getComServicesEndpoint()}/partners/invite`, user, { params });
  },
  unverifiedUpdate: props => {
    const { partnerId, data } = props;
    return axios.put(`${getComServicesEndpoint()}/partners/unverified/${partnerId}`, data);
  },
  sendToVerification: id => {
    return axios.patch(`${getComServicesEndpoint()}/partners/unverified/${id}/to_verification`);
  },
  assignVerifier: ({ partnerId, userId }) => {
    const data = { userId };
    return axios.patch(`${getComServicesEndpoint()}/partners/unverified/${partnerId}/on_verification`, data);
  },
  approve: id => {
    return axios.patch(`${getComServicesEndpoint()}/partners/unverified/${id}/verified`);
  },
  reject: data => {
    const { partnerId, rejectId, comment } = data;
    return axios.patch(`${getComServicesEndpoint()}/partners/unverified/${partnerId}/rejected`, {
      rejectId,
      comment,
    });
  },
  delete: id => {
    return axios.delete(`${getComServicesEndpoint()}/partners/${id}`);
  },
  updatePermissions: ({ id, permissions }) => {
    return axios.patch(`${getComServicesEndpoint()}/partners/${id}/permissions`, permissions);
  },
  updateType: ({ id, type }) => {
    const data = { type };
    return axios.patch(`${getComServicesEndpoint()}/partners/${id}/type`, data);
  },
  updateClientOrg: ({ id, clientOrg }) => {
    return axios.patch(`${getComServicesEndpoint()}/partners/${id}/clientOrg`, { clientOrg });
  },
  responsiblePerson: {
    all: ({ partnerId, sort, query, page, pageSize, signal }) => {
      const params = new URLSearchParams();

      if (sort) {
        if (typeof sort === 'string') {
          params.append('sort', sort);
        } else {
          sort.forEach(item => params.append('sort', item));
        }
      }
      if (query) params.append('q', query);

      params.append('page', (page - 1).toString(10));
      params.append('size', pageSize.toString(10));

      return axios.get(`${getComServicesEndpoint()}/partners/${partnerId}/responsible-persons`, {
        params,
        cancelToken: signal && createCancelToken(axios, signal),
      });
    },
    create: ({ partnerId, fullName, phone }) => {
      return axios.post(`${getComServicesEndpoint()}/partners/${partnerId}/responsible-persons`, {
        fullName,
        phone,
      });
    },
    update: ({ id, partnerId, fullName, phone }) => {
      return axios.patch(`${getComServicesEndpoint()}/partners/${partnerId}/responsible-persons/${id}`, {
        fullName,
        phone,
      });
    },
    find: ({ partnerId, fullName, signal }) => {
      const params = new URLSearchParams();
      params.append('q', fullName?.trim() ?? '');
      params.append('page', '0');
      params.append('size', pageSizeAll.toString());

      return axios
        .get(`${getComServicesEndpoint()}/partners/${partnerId}/responsible-persons`, {
          params,
          cancelToken: signal && createCancelToken(axios, signal),
        })
        .then(response => {
          const filteredData = response.data.filter((item: PartnerResponsiblePerson) =>
            comparePartnerFullNames(item.fullName, fullName)
          );
          return {
            ...response,
            data: filteredData.length > 0 ? filteredData[0] : null,
          };
        });
    },
    getOrCreate: async data => {
      let responsiblePerson = (
        await partner.responsiblePerson.find({
          partnerId: data.partnerId,
          fullName: data.fullName,
        })
      ).data;
      if (!responsiblePerson) {
        responsiblePerson = (await partner.responsiblePerson.create(data)).data;
      } else {
        // обновляем данные если телефон отличается
        if (responsiblePerson.phone !== data.phone && !responsiblePerson.accountId) {
          responsiblePerson = {
            ...responsiblePerson,
            phone: data.phone,
          };
          await partner.responsiblePerson.update({ id: responsiblePerson.id, ...data });
        }
      }
      return Promise.resolve({
        data: responsiblePerson,
        status: 200,
        headers: {},
        statusText: 'OK',
        config: {
          headers: {} as any,
        },
      });
    },
  },
};

export default partner;
