import { UserFieldView } from '@components/common/userInfoView';
import PartnerTableCell, { PartnerTableCellCommonProps } from './index';

const PartnerTableCellEmail = (props: PartnerTableCellCommonProps) => {
  const {
    partner: { owner },
  } = props;

  return (
    <PartnerTableCell
      {...props}
      value={
        <UserFieldView
          user={owner}
          field={'email'}
        />
      }
    />
  );
};

export default PartnerTableCellEmail;
