/*
 * сложные правила
 */

import { EClientOrgUserActionType } from '../../types';
import { removeClientOrgUserAction } from '../../utils/actions';
import { isClientOrgUserAlreadyLogged } from '../conditions';
import { ClientOrgUserLifeCycleActionsRule } from '../types';

export const removeResendWelcomeMailActionRule: ClientOrgUserLifeCycleActionsRule = {
  description: 'Удаление возможности повторной отсылки приветственного письма',
  algorithm: [...isClientOrgUserAlreadyLogged.algorithm],
  call: props => {
    if (isClientOrgUserAlreadyLogged.call(props)) {
      removeClientOrgUserAction(props.nextActions, EClientOrgUserActionType.ResendWelcomeMail);
      return true;
    }
    return false;
  },
};
