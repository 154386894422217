/*
 * простые условия
 */
import { ClientOrgUserLifeCycleCondition } from '../types';

export const isClientOrgUserAlreadyLogged: ClientOrgUserLifeCycleCondition = {
  description: 'Проверка заходил ли пользователь в витрину',
  algorithm: ['Пользователь уже заходил в витрину'],
  call: ({ obj }) => {
    const { lastLoggedInAt } = obj;

    return !!lastLoggedInAt;
  },
};
