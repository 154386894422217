import { RootState } from '@/data/store/store';
import { createSelector } from '@reduxjs/toolkit';
import { ECustomerActionType } from '../../types';

export const getCustomerActionsIsProcessingSelector = (id: UUID) =>
  createSelector(
    () => id,
    (store: RootState) => store.customer.actions.actions,
    (id, actions) => actions.some(action => action.id === id && action.isFetching)
  );

export const isCustomersAnyChangedSelector = () => (store: RootState) =>
  store.customer.actions.actions.filter(action => !action.isFetching).length;

export const getIsCustomerAnyActionByTypeExecutedSelector = (id: UUID, ...actions: ECustomerActionType[]) =>
  createSelector(
    () => id,
    () => actions,
    (store: RootState) => store.customer.actions.actions,
    (id, argActions, storeActions) =>
      storeActions.find(action => action.id === id && action.isFetched && argActions.some(a => a === action.type))
        ?.type ?? null
  );
