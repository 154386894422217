import { UserFieldView } from '@components/common/userInfoView';
import SportsmanTableCell, { SportsmanTableCellCommonProps } from './index';

const SportsmanTableCellName = (props: SportsmanTableCellCommonProps) => {
  const { sportsman } = props;

  return (
    <SportsmanTableCell
      {...props}
      disabled={!!sportsman.notSelectableReason}
      value={
        <UserFieldView
          user={sportsman}
          field={'shortName'}
        />
      }
    />
  );
};

export default SportsmanTableCellName;
