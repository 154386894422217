import { PartnerDraft } from '@/domain';

export enum EPartnerApplicationActionType {
  Save = 'save',
  Approve = 'approve',
  Reject = 'reject',
  Edit = 'edit',
  SendToVerification = 'send_to_verification',
  ReturnToVerification = 'return_to_verification',
}

export type PartnerApplicationVerificationActionType =
  | EPartnerApplicationActionType.Approve
  | EPartnerApplicationActionType.Reject;

export type PartnerApplicationEditActionType = Exclude<
  EPartnerApplicationActionType,
  EPartnerApplicationActionType.Edit | EPartnerApplicationActionType.Reject
>;

export type PartnerApplicationDetailsActionType = EPartnerApplicationActionType.Edit;

export type PartnerPermissionsAttributes = Pick<PartnerDraft, 'permissions' | 'agreements' | 'type' | 'clientOrg'>;
