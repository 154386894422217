import { Grid } from '@mui/material';
import { SwitchBaseProps } from '@mui/material/internal/SwitchBase';
import { MouseEventHandler } from 'react';
import { DataTreeItem } from '../../../../../../../domain/model';
import { DictionaryItemContentProps } from '../../../types';
import { DictionaryItemElementArrow } from '../../element';
import { ListItemCheckbox } from '../controls';
import { DictionaryItemConstructor } from '../index';

type DictionaryCheckboxItemProps<T> = DictionaryItemContentProps<T> & {
  readonly checked: boolean;
  readonly clickAsChange?: boolean;
  readonly onChange?: (item: DataTreeItem<T>, selected: boolean) => void;
};

export const DictionaryCheckboxItem = <T extends object>(props: DictionaryCheckboxItemProps<T>) => {
  const { data, expanded, checked, clickAsChange = true, onClick, onChange } = props;

  const hasChilds = Array.isArray(data.children) && data.children.length > 0;

  const onClickInternal: MouseEventHandler<HTMLElement> = event => {
    if (clickAsChange && !hasChilds) {
      event.preventDefault();
      event.stopPropagation();
      onChange?.(data, !checked);
    }
    onClick?.(event);
  };

  const onChangeInternal: SwitchBaseProps['onChange'] = (event, checked) => {
    event.preventDefault();
    event.stopPropagation();
    onChange?.(data, checked);
  };

  return (
    <DictionaryItemConstructor
      {...props}
      content={data.label}
      onClick={onClickInternal}
      startAdornment={
        <Grid
          container
          spacing={1}
          wrap='nowrap'
          alignItems='center'
          justifyContent='flex-end'
        >
          <Grid item>
            <ListItemCheckbox
              color='primary'
              checked={checked}
              disabled={!onChange}
              onChange={onChangeInternal}
            />
          </Grid>
        </Grid>
      }
      endAdornment={
        <Grid
          container
          spacing={1}
          wrap='nowrap'
          alignItems='center'
          justifyContent='flex-end'
        >
          {hasChilds && (
            <Grid item>
              <DictionaryItemElementArrow active={expanded} />
            </Grid>
          )}
        </Grid>
      }
    />
  );
};
