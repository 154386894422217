import { ApiCancellable, ApiQueryDsl, ApiRequestListDiscriminator, ApiRequestPageable } from '@/data/api/types';
import { getPageableFromResponseHeaders, getQueryDslByDataFilterValues } from '@/data/api/utils';
import { Nullable, Pageable, RzdRestSocialPackageApplication } from '@/domain';
import { PaginationSize } from '@/presentation/types';
import Api from 'data/api';
import { SocialPackageApplicationTableFilterValues } from '../filterUtils';

export type AllCommandProps = ApiCancellable & {
  readonly search: {
    readonly pageSize: PaginationSize;
    readonly sort: Nullable<string | string[]>;
  };
  readonly filters: SocialPackageApplicationTableFilterValues;
  readonly pageNumber: number;
};

type BuildListQueryParamsReturn = ApiCancellable &
  ApiRequestPageable & {
    readonly query?: Nullable<string>;
    readonly querydsl?: Nullable<ApiQueryDsl>;
  };

export type CountProps = Omit<AllCommandProps, 'pageNumber'>;

type SocialPackageApplicationServices = {
  readonly all: (props: AllCommandProps) => Promise<Pageable<RzdRestSocialPackageApplication>>;
  readonly buildListQueryParams: (props: AllCommandProps) => BuildListQueryParamsReturn;
  readonly count: (props: CountProps) => Promise<number>;
};

const socialPackageApplicationServices: SocialPackageApplicationServices = {
  buildListQueryParams: props => {
    const { search, filters, pageNumber, signal } = props;
    const { pageSize, sort } = search;
    const filterDsl = getQueryDslByDataFilterValues(filters);

    const querydsl: ApiQueryDsl = [];

    if (filterDsl) {
      querydsl.push(...filterDsl);
    }

    return {
      sort,
      signal,
      pageSize,
      page: pageNumber,
      querydsl,
    };
  },
  all: async props => {
    const response = await Api.socialPackageApplication.all(
      socialPackageApplicationServices.buildListQueryParams(props)
    );
    const { pageCount, totalCount, page } = getPageableFromResponseHeaders(response);

    return { data: response.data, totalCount, pageCount, pageNumber: page };
  },
  count: async props => {
    const { data: response } = await Api.socialPackageApplication.all({
      ...socialPackageApplicationServices.buildListQueryParams({ ...props, pageNumber: 1 }),
      discriminator: ApiRequestListDiscriminator.Count,
    });

    return response[0].count;
  },
};

export default socialPackageApplicationServices;
