import {
  ESportEventApplicationStatus,
  ESportEventClassificationType,
  Nullable,
  SportOption,
  SportUserProfileShort,
  TeamShort,
  TournamentApplication,
  TournamentApplicationMember,
  TournamentClassificationResultsRequest,
  TournamentIndividualApplication,
  TournamentResults,
  TournamentResultsRequest,
  TournamentTeam,
  TournamentTeamsResults,
  TournamentUsersResultsRequest,
} from '@/domain';
import { userInfoView } from 'presentation/utils/userInfoView';
import { EEventStep, TournamentViewStepType } from '../../types';
import { ETournamentViewApplicationsTabs } from './types';
import { SportsmanSelectRestriction } from '@features/sportsman/search/types';

export interface TournamentNominationMember extends SportOption {
  readonly team: TeamShort;
}

export const tournamentWinnersMaxCount = 3;

export const getTournamentViewStepText = (step: TournamentViewStepType): string => {
  switch (step) {
    case EEventStep.General:
      return 'Описание мероприятия';
    case EEventStep.Results:
      return 'Результаты';
    case EEventStep.Chat:
      return 'Чат';
    case EEventStep.Applications:
      return 'Участники';
  }
};

const userProfileConvertToUserSportOption = (profile: SportUserProfileShort): SportOption => {
  const { lastName, firstName, id, photo } = profile;
  return {
    id,
    name: [firstName, lastName].join(' '),
    image: photo?.path,
  };
};

export const convertTournamentResultsToTournamentResultsRequest = (
  results: TournamentResults
): TournamentResultsRequest => {
  const { maleResults, femaleResults, mixedResults, resultsFile } = results;

  return {
    maleResults: maleResults
      ? {
          ...maleResults,
          usersResults:
            maleResults?.usersResults?.map(userResult => ({
              ...userResult,
              user: userProfileConvertToUserSportOption(userResult.user),
            })) ?? null,
        }
      : null,
    femaleResults: femaleResults
      ? {
          ...femaleResults,
          usersResults:
            femaleResults?.usersResults?.map(userResult => ({
              ...userResult,
              user: userProfileConvertToUserSportOption(userResult.user),
            })) ?? null,
        }
      : null,
    mixedResults: mixedResults
      ? {
          ...mixedResults,
          usersResults:
            mixedResults?.usersResults?.map(userResult => ({
              ...userResult,
              user: userProfileConvertToUserSportOption(userResult.user),
            })) ?? null,
        }
      : null,
    resultsFile,
  };
};

export const getTournamentClassificationResults = (
  classification: ESportEventClassificationType,
  results: TournamentResultsRequest
): Nullable<TournamentClassificationResultsRequest> => {
  switch (classification) {
    case ESportEventClassificationType.Male:
      return results.maleResults;
    case ESportEventClassificationType.Female:
      return results.femaleResults;
    case ESportEventClassificationType.Mixed:
      return results.mixedResults;
  }
};

export const getTournamentResultsTeamsByClassification = (
  classifications: ESportEventClassificationType[],
  results: TournamentResultsRequest,
  tournamentTeams: TournamentTeam[]
): TeamShort[] => {
  const teamsResults: TournamentTeamsResults[] = [];
  const usersResults: TournamentUsersResultsRequest[] = [];

  classifications.forEach(classification => {
    const classificationResults = getTournamentClassificationResults(classification, results);
    if (classificationResults?.teamsResults) {
      teamsResults.push(...classificationResults.teamsResults);
    }
    if (classificationResults?.usersResults) {
      usersResults.push(...classificationResults.usersResults);
    }
  });

  //формируем коллекцию команд которые по фактически введенным результатам принадлежат зачету
  return (
    teamsResults
      //добавляем команды из призовых мест
      ?.map(teamResult => teamResult.team)
      //добавляем команды номинантов
      ?.concat(
        usersResults.map(
          userResult =>
            (tournamentTeams ?? []).find(tournamentTeam =>
              tournamentTeam.members.some(member => member.teamMember.userProfile.id === userResult.user.id)
            )!.team
        ) ?? []
      ) ?? []
  );
};

export const getTournamentResultsAllowedMembersForNominationsByClassification = (
  otherClassificationTeamsByResults: TeamShort[],
  tournamentTeams: TournamentTeam[]
): TournamentNominationMember[] => {
  return (
    tournamentTeams
      //формируем начальный перечень
      ?.flatMap(tournamentTeam =>
        tournamentTeam.members.map(member => ({
          id: member.teamMember.userProfile.id,
          name: userInfoView(member.teamMember.userProfile).fullName,
          image: member.teamMember.userProfile.photo?.path ?? null,
          team: tournamentTeam.team,
        }))
      )
      //убираем тех кто выбран в другом зачёте
      ?.filter(
        member =>
          !otherClassificationTeamsByResults.some(
            otherClassificationTeam => otherClassificationTeam.id === member.team.id
          )
      ) ?? []
  );
};

export const tournamentViewGetStatusByTab = (
  tab: ETournamentViewApplicationsTabs
): Nullable<ESportEventApplicationStatus> => {
  switch (tab) {
    case ETournamentViewApplicationsTabs.All: {
      return null;
    }
    case ETournamentViewApplicationsTabs.New: {
      return ESportEventApplicationStatus.New;
    }
    case ETournamentViewApplicationsTabs.Accepted: {
      return ESportEventApplicationStatus.Accepted;
    }
    case ETournamentViewApplicationsTabs.Rejected: {
      return ESportEventApplicationStatus.Rejected;
    }
    case ETournamentViewApplicationsTabs.Cancelled: {
      return ESportEventApplicationStatus.Cancelled;
    }
  }
};

export const tournamentViewGetApplicationLabelTabs = (tab: ETournamentViewApplicationsTabs): string => {
  switch (tab) {
    case ETournamentViewApplicationsTabs.All:
      return 'Все';
    case ETournamentViewApplicationsTabs.New:
      return 'На рассмотрении';
    case ETournamentViewApplicationsTabs.Accepted:
      return 'Принято';
    case ETournamentViewApplicationsTabs.Rejected:
      return 'Отказано';
    case ETournamentViewApplicationsTabs.Cancelled:
      return 'Отозвано';
  }
};

export const filterApplicationsForRestrictions = (application: { status: ESportEventApplicationStatus }) => {
  return (
    application.status === ESportEventApplicationStatus.New ||
    application.status === ESportEventApplicationStatus.Accepted
  );
};

export const createRestriction = (item: TournamentApplicationMember | TournamentIndividualApplication) => {
  const reason = 'Пользователь уже участвует или подал заявку';

  if ('teamMember' in item) {
    return { id: item.teamMember.userProfile.id, reason };
  }

  return { id: item.member.userProfile.id, reason };
};

export const tournamentUsersRestrictions = (
  applications: Nullable<TournamentApplication[]>
): SportsmanSelectRestriction[] => {
  if (!applications) {
    return [];
  }

  // Фильтруем заявки только новые или принятые
  const applicationsFilter = applications.filter(filterApplicationsForRestrictions);

  const users: SportsmanSelectRestriction[] = [];

  applicationsFilter.map(item => {
    item.members.map(member => {
      users.push(createRestriction(member));
    });
  });

  return users;
};

export const tournamentTeamsRestrictions = (
  applications: Nullable<TournamentApplication[]>
): SportsmanSelectRestriction[] => {
  if (!applications) {
    return [];
  }

  // Фильтруем заявки только новые или принятые
  const applicationsFilter = applications.filter(filterApplicationsForRestrictions);

  return applicationsFilter.map(item => {
    return { id: item.team.id, reason: 'Команда уже участвует или подала заявку' };
  });
};

export const tournamentIndividualUsersRestrictions = (
  applications: Nullable<TournamentIndividualApplication[]>
): SportsmanSelectRestriction[] => {
  if (!applications) {
    return [];
  }

  return applications.filter(filterApplicationsForRestrictions).map(createRestriction);
};
