import { FCC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PanelAction } from '../../../../types';
import { useCustomerActions } from '../../actions/useActions';
import { CustomerActionDetailsType, ECustomerActionType } from '../../types';
import { getCustomerActionsConfigByPermissions } from '../../utils/actions';
import { CustomerDetailsContainerProps } from '../container';
import { CustomerDetailsLifecycleContext, CustomerDetailsLifecycleContextValue } from '../context';
import useCustomerDetailsActions from '../hooks/useActions';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { customerDetailsCustomerSelector } from '../store/selectors';

export const CustomerDetailLifecycleProvider: FCC<CustomerDetailsContainerProps> = ({
  children,
  id,
  lifecycle,
  access,
}) => {
  const handlers = useContextHandlers();

  const {
    utils: {
      selectors: { getIsCustomerExecutedActionsSelector },
    },
  } = useCustomerActions();

  const isActionsForRefetch = useSelector(
    getIsCustomerExecutedActionsSelector(id, ECustomerActionType.Activate, ECustomerActionType.Deactivate)
  );

  const customer = useSelector(customerDetailsCustomerSelector);

  const allowedActions = getCustomerActionsConfigByPermissions({
    ...access,
  });

  const lifeCycleRules = lifecycle.build({ allowedActions, obj: customer });

  const actions = useCustomerDetailsActions({
    lifeCycleRules,
  });

  const onAction = (action: PanelAction<CustomerActionDetailsType>) => {
    if (!customer) {
      return;
    }

    const { type } = action;
    switch (type) {
      case ECustomerActionType.Activate:
        handlers.onCustomerActivate();
        break;
      case ECustomerActionType.Deactivate:
        handlers.onCustomerDeactivate();
        break;
    }
  };

  useEffect(() => {
    if (isActionsForRefetch) {
      handlers.onCustomerRefetch();
    }
  }, [isActionsForRefetch, handlers.onCustomerRefetch]);

  const value: CustomerDetailsLifecycleContextValue = {
    lifecycle,
    lifeCycleRules,
    actions,
    onAction,
  };

  return <CustomerDetailsLifecycleContext.Provider value={value}>{children}</CustomerDetailsLifecycleContext.Provider>;
};
