import { AddressLocalityFullPathView } from '../../../address';
import SportsmanTableCell, { SportsmanTableCellCommonProps } from './index';

const SportsmanTableCellLocality = (props: SportsmanTableCellCommonProps) => {
  const {
    sportsman: { locality, notSelectableReason: disabled },
  } = props;

  return (
    <SportsmanTableCell
      {...props}
      disabled={!!disabled}
      value={<AddressLocalityFullPathView address={locality} />}
    />
  );
};

export default SportsmanTableCellLocality;
