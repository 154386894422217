import { ClientOrgUser } from '@/domain';
import { EUserStatus } from '@/domain/model/enums';
import { DataTableColumns } from '@components/common/table';
import {
  GetClientOrgUserActionsByPermissionsProps,
  getClientOrgUserActionsConfigByPermissions,
} from '@features/user/userClientOrg/utils/actions';
import {
  ClientOrgUserActions,
  ClientOrgUserActionTableType,
  EClientOrgUserActionType,
  EClientOrgUserTableTab,
} from '../types';

export type ClientOrgUserActionCall = {
  readonly action: ClientOrgUserActionTableType;
  readonly clientOrgUser: ClientOrgUser;
};

export enum EClientOrgUserTableColumn {
  Date = 'createdAt',
  Name = 'name',
  Phone = 'phone',
  Email = 'email',
}

export type ClientOrgUserTableTabsCounter = {
  [name in EClientOrgUserTableTab]?: number;
};

export const clientOrgUsersTableColumns: DataTableColumns<EClientOrgUserTableColumn> = {
  [EClientOrgUserTableColumn.Date]: {
    title: 'Дата',
    width: '10rem',
    sortable: true,
  },
  [EClientOrgUserTableColumn.Name]: {
    title: 'ФИО',
    width: '10rem',
    sortable: false,
  },
  [EClientOrgUserTableColumn.Phone]: {
    title: 'Телефон',
    width: '10rem',
    sortable: true,
  },
  [EClientOrgUserTableColumn.Email]: {
    title: 'Электронная почта',
    width: '10rem',
    sortable: true,
  },
};

const clientOrgUserTableDefaultColumns = [
  EClientOrgUserTableColumn.Date,
  EClientOrgUserTableColumn.Name,
  EClientOrgUserTableColumn.Phone,
  EClientOrgUserTableColumn.Email,
];

export const getClientOrgUserTableDefaultColumns = (): DataTableColumns<EClientOrgUserTableColumn> => {
  const defaultColumns = clientOrgUserTableDefaultColumns;

  const allColumns: EClientOrgUserTableColumn[] = [];

  allColumns.push(
    EClientOrgUserTableColumn.Date,
    EClientOrgUserTableColumn.Name,
    EClientOrgUserTableColumn.Phone,
    EClientOrgUserTableColumn.Email
  );

  return allColumns.reduce<DataTableColumns<EClientOrgUserTableColumn>>((prev, column) => {
    return {
      ...prev,
      [column]: {
        ...clientOrgUsersTableColumns[column],
        hidden: !defaultColumns.includes(column),
      },
    };
  }, {});
};

export const getStatesFilterForClientOrgUserTableTab = (
  tab: EClientOrgUserTableTab,
  defaultStatuses: EUserStatus[] = []
): EUserStatus[] => {
  switch (tab) {
    case EClientOrgUserTableTab.Enabled:
      return [EUserStatus.Enabled, EUserStatus.NotFilled];

    case EClientOrgUserTableTab.Invited:
      return [EUserStatus.Enabled, EUserStatus.NotFilled];

    case EClientOrgUserTableTab.Disabled:
      return [EUserStatus.Disabled];

    default: {
      return defaultStatuses;
    }
  }
};

export const getClientOrgUsersTableTabs = (): EClientOrgUserTableTab[] => [
  EClientOrgUserTableTab.Enabled,
  EClientOrgUserTableTab.Invited,
  EClientOrgUserTableTab.Disabled,
];

export const getClientOrgUsersTableTabsForCounts = (): EClientOrgUserTableTab[] => [
  EClientOrgUserTableTab.Enabled,
  EClientOrgUserTableTab.Invited,
  EClientOrgUserTableTab.Disabled,
];

export const getClientOrgUsersTableTabName = (tab: EClientOrgUserTableTab): string => {
  switch (tab) {
    case EClientOrgUserTableTab.Disabled:
      return 'Приостановлены';
    case EClientOrgUserTableTab.Invited:
      return 'Не заходили на витрину';
    case EClientOrgUserTableTab.Enabled:
      return 'Заходили на витрину';
  }
};

interface GetClientOrgUserTableTabActionsProps {
  readonly tab: EClientOrgUserTableTab;
  readonly actions: ClientOrgUserActions<ClientOrgUserActionTableType>;
}

// получение возможных действий с пользователем для вкладки таблицы
const getClientOrgUserTableTabActionsConfig = (
  props: GetClientOrgUserTableTabActionsProps
): ClientOrgUserActions<ClientOrgUserActionTableType> => {
  const { actions, tab } = props;

  switch (tab) {
    case EClientOrgUserTableTab.Invited:
      return actions.filter(item => item.type === EClientOrgUserActionType.ResendWelcomeMail);
    case EClientOrgUserTableTab.Enabled:
    case EClientOrgUserTableTab.Disabled:
      return [];
  }
};

// фильтрация действий для таблицы пользователей по всем действиям
const filterClientOrgUserTableActions = (
  actions: ClientOrgUserActions
): ClientOrgUserActions<ClientOrgUserActionTableType> => {
  const tableActions: ClientOrgUserActions<ClientOrgUserActionTableType> = [];

  actions.forEach(action => {
    switch (action.type) {
      case EClientOrgUserActionType.ResendWelcomeMail:
        tableActions.push({
          type: action.type,
          disabled: action.disabled,
          label: action.label,
          primary: action.primary,
          position: action.position,
        });
        break;
    }
  });

  return tableActions;
};

export const getClientOrgUserTableTabActions = (
  permissions: GetClientOrgUserActionsByPermissionsProps,
  tab: EClientOrgUserTableTab
): ClientOrgUserActions<ClientOrgUserActionTableType> => {
  const allowedActions = getClientOrgUserActionsConfigByPermissions(permissions);
  const actions = filterClientOrgUserTableActions(allowedActions);
  return getClientOrgUserTableTabActionsConfig({ tab, actions });
};
