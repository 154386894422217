import { ClientOrg, ClientOrgCreate, ClientOrgCreateRequest, EClientOrgActionDiscriminator } from '@/domain';
import axios, { AxiosResponse } from 'axios';
import { createCancelToken } from './index';
import {
  ApiCancellable,
  ApiQueryDsl,
  ApiRequestListDiscriminator,
  ApiRequestPageable,
  ApiResponseMaBeCounters,
} from './types';
import { appendQueryDslParams, getComServicesEndpoint } from './utils';

type ClientOrgBlockCommand = {
  readonly discriminator: EClientOrgActionDiscriminator.ClientOrgBlockCommand;
};

type ClientOrgUnblockCommand = {
  readonly discriminator: EClientOrgActionDiscriminator.ClientOrgUnblockCommand;
};

type ClientOrgUpdateCommand = ClientOrgCreate & {
  readonly discriminator: EClientOrgActionDiscriminator.ClientOrgUpdateCommand;
};

type ClientOrgResendWelcomeCommand = {
  readonly userIds: UUID[];
  readonly discriminator: EClientOrgActionDiscriminator.ClientOrgResendWelcomeMailCommand;
};

export type ClientOrgAllProps<D extends ApiRequestListDiscriminator> = ApiCancellable &
  ApiRequestPageable & {
    readonly query?: Nullable<string>;
    readonly querydsl?: Nullable<ApiQueryDsl>;
    readonly discriminator?: D;
  };

type AloneIdProps = ApiCancellable & {
  readonly id: UUID;
};

type ClientOrgAction =
  | ClientOrgBlockCommand
  | ClientOrgUnblockCommand
  | ClientOrgUpdateCommand
  | ClientOrgResendWelcomeCommand;

type ActionProps = AloneIdProps & ClientOrgAction;

type CreateProps = {
  readonly data: ClientOrgCreateRequest;
};

type ClientOrgApi = {
  readonly all: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: ClientOrgAllProps<D>
  ) => Promise<AxiosResponse<ApiResponseMaBeCounters<D, ClientOrg[]>>>;
  readonly one: (props: AloneIdProps) => Promise<AxiosResponse<ClientOrg>>;
  readonly create: (props: CreateProps) => Promise<AxiosResponse<ClientOrg>>;
  readonly action: (props: ActionProps) => Promise<AxiosResponse<ClientOrg>>;
};

/**
 * АПИ по работе с клиентами
 */
const clientOrg: ClientOrgApi = {
  all: props => {
    const { sort, query, page, pageSize, querydsl, discriminator, signal } = props;

    const params = new URLSearchParams();

    if (sort) {
      if (typeof sort === 'string') {
        params.append('sort', sort);
      } else {
        sort.forEach(item => params.append('sort', item));
      }
    }
    if (query) params.append('q', query);
    if (querydsl) appendQueryDslParams(params, querydsl);

    params.append('page', (page - 1).toString(10));
    params.append('size', pageSize.toString(10));

    if (discriminator) {
      params.append('resultType', discriminator);
    }

    if (discriminator) {
      params.append('resultType', discriminator);
    }

    return axios.get(`${getComServicesEndpoint()}/client-orgs`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  one: ({ id, signal }) => {
    return axios.get(`${getComServicesEndpoint()}/client-orgs/${id}`, {
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  create: ({ data }) => {
    return axios.post(`${getComServicesEndpoint()}/client-orgs`, data);
  },
  action: ({ id, signal, ...data }) => {
    return axios.post(`${getComServicesEndpoint()}/client-orgs/${id}`, data, {
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
};

export default clientOrg;
