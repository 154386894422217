import styled from '@emotion/styled';

export const ContentWrapper = styled.div`
  margin-top: ${p => p.theme.spacing(2.5)};
`;

export const CollectionActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: ${p => p.theme.spacing(2.5)};
`;
