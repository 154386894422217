import { UserFieldView } from '@components/common/userInfoView';
import PartnerEmployeeTableCell, { PartnerEmployeeTableCellCommonProps } from './index';

const PartnerEmployeeTableCellEmail = (props: PartnerEmployeeTableCellCommonProps) => {
  const { employee } = props;

  return (
    <PartnerEmployeeTableCell
      {...props}
      value={
        <UserFieldView
          user={employee}
          field={'email'}
        />
      }
    />
  );
};

export default PartnerEmployeeTableCellEmail;
