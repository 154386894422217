import { UserFieldView } from '@components/common/userInfoView';
import CustomerTableCell, { CustomerTableCellCommonProps } from './index';

const CustomerTableCellPernr = (props: CustomerTableCellCommonProps) => {
  const { customer } = props;

  return (
    <CustomerTableCell
      {...props}
      value={
        <UserFieldView
          user={customer}
          field={'pernr'}
        />
      }
    />
  );
};

export default CustomerTableCellPernr;
