import ErrorHandler from '@/data/network/errorHandler';
import { ServerErrorResponse } from '@/data/network/types';
import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableFailed, fetchableFetched, fetchableFetching } from '@/data/store/types';
import { CaseReducer, createAsyncThunk, createSlice, Draft, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import customerService from '../../services';
import { ECustomerActionType } from '../../types';

const handleActionProcess = (
  state: Draft<CustomerActionsState>,
  id: UUID,
  actionType: ECustomerActionType,
  fetchable: Fetchable,
  error: Nullable<ServerErrorResponse | undefined> = null
) => {
  let process = state.actions.find(change => change.id === id);

  if (process) {
    process.id = id;
    process.type = actionType;
    process.error = error ?? null;
    process.isFetching = fetchable.isFetching;
    process.isFetched = fetchable.isFetched;
    process.isFailed = fetchable.isFailed;
  } else {
    process = {
      ...fetchable,
      id,
      type: actionType,
      error: null,
    };

    state.actions.push(process);
  }
};

export interface CustomerActionsState {
  readonly actions: (Fetchable & {
    id: UUID;
    type: ECustomerActionType;
    error: Nullable<ServerErrorResponse>;
  })[];
}

type Reducer<T = undefined> = CaseReducer<CustomerActionsState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<CustomerActionsState> {
  customerActionsOptimize: Reducer;
}

export const customerActionsDeactivate = createAsyncThunk<boolean, { id: UUID }, AppThunkAPIConfig>(
  'customer/actions/deactivate',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await customerService.common.deactivate({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue({
        error: e.response.data,
      });
    }
  }
);

export const customerActionsActivate = createAsyncThunk<boolean, { id: UUID }, AppThunkAPIConfig>(
  'customer/actions/activate',
  async ({ id }, { getState, rejectWithValue }) => {
    try {
      return await customerService.common.activate({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue({
        error: e.response.data,
      });
    }
  }
);

export const customerActionsSendProfileQuery = createAsyncThunk<
  boolean,
  {
    id: UUID;
    pernr: string;
  },
  AppThunkAPIConfig<ServerErrorResponse>
>('customer/actions/sendProfileQuery', async ({ pernr }, { rejectWithValue }) => {
  try {
    return await customerService.common.sendProfilesQuery({ pernrs: [pernr] });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

const slice = createSlice<CustomerActionsState, Reducers>({
  name: 'customer/actions',
  initialState: {
    actions: [],
  },
  reducers: {
    customerActionsOptimize: state => {
      // Оставляем только выполняющиеся действия
      state.actions = state.actions.filter(action => action.isFetching);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(customerActionsActivate.pending, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, ECustomerActionType.Activate, fetchableFetching);
      })
      .addCase(customerActionsActivate.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, ECustomerActionType.Activate, fetchableFetched);
      })
      .addCase(customerActionsActivate.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, ECustomerActionType.Activate, fetchableFailed, payload);
      })
      .addCase(customerActionsDeactivate.pending, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, ECustomerActionType.Deactivate, fetchableFetching);
      })
      .addCase(customerActionsDeactivate.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, ECustomerActionType.Deactivate, fetchableFetched);
      })
      .addCase(customerActionsDeactivate.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, ECustomerActionType.Deactivate, fetchableFailed, payload);
      })
      .addCase(customerActionsSendProfileQuery.pending, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, ECustomerActionType.SendProfileQuery, fetchableFetching);
      })
      .addCase(customerActionsSendProfileQuery.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, ECustomerActionType.SendProfileQuery, fetchableFetched);
      })
      .addCase(customerActionsSendProfileQuery.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, ECustomerActionType.SendProfileQuery, fetchableFailed, payload);
      });
  },
});

export const { customerActionsChangeDialogState, customerActionsOptimize } = slice.actions;

export default slice.reducer;
