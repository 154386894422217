import { RootState } from '@/data/store/store';
import { ClientOrgUser } from '@/domain';
import { ClientOrg } from '@/domain/model/clientOrg';
import { createContext } from 'react';
import { EClientOrgUserActionType } from '../types';
import { ClientOrgUserActionsState } from './store/slice';

type ClientOrgUserTypes = ClientOrg;

type ImportProps = { clientOrgId: UUID; file: File };

export interface ClientOrgUserActionsSelectors {
  readonly getIsClientOrgUserProcessingSelector: (id: Nullable<UUID>) => (store: RootState) => boolean;
  readonly getIsClientOrgUserExecutedActionsSelector: (
    id: Nullable<UUID>,
    ...actions: EClientOrgUserActionType[]
  ) => (store: RootState) => Nullable<EClientOrgUserActionType>;
  readonly isClientOrgUsersAnyChangedSelector: (store: RootState) => number;
}

export type ClientOrgUserActionsContextType = {
  readonly onChangeDialogState: (name: keyof ClientOrgUserActionsState['dialogs'], data: Nullable<ClientOrg>) => void;
  readonly onImport: (props: ImportProps) => Promise<string>;

  readonly onResendWelcomeMail: (clientOrg: ClientOrg, clientOrgUsers: ClientOrgUser[]) => Promise<ClientOrg>;

  readonly onTryImport: (clientOrg: ClientOrgUserTypes) => void;

  readonly utils: {
    readonly selectors: ClientOrgUserActionsSelectors;
  };
};

const ClientOrgUserActionsContext = createContext<ClientOrgUserActionsContextType>(
  {} as ClientOrgUserActionsContextType
);

export default ClientOrgUserActionsContext;
