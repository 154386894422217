import { forwardRef, ReactNode } from 'react';
import styled from '@emotion/styled';

interface DefaultFooterWrapperProps {
  readonly children?: ReactNode;
}

export const DefaultFooterWrapper = styled(
  forwardRef(({ ...others }: DefaultFooterWrapperProps, ref: any) => (
    <div
      ref={ref}
      {...others}
    />
  ))
)`
  border-top: 1px solid ${p => p.theme.palette.secondary.main};

  padding: ${p => p.theme.spacing(2)} ${p => p.theme.spacing(7.5)} ${p => p.theme.spacing(2)}
    ${p => p.theme.spacing(6.25)};
`;
