import { RzdSocialPackage } from '@/domain';
import { PanelAction, PanelActions } from '@/presentation/types';

export enum ESocialPackageTableTab {
  /** Распределение лимитов */
  Appointed = 'appointed',
  /** Готовы к активации */
  Allocated = 'allocated',
  /** Действующие */
  Active = 'active',
  /** Не активные */
  Archive = 'archive',
}

export enum ESocialPackageActionType {
  /** Перенести в архив отменить */
  Archive = 'archive',
  /** Установить лимиты */
  AllocateLimit = 'allocateLimit',
  /** Активировать */
  Activate = 'activate',
  /** Вернуть в статус распределения лимитов */
  BackToAppointed = 'backToAppointed',
  /** Переотправка письма пользователю о назначении соц.пакета */
  ResendReadyMail = 'resendReadyMail',
}

export type SocialPackageActionTableType = ESocialPackageActionType;

export type SocialPackageActionTableCollectionType = Exclude<
  SocialPackageActionTableType,
  ESocialPackageActionType.ResendReadyMail
>;

export type SocialPackageActionTableCommonType = SocialPackageActionTableType;

export type ActionSocialPackageType = RzdSocialPackage;
export type SocialPackageAction<A extends string = ESocialPackageActionType, P = any> = PanelAction<A, P>;
export type SocialPackageActions<A extends string = ESocialPackageActionType> = PanelActions<A>;

export enum ESocialPackageTableColumn {
  /** дата последнего обновления по пакету */
  LastStatusDate = 'lastStatusDate',
  /** Статус соц пакета */
  Status = 'status',
  /** ФИО пользователя */
  EmployeeFullName = 'employee.firstName',
  /** Код значимой категории */
  EmployeeCategory = 'employeeCategory',
  /** Табельный пользователя */
  EmployeePernr = 'employee.pernr',
  /** Email пользователя */
  EmployeeEmail = 'employee.email',
  /** Статус пользователя */
  EmployeeStatus = 'employee.status',
  /** Железная дорога   */
  RZDRoad = 'employee.road.id',

  ValidityYear = 'year',
  /** Дата окончания */
  EndDate = 'endDate',
  /** Общий лимит */
  MaxLimit = 'maxLimit',
  /** Распределенный максимальный лимит на личный отдых */
  LimitToSelfRest = 'limitToSelfRest',
  /** Распределенный максимальный лимит на отдых семьи */
  LimitToFamilyRest = 'limitToFamilyRest',
  /** Согласие работника c соцпакетом */
  EmployeeAgreement = 'EmployeeAgreement',
  /** Причина закрытия */
  RejectionReason = 'rejectionReason',
}

export type SocialPackageCollectionActionPayload = {
  readonly socialPackages: RzdSocialPackage[];
};
