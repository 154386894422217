import { LinkProps, Typography } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon/SvgIcon';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import HelpIcon from '@mui/icons-material/HelpOutline';
import { set } from 'lodash';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ExpandingHintContainer,
  HintCollapseTitle,
  HintContentWrapper,
  HintIcons,
  HintLink,
  HintWrapper,
} from './controls';

type ExpandingHintProps = {
  readonly collapsedTitle?: ReactNode;
  readonly expandedTitle?: ReactNode;
  readonly IconLess?: typeof SvgIcon | null;
  readonly IconMore?: typeof SvgIcon | null;
  readonly children?: ReactNode;
};

const ContentLimiterHintLayout = (props: ExpandingHintProps) => {
  const { children, collapsedTitle, expandedTitle, IconLess = ExpandLessIcon, IconMore = HelpIcon } = props;

  const ref = useRef<any>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const setStyleTextOverflow = useCallback(
    (vision: boolean) => {
      if (ref.current) {
        set(ref.current.style, 'overflow', vision ? 'hidden' : 'visible');
        set(ref.current.style, 'white-space', vision ? 'nowrap' : 'initial');
        set(ref.current.style, 'textOverflow', vision ? 'ellipsis' : 'initial');
      }
    },
    [ref.current]
  );

  useEffect(() => {
    setStyleTextOverflow(true);
  }, []);

  const onTransitionEnd = useCallback(() => {
    /** Нужно для решения проблемы.
     * Проблема: transition не отрабатывает для max-height при схлопывании,
     * т.к. текст становиться в одну строчки(white-space:nowrap) и блок становиться не анимированным.
     * Ловим событие по завершению схлопывания и делаем текст обрезанным(добавляем ... если не вмещаеться)
     * */
    if (!isOpen) {
      setStyleTextOverflow(true);
    }
  }, [isOpen, setStyleTextOverflow]);

  const handleExpandLess = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleExpandMore = useCallback(() => {
    setStyleTextOverflow(false);
    setIsOpen(true);
  }, [setStyleTextOverflow]);

  const collapseTitleRender = useMemo(() => {
    if (isOpen) {
      return null;
    }

    if (collapsedTitle === undefined) {
      return <HintCollapseTitle>{children}</HintCollapseTitle>;
    }

    return collapsedTitle;
  }, [isOpen, collapsedTitle]);

  return (
    <ExpandingHintContainer>
      <HintWrapper
        $open={isOpen}
        onTransitionEnd={onTransitionEnd}
      >
        <Typography
          ref={ref}
          variant={'body2'}
          component={'div'}
        >
          {collapseTitleRender}
          {isOpen && (
            <HintContentWrapper>
              {expandedTitle && <Typography variant={'body2'}>{expandedTitle}</Typography>}
              <Typography variant={'body2'}>{children}</Typography>
            </HintContentWrapper>
          )}
        </Typography>
      </HintWrapper>

      {(IconLess || IconMore) && (
        <HintIcons>
          {isOpen && IconLess ? (
            <IconLess
              color={'disabled'}
              onClick={handleExpandLess}
            />
          ) : (
            <>
              {IconMore && (
                <IconMore
                  color={'disabled'}
                  onClick={handleExpandMore}
                />
              )}
            </>
          )}
        </HintIcons>
      )}
    </ExpandingHintContainer>
  );
};

/**
 * Подкомпонент ссылки
 * @constructor
 * @param props
 */
ContentLimiterHintLayout.Link = (props: LinkProps) => <HintLink {...props} />;

export default ContentLimiterHintLayout;
