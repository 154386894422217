import { MpCustomer } from '@/domain';
import customerService from '@features/customer/services';
import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { AppThunkAPIConfig } from 'data/store/store';
import { Fetchable, fetchableDefault } from 'data/store/types';
import ErrorHandler from '../../../../../data/network/errorHandler';

export const customerDetailsByIdFetch = createAsyncThunk<MpCustomer, UUID, AppThunkAPIConfig>(
  'customer/details/byId/fetch',
  async (id, { rejectWithValue, signal }) => {
    try {
      return await customerService.common.one({ id, signal });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);

      return rejectWithValue(e.response.data);
    }
  }
);

export interface CustomerDetailsState {
  readonly byId: Fetchable & {
    readonly user: Nullable<MpCustomer>;
  };
}

type Reducer<T = undefined> = CaseReducer<CustomerDetailsState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<CustomerDetailsState> {
  customerDetailsStateReset: Reducer;
}

const slice = createSlice<CustomerDetailsState, Reducers, 'details'>({
  name: 'details',
  initialState: {
    byId: {
      ...fetchableDefault,
      user: null,
    },
  },
  reducers: {
    customerDetailsStateReset: state => {
      state.byId = {
        ...fetchableDefault,
        user: null,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(customerDetailsByIdFetch.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
        state.byId.user = null;
      })
      .addCase(customerDetailsByIdFetch.fulfilled, (state, { payload }) => {
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;

        state.byId.user = payload;
      })
      .addCase(customerDetailsByIdFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;

        if (!aborted) {
          state.byId.isFetching = false;
          state.byId.isFetched = false;
          state.byId.isFailed = true;
        }

        state.byId.user = null;
      });
  },
});

export const { customerDetailsStateReset } = slice.actions;

export default slice.reducer;
