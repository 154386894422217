import { SportOption } from '../../../domain/model';
import { Address } from '../../../domain/model/address';
import { ActivityType } from '../../../domain/model/event';
import { Nullable } from '../../../domain/model/types';

export enum ECollectionSearchDialogTag {
  SportsmanMultiple = 'sportsman_multiple',
  SportsmanSingle = 'sportsman_single',
}

export interface SportsmansFilter {
  readonly activityTypes: Nullable<ActivityType[]>;
  readonly roads: Nullable<SportOption[]>;
  readonly locality: Nullable<Address>;
  readonly gender: Nullable<SportOption>;
  readonly ageMin: Nullable<number>;
  readonly ageMax: Nullable<number>;
  readonly teamId: Nullable<UUID>;
}

export interface BonusInfo {
  readonly count: Nullable<number>;
  readonly isFetching: boolean;
}
